<template>
    <div class="flex-auto overflow-x-auto px-4 lg:px-10 py-4 pt-0">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-20/12">
            <div class="px-4 mt-0 border shadow-md">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Product Information
            </h6>
            <div class="">
              <div class="flex">
                <div class="w-4/12">
                  <div class="relative mb-3 mt-2">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Provider:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedProvider">
                      <option disabled selected>Select a provider</option>
                      <option v-for="provider in filteredProviders" :key="provider.supplier_id" :value="provider.supplier_id"
                      >{{ provider.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedService">
                      <option disabled selected>Select a service</option>
                      <option v-for="service in services" :key="service.vendorserviceId" :value="service.vendorserviceId">{{ service.serviceName}}</option>
                    </select>
                  </div>
                </div>
              </div>              

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service Type:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedServiceType">
                      <option selected>Internet</option>
                      <option>Layer2</option>
                    </select>
                  </div>
                </div>
              </div>         

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Feasibility Rule:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedMapRule">
                      <option disabled selected>Select a rule</option>
                      <option v-for="mapRule in mapRules" :key="mapRule.maprule_id" :value="mapRule.maprule_id">{{ getDescriptionAfterAsterisk(mapRule.description) }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Product Name:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="text"
                    required
                    placeholder="Bronze Starter *" 
                    v-model="productName"/>
                  </div>
                </div>
              </div>                 

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Cost Code:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="text"
                    required
                    placeholder="LQ-L2-12-10 *" 
                    v-model="costCode"/>
                  </div>
                </div>
              </div>


            <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Capped:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2 mr-2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="checkboxcapped" @click="toggleCheckboxcapped"
                     v-model="checkboxcapped"
                     />
                  </div>
                </div>
            </div> 


            <div v-if="!checkboxcapped">
            <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Synchronous</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/12 mr-2">
                  <label class="switch">
                    <input type="checkbox" v-model="checkbox" @click="toggleCheckbox">
                    <div class="slider round"></div>
                  </label>
                </div>
                <div class="w-4/12 mt-2 ml-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Asynchronous:</label>
                    </div>
                  </div>
                </div>
            </div>

              <div v-if="!checkbox" class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Download:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacity"/>
                  </div>
                  Mbps
                </div>
              </div> 

              <div v-else>
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Download:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2 mb-2 border">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacity"/>
                  </div>
                  Mbps
                </div>
              </div> 
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Upload:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacityupload"/>
                  </div>
                  Mbps
                </div>
              </div> 
            </div>
          </div> 

          <div v-else>
            <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Data:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="600 GB" 
                    v-model="capped"/>
                  </div>
                  GB
                </div>
              </div> 
          </div>
          
          <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-0">
                    <div class="text-xs whitespace-nowrap mb-0">
                      <label>FUP:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2 mr-2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="checkboxfup" @click="toggleCheckboxfup"
                     v-model="checkboxfup"
                     />
                  </div>
                </div>
            </div> 

            <div v-if="checkboxfup && !checkboxcapped">
            <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Data:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2 mt-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="600 GB" 
                    v-model="fup"/>
                  </div>
                  GB
                </div>
              </div> 
          </div>

          
              <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Contract Term:</label>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mt-2 pt-2 pb-2" type="number"
                    required
                    min="1"
                    placeholder="12 Months"
                    v-model="contractTermNumber"/>
                  </div>
                  <div class="block">
                    <div class="block mt-4">
                     <span class="text-xs mt-2 pt-2 pb-2 font-semibold">Months</span>
                    </div>

                </div>
                </div>
              </div> 

            </div>  
            </div>
          </div>

          <div class="w-full lg:w-3/12">
            <div class="border px-4 mt-0 ml-4 shadow-md">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Pricing
            </h6>
            <div class="flex">
              <div class="w-4/12 mt-2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap mb-2">
                    <label for="selectedCurrency">Currency:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div v-if="selectedProviderCurrency" class="block">
                  <input
                    id="selectedCurrency"
                    class="placeholder-blueGray-300 font-bold bg-white text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                    v-model="selectedCurrency"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="flex">
                <div class="w-4/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>VAT Inclusive:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="vatInclusive"
                     v-model="computedVatInclusive"
                     />
                  </div>
                </div>
              </div> 

              <div class="flex">
                <div class="w-4/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>VAT Exclusive:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="vatExclusive"
                    v-model="computedVatExclusive"
                     />
                  </div>
                </div>
              </div> 
                           
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-2">
                    <div class="text-xs whitespace-nowrap">
                      <label>VAT Percentage:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-sm font-semibold items-center">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2 disabled-percentage" type="number" 
                    placeholder="0.00"
                    v-model="vatPercentage" 
                    disabled
                    min="0" 
                    step="0.01"/>
                  </div>
                  %
                </div>
              </div>               


              <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Monthly:</label>
                    </div>
                  </div>
                </div>
                <div class="flex mt-2 text-sm font-semibold items-center">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="number" 
                    :placeholder="getSymbol(selectedCurrency) + ' 1500'"
                    v-model="sellingPrice" 
                    min="0" 
                    step="0.01" />
                  </div>
                  {{ getSymbol(selectedCurrency) }}
                </div>
              </div>  
 

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Once-Off:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-sm font-semibold items-center">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="number" 
                    :placeholder="getSymbol(selectedCurrency) + ' 2500'"
                    v-model="onceoffPrice" 
                    min="0" 
                    step="0.01" />
                  </div>
                  {{ getSymbol(selectedCurrency) }}
                </div>
              </div>
              
              <div class="mt-4 mb-2 flex">
                <div class="w-4/12">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Promotion Item:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" v-model="isPromotion" />
                  </div>
                </div>
              </div>

              
              <div class="mt-4 mb-2 flex">
                <div class="w-4/12">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Active Status:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" v-model="isActive" />
                  </div>
                </div>
              </div>

            </div>

          <div
          @click="saveProduct"
              :class="[
                'px-4 ml-4 py-4 text-sxs uppercase whitespace-nowrap text-left text-white rounded-b border shadow-md',
                {
                  'bg-emerald-600 cursor-pointer text-white': isFormValid,
                  'opacity-30 cursor-not-allowed text-blueGray-300': !isFormValid,
                  'font-semibold' : isFormValid,
                },
              ]"
              :disabled="!isFormValid"
              :style="{ backgroundColor: isFormValid ? '' : '#f1f3f6' }"
              ref="saveButton"
            >
                      <i class="fas fa-save mr-1"></i>
                      Save Product
          </div>

          </div>          



          <div class="w-full lg:w-20/12">
            <div class="border px-4 mt-0 ml-4 shadow-md">
              <div class="flex items-center justify-between">
                <div>
                  <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
                    Features (Included)
                  </h6>
                </div>
              </div>              

              <div v-if="editMode" class="flex">
                <div class="w-3/12 mt-4">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Feature:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2">
                  <div class="block">
                    <select
                      class="placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap ml-2 mb-2 pt-2 pb-2"
                      v-model="newFeature"
                    >
                      <option disabled value="">Select a feature</option>
                      <option v-for="feature in featureData" :value="feature.name" :key="feature.name">{{ feature.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="w-1/2 ml-5 mt-3">
                  <div class="block">
                    <button
                      v-if="editMode"
                      @click="saveChanges"
                      class="px-2 py-1 text-xs bg-emerald-600 text-white rounded"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
       

            <div class="grid grid-cols-2 gap-4 mt-4 mb-2">
            <div class="flex" v-for="(feature, index) in features" :key="index">
                <div class="w-6/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs capitalize whitespace-nowrap mb-2">
                      <span v-if="removeMode"
                        class="mr-2 text-red-500 cursor-pointer"
                        @click="deleteFeature(index)"
                      >
                      <i class="fas fa-times-circle"></i>
                      </span>
                      <span>{{ feature.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-1/12 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 ml-4" type="checkbox"
                    :disabled="editMode || removeMode"
                    :id="'feature_' + feature.id"
                    v-model="selectedFeatures"
                    :value="feature.id"
                    :style="{ cursor: (editMode || removeMode) ? 'not-allowed' : 'pointer', opacity: (editMode || removeMode) ? '0.2' : '1' }"
                    />
                  </div>
                </div>
              </div> 
            </div>


            </div>
          </div>  
          
        </div>

      </div> 



        
</template>
<script>
import pricing from "@/assets/img/pricing.png"
import axios from 'axios'
import {toast} from 'bulma-toast'
import { getCurrencyList  } from '@/components/currency'

export default {
  
  components: {
  },
  
  data() {
    return {
      editMode: false,
      removeMode: false,
      errors: [],
      pricing,
      checkbox: false, 
      checkboxcapped: false,
      checkboxfup: false,
      productName: '',
      vendor: [],
      selectedProvider: 'Select a provider',
      selectedProviderData: [],
      selectedServiceType: 'Internet',
      selectedUserProviders: [],
      selectedUserCurrency: [],
      selectedService: 'Select a service',
      selectedMapRule: 'Select a rule',
      costCode: '',
      suppliercostCode: '',
      capacity: '',
      capacityupload: '',
      capped: '',
      fup: '',
      contractTerm: 'months',
      contractTermNumber: '',
      isActive: false,
      isPromotion: false,
      selectedCurrency: '',
      currencyList: [],
      vatInclusive: false,
      vatExclusive: true,
      vatPercentage: '',
      sellingPrice: null,
      onceoffPrice: null,
      installationCost: '',
      providers: [],
      products: [],
      services: [],
      mapRules: [],
      mapRulesCopy: [],
      features: [],
      featureData: [],
      updatedFeatures: [],
      newFeature: '',
      selectedOptionIndex: -1,
      priorityOptions: [],
      selectedFeatures: [],
      selectedProductID: '',
    };
  },
  emits: ['update-add'],
  async created() {
    this.currencyList = await getCurrencyList()
    },
  async mounted() {

    this.getVendors()
    this.checkUserPreferencesVendor()
    this.UserPreferences()
    await this.getFeatureData()
    await this.getFeatures() 
    if (this.features && this.features.length > 0) { 
      const newFeatures = this.featureData.filter(feature => !this.features.some(existingFeature => existingFeature.name === feature.name));
      if (newFeatures.length > 0) {
        await this.saveFeatures(newFeatures); 
      }
      
      const featuresToDelete = this.features.filter(existingFeature => !this.featureData.some(feature => feature.name === existingFeature.name));
      if (featuresToDelete.length > 0) {
        this.deleteFeature(featuresToDelete)
      }

    } else {
        await this.saveFeatures(this.featureData);
    }
    

  },

  
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },

  watch: {
    isFormValid(newIsFormValid) {
      if (newIsFormValid) {
        this.$refs.saveButton.classList.add('lead-itemprovider');
      } else {
        this.$refs.saveButton.classList.remove('lead-itemprovider');
      }
    },

    selectedProvider(newSelectedProvider) {
      this.fetchServicesAndMapRules(newSelectedProvider)
    },
    selectedServiceType(newSelectedProvider) {
    },
    selectedService() {

      this.filterMapRules();
    },    
    vatExclusive(newValue) {
      if (newValue) {
        this.vatInclusive = false;
        this.vatPercentage = this.selectedProviderData.vat_rate;
      }
    },
    vatInclusive(newValue) {
      if (newValue) {
        this.vatExclusive = false
        this.vatPercentage = ''
      }
    }

  },
  

  
  computed: {
    isFormValid() {
      return (
       this.selectedProvider !== 'Select a provider' && 
       this.selectedProvider &&
       this.selectedService !== 'Select a service' &&
       this.selectedService &&
       this.selectedServiceType &&
       this.selectedMapRule !== 'Select a rule' &&
       this.selectedMapRule &&
       this.productName &&
      this.costCode &&
      (this.checkboxcapped ?
        (typeof this.capped === 'number' && this.capped >= 1) : 
        (this.checkbox ? 
          (typeof this.capacity === 'number' && this.capacity >= 1 && typeof this.capacityupload === 'number' && this.capacityupload >= 1) : 
          (typeof this.capacity === 'number' && this.capacity >= 1)
        )
      ) &&
      (this.checkboxfup ? 
        (typeof this.fup === 'number' && this.fup >= 1) : 
        true 
      ) &&
       (!isNaN(this.contractTermNumber) && parseFloat(this.contractTermNumber) >= 0) &&
       (this.vatExclusive ? this.vatPercentage !== '' : true) &&
       (!isNaN(this.sellingPrice) && parseFloat(this.sellingPrice) >= 0)  &&
       (!isNaN(this.onceoffPrice) && parseFloat(this.onceoffPrice) >= 0) 
      )
    },  

    selectedProviderCurrency() {
      const isProviderSelected = this.selectedUserProviders.includes(this.selectedProvider);

      if (isProviderSelected) {
        this.selectedProviderData = this.selectedUserCurrency.find(provider => provider.supplier_id === this.selectedProvider);
        
        if (this.selectedProviderData && this.selectedProviderData.currency) {
          this.selectedCurrency = this.selectedProviderData.currency;
          this.vatPercentage = this.selectedProviderData.vat_rate;
          return this.selectedProviderData.currency;
        }
      }

      this.selectedCurrency = null;
      return null;
    },

    filteredProviders() {
      if (this.selectedProvider !== 'Select a provider' && this.selectedProvider.length > 0) {
        
      return this.providers
        .filter(provider => provider.supplier_id === this.selectedProvider)
        .sort((a, b) => a.name.localeCompare(b.name));
      
    } else if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
      return this.providers
        .filter(provider => this.selectedUserProviders.includes(provider.supplier_id))
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  },
    formattedCapacity() {
      return `${this.capacity} Mbps`;
    },
    computedVatInclusive: {
      get() {
        return this.vatInclusive;
      },
      set(value) {
        if (!value) {
          this.vatExclusive = true;
        }
        this.vatInclusive = value;
      },
    },
    computedVatExclusive: {
      get() {
        return this.vatExclusive;
      },
      set(value) {
        if (!value) {
          this.vatInclusive = true;
        }
        this.vatExclusive = value;
      },
    },
  },

    methods: {
      toggleEditState() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.removeMode = false
      } else {
        this.newFeature = '';
      }
    },
    toggleRemoveState(){
      this.removeMode = !this.removeMode;

      if (this.removeMode) {
        this.editMode = false
      } else {
        
      }
    },
    deleteFeature(featuresToDelete) {
      featuresToDelete.forEach(existingFeature => {
        const index = this.features.findIndex(feature => feature.name === existingFeature.name);
        if (index !== -1) {
          this.features.splice(index, 1);
        }
      });
      this.updatedFeatures = this.features.map(feature => feature.id)
      this.updateFeatures()
    },
    getSymbol(currency) {
      const currencyObject = this.currencyList.find(item => item.code === currency);
      return currencyObject ? currencyObject.symbol : '';
    },
      toggleCheckbox() {
      this.checkbox = !this.checkbox
      this.capacityupload = ''
      this.capacity = ''
      },      

      toggleCheckboxcapped() {
        this.checkboxcapped = !this.checkboxcapped
        this.capped = ''
        this.capacityupload = ''
        this.capacity = ''
        if (this.checkboxcapped) {
          this.checkboxfup = false;
        }
      },

      toggleCheckboxfup() {
        this.checkboxfup = !this.checkboxfup
        this.fup = ''
        this.capped = ''
        if (this.checkboxfup) {
          this.checkboxcapped = false;
        }
      },

      selectOption(index) {
          this.selectedOptionIndex = this.selectedOptionIndex === index ? -1 : index;
        },

      handleVatInclusiveToggle() {
        if (this.computedVatInclusive) {
          this.computedVatExclusive = false;
        }
      },

      handleVatExclusiveToggle() {
        if (this.computedVatExclusive) {
          this.computedVatInclusive = false;
        }
      },
      getDescriptionAfterAsterisk(description) {
        const parts = description.split('*');
        return parts.length > 1 ? parts[1] : description;
      },      

      filterMapRules() {
        if (!this.selectedService) {
          this.mapRules = [];
          return;
        }
        this.mapRules = this.vendor.maprules.filter(rule => rule.service_id === this.selectedService)
        this.mapRulesCopy = [...this.mapRules]
      },      

      async fetchServicesAndMapRules(vendorId) {
        this.$store.commit('setIsLoading', true)

        this.services = []
        try {
          const [serviceResponse, mapRuleResponse] = await Promise.all([
            axios.get(`/vendor/vendors/${vendorId}/services/`),
            axios.get(`/vendor/vendors/${vendorId}/maprules/`),
          ]);

            this.vendor.services = serviceResponse.data;
            this.vendor.maprules = mapRuleResponse.data;
            this.checkUserPreferences(vendorId);
            if (this.vendor.maprules.length > 0) {
              this.filterMapRules()
            }

          } catch (error) {
            console.error(`Error fetching services and map rules for vendor ${vendorId}:`, error);
          }

          this.$store.commit('setIsLoading', false)
        },  

        getVendors() {
          this.$store.commit('setIsLoading', true)
          axios.get('/vendor/vendors')
            .then(response => {
              this.providers = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

        async getFeatureData() {
          this.$store.commit('setIsLoading', true)
          await axios.get('/products/featuredata/')
            .then(response => {
              this.featureData = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },


        async getFeatures() {
          this.$store.commit('setIsLoading', true)
          await axios.get('/products/features/')
            .then(response => {
              this.features = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

        async saveProduct() {
          this.$store.commit('setIsLoading', true)
          const selectedVendor = this.providers.find(product => product.supplier_id === this.selectedProvider)
          let selectedCapacity;
          if (this.capacityupload > 0) {
              selectedCapacity = this.capacityupload;
          } else {
              selectedCapacity = this.capacity;
          }


          const formData = {
                  supplier_id: this.selectedProvider,
                  service_type: this.selectedServiceType,
                  name: this.productName,
                  cost_code: this.costCode,
                  capacity: this.capacity ? this.capacity : 0,
                  capacityupload: selectedCapacity ? selectedCapacity : 0,
                  capped: this.capped ? this.capped : 0, 
                  fup: this.fup ? this.fup : 0, 
                  term: this.contractTermNumber,
                  currency: this.selectedCurrency,
                  inclusive: this.vatInclusive,
                  exclusive: this.vatExclusive,
                  percentage: this.vatPercentage,
                  mrc: this.sellingPrice,
                  nrc: this.onceoffPrice,
                  is_active: this.isActive,
                  promotion_id: this.isPromotion,
                  business: this.$store.state.business.id,
                  maprule: this.selectedMapRule,
                  service: this.selectedService,
                  vendor: selectedVendor.id,
                  features: this.selectedFeatures,
                }
          await axios
              .post('/products/product/', formData)
              .then(response => {
                  toast({
                    message: 'Product added',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })

                  const isAddExpanded = false
                  this.$emit('update-add', isAddExpanded)
   
              })
              .catch(error => {
                if(error.response) {
                  if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.business[0]
                    const capitalizeMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
                    toast({
                     message: capitalizeMessage,
                     type: 'is-danger',
                     dismissible: true,
                     pauseOnHover: true,
                     duration: 5000,
                     position: 'bottom-right',
                    })
                  }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
              })

          this.$store.commit('setIsLoading', false)
        },

        async saveFeatures(features) {
          try {
            await Promise.all(features.map(async feature => {
              const formData = {
                name: feature.name,
                is_active: true,
                business: this.$store.state.business.id,
              };
              await axios.post('/products/features/', formData);
            }));
            toast({
              message: 'New features added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });

            this.getFeatures()

          } catch (error) {
            console.error('Error saving features:', error);
            toast({
              message: 'Error saving new features',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });
          }
        },

        async saveChanges() {
          this.$store.commit('setIsLoading', true)

          const formData = {
            name: this.newFeature,
            is_active: true,
            business: this.$store.state.business.id,
          }

          await axios
              .post('/products/features/', formData)
              .then(response => {
                  toast({
                    message: 'Feature added',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
                  this.editMode = false
                  this.newFeature = ''
                  this.getFeatures()
              })
              .catch(error => {
                if(error.response) {
                  for (const property in error.response.data) {
                    this.errors.push(`${property}: ${error.response.data[property]}`)
                  }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
              })

          this.$store.commit('setIsLoading', false)

        },

        async updateFeatures() {
          this.$store.commit('setIsLoading', true)

          const formData = {
            features: this.updatedFeatures
          }


          await axios
              .post('/products/update_features/', formData)
              .then(response => {
                  toast({
                    message: 'Feature removed',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
                  
                  this.getFeatures()

              })
              .catch(error => {
                if(error.response) {
                  for (const property in error.response.data) {
                    this.errors.push(`${property}: ${error.response.data[property]}`)
                  }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
              })

          this.$store.commit('setIsLoading', false)
        },


        async UserPreferences() {
              try {
                const response = await axios.get('/coverage/userglobalcoverages/');
                this.userPreferences = response.data;
              } catch (error) {
                console.error('Error fetching user preferences', error);
              }
            },

        checkUserPreferencesVendor() {
          this.$store.commit('setIsLoading', true)
          axios
            .get('/mapche-api/v1/user-preferences/')
            .then((response) => {
              this.selectedUserProviders = []
              this.selectedUserCurrency = response.data
              this.selectedUserProviders = response.data.map(item => item.supplier_id)
             })
            .catch((error) => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

      checkUserPreferences(vendorId) {
        axios
          .get('/mapche-api/v1/user-preferences/')
          .then((response) => {
            this.selectedVendors = response.data.map(item => item.supplier_id);
            this.selectedVendors.forEach(supplier_id => {
              if (supplier_id === vendorId) {
                  response.data.forEach(item => {
                    const userServices = item.services;
                    const uservendor_id = item.supplier_id;
                    userServices.forEach(service_id => {
                      if (uservendor_id === vendorId) {
                        this.vendor.services.forEach(service => {
                          if (service.service_id === service_id) {
                            const serviceName = service.name
                            const vendorserviceId = service.service_id 
                            this.services.push({ vendorserviceId, serviceName });
                            
                          }

                        })
                      }
                    })
                  })

              }
            })
          })
          .catch((error) => {
            console.error(error);
          });
        },
    }
};
</script>
<style scoped>
  .lead-itemprovider {
  padding: 10px;
    cursor: pointer;
}

.lead-itemprovider:hover {

   background-color: #f1f3f6;
   color: rgba(5, 150, 105, 1)
}


  .opacity-50 {
    opacity: 0.5;
    cursor: not-allowed;
  }  
  .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(5,150,105,1); 
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: rgb(255, 0, 89);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #e2e8f0;
  }

  .disabled-percentage {
    background-color: rgba(241, 245, 249, 1);
    cursor: not-allowed;
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center center;
  }

</style>