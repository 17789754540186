<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <i class="fas fa-bars"></i>
      </button>
        <router-link
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-0 px-0"
        to="/admin/dashboard"
      >
      {{ businessName? businessName : 'MapChi' }}
      </router-link>
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/"
              >

              {{ $store.state.business.name ? $store.state.business.name : 'MapChi' }}
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              id="search"
              placeholder="Search"
              class="px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              to="/admin/dashboard"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tv mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Dashboard
              </a>
            </router-link>
          </li>
        </ul>







          <hr class="my-4 md:min-w-full" />

        <div class="flex justify-between items-center">

        <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-0 pb-0 no-underline">
          Connectivity
          <button
            class="ml-2 cursor-pointer text-black text-sm focus:outline-none"
            type="button"
            v-on:click="toggleConnect()"
            :class="[
              isDropConnect ? 'text-emerald-500 hover:text-emerald-600' : 'text-blueGray-700 hover:text-blueGray-500',
              isDropConnect ? 'bg-emerald-100' : '' 
            ]"
          >
            <i class="fas fa-chevron-circle-down"
              :class="[isDropConnect ? 'opacity-75' : 'text-blueGray-500 hover:text-emerald-600']"
            ></i>
          </button>
        </h6>
        </div>

        <ul v-show="showConnect" class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
            <router-link
              to="/admin/search"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-search-location mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Searches
              </a>
            </router-link>
          </li>  

          <li class="items-center">
            <router-link
              to="/admin/networks"
              v-if="(hasBusiness && hasMobile && !isResellerinvited)"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-signal mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Networks
              </a>
            </router-link>
          </li>                  
          <li class="items-center">
            <router-link
              to="/admin/networks"
              v-if="isPlanNetworks && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserProduct)"

              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-signal mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Networks
              </a>
            </router-link>
          </li>       
        
          </ul> 
<div v-if="hasBusiness && hasMobile">
        <hr class="my-4 md:min-w-full" />
        <h6 
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-0 pb-0 no-underline"
        >
          Company
        </h6>        

     <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">

          <li class="items-center">
            <router-link
              to="/admin/leads"
              v-if="hasBusiness && hasMobile"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-id-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Leads
              </a>
            </router-link>
          </li>     
          <li class="items-center">
            <router-link
              to="/admin/leads"
              v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-id-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Leads
              </a>
            </router-link>
          </li>     


      <li class="items-center">
        <router-link
              to="/admin/leads"
              v-if="isComing && (hasBusiness && hasMobile)"
              v-slot="{ href, navigate, isActive }"
            >
          
              <a
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
              <span class="text-xs font-bold capitalize py-2 rounded block leading-normal relative opacity-50">              
                <i
                  class="fas fa-user-friends mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Customer 
                <span class="absolute top-0 left-0 transform -translate-x-1 translate-y-1 text-xxs bg-blueGray-100 uppercase text-blueGrey px-3 py-0 rounded-full opacity-75">
                 Coming Soon...
                </span>
              </span>
              </a>
            </router-link>
          </li>
          



          <li class="items-center">
            <router-link
              to="/admin/resellers"
              v-if="hasBusiness && hasMobile && !isResellerinvited"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-landmark mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Resellers
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/admin/resellers"
              v-if="isPlanResellers && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales)"              
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-landmark mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Resellers
              </a>
            </router-link>
          </li>
        </ul>
      </div>                 
<div v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales || isCurrentUserSalesManager" >
        <hr class="my-4 md:min-w-full" />
        <div class="flex justify-between items-center">

        <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-0 pb-0 no-underline">
          Company
          <button
            class="ml-2 cursor-pointer text-black text-sm focus:outline-none"
            type="button"
            v-on:click="toggleCompany()"
            :class="[
              isDropCompany ? 'text-emerald-500 hover:text-emerald-600' : 'text-blueGray-700 hover:text-blueGray-500',
              isDropCompany ? 'bg-emerald-100' : '' 
            ]"
          >
            <i class="fas fa-chevron-circle-down"
              :class="[isDropCompany ? 'opacity-75' : 'text-blueGray-500 hover:text-emerald-600']"
            ></i>
          </button>
        </h6>
        </div>
  

     <ul v-show="showCompany" class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="items-center">
            <router-link
              to="/admin/leads"
              v-if="hasBusiness && hasMobile"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-id-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Leads
              </a>
            </router-link>
          </li>     
          <li class="items-center">
            <router-link
              to="/admin/leads"
              v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales || isCurrentUserSalesManager"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-id-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Leads
              </a>
            </router-link>
          </li>     



      <li v-if="isComing && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales || isCurrentUserSalesManager)" class="items-center">
              <a
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
              <span class="text-xs font-bold capitalize py-2 rounded block leading-normal relative opacity-50">              
                <i
                  class="fas fa-user-friends mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Customer 
                <span class="absolute top-0 left-0 transform -translate-x-1 translate-y-1 text-xxs bg-blueGray-100 uppercase text-blueGrey px-3 py-0 rounded-full opacity-75">
                 Coming Soon...
                </span>
              </span>
              </a>
          </li>
          
          <li class="items-center">
            <router-link
              to="/admin/resellers"
              v-if="hasBusiness && hasMobile && !isResellerinvited"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-landmark mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Resellers
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/admin/resellers"
              v-if="isPlanResellers && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales || isCurrentUserSalesManager)"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-landmark mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Resellers
              </a>
            </router-link>
          </li>

        </ul>
      </div>

        <hr class="my-4 md:min-w-full" />

        <div class="flex justify-between items-center">

        <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-0 pb-0 no-underline">
          Administration
          <button
            class="ml-2 cursor-pointer text-black text-sm focus:outline-none"
            type="button"
            v-on:click="toggleAdmin()"
            :class="[
              isDropAdmin ? 'text-emerald-500 hover:text-emerald-600' : 'text-blueGray-700 hover:text-blueGray-500',
              isDropAdmin ? 'bg-emerald-100' : '' 
            ]"
          >
            <i class="fas fa-chevron-circle-down"
              :class="[isDropAdmin ? 'opacity-75' : 'text-blueGray-500 hover:text-emerald-600']"
            ></i>
          </button>
        </h6>
        </div>


        <ul v-show="showAdmin" class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
              to="/admin/my-account"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-wallet mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Account
              </a>
            </router-link>
          </li>


          <li class="items-center">
            <router-link
              to="/admin/settings"
              v-if="hasBusiness && hasMobile"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-cog mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Settings
              </a>
            </router-link>
          </li>
          <li class="items-center">
            <router-link
              to="/admin/settings"
              v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserProduct || isCurrentUserMarketing"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-cog mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Settings
              </a>
            </router-link>
          </li>




          <li class="items-center">
            <router-link
              to="/admin/users"
              v-if="hasBusiness && hasMobile"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-user-cog mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Manage Users
              </a>
            </router-link>
          </li> 

          <li class="items-center">
            <router-link
              to="/admin/users"
              v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSalesManager"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-user-cog mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Manage Users
              </a>
            </router-link>
          </li> 




<li class="items-center">
            <router-link
              to="/admin/reselleruser"
              v-if="hasBusiness && hasMobile && !isResellerinvited"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-users-cog mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Manage Reseller
              </a>
            </router-link>
          </li> 

          <li class="items-center">
            <router-link
              to="/admin/reselleruser"
              v-if="isPlanResellers && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSales || isCurrentUserSalesManager)"
              
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-users-cog mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Manage Reseller
              </a>
            </router-link>
          </li> 



          <li class="items-center">
            <router-link
              to="/admin/pricing"
              v-if="hasBusiness && hasMobile && !isResellerinvited"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tags mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Price plans
              </a>
            </router-link>
          </li>                    
          <li class="items-center">
            <router-link
              to="/admin/pricing"
              v-if="!usePindropPricing && isPlanNetworks && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserProduct)"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-tags mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Price plans
              </a>
            </router-link>
          </li>             




<li class="items-center">
            <router-link
              to="/admin/theme"
              v-if="hasBusiness && hasMobile"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-paint-brush mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Theme
              </a>
            </router-link>
          </li>                    
          <li class="items-center">
            <router-link
              to="/admin/theme"
              v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserMarketing"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-paint-brush mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Theme
              </a>
            </router-link>
          </li>     





          <li class="items-center">
            <router-link
              to="/subscribe/plans"
              v-if="!hasPlan && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserFinance)"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-credit-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Plans
              </a>
            </router-link>
          </li>          




          <li class="items-center">
            <router-link
              to="/admin/subscription"
              v-if="hasPlan && (isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserFinance)"
              v-slot="{ href, navigate, isActive }"
            >
              <a
                :href="href"
                @click="navigate"
                class="text-xs capitalize py-3 font-bold block"
                :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                  class="fas fa-credit-card mr-2 text-sm"
                  :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Subscription
              </a>
            </router-link>
          </li>

        </ul>


      </div>
    </div>
  </nav>
</template>


<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import axios from "axios";

export default {
  
  mounted() {
      this.getTeam()
    },

  data() {
    return {
      collapseShow: "hidden",
      showDropdown: false,
      isActive: false,
      isDrop: false,
      showAdmin: true,
      isDropAdmin: true,
      showCompany: true,
      isDropCompany: true,
      showConnect: true,
      isDropConnect: true,
      showCoverage: true,
      isDropCoverage: true,
      isComing: false,
      team: {
          members: [],
          created_by: {}
        },
    }
  },

  watch: {
  businessName(newName, oldName) {
      if (newName !== oldName) {
        this.getTeam()
      }
    }
  },
  computed: {
    isDemo() {
      const demo = this.$store.state.is_demo;
      return demo
  },
    usePindropPricing() {
      const pindropPricing = this.$store.state.is_innovator
      return pindropPricing
    },

    isCreatedByCurrentUser() {
      const teamCreatedBy = this.team.created_by
      const userId = this.$store.state.user.id
      return teamCreatedBy && teamCreatedBy.id === +userId;
    },

    hasPlan() {
      const plan = this.$store.getters.hasBusinessPlan
      return plan
    },

    hasBusiness() {
      const businessId = !this.$store.state.business.id
      return businessId
    },

    hasMobile() {
      const mobile = !this.$store.state.mobile.phone_number
      return mobile
    },
    
    businessName() {
      return this.$store.state.business.name;
    },
    isCurrentUserAdmin() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Admin")
   },
   isCurrentUserSales() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Sales")
   },   

   
   isCurrentUserSalesManager() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Manager")
   },   
   
   isCurrentUserFinance() {
    const userRoles = this.$store.state.user.roles 


    return userRoles.includes("Finance")
   },   
   isCurrentUserProduct() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Product")
   },  

   isCurrentUserMarketing() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Marketing")
   },   


   isResellerinvited() {
    const resellerinvited = this.$store.state.resellerinvited
    return resellerinvited
   },

   isPlanBroker() {
    const plan = this.$store.state.business.plan; 
    return !!plan
   },

   isPlanNetworks() {
    const plan = this.$store.state.business.plan; 
    const validPlans = ['Innovator', 'Trailblazer', 'Explorer']; 

    if(plan === 'Broker') {
        return false; 
    }

    return validPlans.includes(plan);
   }, 
   isPlanResellers() {
    const plan = this.$store.state.business.plan; 


    return plan === 'Innovator';
   },   
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.isDrop = !this.isDrop;
    },
    toggleAdmin(){
      this.showAdmin = !this.showAdmin;
      this.isDropAdmin = !this.isDropAdmin;
    },
    toggleCompany(){
      this.showCompany = !this.showCompany;
      this.isDropCompany = !this.isDropCompany;
    },
    toggleConnect(){
      this.showConnect = !this.showConnect;
      this.isDropConnect = !this.isDropConnect;
    },
    toggleCoverage(){
      this.showCoverage = !this.showCoverage;
      this.isDropCoverage = !this.isDropCoverage;
    },
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    async getTeam() {
        this.$store.commit('setIsLoading', true)
        await axios
            .get('/mapche-api/v1/business/get_my_business/')
            .then(response => {
              this.team = response.data
            })
            .catch(error => {
              console.log(error)
            })
        this.$store.commit('setIsLoading', false)
      }
  },
  components: {
    UserDropdown,
  },
};
</script>
