<template>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
  <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
      Google Information
    </h6>
    <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
            Google API Key
            </label>
            <input
              type="text"
              required
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder="000000000000000000000000000000000000000 *"
              maxlength="39"
              v-model="google_api_key"
              :disabled="!editGoogleAddress"
              ref="googleAddressInput"
            />
          </div>
        </div>

        
    </div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Google Maps API Key 
            </label>
            <textarea
              type="text"
              disabled
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="3"
              v-model="text"
            ></textarea>
          </div>
        </div>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
            Google Maps Api Key in 1 minute
            </label>
            <div ref="videoContainer"></div>
          </div>
        </div>

      </div>
    <hr class="mt-4 border-b-1 border-blueGray-300" />
    <div v-if="!isDemo" class="ml-4">
      <button v-if="userPk && showGoogleEditButton" class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click.prevent="enableEditGoogleAddress">Edit</button>
      <button v-if="userPk && showGoogleSaveButton" class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click.prevent="saveGoogleChanges">{{ saveGoogleButtonLabel }}</button>
      <button v-else-if="userPk && !google_api_key" class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click.prevent="saveGoogleChanges">Save</button>
    </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
mounted() {
  this.container()
  this.getUserProfile()
},
data() {
  return {
    google_api_key: '',
    editGoogleAddress: false,
    userPk: null,
    text: 'For efficient Google Maps integration, we have prepared a video tutorial outlining the process of obtaining a Google Maps API key swiftly and effortlessly. Ensure that the Maps JavaScript API and Places API are enabled for seamless MapChi functionality.'
  }
},

computed: {
  
  isDemo() {
    const demo = this.$store.state.is_demo;
    return demo
  },

  showGoogleEditButton() {
    return this.google_api_key && !this.editGoogleAddress
  },

  showGoogleSaveButton() {
    return this.google_api_key && this.editGoogleAddress
  },

  saveGoogleButtonLabel() {
    return this.editGoogleAddress ? 'Save' : 'Edit'
  }
  
},

methods: {
  container() {
    const script1 = document.createElement('script');
    script1.src = 'https://fast.wistia.com/embed/medias/s6a87os7iq.jsonp';
    script1.async = true;

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    const span = document.createElement('span');
    span.className = 'wistia_embed wistia_async_s6a87os7iq popover=true';
    span.style = 'display:inline-block;height:160px;position:relative;width:490px;'
    span.innerHTML = '&nbsp;';

    const videoContainer = this.$refs.videoContainer;
    videoContainer.appendChild(script1);
    videoContainer.appendChild(script2);
    videoContainer.appendChild(span);

  },
  async getUserProfile() {
    this.$store.commit('setIsLoading', true)

      axios.get('/mapche-api/v1/userprofile')
        .then(response => {
          if(response.data[0]) {
            if (!this.isDemo) {
              this.google_api_key = response.data[0].google_api_key
            }
            else {
                const placeholder = "demo-1234-4321-56768-987654321000"
                this.google_api_key = placeholder
            }
              if (localStorage.getItem('userid') !== localStorage.getItem('created')) {
                this.userPk = response.data[0].admin_id
              } else {
                this.userPk = response.data[0].id
              }


            if (this.google_api_key) {
            this.editGoogleAddress = false
            } else {
              this.editGoogleAddress = true
            }
          } else {
           this.editGoogleAddress = false
          }
        })
        .catch((error) => {
          toast({
            message: 'An error occurred. Please try again later.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          })
        })

    this.$store.commit('setIsLoading', false)    
  },

  enableEditGoogleAddress() {
    this.editGoogleAddress = true
    this.$nextTick(() => {
      this.$refs.googleAddressInput.focus()
    })
  },

  saveGoogleChanges() {

    if (this.userPk) {
      if (!this.validateGoogleAPIKey(this.google_api_key)) {
        toast({
                message: 'The GOOGLE API KEY is invalid',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
        return;
      }
      axios.patch(`/mapche-api/v1/userprofile/${this.userPk}/update_google_api_key/`, {
        google_api_key: this.google_api_key,
      })
      .then(response => {
        toast({
                message: 'Google Key, updated successfully',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
        this.editGoogleAddress = false
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.google_api_key) {
            const websiteErrorMessage = error.response.data.google_api_key[0];
            toast({
              message: websiteErrorMessage,
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
            this.google_api_key = ''
            this.getUserProfile()
          }
        } else {
          toast({
            message: 'An error occurred. Please try again later.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          })
          this.google_api_key = ''
          }
      })

    }
  },

  validateGoogleAPIKey(key) {
    const regex = /^[a-zA-Z0-9]{39}$/;
    return regex.test(key)
  },

}
}
</script>
