<template>
  
  <div class="flex-auto overflow-x-auto px-4 lg:px-10 py-4 pt-0">
    <div class="flex flex-wrap">
      <div class="w-full lg:flex lg:flex-grow">
        <div class="px-4 mt-0 border shadow-md">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-3 font-bold uppercase">
                User Information
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="email"
                    >
                      Email Address
                    </label>
                    <input
                      class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                      type="email"
                      required
                      placeholder="example@example.com"
                      id="email"
                      autocomplete="email"
                      v-model="username"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-1/2 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="role"
                    >
                      User Role
                    </label>
                    <select
                      class="w-full text-xs mb-2 pt-2 pb-6"
                      v-model="selectedRole"
                      multiple
                    >
                      <option disabled selected class="pb-2">Assign a role / mutiple roles</option>
                      <option
                        v-for="(role, index) in userRoles"
                        :key="index"
                        :value="role"
                      >
                        {{ role }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="flex align-middle px-4">
                  <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    @click="sendUserInvite()"
                  >
                    Add
                  </button>
                  <div class="flex error-sign-up" v-if="errors.length">
                    <span class="scale-75 material-symbols-outlined mr-1">error</span>
                    <p>{{ errors[0] }}</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

  export default {
    name: 'CardUserAdd',
    data() {
    return {
      username: '',
      selectedRole: [],
      userRoles: [],
      errors: []
    }
  },
  emits: ['update-add'],
  mounted() {
    this.getRoles()
  },

  computed: {
  isBrokerGoogle() {
    const plan = this.$store.state.business.plan; 
    const validPlans = ['Innovator', 'Trailblazer', 'Explorer']; 

    if (plan === 'Broker' || this.$store.state.reseller.id) {
        return false;
    }


    return validPlans.includes(plan);
  }
},

    methods: {
      async getRoles() {
        await axios
          .get('/mapche-api/v1/business/get_user_roles/')
          .then(roles => {
            this.userRoles = !this.isBrokerGoogle
              ? roles.data.user_roles.filter(role => role !== 'Product')
              : roles.data.user_roles;
            
            if (this.$store.state.user.roles.includes('Manager')) {
              const isAdminAssigned = this.$store.state.user.roles.includes('Admin');
              const isFinanceAssigned = this.$store.state.user.roles.includes('Finance');

              if (isAdminAssigned) {
              } else {
                this.userRoles = this.userRoles.filter(role => role !== 'Admin');
              }

              if (isFinanceAssigned) {
              } else {
                this.userRoles = this.userRoles.filter(role => role !== 'Finance');
              }
            }
            
          })
          .catch(error => {
            console.log(error);
          });
      },

      async sendUserInvite() {
        this.errors = []

        if (this.username === '') {
          this.errors.push('The Email is missing.')
        } else if (!/\S+@\S+\.\S+/.test(this.username)) {
          this.errors.push('Please enter a valid email address.')
        }

        if (!this.selectedRole.length) {
          this.errors.push('Select a role for the user')
        }

        if (!this.errors.length) {
          this.$store.commit('setIsLoading', true)
        
          const formData = {
            email: this.username,
            roles: this.selectedRole,
          }

          await axios
             .post('/mapche-api/v1/business/invite/', formData)  
             .then(response => {
                  toast({
                    message: 'The user was invited, an invite with instructions has been sent.',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
          
                  const isAddExpanded = false
                  this.$emit('update-add', isAddExpanded)

              })
              .catch(error => {
                if (error.response && error.response.status === 400) {
                  this.errors = [];

                  for (const property in error.response.data) {
                    const errorMessage = `${error.response.data[property]}`;

                    toast({
                      message: errorMessage,
                      type: 'is-danger', 
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 5000,
                      position: 'bottom-right',
                    });
                  }
                }  else if (error.message) {
                    const errorMessage= 'Something went wrong. Please try again!'
                    toast({
                      message: errorMessage,
                      type: 'is-danger', 
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 5000,
                      position: 'bottom-right',
                    });
                }
              })


              
        this.$store.commit('setIsLoading', false)

      } else if (this.errors.length) {
          setTimeout(() => {
            this.errors = []
          }, 3000)
          }

      }
  
    }
  }
</script>
<style scope>
.error-sign-up {
border-radius: 0.5rem;
margin-left: 8rem;
align-items: center;
justify-content: center;
}

.error-sign-up p {
font-size: 12px;
font-weight: bold;
color: #f420cd;
}
.error-sign-up span {
color: #f420cd;
}
</style>