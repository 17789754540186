<template>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
          Information
        </h6>
        <div v-if="!userPk" class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Mapchi-Key
              </label>
              <input
                type="text"
                required
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="00000000-0000-0000-0000-000000000000 *"
                maxlength="36"
                v-model="mapchi_key"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Website address
              </label>
              <input
                type="url"
                required
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="https://www.mapchi.com *"
                v-model="websiteaddress"
                :disabled="!editWebsiteAddress"
              />
            </div>
          </div>

            
        </div>

        <div v-else class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Mapchi-Key
              </label>
              <input
                type="text"
                required
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="00000000-0000-0000-0000-000000000000 *"
                maxlength="36"
                v-model="mapchi_key"
                disabled
              />

            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Website address
              </label>
              <input
                type="url"
                required
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="https://www.mapchi.com *"
                v-model="websiteaddress"
                :disabled="!editWebsiteAddress"
                ref="websiteAddressInput"
              />
            </div>
          </div>
        </div>



        <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
          Important
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Mapchi-Key | Website Address
              </label>
              <textarea
                type="text"
                disabled
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="6"
              >The MAPCHI-KEY is your unique identifier that links the widget/iframe to your website. Keeping the key private is crucial as it contains sensitive information that could compromise your account and data. The website address needs to be accurate and up-to-date to prevent widget/iframe functionality issues. It's important to note that if the MAPCHI-KEY does not match the website address, the widget/iframe won't work. Both the MAPCHI-KEY and website address are linked to a monthly paid subscription, making accuracy crucial for revenue and reputation.
              </textarea
              >
            </div>
          </div>
        </div>

        <hr class="mt-4 border-b-1 border-blueGray-300" />
        <div v-if="!isDemo" class="ml-4">
          <button v-if="!userPk" class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click.prevent="saveChanges">Save</button>
          <button v-if="userPk && showEditButton" class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click.prevent="enableEditWebsiteAddress">Edit</button>
          <button v-if="userPk && showSaveButton" class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click.prevent="saveChanges">{{ saveButtonLabel }}</button>
        </div>
        <hr class="mt-6 border-b-1 border-blueGray-300" />
      </form>
    </div>
</template>

<script>
import axios from 'axios'
import {toast} from 'bulma-toast'

export default {
  mounted() {
    this.getUserProfile()
  },
  data() {
    return {
      mapchi_key: '',
      websiteaddress: null,
      editWebsiteAddress: false,
      userPk: null,
    }
  },

  computed: {
    showEditButton() {
      return (this.websiteaddress === null || this.websiteaddress || this.websiteaddress === '') && !this.editWebsiteAddress
    },

    showSaveButton() {
      return this.websiteaddress && this.editWebsiteAddress
    },

    saveButtonLabel() {
      return this.editWebsiteAddress ? 'Save' : 'Edit'
    },
    
    isDemo() {
      const demo = this.$store.state.user.username;
      return demo === 'demo@mapchi.com';
    },
  },

  methods: {
    async getUserProfile() {
      this.$store.commit('setIsLoading', true)

        axios.get('/mapche-api/v1/userprofile/')
          .then(response => {
            if(response.data[0]) {
              if (!this.isDemo) {
              this.mapchi_key = response.data[0].mapche_key
              }
              else {
                const placeholder = "demo-1234-4321-56768-987654321000"
                this.mapchi_key = placeholder
              }
              this.websiteaddress = response.data[0].website_address

              if (localStorage.getItem('userid') !== localStorage.getItem('created')) {
                this.userPk = response.data[0].admin_id
              } else {
                this.userPk = response.data[0].id
              }

              this.editWebsiteAddress = false
            } else {
             this.editWebsiteAddress = true
            }
          })
          .catch((error) => {
            toast({
              message: 'An error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
          })

      this.$store.commit('setIsLoading', false)    
    },

    enableEditWebsiteAddress() {
      this.editWebsiteAddress = true
      this.$nextTick(() => {
        this.$refs.websiteAddressInput.focus()
      })
    },


    saveChanges() {

      if (!this.userPk) {
        if (!this.validateMapchiKey(this.mapchi_key)) {
          toast({
                  message: 'The MAPCHI-KEY is invalid. Use the mapchi-key on your website',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
          return;
        }
        if (!this.validateWebsiteAddress(this.websiteaddress)) {
          toast({
                  message: 'The Website URL is incorrect, expecting http:// or https://',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
          return;
        }


        axios.post('/mapche-api/v1/userprofile/', {
          mapche_key: this.mapchi_key,
          website_address: this.websiteaddress,
        })
        .then(response => {
          this.editWebsiteAddress = false
            toast({
                  message: 'Mapchi Information, updated successfully',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
          this.getUserProfile()
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.website_address) {
              const websiteErrorMessage = error.response.data.website_address[0];
              toast({
                message: websiteErrorMessage,
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
              this.websiteaddress = null
            }
            if (error.response.data.mapche_key) {
              const mapcheKeyErrorMessage = error.response.data.mapche_key[0];
              toast({
                message: mapcheKeyErrorMessage,
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
              this.mapchi_key = ''
            }
          } else {
            toast({
              message: 'An error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
            this.mapchi_key = ''
            this.websiteaddress = null
          }
          })

      } else {

        axios.patch(`/mapche-api/v1/userprofile/${this.userPk}/update_website_address/`, {
          website_address: this.websiteaddress,
        })
        .then(response => {
          toast({
                  message: 'Website Address, updated successfully',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
          this.editWebsiteAddress = false
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.website_address) {
              const websiteErrorMessage = error.response.data.website_address[0];
              toast({
                message: websiteErrorMessage,
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
              this.websiteaddress = null
              this.getUserProfile()
            }
          } else {
            toast({
              message: 'An error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
            this.mapchi_key = ''
            this.websiteaddress = null        
            }
        })
      }
    
    },

    validateMapchiKey(key) {
      const regex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
      return regex.test(key)
    },

    validateWebsiteAddress(address) {

      const regex = /^(http|https):\/\/[^ "]+$/;
      return regex.test(address);
    },
    getDomainName(url) {
        const lowercaseURL = url.toLowerCase()
        const match = lowercaseURL.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im);
      
        if (match) {
         return match[1]
        }
      return ''
    },    
  }
}
</script>
