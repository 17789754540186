<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >

    
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-4 py-3 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Reseller
            </th>
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Contact Person
            </th>
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Email
            </th>
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Phone Number
            </th>  
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Activation
            </th>  
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Account Active
            </th>                                   
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Subscription
            </th>            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Assigned To
          </th>            

          </tr>
        </thead>
       <tbody>
        <template v-for="(reseller, index) in combinedResellersAndPhone" :key="index">
          <tr class="lead-item" @click="selectReseller(reseller.leads, reseller.business, index)" :class="{ 'bg-gray-200': selectedReseller === reseller.leads}"
          >
          <td 
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
          >
          <div class="flex item-center">
            <span
              class="ml-1 font-bold capitalize"
              :class="[
              color === 'light' ? 'text-blueGray-600' : 'text-white']" style="line-height: 2.5rem;"
            >
            {{ reseller.business.name }}
            </span>
          </div>
          </td>
      
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{ reseller.first_name}} {{ reseller.last_name}}
          </td>
          <td
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          {{ reseller.username }}
          </td>
          <td
            class="px-4 align-middle text-xs whitespace-nowrap capitalize"
          >
            {{ reseller.phone_number }}
          </td>

          <td
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          {{ reseller.activation_date }}
          </td>
         
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          <div class="flex ml-3 justify-left xs:border-l">
              <input type="checkbox" class="focus:ring-0 text-emerald-600" v-model="reseller.reseller_active" disabled />
            </div>
          </td>   
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          <div class="flex ml-0 justify-left xs:border-l">
            <span v-if="reseller.subscription_active">
              <input type="checkbox" class="focus:ring-0 text-emerald-600" v-model="reseller.subscription_active" disabled />
            </span>
            <span v-else>
              <i class="fas fa-times-circle text-red-500"></i>
            </span>
          </div>
          </td>  
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          {{ reseller.member_first }} {{ reseller.member_last }}
          </td>                    
        </tr>
        </template>
        <template v-for="invite in invitedInvites" :key="invite.id">
          <tr 
           class="lead-itemprovider" 
          >
          <td v-if="invite.status === 'declined'"
              class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 py-4">
              <span
                  class="ml-3 font-bold capitalize cursor-pointer"
                  :class="[color === 'light' ? 'text-red-500' : 'text-white']"
                  style="line-height: 2.5rem;">
                  <i class="fas fa-times-circle mr-1"></i> Declined
              </span>
            </td>
          <td v-else 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-2 py-4">
              <span
                  class="ml-3 font-bold capitalize cursor-pointer"
                  :class="[color === 'light' ? 'text-emerald-600' : 'text-white']"
                  style="line-height: 2.5rem;">
                  <i class="far fa-envelope mr-1"></i> Invited
              </span>
          </td>          
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-5 p-4 capitalize"
          >
          {{ invite.company_name }}
          </td>
          <td
            class="px-4 py-4 align-middle text-xs"
          >
           {{ invite.email }}
          </td>
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 p-4 capitalize font-bold"
          >
          </td>
          <td 
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          </td>
          <td
            class="capitalize border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div class="flex ml-6 justify-left xs:border-l">
              <i v-if="invite.status === 'declined'" class="fas fa-times-circle text-red-500 text-lg"></i>
              <i v-else class="far fa-envelope text-xl text-emerald-600 "></i>
            </div>
          </td>          
          <td 
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          {{ invite.member_first }} {{ invite.member_last }}
          </td>          
        </tr>
        </template>
      </tbody>
    </table>
   </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { toast } from 'bulma-toast'

export default {
  name: 'Resellers',
  
  mounted() {

  },

  data() {
    return {
      selectedReseller: null,
      selectedBusiness: '',
    }
  },
  components: {

  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    resellers: Array,
    invites: Object,
  },
  
  watch: {
    selectedReseller(reseller) {
      if (reseller) {
          this.$emit('resellerlead-selected', reseller, this.selectedBusiness)
      }
    },
  },

  computed: {
    invitedInvites() {
      if (this.invites && this.invites.invites) {
        const businessId = this.$store.state.user.username

        return this.invites.invites.filter(invite => (invite.status === 'invited' || invite.status === 'declined') && invite.member_id === businessId)
        .map(invite => {
          return {
            id: invite.id,
            email: invite.email,
            company_name: invite.company_name,
            accountId: invite.accountId,
            member_id: invite.member_id,
            member_first: invite.member_first,
            member_last: invite.member_last,            
            status: invite.status,
          };
        });
      }
      return [];
    },

    combinedResellersAndPhone() {
    const resellers = this.resellers || [];
    const resellerDataArray = [];

    if (resellers.length > 0 && !this.selectedReseller) {
    this.selectedReseller = resellers[0].leads;
    this.selectedBusiness = resellers[0].business.name;
    }

    resellers.forEach((reseller) => {
      const relationshipId = reseller.id;
      const business = reseller.business;
      const leads = reseller.leads || [];

      if (business) {
        const userId = business.created_by.id;
        const firstname = business.created_by.first_name;
        const lastname = business.created_by.last_name;
        const username = business.created_by.username;
        const member_first = reseller.member_first;
        const member_last = reseller.member_last;
        const phoneNumber = business.member_phone[userId];
        const formattedLoginDate = business.member_login[userId] || null;
        const formattedDate = business.created_at || null;
        const loginTimestampLogin = formattedLoginDate ? this.formatDate(formattedLoginDate) : '';
        const loginTimestamp = formattedDate ? this.formatDate(formattedDate) : '';
        const planStatus = business.plan_status || null;
        const resellerActive = business.member_active || {};

        const isActive = planStatus === 'active' || planStatus === 'trialing';
        const subscriptionActive = isActive && resellerActive[userId] === true;

        resellerDataArray.push({
          business: business,
          first_name: firstname,
          last_name: lastname,
          username: username,
          member_first: member_first,
          member_last: member_last,
          phone_number: phoneNumber,
          leads: leads,
          activation_date: loginTimestamp,
          last_login: loginTimestampLogin,
          reseller_active: resellerActive[userId] === true,
          subscription_active: subscriptionActive,
        });
      }
    });

    return resellerDataArray;
  },
  },


  methods: {
    selectReseller(reseller, business) {
      this.selectedReseller = reseller
      this.selectedBusiness = business.name
    },

    formatDate(timestamp) {
      return moment(timestamp).format('D MMM YYYY')
     },
  }
}
</script>

<style scoped>
  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #f1f3f6;
  }

  .rejected {
    text-decoration: line-through;
  }
</style>