<template>
    <div class="flex-auto overflow-x-auto px-4 lg:px-10 py-4 pt-0">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-20/12">
            <div class="px-4 mt-0 border shadow-md">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Product Information
            </h6>
            <div class="">
              <div class="flex">
                <div class="w-4/12">
                  <div class="relative mb-3 mt-2">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Provider:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedProvider">
                      <option disabled selected>Select a provider</option>
                      <option v-for="provider in filteredProviders" :key="provider.supplier_id" :value="provider.supplier_id"
                      >{{ provider.name}}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedService">
                      <option disabled selected>Select a service</option>
                      <option v-for="service in services" :key="service.vendorserviceId" :value="service.vendorserviceId">{{ service.serviceName}}</option>
                    </select>
                  </div>
                </div>
              </div>              

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service Type:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedServiceType">
                      <option selected>Internet</option>
                      <option>Layer2</option>
                    </select>
                  </div>
                </div>
              </div>         

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Feasibility Rule:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedMapRule">
                      <option disabled selected>Select a rule</option>
                      <option v-for="mapRule in mapRules" :key="mapRule.maprule_id" :value="mapRule.maprule_id">{{ getDescriptionAfterAsterisk(mapRule.description) }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Product Name:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="text"
                    required
                    placeholder="Bronze Starter *" 
                    v-model="productName"/>
                  </div>
                </div>
              </div>                 

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Cost Code:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="text"
                    required
                    placeholder="LQ-L2-12-10 *" 
                    v-model="costCode"/>
                  </div>
                </div>
              </div>


            <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Capped:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2 mr-2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="checkboxcapped" @click="toggleCheckboxcapped"
                     v-model="checkboxcapped"
                     />
                  </div>
                </div>
            </div> 


                        <div v-if="!checkboxcapped">
            <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Synchronous</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/12 mr-2">
                  <label class="switch">
                    <input type="checkbox" id="checkboxsync" @click="toggleCheckboxasync"
                    v-model="checkboxsync" 
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
                <div class="w-4/12 mt-2 ml-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Asynchronous:</label>
                    </div>
                  </div>
                </div>
            </div>

              <div v-if="!checkboxsync" class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Download:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacity"/>
                  </div>
                  Mbps
                </div>
              </div> 

              <div v-else>
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Download:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2 mb-2 border">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacity"/>
                  </div>
                  Mbps
                </div>
              </div> 
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Upload:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacityupload"/>
                  </div>
                  Mbps
                </div>
              </div> 
            </div>
          </div> 

          <div v-else>
            <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Data:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="600 GB" 
                    v-model="capped"/>
                  </div>
                  GB
                </div>
              </div> 
          </div>
          
          <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-0">
                    <div class="text-xs whitespace-nowrap mb-0">
                      <label>FUP:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2 mr-2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="checkboxfup" @click="toggleCheckboxfup"
                     v-model="checkboxfup"
                     />
                  </div>
                </div>
            </div> 

            <div v-if="checkboxfup && !checkboxcapped">
            <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Data:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2 mt-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="600 GB" 
                    v-model="fup"/>
                  </div>
                  GB
                </div>
              </div> 
          </div>



              <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Contract Term:</label>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mt-2 pt-2 pb-2" type="number"
                    required
                    min="1"
                    placeholder="12 Months"
                    v-model="contractTermNumber"/>
                  </div>
                  <div class="block mt-4">
                    <span class="text-xs mt-2 pt-2 pb-2 font-semibold">Months</span>
                  </div>
                </div>
              </div> 

            </div>  
            </div>
          </div>

          <div class="w-full lg:w-3/12">
            <div class="border px-4 mt-0 ml-4 shadow-md">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Pricing
            </h6>
            <div class="flex">
              <div class="w-4/12 mt-2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap mb-2">
                    <label for="selectedCurrency">Currency:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div v-if="selectedProviderCurrency" class="block">
                  <input
                    id="selectedCurrency"
                    class="placeholder-blueGray-300 bg-white font-bold text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                    v-model="selectedCurrency"
                    disabled
                  />
                </div>
              </div>
            </div>


            <div class="flex">
                <div class="w-4/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>VAT Inclusive:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="vatInclusive"
                     v-model="computedVatInclusive"
                     />
                  </div>
                </div>
              </div> 

              <div class="flex">
                <div class="w-4/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>VAT Exclusive:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" id="vatExclusive"
                    v-model="computedVatExclusive"
                     />
                  </div>
                </div>
              </div> 
                           
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-2">
                    <div class="text-xs whitespace-nowrap">
                      <label>VAT Percentage:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-sm font-semibold items-center">
                  <div v-if="selectedProviderCurrency" class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2 disabled-percentage" type="number" 
                    placeholder="0.00"
                    v-model="vatPercentage" 
                    disabled
                    min="0" 
                    step="0.01"/>
                  </div>
                  %
                </div>
              </div>               


              <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Monthly:</label>
                    </div>
                  </div>
                </div>
                <div class="flex mt-2 text-sm font-semibold items-center">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="number" 
                    placeholder="R 1500"
                    v-model="sellingPrice" 
                    min="0" 
                    step="0.01" />
                  </div>
                  {{ getSymbol(selectedCurrency) }}
                </div>
              </div>  

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Once-Off:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-sm font-semibold items-center">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="number" 
                    placeholder="R 2500"
                    v-model="onceoffPrice" 
                    min="0" 
                    step="0.01" />
                  </div>
                  {{ getSymbol(selectedCurrency) }}
                </div>
              </div> 

              <div class="mt-4 mb-2 flex">
                <div class="w-4/12">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Promotion Item:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" v-model="isPromotion" />
                  </div>
                </div>
              </div>

              <div class="mt-4 mb-2 flex">
                <div class="w-4/12">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Active Status:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 cursor-pointer" type="checkbox" v-model="isActive" />
                  </div>
                </div>
              </div>


            </div>
            <div
              @click="updateProduct" 
              :disabled="!isFormValid"
              :style="{ opacity: !isFormValid ? '0' : '1' }"
              class="lead-itemprovider px-4 ml-4 py-4 text-sxs uppercase whitespace-nowrap font-semibold text-left bg-emerald-600 text-white rounded-b hover:text-emerald-600 hover: border shadow-md"
            >
            <i class="fas fa-save mr-1"></i>
            Update Product
          </div>

          </div>          



          <div class="w-full lg:w-20/12">
            <div class="border px-4 mt-0 ml-4 shadow-md">
              <div class="flex items-center justify-between">
                <div>
                  <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
                    Features (Included)
                  </h6>
                </div>
              </div>              

              <div v-if="editMode" class="flex">
                <div class="w-3/12 mt-4">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Feature:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2">
                  <div class="block">
                    <select
                      class="placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap ml-2 mb-2 pt-2 pb-2"
                      v-model="newFeature"
                    >
                      <option disabled value="">Select a feature</option>
                      <option v-for="feature in featureData" :value="feature.name" :key="feature.name">{{ feature.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="w-1/2 ml-5 mt-3">
                  <div class="block">
                    <button
                      v-if="editMode"
                      @click="saveChanges"
                      class="px-2 py-1 text-xs bg-emerald-600 text-white rounded"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>

            <div class="grid grid-cols-2 gap-4 mt-4 mb-2">
            <div class="flex" v-for="(feature, index) in features" :key="index">
                <div class="w-6/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs capitalize whitespace-nowrap mb-2">
                      <span v-if="removeMode"
                        class="mr-2 text-red-500 cursor-pointer"
                        @click="deleteFeature(index)"
                      >
                      <i class="fas fa-times-circle"></i>
                      </span>
                      <span>{{ feature.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="w-1/12 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600 ml-4" type="checkbox"
                    :disabled="editMode || removeMode"
                    :id="'feature_' + feature.id"
                    v-model="selectedFeatures"
                    :value="feature.id"
                    :style="{ cursor: (editMode || removeMode) ? 'not-allowed' : 'pointer', opacity: (editMode || removeMode) ? '0.2' : '1' }"
                    />
                  </div>
                </div>
              </div> 
            </div>



            </div>
          </div> 
          
        </div>

      </div> 

    <div class="flex-auto overflow-x-auto px-0 lg:px-10 py-4 pt-0">
     <div class="flex flex-wrap">


          <div class="w-full lg:w-6/12 ">
            <div class="border px-4 mt-0 ml-4 shadow-md">
              <div class="flex items-center justify-between">
              <div>
                <h6 v-if="isDemo" class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
                  Feasibility Priority (Global)
                  <span class="font-normal capitalize text-red-500 opacity-50"> - Slider is disabled for demo purposes</span>
                </h6>
              <h6 v-else class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
                  Feasibility Priority (Global)
              </h6>
              </div>                
              <div class="flex items-center space-x-2">
                <div v-if="isDemo" class="mr-2">
                  <label class="switch">
                    <input type="checkbox" @click="toggleCheckbox" disabled>
                    <div class="sliderdemo round" title="Slider is disabled for demo purposes"></div>
                  </label>
                </div>
                <div v-else class="mr-2">
                  <label class="switch">
                    <input type="checkbox" @click="toggleCheckbox">
                    <div class="slider round"></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="flex space-x-4" :class="{ 'opacity-50': !this.checkbox }">
              <div class="w-1/2 border mb-4 p-4 shadow-md">
                <div class="mb-0">
                  <label class="text-xs font-bold block mb-1 uppercase">Options</label>
                  <ul>
                    <li class="lead-item text-xs mb-2" v-for="mapRule in sortedMapRules" :key="mapRule.maprule_id">
                      <div class="flex items-center cursor-pointer">
                        <span class="flex-1" @click="this.checkbox ? moveToPriorityList(mapRule.maprule_id) : null"
                        :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                        >
                          {{ getDescriptionAfterAsterisk(mapRule.description) }}
                        </span>
                        <span>
                          <i
                            class="fas fa-arrow-right cursor-pointer text-emerald-600"
                            @click="this.checkbox ? moveToPriorityList(mapRule.maprule_id) : null"
                            :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                            >
                          </i>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="flex items-center justify-center">
                <i class="fas fa-angle-double-left text-xl cursor-pointer text-red-500" 
                @click="handleMoveAllToOptionsList"
                :title="this.checkbox ? 'Move All' : 'Disabled'"
                :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                ></i>
              </div>              
              <div class="w-1/2 border mb-4 p-4 shadow-md">
                <label class="text-xs font-bold block mb-2 uppercase">Priority List</label>
                <ul>
                  <li class="lead-item text-xs mb-2" v-for="(priorityOption, index) in priorityOptions" :key="priorityOption.maprule_id"
                  >
                    <div class="flex items-center">
                      <span>
                        <i
                          class="fas fa-arrow-left cursor-pointer text-red-500"
                          @click="this.checkbox ? moveToOptionsList(index) : null"
                          :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                        ></i>
                      </span>
                      <span class="flex-1 text-right" @click="this.checkbox ? moveToOptionsList(index) : null"
                      :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                      >
                        {{getDescriptionAfterAsterisk(priorityOption.description)}} : <span class="font-bold">{{ index + 1 }}</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </div>

          </div>
          </div>

          
</template>
<script>
import pricing from "@/assets/img/pricing.png"
import axios from 'axios'
import {toast} from 'bulma-toast'
import { getCurrencyList  } from '@/components/currency'

export default {
  
  components: {
  },
  
  data() {
    return {
      editMode: false,
      removeMode: false,
      errors: [],
      pricing,
      checkbox: false, 
      checkboxsync: false,
      checkboxcapped: false,
      checkboxfup: false,
      productName: '',
      vendor: [],
      selectedProvider: 'Select a provider',
      selectedProviderData: [],
      selectedServiceType: 'Internet',
      selectedUserProviders: [],
      selectedUserCurrency: [],
      selectedService: 'Select a service',
      selectedMapRule: 'Select a rule',
      costCode: '',
      suppliercostCode: '',
      capacity: '',
      capacityupload: '',
      capped: '',
      fup: '',
      contractTerm: 'months',
      contractTermNumber: '',
      isActive: false,
      isPromotion: false,
      selectedCurrency: '',
      currencyList: [],
      vatInclusive: true,
      vatExclusive: false,
      vatPercentage: '',
      sellingPrice: null,
      onceoffPrice: null,
      installationCost: '',
      providers: [],
      products: [],
      services: [],
      mapRules: [],
      mapRulesCopy: [],
      options: [],
      features: [],
      featureData: [],
      include: false,
      updatedFeatures: [],
      newFeature: '',
      selectedOptionIndex: -1,
      priorityOptions: [],
      selectedFeatures: [],
      selectedProductID: '',
      selectedVendor: '',
      selectedProviderPopulated: false,
      updatedproduct: [],
      updatedproductfeasibility: [],

    };
  },
  emits: ['update-update'],
  async created() {
    this.currencyList = await getCurrencyList()
  },
  async mounted() {
    this.getVendors()
    this.checkUserPreferencesVendor()
    this.setupProductData()
    await this.getFeatureData()
    await this.getFeatures() 
    if (this.features && this.features.length > 0) { 
      const newFeatures = this.featureData.filter(feature => !this.features.some(existingFeature => existingFeature.name === feature.name));
      if (newFeatures.length > 0) {
        await this.saveFeatures(newFeatures); 
      }
      
      const featuresToDelete = this.features.filter(existingFeature => !this.featureData.some(feature => feature.name === existingFeature.name));
      if (featuresToDelete.length > 0) {
        this.deleteFeature(featuresToDelete)
      }

    } else {
        await this.saveFeatures(this.featureData);
    }

    this.getAllProductsFeasibility()
    
    
    
    if (this.selectedProviderPopulated) {
    this.$watch(
      'selectedService',
      (newSelectedService, oldSelectedService) => {
        if (newSelectedService !== oldSelectedService) {
          this.filterMapRules();
        }
      },
      { deep: true }
    )
    }

  },

  
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    product: Object,
  },

  watch: {
    selectedProvider: {
      handler(newSelectedProvider, oldSelectedProvider) {
        if (newSelectedProvider !== oldSelectedProvider) {
          this.fetchServicesAndMapRules(newSelectedProvider);
        }
      },
      deep: true, 
    },

    selectedServiceType(newSelectedProvider) {
    },

    vatExclusive(newValue) {
      if (newValue) {
        this.vatInclusive = false;
        this.vatPercentage = this.selectedProviderData.vat_rate;
      }
    },
    vatInclusive(newValue) {
      if (newValue) {
        this.vatExclusive = false;
        this.vatPercentage = ''
      }
    },

    isActive(newIsActiveValue) {
      if (newIsActiveValue === true) {
        this.include = true
        this.fetchServicesAndMapRules(this.selectedProvider)
        this.getAllProductsFeasibility()
      } else {
        this.include = false
        this.getAllProductsFeasibility()
      }
    }

  },
  

  
  computed: {
    isDemo() {
          const demo = this.$store.state.is_demo;
          return demo
        },
    isFormValid() {
      return (
       this.selectedProvider !== 'Select a provider' && 
       this.selectedProvider &&
       this.selectedService !== 'Select a service'&&
       this.selectedService &&
       this.selectedServiceType &&
       this.selectedMapRule !== 'Select a rule' &&
       this.selectedMapRule &&
       this.productName &&
       this.costCode  &&
      (this.checkboxcapped ?
        (typeof this.capped === 'number' && this.capped >= 1) : 
        (this.checkboxsync ? 
          (typeof this.capacity === 'number' && this.capacity >= 1 && typeof this.capacityupload === 'number' && this.capacityupload >= 1) : 
          (typeof this.capacity === 'number' && this.capacity >= 1)
        )
      ) &&
      (this.checkboxfup ? 
        (typeof this.fup === 'number' && this.fup >= 1) : 
        true 
      ) &&

       (!isNaN(this.contractTermNumber) && parseFloat(this.contractTermNumber) >= 0) &&
       (this.vatExclusive ? this.vatPercentage !== '' : true) &&
       (!isNaN(this.sellingPrice) && parseFloat(this.sellingPrice) >= 0)  &&
       (!isNaN(this.onceoffPrice) && parseFloat(this.onceoffPrice) >= 0) 
      );

    },      

    selectedProviderCurrency() {
      const isProviderSelected = this.selectedUserProviders.includes(this.selectedProvider);

      if (isProviderSelected) {
        this.selectedProviderData = this.selectedUserCurrency.find(provider => provider.supplier_id === this.selectedProvider);
        
        if (this.selectedProviderData && this.selectedProviderData.currency) {
          this.selectedCurrency = this.selectedProviderData.currency;
          this.vatPercentage = this.selectedProviderData.vat_rate;
          return this.selectedProviderData.currency;
        }
      }

      this.selectedCurrency = null;
      return null;
    },

    filteredProviders() {
      if (this.selectedProvider !== 'Select a provider' && this.selectedProvider.length > 0) {
      return this.providers
        .filter(provider => provider.supplier_id === this.selectedProvider)
        .sort((a, b) => a.name.localeCompare(b.name));
    } else if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
      return this.providers
        .filter(provider => this.selectedUserProviders.includes(provider.supplier_id))
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  },
    sortedMapRules() {
     let matchingMapRules = []
    for (const updatedFeasibility of (this.updatedproductfeasibility)) {
        const updatedMapRuleId = updatedFeasibility.maprule
        const matchingRule = this.mapRulesCopy.find(rule => rule.maprule_id === updatedMapRuleId)
        if (matchingRule) {
          if (!this.include) {
            if (matchingRule.maprule_id !== this.selectedMapRule) {
              matchingMapRules.push(matchingRule);    
            }
            const Priority = this.priorityOptions.find(rule => rule.maprule_id === updatedMapRuleId)
              if (Priority) {
                if (Priority.maprule_id === matchingRule.maprule_id) {
                  matchingMapRules = matchingMapRules.filter(rule => rule.maprule_id !== Priority.id)
                }
              }
          } else {

              const Priority = this.priorityOptions.find(rule => rule.maprule_id === updatedMapRuleId)
              if (Priority) {

                if (Priority.id !== matchingRule.maprule_id) {
                    matchingMapRules.push(matchingRule);    
                }

            } else {
              if (updatedFeasibility.is_active) {
                matchingMapRules.push(matchingRule);
              } else if (matchingRule.maprule_id === this.selectedMapRule) {
                matchingMapRules.push(matchingRule);
              }
            }
          }
        }






    }

    this.options = matchingMapRules
    

     return matchingMapRules.slice().sort((a, b) => {
      const priorityA = this.getPriority(a.description.toLowerCase());
      const priorityB = this.getPriority(b.description.toLowerCase());

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      if (priorityA === 7) { 
        const distanceA = this.extractDistance(a.description);
        const distanceB = this.extractDistance(b.description);
        return distanceA - distanceB;
      }

      return a.description.localeCompare(b.description);
    });

  },
    formattedCapacity() {
      return `${this.capacity} Mbps`;
    },
    computedVatInclusive: {
      get() {
        return this.vatInclusive;
      },
      set(value) {
        if (!value) {
          this.vatExclusive = true;
        }
        this.vatInclusive = value;
      },
    },
    computedVatExclusive: {
      get() {
        return this.vatExclusive;
      },
      set(value) {
        if (!value) {
          this.vatInclusive = true;
        }
        this.vatExclusive = value;
      },
    },
  },

  beforeUpdate() {
    if (this.selectedProvider !== this.product.supplier_id) {
      this.selectedProviderPopulated = false;
    }
  },



    methods: {
      setupProductData() {
        if (this.product) {
          this.selectedProductID = this.product.id
          this.selectedProvider = this.product.supplier_id
          this.selectedService = this.product.service
          this.selectedServiceType = this.product.service_type
          this.selectedMapRule = this.product.maprule
          this.productName = this.product.name
          this.costCode = this.product.cost_code
          if (this.product.capped > 0) {
            this.capped = this.product.capped
            this.checkboxcapped = true
          }
          if(this.product.capacity > 0) {

          if (this.product.capacity === this.product.capacityupload) {
              this.capacity = this.product.capacity
            } else {
              this.capacity = this.product.capacity
              this.capacityupload = this.product.capacityupload
              this.checkboxsync = true          
            }
          }  
          if (this.product.fup > 0) {
            this.fup = this.product.fup
            this.checkboxfup = true
          }
          this.contractTermNumber = this.product.term
          this.isActive = this.product.is_active
          this.isPromotion = this.product.promotion_id
          this.selectedCurrency = this.product.currency
          this.vatInclusive = this.product.inclusive 
          this.vatExclusive = this.product.exclusive
          this.vatPercentage = this.product.percentage
          this.sellingPrice = this.product.mrc 
          this.onceoffPrice = this.product.nrc
          this.selectedFeatures = this.product.features
          this.selectedVendor = this.product.vendor
          this.selectedProviderPopulated = true;
        }
      },      
      toggleEditState() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.removeMode = false
      } else {
        this.newFeature = '';
      }
    },
    toggleRemoveState(){
      this.removeMode = !this.removeMode;

      if (this.removeMode) {
        this.editMode = false
      } else {
        
      }
    },

    deleteFeature(featuresToDelete) {
      featuresToDelete.forEach(existingFeature => {
        const index = this.features.findIndex(feature => feature.name === existingFeature.name);
        if (index !== -1) {
          this.features.splice(index, 1);
        }
      });
      this.updatedFeatures = this.features.map(feature => feature.id)
      this.updateFeatures()
    },


    getSymbol(currency) {
      const currencyObject = this.currencyList.find(item => item.code === currency);
      return currencyObject ? currencyObject.symbol : '';
    },
      toggleCheckbox() {
      this.checkbox = !this.checkbox
      },      

      toggleCheckboxasync() {
        this.checkboxsync = !this.checkboxsync
        
        if (this.checkboxsync) {
          this.setupProductData()
        } else {
          this.capacityupload = ''
        }
      },

      toggleCheckboxcapped() {
        this.checkboxcapped = !this.checkboxcapped
        this.capacityupload = ''
        this.capacity = ''
        this.fup = ''
        if (this.checkboxcapped) {
          this.checkboxfup = false
          this.checkboxsync = false
        } else {
          this.capped = ''
          this.capacityupload = ''
          this.capacity = ''
        }
      },

      toggleCheckboxfup() {
        this.checkboxfup = !this.checkboxfup
        this.capped = ''

        if (this.checkboxfup) {
          this.checkboxcapped = false
        } else {
          this.fup = ''
          this.capped = ''
        }
      },

      getPriority(description) {
        const sortOrder = [
        { keyword: 'special', priority: 1 },
        { keyword: 'ftth', priority: 2 },
        { keyword: 'fttb', priority: 3 },
        { keyword: 'broadband', priority: 4 },
        { keyword: 'precinct', priority: 5 },
        { keyword: 'on-net', priority: 6 },
        { keyword: 'onnet', priority: 6 },
        { keyword: /\((\d+)\s*m\)/, priority: 7 },
        { keyword: 'zone', priority: 8 },
        { keyword: 'metro', priority: 9 },
        { keyword: 'national', priority: 10 },
        { keyword: 'offnet', priority: 11 },
        { keyword: 'off-net', priority: 11 }
      ]        
        const match = sortOrder.find(order => {
          if (order.keyword instanceof RegExp) {
            return description.match(order.keyword);
          } else {
            return description.includes(order.keyword);
          }
        });

        return match ? match.priority : Number.POSITIVE_INFINITY;
      },

      extractDistance(description) {
        const distanceMatch = description.match(/\((\d+)m\)/);
        return distanceMatch ? parseInt(distanceMatch[1]) : Number.POSITIVE_INFINITY;
      },

      selectOption(index) {
          this.selectedOptionIndex = this.selectedOptionIndex === index ? -1 : index;
        },

        handleMoveAllToOptionsList() {
          if (this.checkbox) {
            this.moveAllToOptionsList();
          }
        },
        moveAllToOptionsList() {
            this.priorityOptions.forEach(option => {
              if (!this.mapRulesCopy.some(opt => opt.maprule_id === option.maprule_id)) {
                this.mapRulesCopy.push(option);
              }
            });
            this.priorityOptions = []; 
        },        
        moveToPriorityList(mapRuleId) {
          const selectedOption = this.mapRulesCopy.find(rule => rule.maprule_id === mapRuleId)
          if (selectedOption) {
            if (!this.priorityOptions.some(option => option.maprule_id === mapRuleId)) {
              this.priorityOptions.push(selectedOption)
              const index = this.mapRulesCopy.findIndex(rule => rule.maprule_id === mapRuleId);
              this.mapRulesCopy.splice(index, 1);
            } else {
              console.log('Option already in priorityOptions');
            }
          }
        },
        moveToOptionsList(index) {
          const selectedOption = this.priorityOptions[index];
          this.mapRulesCopy.push(selectedOption);
          this.priorityOptions.splice(index, 1);
        },
      handleVatInclusiveToggle() {
        if (this.computedVatInclusive) {
          this.computedVatExclusive = false;
        }
      },

      handleVatExclusiveToggle() {
        if (this.computedVatExclusive) {
          this.computedVatInclusive = false;
        }
      },
      getDescriptionAfterAsterisk(description) {
        const parts = description.split('*');
        return parts.length > 1 ? parts[1] : description;
      },      
      filterMapRules() {
        if (!this.selectedService) {
          this.mapRules = [];
          return;
        }
        this.mapRules = this.vendor.maprules.filter(rule => rule.service_id === this.selectedService)
        this.mapRulesCopy = [...this.mapRules]

      },

      async fetchServicesAndMapRules(vendorId) {
        this.$store.commit('setIsLoading', true)
        this.services = []
        try {
          const [serviceResponse, mapRuleResponse] = await Promise.all([
            axios.get(`/vendor/vendors/${vendorId}/services/`),
            axios.get(`/vendor/vendors/${vendorId}/maprules/`),
          ]);

            this.vendor.services = serviceResponse.data;
            this.vendor.maprules = mapRuleResponse.data;
            this.checkUserPreferences(vendorId)
            if (this.vendor.maprules.length > 0) {
              this.filterMapRules()
            }            

          } catch (error) {
            console.error(`Error fetching services and map rules for vendor ${vendorId}:`, error);
          }

          this.$store.commit('setIsLoading', false)
        },  

        async getVendors() {
          this.$store.commit('setIsLoading', true)
          await axios.get('/vendor/vendors')
            .then(response => {
              this.providers = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

        getProduct(selectedProductID) {
          this.$store.commit('setIsLoading', true)

          axios.get(`/products/product/${selectedProductID}`)
            .then(response => {
              this.updatedproduct = response.data
              this.selectedFeatures = this.updatedproduct.features
            })
            .catch(error => {
              console.error(error);
            })

            this.$store.commit('setIsLoading', false)  
        },

        async getAllProductsFeasibility() {
          this.$store.commit('setIsLoading', true)

          await axios.get('/products/product/')
            .then(response => {
              if (this.include) {
                const filterprod = response.data
                this.updatedproductfeasibility = _.uniqBy(filterprod, 'maprule_name');
              } else {
                const filterprod = response.data.filter(record => record.is_active === true)
                
                this.updatedproductfeasibility = _.uniqBy(filterprod, 'maprule_name');
                
              }
              this.getFeasibilityPriority()
            })
            .catch(error => {
              console.error(error);
            })
            
            this.$store.commit('setIsLoading', false)  
        },

        async getFeatureData() {
          this.$store.commit('setIsLoading', true)
          await axios.get('/products/featuredata/')
            .then(response => {
              this.featureData = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

        async getFeatures() {
          this.$store.commit('setIsLoading', true)
          await axios.get('/products/features/')
            .then(response => {
              this.features = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },


        
        async getFeasibilityPriority() {
          this.$store.commit('setIsLoading', true)

          await axios
            .get(`/vendor/vendors/${this.selectedVendor}/maprulepriority/`)
            .then(response => {
              const maprulepriorityamend = []
              const matchingMapRulesPriority = []
              const maprulepriority = response.data    
              if (maprulepriority && maprulepriority.length > 0) {
                for (const updatedFeasibility of (this.updatedproductfeasibility)) {
                    const updatedMapRuleId = updatedFeasibility.maprule
                    const matchingRule = maprulepriority.find(rule => rule.rule === updatedMapRuleId)
                    if (matchingRule) {
                        maprulepriorityamend.push(matchingRule);
                    } 
                }

              const optionsWithPriority = maprulepriorityamend.filter(priority => priority.priority !== null);

              const optionsWithoutPriority = maprulepriorityamend.filter(priority => priority.priority === null);


              for (const priority of optionsWithPriority) {
                  const updatedMapRuleId = priority.rule;
                  const matchingRule = this.mapRulesCopy.find(rule => rule.maprule_id === updatedMapRuleId);
                  if (matchingRule) {
                    if (!this.priorityOptions.some(option => option.maprule_id === updatedMapRuleId)) {
                      this.priorityOptions.push(matchingRule)
                      const index = this.mapRulesCopy.findIndex(option => option.maprule_id === updatedMapRuleId);
                      if (index !== -1) {
                      this.mapRulesCopy.splice(index, 1);
                      }
                    } else {
                      console.log('Option already in priorityOptions');
                    }
                  } 
                } 

                if (!this.include) {
                  this.priorityOptions.forEach(option => {
                    if (!this.mapRulesCopy.some(opt => opt.maprule_id === option.maprule_id)) {
                      this.mapRulesCopy.push(option);
                    }

                  const index = this.priorityOptions.findIndex(option => option.maprule_id === this.selectedMapRule);
                  if (index !== -1) {
                      this.priorityOptions.splice(index, 1);
                  }
                })                  
                  



                }

             }
            })
            .catch(error => {
              console.error(error);
            });

            this.$store.commit('setIsLoading', false)  
        },


        async saveFeatures(features) {
          try {
            await Promise.all(features.map(async feature => {
              const formData = {
                name: feature.name,
                is_active: true,
                business: this.$store.state.business.id,
              };
              await axios.post('/products/features/', formData);
            }));
            toast({
              message: 'New features added',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });

            this.getFeatures()

          } catch (error) {
            console.error('Error saving features:', error);
            toast({
              message: 'Error saving new features',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });
          }
        },


        async saveChanges() {
          this.$store.commit('setIsLoading', true)

          const formData = {
            name: this.newFeature,
            is_active: true,
            business: this.$store.state.business.id,
          }

          await axios
              .post('/products/features/', formData)
              .then(response => {
                  toast({
                    message: 'Feature added',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
                  this.editMode = false
                  this.newFeature = ''
                  this.getFeatures()
              })
              .catch(error => {
                if(error.response) {
                  for (const property in error.response.data) {
                    this.errors.push(`${property}: ${error.response.data[property]}`)
                  }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
              })

          this.$store.commit('setIsLoading', false)

        },

        async updateProduct() {
          this.$store.commit('setIsLoading', true)
          let selectedCapacity
          if (this.capacityupload > 0) {
              selectedCapacity = this.capacityupload;
          } else {
              selectedCapacity = this.capacity;
          }
           const formData = {
                  supplier_id: this.selectedProvider,
                  service_type: this.selectedServiceType,
                  name: this.productName,
                  cost_code: this.costCode,
                  capacity: this.capacity ? this.capacity : 0,
                  capacityupload: selectedCapacity ? selectedCapacity : 0,
                  capped: this.capped ? this.capped : 0, 
                  fup: this.fup ? this.fup : 0, 
                  term: this.contractTermNumber,
                  currency: this.selectedCurrency,
                  inclusive: this.vatInclusive,
                  exclusive: this.vatExclusive,
                  percentage: this.vatPercentage,
                  mrc: this.sellingPrice,
                  nrc: this.onceoffPrice,
                  is_active: this.isActive,
                  promotion_id: this.isPromotion,
                  business: this.$store.state.business.id,
                  maprule: this.selectedMapRule,
                  service: this.selectedService,
                  vendor: this.selectedVendor.id,
                  features: this.selectedFeatures,
                }                

            await axios
              .put(`/products/product/${this.selectedProductID}/`, formData)
              .then(response => {
                  toast({
                    message: 'Product updated',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })

                  const isEditExpanded = false
                  this.updateMaprulePriorities()
                  this.$emit('update-update', isEditExpanded)
                  
              })
              .catch(error => {
                if(error.response) {
                  if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.business[0]
                    const capitalizeMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
                    toast({
                     message: capitalizeMessage,
                     type: 'is-danger',
                     dismissible: true,
                     pauseOnHover: true,
                     duration: 5000,
                     position: 'bottom-right',
                    })
                  }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
              })

          this.$store.commit('setIsLoading', false)
        },

        async updateMaprulePriorities() {
          this.$store.commit('setIsLoading', true)

                     
            const priorityData = {
              maprule_priorities: this.priorityOptions,
              options: this.options,
              vendor_id: this.selectedVendor
            }

            axios
              .post(`/vendor/vendors/${this.selectedVendor}/updatemaprulepriority/`, priorityData)
              .then(response => {
              })
              .catch(error => {
                console.error('Error:', error);
              })

            

          this.$store.commit('setIsLoading', false)
        },

        async updateFeatures() {
          this.$store.commit('setIsLoading', true)

          const formData = {
            features: this.updatedFeatures
          }


          await axios
              .post('/products/update_features/', formData)
              .then(response => {
                  toast({
                    message: 'Feature removed',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
                  this.getFeatures()
                  this.getProduct(this.selectedProductID)
              })
              .catch(error => {
                if(error.response) {
                  for (const property in error.response.data) {
                    this.errors.push(`${property}: ${error.response.data[property]}`)
                  }
                } else if (error.message) {
                    this.errors.push('Something went wrong. Please try again!')
                }
              })

          this.$store.commit('setIsLoading', false)
        },


        async checkUserPreferencesVendor() {
          this.$store.commit('setIsLoading', true)
          await axios
            .get('/mapche-api/v1/user-preferences/')
            .then((response) => {
              this.selectedUserProviders = []
              this.selectedUserCurrency = response.data
              this.selectedUserProviders = response.data.map(item => item.supplier_id)
             })
            .catch((error) => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

      checkUserPreferences(vendorId) {
        axios
          .get('/mapche-api/v1/user-preferences/')
          .then((response) => {
            this.selectedVendors = response.data.map(item => item.supplier_id);

            this.selectedVendors.forEach(supplier_id => {
              if (supplier_id === vendorId) {
                  response.data.forEach(item => {
                    const userServices = item.services
                    const uservendor_id = item.supplier_id
                    userServices.forEach(service_id => {
                      if (uservendor_id === vendorId) { 

                        this.vendor.services.forEach(service => {
                          
                          if (service.service_id === service_id) {
                            const serviceName = service.name
                            const vendorserviceId = service.service_id
                            this.services.push({ vendorserviceId, serviceName });
                          }

                        })
                      }
                    })
                  })

              }
            })
          })
          .catch((error) => {
            console.error(error);
          });
        },
    }
};
</script>
<style scoped>
  .lead-itemprovider {
  padding: 10px;
    cursor: pointer;
}

.lead-itemprovider:hover {
   background-color: #f1f3f6;
}

  .opacity-50 {
    opacity: 0.5;
    cursor: not-allowed;
  }  
  .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 0, 89);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slidersync {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(5,150,105,1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .sliderdemo {
    position: absolute;
    cursor: not-allowed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(241, 245, 249, 1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

  }


  .sliderdemo:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slidersync:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: rgba(5,150,105,1)
  }

  input:checked + .slidersync {
    background-color: rgb(255, 0, 89);
    
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:focus + .slidersync {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  input:checked + .slidersync:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }


  .slider.round {
    border-radius: 34px;
  }

  .slidersync.round {
    border-radius: 34px;
  }

  .sliderdemo.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .slidersync.round:before {
    border-radius: 50%;
  }

  .sliderdemo.round:before {
    border-radius: 50%;
  }
  
  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #e2e8f0;
  }

  .disabled-percentage {
    background-color: rgba(241, 245, 249, 1);
    cursor: not-allowed;
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center center;
  }

</style>