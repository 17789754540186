<template>
  <div class="flex-auto overflow-x-auto px-4 lg:px-10 py-4 pt-0">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="px-4 mt-0 border shadow-md">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-3 font-bold uppercase">
                Pricing API Details
              </h6>
              <div class="flex flex-wrap">
              <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="selectedVendor"
                      >
                      Provider
                    </label>
                    <select class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2 capitalize" v-model="selectedVendor">
                      <option value="" selected>Select Network</option>
                      <option v-for="vendor in filteredVendors" :key="vendor" :value="vendor">
                      {{ vendor.name }} 
                    </option>
                    </select>
                  </div>
              </div>
              <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">

                    <td
                      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-0"
                    >
                      <div v-if="isSelectNetwork" class="flex ml-1">
                        <img
                        :src="getproviderLogo(selectedVendor.logo_url)"
                        class="h-20 w-32 bg-white"
                      />
                      </div>
                    </td>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      API Details ?
                    </label>
                    <textarea
                      type="text"
                      disabled
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      rows="6"
                      v-model="text"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div v-if="selectedVendor" class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="apiURL"
                    >
                      API URL
                    </label>
                    <input
                      class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                      type="text"
                      placeholder="https://mapchi.com/api/v2/"
                      id="apiUrl"
                      v-model="apiUrl"
                    />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="apiKey"
                    >
                      API TOKEN
                    </label>
                    <input
                      class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                      type="text"
                      placeholder="123DFGsgg"
                      id="apiKey"
                      v-model="apiKey"
                    />
                  </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                            class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                            for="apiUser"
                          >
                            API Username
                          </label>
                          <input
                            class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                            type="text"
                            placeholder="Username"
                            id="apiUser"
                            v-model="apiUser"
                          />
                  </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                  <div class="sign-up-input">
                    <label
                            class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                            for="apiPass"
                          >
                            API Password
                          </label>
                          <input
                            class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                            :type="showPassword ? 'text' : 'password'"
                            required
                            placeholder="Password *"
                            id="apiPass"
                            v-model="apiPass"
                          />
                          <button class="text-xl focus:outline-none focus:ring-0" @click="showPassword = !showPassword">
                            <i class="fas fa-eye" :class="{ 'text-emerald-600': showPassword, 'text-blueGray-400': !showPassword }"></i>
                          </button>
                  </div>
                </div>
               <div class="flex align-middle px-4">
                <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="validationSuccess ? updateReseller() : validateCredentials()"
                >
                  {{ validationSuccess ? 'Update' : 'Validate' }}
                </button>

                  <div class="flex error-sign-up" v-if="errors.length">
                    <span class="scale-75 material-symbols-outlined mr-1">error</span>
                    <p>{{ errors[0] }}</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

  export default {
    name: 'CardCoverageEdit',
    data() {
      return {
        username: '',
        accountID: '',
        exist: '',
        showPassword: false,
        validationSuccess: false,
        availvendors: [],
        apiProviders: [],
        apiUrl: '',
        apiUser: '',
        apiKey: '',
        apiPass: '',
        apiTimeout: 30,
        team: {
          members: [],
        },
        selectedVendor: '',
        providers: {
          country: [],  
          vendors: [], 
        }, 
        errors: [],
        default: {
             // Incase no provider logo is available
             mapchi:'https://res.cloudinary.com/dlucmehqv/image/upload/v1718364729/mapchi-iconnewblue_lipacz_xkhiey_adt4mv.png'
        },
        text: 'If your provider offers pricing information through APIs, please input those details here. The API information will be utilized for your specific pricing, discounts, and feasibility-related queries associated with your provider agreement.If you dont have any API details, dont worry. You can still utilize all networks for feasibility and coverage checks. Just be aware that pricing wont be automated. However, you can easily input it manually through the Price Plans tab under Administration to ensure acurate product pricing.'
      }
    },
  
  props: {
    country: Number,
    countryName: String,
  },

  emits: ['update-edit'],

  mounted() {
    this.getProviders()
    this.getApiProviders()
    this.getVendors()
    if (this.filteredVendors.length > 0) {
      this.selectedVendor = this.filteredVendors[0];
    }

  },

  computed: {
    isSelectNetwork() {
      return this.selectedVendor !== '';
    },

    filteredVendors() {
      let filteredVendors = [];
      if (Array.isArray(this.providers) && this.providers.length > 0) {
        const matchingProvider = this.providers.find(provider => provider.country === this.country);
        if (matchingProvider) {
          filteredVendors = this.apiProviders
            .filter(apiProvider => apiProvider.vendor.some(vendor => matchingProvider.vendors.includes(vendor)))
            .map(apiProvider => ({
              name: apiProvider.name,
              logo_url: apiProvider.logo_url || '',
              apiDetailsId: apiProvider.id 
            }));
        }
      }
      return filteredVendors;
    },

  },

    watch: {
    country(newValue) {
      this.getProviders(newValue);
    },
    selectedVendor(newValue) {
        this.fetchApiDetails(newValue);

     },
   },

    methods: {
      async fetchApiDetails(newValue) {
        try {
          const response = await axios.get(`/coverage/api-details/${newValue.apiDetailsId}/`);
          if (response.data) {
            this.exist = response.data.api_url
            this.apiUrl = response.data.api_url;
            this.apiUser = response.data.api_user;
            this.apiKey = response.data.api_key;
          } else {
            this.apiUrl = '';
            this.apiUser = '';
            this.apiKey = '';
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            this.apiUrl = '';
            this.apiUser = '';
            this.apiKey = '';
          } else {
            console.error('Error fetching ApiDetails:', error);
          }
        }
      },


      getproviderLogo(logoUrl) {
        return logoUrl;
      },

      async getVendors() {
         this.$store.commit('setIsLoading', true)
        try {
          const response = await axios.get(`/vendor/vendors/`)
          this.availvendors = response.data;
        } catch (error) {
          console.error(error);
        }
        this.$store.commit('setIsLoading', false)
      },  

      async getApiProviders() {
        await axios
            .get('/coverage/apiprovider/')
            .then(response => {
              this.apiProviders = response.data
            })
            .catch(error => {
              console.log(error)
            })

      },


      async getProviders() {
        await axios
            .get('/coverage/userglobalcoverages/')
            .then(response => {
              this.providers = response.data
            })
            .catch(error => {
              console.log(error)
            })
      },

      async getTeam() {
        await axios
            .get('/mapche-api/v1/business/get_my_business/')
            .then(response => {
              this.team = response.data
            })
            .catch(error => {
              console.log(error)
            })

      },


      async updateReseller() {
        this.errors = [];

        if (!this.apiUrl.trim()) {
          this.errors.push('API URL cannot be empty');
        }

        if (!this.selectedVendor || !this.selectedVendor.apiDetailsId) {
          this.errors.push('Selected vendor does not have an associated API details ID');
        }
        if (!this.errors.length) {
          this.$store.commit('setIsLoading', true);
          const formData = {
            business: this.$store.state.business.id,
            api_url: this.apiUrl,
            api_user: this.apiUser,
            api_key: this.apiKey,
            api_timeout: this.apiTimeout,
            api_provider: this.selectedVendor.apiDetailsId
          };

          let method = 'post';
          let url = '/coverage/api-details/';

          if (this.exist.trim() && this.selectedVendor.apiDetailsId) {
            method = 'put';
            url += `${this.selectedVendor.apiDetailsId}/`;
          }

          try {
            await axios[method](url, formData);

            toast({
              message: 'Data saved successfully',
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });

            this.exist = ''
            const isEditExpanded = false;
            this.$emit('update-edit', isEditExpanded, this.country, this.selectedVendor.apiDetailsId);
          } catch (error) {
            if (error.response && error.response.status === 400) {
              this.errors = [];

              for (const property in error.response.data) {
                const errorMessage = `${property}: ${error.response.data[property]}`;

                toast({
                  message: errorMessage,
                  type: 'is-danger', 
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                });
              }
            } else if (error.message) {
              const errorMessage = 'Something went wrong. Please try again!';
              toast({
                message: errorMessage,
                type: 'is-danger', 
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              });
            }
          }

          this.$store.commit('setIsLoading', false);
        } else if (this.errors.length) {
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        }
      },

      async validateCredentials() {
        this.$store.commit('setIsLoading', true); 
        
        try {
          let apiUrl, formData, token, connection, requestBody;

          switch (this.countryName) {
            case 'South Africa':
              apiUrl = this.apiUrl;
              formData = {
                username: this.apiUser,
                password: this.apiPass 
              };
              break;

            default:
              throw new Error('Unsupported country');
          }

          if (apiUrl) {
            let response;
            if (formData) {
              response = await axios.post(apiUrl, formData);
            } else if (token) {
              response = await axios.post(apiUrl, requestBody, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                }
              });
            } else {
              throw new Error('API URL or token not provided');
            }

            if (response.data) {
              toast({
                message: 'Credentials are valid',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              });
              
              this.validationSuccess = true;
              this.updateReseller();

              return response.data;
            } else {
              throw new Error('Response data not found');
            }
          } else {
            throw new Error('API URL not provided');
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast({
              message: 'Unauthorized access. Please check your credentials.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });
          } else {
            toast({
              message: 'Failed to validate credentials. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            });
          }
        } finally {
          this.$store.commit('setIsLoading', false); 
        }
      },




  
    }
  }
</script>
<style scope>
.error-sign-up {
border-radius: 0.5rem;
margin-left: 8rem;
align-items: center;
justify-content: center;
}

.error-sign-up p {
font-size: 12px;
font-weight: bold;
color: #f420cd;
}
.error-sign-up span {
color: #f420cd;
}

.highlighted {
  font-weight: bold; 
}

.sign-up-input {
display: block;
position: relative;
}

.sign-up-input button {
position: absolute;
right: 10px;
top: 60%;
transform: translateY(-50%);
cursor: pointer;
color: #747474;
}


</style>
