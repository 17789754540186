<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
   <div class="rounded-t mb-0 px-6 py-4 border-0">
      <div class="flex justify-between items-center">
        <div>
          <h3 v-if="selectedUserProviders.length"
            class="font-bold text-xl"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Items<i class="fas fa-share text-emerald-500 px-1"></i>{{ totalproducts }}
          </h3>
          <h6 v-else class="text-blueGray-700 text-xl font-bold">Lets add a provider first
            <router-link
              :to="{ name: 'Networks'}"
              class="bg-emerald-600 text-white active:bg-emerald-600 ml-4 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              Networks
            </router-link>
          </h6>
        </div>


        <div v-if="selectedUserProviders.length" class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="query"
                v-on:click.stop
              />
        </div>
     </div>
    </div>  
<div v-if="selectedUserProviders.length">
  <div :class="leadHeaderClasses">
    <div style="max-width: calc(9rem * 11)">
      <div class="flex overflow-x-auto space-x-2">
        <div v-if="query.length === 0 && selectedUserProviders.length" v-for="provider in filteredProviders" :key="provider.supplier_id" 
          :class="{ 'selected-provider': !selectedProvider.includes(provider.supplier_id) }">
          <div class="flex flex-col items-center">
            <img
              :src="getProviderImageURL(provider)"
              class="lead-item max-w-xs bg-white rounded-t rounded-full border mx-3"
              @click="selectProvider(provider)"
              :class="{ 'selected-provider-img': selectedProvider.includes(provider.supplier_id) }"
            />
            <span class="mt-3"></span>
          </div>
        </div>
        <div v-if="query.length > 0 && selectedUserProviders.length > 0" v-for="provider in filteredProvidersSearch" :key="provider.supplier_id" 
          :class="{ 'selected-provider': !selectedProvider.includes(provider.supplier_id) }">
          <div class="flex flex-col items-center">
            <img
              :src="getProviderImageURL(provider)"
              class="lead-item max-w-xs bg-white rounded-t rounded-full border mx-3"
              @click="selectProvider(provider)"
              :class="{ 'selected-provider-img': selectedProvider.includes(provider.supplier_id) }"
            />
            <span class="mt-3"></span>
          </div>
        </div>

      </div>
    </div>
  </div>


    <div class="border w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              @click="toggleAddExpanded"
              class="lead-itemprovider px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left bg-emerald-600 text-white hover:text-emerald-600"
            >
            <i :class="isAddExpanded ? 'fas fa-times-circle' : 'fas fa-plus-circle'"></i>
                <span class="ml-2">{{ isAddExpanded ? 'Cancel' : 'Add Price' }}</span>
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Provider
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Product Name
            </th>            
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Service
            </th>            
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Service Type
            </th>            
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Cost Code
            </th>
            <th
              class="px-6 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Feasibility Rule
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Speed
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Term
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Once-Off
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Monthly
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>            
          </tr>
        </thead>
        <tbody>
          <template v-for="product in sortedProducts" :key="product.vendor_id">
          <tr 
           class="lead-itemprovider" 
          :class="{ 'bg-gray-200': selectedProduct === product}"

          >
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 py-4"
          >
           <span
              class="ml-3 font-bold capitalize cursor-pointer hover:text-emerald-600"
              :class="[
                color === 'light' ? 'text-blueGray-600' : 'text-white',
                { 'hover:bg-gray-200': !isEditExpanded }
              ]"
              style="line-height: 2.5rem;"
              @click="toggleEdit(product)"
            >
            <i class="fas fa-caret-down mr-1"></i>{{ isEditExpanded && selectedProduct === product ? 'Close' : 'Edit' }}
            </span>            
          </td>
          <td
            class="px-4 border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
            {{ product.vendor_name}}
          </td>
          <td
            class="px-4 align-middle text-xs"
          >
           {{ product.name }}
          </td>
          <td
            class="px-4 align-middle text-xs whitespace-nowrap capitalize"
          >
            {{ product.service_name}}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
            {{ product.service_type }}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap uppercase"
          >
          {{ product.cost_code }}
          </td>        
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          {{ getDescriptionAfterAsterisk(product.maprule_name) }}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            {{ product.capacity }} Mbps
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            {{ product.term}} Months
          </td>
          <td
            class="px-5 align-middle text-xs whitespace-nowrap"
          >
             {{ getSymbol(product.currency) }} {{ product.nrc }}
          </td>
          <td
            class="px-5 align-middle text-xs whitespace-nowrap"
          >
          {{ getSymbol(product.currency) }} {{ product.mrc }}
          </td>                 
          <td
            class="px-5 align-middle text-xs whitespace-nowrap"
          >
          <input type="checkbox" class="focus:ring-0 text-emerald-600" v-model="product.is_active" disabled />
          </td>          
        </tr>
        <tr v-if="selectedProduct === product && isEditExpanded">
          <td colspan="12"> 
            <CardPricing :product="product" @update-update="handleUpdateFeatures" /> 
          </td>
        </tr> 
        </template>
        <tr v-if="isAddExpanded">
          <td colspan="12"> 
            <CardPricingAdd @update-add="handleUpdateAdd"/> 
          </td>          
        </tr> 
      </tbody>
   </table>
   </div>
   </div>
   </div>
   </template>
<script>
import axios from 'axios'
import CardPricing from "@/components/Cards/CardPricing.vue";
import CardPricingAdd from "@/components/Cards/CardPricingAdd.vue";
import { getCurrencyList  } from '@/components/currency';  

export default {
  name: 'Pricingsum',
  
  async created() {
    this.currencyList = await getCurrencyList()
  },

  mounted() {
    this.getVendors()
  },

  data() {
    return {
      query: '',
      selectedUserProviders: [],
      selectedProvider: [],
      providers: [],
      isEditExpanded: false,
      isAddExpanded: false,
      totalproducts: 0,
      products: [],
      selectedProduct: null,
      currencyList: [],
      allservices: [],
    };
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    }
  },  

  components: {
        CardPricing,
        CardPricingAdd,
  },
  
  watch: {
    isAddExpanded(newValue) {
      if (newValue) {
        this.products = [];
      } else {
        this.getVendors()
      }
    },
    isEditExpanded(newValue) {
      if (!newValue) {
        this.getVendors()
      }
    },

     
     query(newVal, oldVal) {
      if (newVal !== oldVal && this.query && this.query.length > 3 || this.query.length == 0) {
        if (this.query.length === 0) {
          this.selectedProvider = []; 

        }

        this.getVendors()
      }
    }     
  }, 

  computed: {
    leadHeaderClasses() {
      return {
        'lead-header': true,
        'selected-header': this.selectedProvider !== []
      }
    },

    filteredProvidersSearch() {
      const businessId = this.$store.state.business.id
      const allServicesArray = this.allservices.reduce((acc, obj) => {
        return acc.concat(obj.services.map(service => ({ service, supplier_id: obj.supplier_id })))
      }, [])

      const filteredProducts = this.providers.reduce((acc, provider) => {
        const filtered = this.filterProductsByBusiness(provider.products, businessId);
        return acc.concat(this.mapProductsToServices(filtered))
      }, []);     


const filteredSelectedUserProvider = allServicesArray.filter(item => {
  for (const product of filteredProducts) {
    if (product.service === item.service && product.supplier_id === item.supplier_id) {
      return true; 
  }
  }
  return false; 
});

      let filteredProvider = this.providers

      if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
        filteredProvider = filteredProvider.filter(provider =>
        filteredSelectedUserProvider.some(item => item.supplier_id === provider.supplier_id)
        )
      } else {
        return []
      }
      
      filteredProvider = filteredProvider.sort((a, b) => a.name.localeCompare(b.name));
      return filteredProvider
      
    },

    filteredProviders() {
      const businessId = this.$store.state.business.id
      const allServicesArray = this.allservices.reduce((acc, obj) => {
        return acc.concat(obj.services.map(service => ({ service, supplier_id: obj.supplier_id })))
      }, [])

      const filteredProducts = this.providers.reduce((acc, provider) => {
        const filtered = this.filterProductsByBusiness(provider.products, businessId);
        return acc.concat(this.mapProductsToServices(filtered))
      }, []);     
      const filteredSelectedUserProvider = allServicesArray
        .filter(item => filteredProducts.some(product => product.service === item.service))
        .map(item => ({ service: item.service, supplier_id: item.supplier_id }));

      let filteredProvider = this.providers

      if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {

        filteredProvider = filteredProvider.filter(provider =>
          allServicesArray.some(item => item.supplier_id === provider.supplier_id)
        )
      } else {
        return []
      }
      
      filteredProvider = filteredProvider.sort((a, b) => a.name.localeCompare(b.name));
      return filteredProvider
    },

    sortedProducts() {
      const activeProducts = this.products.filter(product => product.is_active);
      const inactiveProducts = this.products.filter(product => !product.is_active);

      const sortByModifiedAtDesc = (a, b) => new Date(b.modified_at) - new Date(a.modified_at);
      const sortedActiveProducts = activeProducts.sort(sortByModifiedAtDesc);
      const sortedInactiveProducts = inactiveProducts.sort(sortByModifiedAtDesc);

      return [...sortedActiveProducts, ...sortedInactiveProducts];
    }
  },
  methods: {

    filteredProviderProducts() {
      const businessId = this.$store.state.business.id;
      const allServicesArray = this.allservices.reduce((acc, obj) => {
        return acc.concat(obj.services.map(service => ({ service, supplier_id: obj.supplier_id })));
      }, []);

      const filteredProducts = this.providers.reduce((acc, provider) => {
        const filtered = this.filterProductsByBusiness(provider.products, businessId);
        return acc.concat(this.mapProductsToServices(filtered));
      }, []);

      const filteredSelectedUserProvider = allServicesArray
      .filter(item => filteredProducts.some(product => product.service === item.service))
      .map(item => ({ service: item.service, supplier_id: item.supplier_id }));
      let finalProducts = this.products

      if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
       finalProducts = finalProducts.filter(product =>
         filteredSelectedUserProvider.some(item => item.supplier_id === product.supplier_id && item.service === product.service)
       )
      } else {
        return [];
      }
      return finalProducts
    },

    toggleAddExpanded() {
      this.isAddExpanded = !this.isAddExpanded;
    },

    handleUpdateFeatures(isEditExpanded, selectedProvider, updatedFeatures) {
      this.isEditExpanded = isEditExpanded
    },

    handleUpdateAdd(isAddExpanded) {
      this.isAddExpanded = isAddExpanded
    },

    getSymbol(currency) {
      const currencyObject = this.currencyList.find(item => item.code === currency);
      return currencyObject ? currencyObject.symbol : 'N/A';
    },

    selectProvider(provider) {
      const selectedProviderIndex = this.selectedProvider.indexOf(provider.supplier_id);

      if (selectedProviderIndex !== -1) {
        this.selectedProvider.splice(selectedProviderIndex, 1); 
      } else {
        this.selectedProvider.push(provider.supplier_id); 
      }
      this.getProduct(this.selectedProvider); 
    },
    getProviderImageURL(provider) {
    return provider.logo_url || 'https://res.cloudinary.com/dlucmehqv/image/upload/v1718364725/Logo_-_Vodacom_cxrly8_pz50zm_wewdpn.png';
  },
    toggleEdit(product) {
        if (this.selectedProduct === product) {
          this.isEditExpanded = !this.isEditExpanded;
        } else {
          this.selectedProduct = product;
          this.isEditExpanded = true;
        }
    },

    filterProductsByBusiness(products, businessId) {
      return products.filter(product => product.business === parseInt(businessId));
    },

    mapProductsToServices(products) {
      return products.map(product => ({
        service: product.service,
        supplier_id: product.supplier_id,
      }))
    },

    async getVendors() {
      this.$store.commit('setIsLoading', true);
      await axios.get(`/vendor/vendors/?search=${this.query}`)
        .then(response => {
          this.providers = response.data;


          if (this.query.length > 0 && this.selectedUserProviders.length > 0) {
            const filteredSupplierIds = this.providers
              .map(provider => provider.supplier_id)
              .filter(supplierId => this.selectedUserProviders.includes(supplierId));
            
            if (filteredSupplierIds.length > 0) {
              this.selectedProvider = [...filteredSupplierIds];
            }
          }

          this.checkUserPreferencesVendor()
        })
        .catch(error => {
          console.error(error);
        });
      this.$store.commit('setIsLoading', false);
    },

    
        async checkUserPreferencesVendor() {
          this.$store.commit('setIsLoading', true)
          await axios
            .get('/mapche-api/v1/user-preferences/')
            .then((response) => {
              this.selectedUserProviders = []
              this.allservices = response.data
              this.selectedUserProviders = response.data.map(item => item.supplier_id)

              this.getProduct(this.selectedProvider)

             })
            .catch((error) => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },


    async getProduct(supplier_id) {
          this.$store.commit('setIsLoading', true)
          if (supplier_id.length > 0) {
          await axios.get(`/products/product/?supplier_id=${supplier_id}&search=${this.query}`)
            .then(response => {
              this.products = response.data
              if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
                const filteredSupplierIds = this.filteredProviderProducts()
                this.totalproducts = filteredSupplierIds.length
                this.products = filteredSupplierIds
              }            
            })
            .catch(error => {
              console.error(error);
            })
          } else {
            await axios.get(`/products/product/?search=${this.query}`)
            .then(response => {
              this.products = response.data

              if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
                const filteredSupplierIds = this.filteredProviderProducts()
                this.totalproducts = filteredSupplierIds.length
                this.products = filteredSupplierIds
              }


            })
            .catch(error => {
              console.error(error);
            });
          }
            this.$store.commit('setIsLoading', false)  
        },
      
      selectProduct(product) {
        this.selectedProduct = product
      },

      getDescriptionAfterAsterisk(description) {
        const parts = description.split('*');
        return parts.length > 1 ? parts[1] : description;
      },
  },
}
</script>
<style scoped>
.lead-itemprovider {
  padding: 10px;
    cursor: pointer;
}

.lead-itemprovider:hover {

   background-color: #f1f3f6;
}


.lead-item {

  transition: transform 0.8s ease, box-shadow 0.3s ease; 
  box-shadow: none; 
}

.lead-item:hover {
  background-color: #fff;
  transform: scale(1.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
  opacity: 1;
}

.lead-header {
  background-color: #f1f3f6;
}


.selected-provider {
  background-color: #f1f3f6;
  opacity: 0.5;
}
.selected-provider:hover {
  background-color: #f1f3f6;
  opacity: 1;
}
.selected-provider-img {
  border-width: 1px; 
  border-style: solid;
  border-color: #e4e4e7;
  transform: scale(1.2);
  box-shadow: 0 1px 2px 0 rgba(168, 7, 130, 0.05);
}

</style>
