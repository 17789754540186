<template>

<div class="page-container">
    <div class="filter-bar" :class="{ expand: isExpanded }">

      <div class="filter-header">
        <div class="sort-selector row">
          <span class="text-xs font-bold uppercase leading-normal px-2 py-2">Sort:</span>
          <div class="expanding-dropdown expanded row">
          <div class="pr-2">
            <span class="text-xs font-bold hover:text-blueGray-700 uppercase px-5 py-2 shadow-md rounded block leading-normal" v-on:click="toggleFilterTab('term')" v-bind:class="{'text-emerald-600 bg-white': filterTab !== 'term', 'text-white bg-emerald-600': filterTab === 'term'}">
            Term</span>
          </div>
          <div class="pr-2">
            <span class="text-xs font-bold hover:text-blueGray-700 uppercase px-5 py-2 shadow-md rounded block leading-normal" v-on:click="toggleFilterTab('speed')" v-bind:class="{'text-emerald-600 bg-white': filterTab !== 'speed', 'text-white bg-emerald-600': filterTab === 'speed'}">
            Speed</span>
          </div>
          <div class="pr-6">
            <span class="text-xs font-bold hover:text-blueGray-700 uppercase px-5 py-2 shadow-md rounded block leading-normal" v-on:click="toggleFilterTab('price')" v-bind:class="{'text-emerald-600 bg-white': filterTab !== 'price', 'text-white bg-emerald-600': filterTab === 'price'}">
            Price</span>
          </div>
          <select class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 uppercase px-7 py-2 shadow-md rounded block leading-normal text-blueGray-500 bg-white" v-model="orderDirection">
        <option value="asc">Low to High</option>
        <option value="desc">High to Low</option>
      </select>
         </div>
        </div>
        <div class="flex-section"></div>

        <div class="filter-collapse-button rounded block shadow-md leading-normal text-emerald-600 hover:text-blueGray-700" v-on:click="toggleExpand">
          <i class="material-icons">filter_list</i>
        </div>
      </div>
      

      <div class="filter-panel mt-2 rounded">

        
        <div
          class="filter-item dropdown filter-dropdown expanding-dropdown focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
          v-on:click="toggleDropdown"
        >
          TERM
          <div
            id="myDropdown"
            class="dropdown-content expanding-dropdown"
            :class="{ expanded: isDropdownExpanded }"
            v-on:click.stop
          >
            <div class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 uppercase px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="filterValue"
                v-on:input="filterItems"
                v-on:click.stop
              />
            </div>
            <a
              v-for="item in filteredItems"
              :key="item"
              href="#"
              v-on:click="selectCategory(item)"
            >
              {{ item }}
            </a>
          </div>
        </div>

        <div
          class="filter-item dropdown filter-dropdown expanding-dropdown focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
          v-on:click="toggleDropdown"
        >
          SPEED
          <div
            id="myDropdown"
            class="dropdown-content expanding-dropdown"
            :class="{ expanded: isDropdownExpanded }"
            v-on:click.stop
          >
            <div class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 uppercase px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="filterValue"
                v-on:input="filterItems"
                v-on:click.stop
              />
            </div>
            <a
              v-for="item in filteredItems"
              :key="item"
              href="#"
              v-on:click="selectCategory(item)"
            >
              {{ item }}
            </a>
          </div>
        </div>


        <div
          class="filter-item dropdown filter-dropdown expanding-dropdown focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
          v-on:click="toggleDropdown"
        >
          PRICE
          <div
            id="myDropdown"
            class="dropdown-content expanding-dropdown"
            :class="{ expanded: isDropdownExpanded }"
            v-on:click.stop
          >
            <div class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 uppercase px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="filterValue"
                v-on:input="filterItems"
                v-on:click.stop
              />
            </div>
            <a
              v-for="item in filteredItems"
              :key="item"
              href="#"
              v-on:click="selectCategory(item)"
            >
              {{ item }}
            </a>
          </div>
        </div>


        <div class="flex items-center">
          <label class="ml-6 flex items-center space-x-1 text-xs font-bold hover:text-blueGray-700 uppercase py-2 leading-normal text-emerald-600">
            <input type="checkbox" class="form-checkbox focus:ring-0 text-emerald-500" />
            <span>Router</span>
          </label>
          <label class="ml-2 flex items-center space-x-1 text-xs font-bold hover:text-blueGray-700 uppercase py-2 leading-normal text-emerald-600">
            <input type="checkbox" class="form-checkbox focus:ring-0 text-emerald-500" />
            <span>IP address</span>
          </label>
          <label class="ml-2 flex items-center space-x-1 text-xs font-bold hover:text-blueGray-700 uppercase py-2 leading-normal text-emerald-600">
            <input type="checkbox" class="form-checkbox focus:ring-0 text-emerald-500" />
            <span>SLA</span>
          </label>
        </div>
      </div>
    </div>
  </div>


  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Description
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Term
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Speed
              </th>                   
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                NRC
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                MRC
              </th>
              <th
                class="align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(speed, index) in filteredResults"
              v-bind:key="index"
            >
            <td 
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left"
            >
              <div class="flex item-center">
                <span
                  class="ml-0 font-bold capitalize"
                  :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white']" style="line-height: 2.5rem;"
                >
                  {{ speed.description}}
                </span>
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                {{ speed.contractPeriod }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                {{ speed.capacity }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                  {{ speed.NRC }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                  {{ speed.MRC }}
              </td>
  
              <td
                class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
              >
              <i  class="material-icons pr-1" style="color: #10b981">verified</i>
              </td>
            </tr>
            <br/>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { getProviders } from '@/components/provider.js';
  
  
  export default {
    name: 'Searches',
  
    mounted() {
      this.providers(this.lat, this.lng, this.suppliername)
    },
  
    data() {
      return {
        isExpanded: false,
        isDropdownExpanded: false,
        filterValue: '',
        dropdownItems: ['1', '12', '24', '36'],
        filteredItems: [],
        filterTab: 'term',
        orderDirection: 'asc',
        searches: [],
        providerApiresults: [],
      };
    },

    props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    suppliername: String,
    lat: Number,
    lng: Number,
    searchQuery: {
      type: String,
      default: '',
    },
   },

   computed: {
      sortedResults() {
      const sorted = [...this.providerApiresults]
      
        return sorted.sort((a, b) => {
          if (a.contractPeriod < b.contractPeriod) return -1;
          if (a.contractPeriod > b.contractPeriod) return 1;

          if (a.capacity < b.capacity) return -1;
          if (a.capacity > b.capacity) return 1;


          return 0; 
        });
      },
      filteredResults() {
      let filtered = this.sortedResults


      if (this.searchQuery) {
        const filterValue = this.searchQuery.toLowerCase();
        filtered = filtered.filter(item => {
          return (
            (item.capacity && item.capacity.toString().toLowerCase().includes(filterValue)) ||
            (item.contractPeriod && item.contractPeriod.toString().toLowerCase().includes(filterValue)) ||
            (item.description && item.description.toLowerCase().includes(filterValue)) ||
            (item.MRC && item.MRC.toString().toLowerCase().includes(filterValue)) ||
            (item.NRC && item.NRC.toString().toLowerCase().includes(filterValue))
          );
        });
      }

      switch (this.filterTab) {
        case 'price':
          filtered = filtered.sort((a, b) =>
            this.orderDirection === 'asc' ? a.MRC - b.MRC : b.MRC - a.MRC
          );
          break;
        case 'term':
          filtered = filtered.sort((a, b) =>
            this.orderDirection === 'asc' ? a.contractPeriod - b.contractPeriod : b.contractPeriod - a.contractPeriod
          );
          break;
        case 'speed':
          filtered = filtered.sort((a, b) =>
            this.orderDirection === 'asc' ? a.capacity - b.capacity : b.capacity - a.capacity
          );
          break;
      }
      return filtered;
      }
   },


    methods: {
      toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    toggleDropdown() {
      this.isDropdownExpanded = !this.isDropdownExpanded;
      this.filteredItems = this.dropdownItems;
    },
    filterItems() {
      const filterValue = this.searchQuery.toLowerCase();
      this.filteredItems = this.dropdownItems.filter(item =>
        item.toLowerCase().includes(filterValue)
      );
    },
    filterResults() {
    },
    selectCategory(item) {
    },

      isFilterTabActive(tab) {
        return this.filterTab === tab
      },

      toggleFilterTab(tab) {
        this.filterTab = this.filterTab === tab ? '' : tab;
      },

      async providers(lat, lng, suppliername) {
        if (suppliername != null) {
          this.$store.commit('setIsLoading', true)

          const { providerApiresults } = await getProviders(lat, lng, suppliername)
          this.providerApiresults = providerApiresults

          this.$store.commit('setIsLoading', false)
      }
      },
   
    }
  }
  </script>
<style scoped>

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");    

.filter-bar {
  width: 100%;
  border-radius: 2px;
}

.row {
  display: flex;
  align-content: center;
  align-items: center;
}

.filter-header {
  display: flex;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
}

.filter-bar.expand .filter-header {
  border-bottom: unset;
}

.sort-selector .sort-label{
  margin-right: 8px;
}

.sort-selector .dropdown {
  background: #E0E0E0;
}

.filter-bar.expand .filter-header {
  border-bottom: unset;
}

.filter-collapse-button {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;
  background-color: #ffffff;
}

.filter-collapse-button i {
  font-size: 22px;
}

.filter-bar.expand .filter-collapse-button{
  background-color: #059669;
  color: #ffffff;
}

.filter-panel {
  display: none;
}

.filter-bar.expand .filter-panel {
  display: flex;
  background: #fff;
  padding: 8px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.filter-item {
  margin-right: 8px;
}

.outline-chip {
  color: white;

}


.md-chip {
  display: flex;
  align-items: center;
  align-content: center;
}

.md-chip span {
  margin: 0 4px;
}

.md-chip i{
  margin-right: 4px;
  color: gray;
}

.md-chip-outline {
  background-color: white;
  border: 1px solid #E0E0E0;
}

.dropdown {
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 13px;
  padding: 4px 12px;
  background: white;
  position: relative;
  height: 32px;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
}

.expanding-dropdown {
    display: flex;
  align-content: center;
  align-items: center;
  font-size: 13px;
  padding: 4px 12px;
  border: 1px solid #10b981;
  position: relative;
  height: 32px;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px;
  overflow: overflow-x-auto;
}

.expanding-dropdown .md-chip {
  margin-right: 8px;
}

.expanding-dropdown.expanded {
  border: unset;
}

.filter-dropdown {
  border-radius: 16px;
}



.dropdown:after{
  content: 'keyboard_arrow_down';
  font-family: 'Material Icons';
  vertical-align: middle;
  margin-left: 8px;
}

.filter-dropdown .dropdown-content {
  display: none;
  position: absolute;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    -moz-osx-font-smoothing: grayscale;

    font-feature-settings: 'liga';
}

.flex-section {
  flex: 1;
}

.option {
  padding: 4px 8px;
  border: 1px solid white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  height: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%; 
  left: 0; 
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #f9f9f9; 
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}


.filter-item.dropdown {
  position: relative; 
}

.dropdown-content .search-box {
  padding: 8px;

}

.dropdown-content.expanded {
  display: block;
}


</style>

