<template>
  <div class="flex-auto overflow-x-auto px-4 lg:px-10 py-4 pt-0">
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="px-4 mt-0 border shadow-md">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-3 font-bold uppercase">
                Active API Providers
              </h6>
              <div>

                    <div v-for="(apicountry, index) in apiProvider" :key="index">
                      <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="selectedVendor"
                      >
                      {{ apicountry.country_name }}
                    </label>
                    <div class="flex items-center mt-2 mb-2">
                                    <span v-for="(provider) in getUniqueProviders(apicountry.api_providers_names)" :key="provider.id" class="text-xs bg-gray-100 px-2 mr-2 capitalize" @click="removeProvider(apicountry, provider)"> 
                                      <span class="ml-1 mb-1 font-bold">{{ provider }}</span><button class="ml-1 mb-1 text-lg">×</button>
                                    </span>
                                  </div>

                    </div>
                  </div>

              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      
</template>
<script>
import axios from 'axios'

  export default {
    name: 'CardCoverageRemove',
    data() {
      return {
        apiProvider: [],
        providers: {
          country: [], 
          vendors: [], 
        }, 
      }
    },
  
  props: {
    country: Number,
  },

  emits: ['update-edit'],

  mounted() {
    this.getProviders()
  },


    methods: {
      getUniqueProviders(apiProviders) {
        const uniqueProviders = new Set(apiProviders);
        return Array.from(uniqueProviders);
      },
      removeProvider(country, provider) {
        const index = country.api_providers_names.indexOf(provider);
        if (index !== -1) {
          
          country.api_providers_names.splice(index, 1);
          const apiID = country.api_providers
          const apiIDString = JSON.stringify(apiID);
          const apiIDArray = JSON.parse(apiIDString);
          country.api_providers = country.api_providers.filter((_, idx) => idx == index);

          const apiProvidersString = JSON.stringify(country.api_providers);
          const apiProvidersArray = JSON.parse(apiProvidersString);
          const isEditExpanded = false;
          const activeRemove = true;
          this.$emit('update-edit', isEditExpanded, this.country, apiProvidersArray, apiIDArray, activeRemove);
        }
      },   
      async getProviders() {
        await axios
            .get('/coverage/userglobalcoverages/')
            .then(response => {
              this.providers = response.data
              this.apiProvider = response.data.filter(country => country.country === this.country);
            })
            .catch(error => {
              console.log(error)
            })
      },



  
    }
  }
</script>
