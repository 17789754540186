<template>
  <div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
<div v-if="!totalresellers && !invites" class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Resellers<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalresellers }}</h6>
      </div>
</div>
  <div v-else>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Resellers<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalresellers}}
        </h6>
        <div class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="query"
                v-on:input="handleSearch"
                v-on:click.stop
              />
        </div>
      </div>
  </div>

<div class="flex flex-wrap">
  <div class="w-full">
    <div class="w-full px-4">
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-emerald-600': openTab === 1}">
          <i class="fas fa-chart-pie text-base mr-1"></i> Summary   
        </a>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-emerald-600': openTab === 2}">
          <i class="fas fa-id-card text-base mr-1"></i> Leads
        </a>
      </li>
    </ul>
  </div>
      <div class="px-4 py-5 flex-auto">
        <div class="tab-content tab-space">
          <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
            <CardResellers v-if="openTab === 1" :resellers="resellers" :invites="invites" @resellerlead-selected="handleResellerLeadSelected" :searchQuery="query"/>
          </div>     
          <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
            <CardResellerLeads :resellerleads="selectedResellerLead" :currentreseller="currentreseller"/>
          </div>
        </div>

    </div>
  </div>
</div>
</div> 
</div>
</template>

<script>
import axios from 'axios'
import CardResellers from "@/components/Cards/CardResellers.vue";
import CardResellerLeads from '@/components/Cards/CardResellerLeads.vue';

export default {
name: "resellers-tabs",

mounted() {
  this.getResellers()
  this.getInvited()
},

data() {
  return {
    totalresellers: 0,
    leads: [],
    resellers: [],
    invites: [],
    selectedResellerLead: [],
    currentreseller: '',
    query: '',
    openTab: 1,
    selectedLeadId: null,
    selectedLeadSearches: null,
    amountSearches: null,
    leadProvider: null,
    suppliername: null,
    lat: null,
    lng: null,
  }
},

components: {
  CardResellers,
  CardResellerLeads,
},


methods: {
  async getResellers() {
        this.$store.commit('setIsLoading', true)

        await axios
          .get('/mapche-api/v1/business/view_reseller_business/')
            .then(response => {
              this.data = response.data

              this.resellers = this.data.map(resellerData => {
                const businessData = resellerData.business;
                const leadsData = resellerData.leads;

                return {
                  id: resellerData.id,
                  business: businessData,
                  leads: leadsData,
                  member_first: resellerData.member_first,
                  member_last: resellerData.member_last,                  
                }
              })
              this.totalresellers = this.resellers.length


            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async getInvited() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/resellerinvite/')
            .then(response => {
              this.invites = response.data

            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

  async getLeads() {
    this.$store.commit('setIsLoading', true)
          axios
            .get('/mapche-api/v1/leads/')
            .then(response => {
              this.totalleads = response.data.count
              this.leads = response.data
              if (this.leads.length > 0) {
                var choice = this.leads.length -1
                this.selectedLead = this.leads[choice]
                this.selectedLeadSearches = this.leads[choice].shopper_phone
                this.suppliername = this.leads[choice].suppliername
                const locdetails = this.selectedLead.search
                this.getLocationDetails(locdetails)
              }
            })
            .catch(error => {
              console.log(error)
            })
    
    this.$store.commit('setIsLoading', false)
  },

  async getLocationDetails(locdetails) {
    this.$store.commit('setIsLoading', true)

    await axios
        .get(`/mapche-api/v1/search/${locdetails}/`)
        .then(response => {
          this.lat = response.data.location_lat
          this.lng = response.data.location_lng
        })
        .catch(error => {
          console.log(error)
        })

    this.$store.commit('setIsLoading', false)
  },

  toggleTabs: function(tabNumber){
    this.openTab = tabNumber
  },

  handleResellerLeadSelected(reseller, currentreseller) {
    this.selectedResellerLead = reseller
    this.currentreseller = currentreseller
  },

}
}
</script>
