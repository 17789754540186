<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 v-if="user.first_name && user.last_name" class="text-blueGray-700 text-xl font-bold">My Account</h6>
        <h6 v-else class="text-blueGray-700 text-xl font-bold">Tell us a bit more</h6>
        <router-link v-if="(isCreatedByCurrentUser || isCurrentUserAdmin)" 
              :to="{'name': 'Settings'}"
              class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              Settings
            </router-link>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
          User Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="firstname"
              >
                First Name
              </label>
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                ref="firstNameInput"
                type="text"
                id="firstname"
                required
                placeholder="First Name *"
                autocomplete="given-name"
                v-model="user.first_name"
                :disabled="!isEditMode"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="lastname"
              >
                Last Name
              </label>
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="text"
                id="lastname"
                required
                placeholder="Last Name *"
                autocomplete="family-name"
                v-model="user.last_name"
                :disabled="!isEditMode"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="email"
              >
                Email Address
              </label>
              <input v-if="isDemo"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                disabled
                autocomplete="email"
                type="email"
                v-model="user.username"
                id="email"
               /> 
               <input v-else
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :disabled="!isEditMode"
                autocomplete="email"
                type="email"
                v-model="user.username"
                id="email"
               /> 
            </div>
          </div>

          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="email"
              >
                Notifications Email
              </label>
               <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-500 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                :disabled="!isEditMode"
                autocomplete="email"
                type="email"
                v-model="user.email"
                id="email"
               /> 
            </div>
          </div>


        <div v-if="!isMobileUpdated" class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label v-if="!isVerified"
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Phone Number
              </label>
              <label v-else
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="otp"
              >
                Enter OTP *
              </label>
                <div v-if="!isVerified">
                  <MazPhoneNumberInput
                  v-model="userverify"
                  color="info"
                  :enableAreaCodes="false"
                  :no-search="true"
                  default-country-code="ZA"
                  :size="'md'"
                  @update="results = $event"
                  :success="isValid"
                  />
                </div>
                <div v-else>
                  <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                ref="otpinput"
                type="text"
                id="otp"
                required
                maxlength="6"
                v-model="otp"
              />
                </div>
             <div v-if="isVerified && isDemo">
              <label
                class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="otp"
              >
                PIN TESTING *
              </label>
              <input
              class="mt-1 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              type="text"
              disabled
              v-model="temp"
              />
            </div>   
            </div>
          </div>
          <div v-else class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="phoneNumber"
              >
                Phone Number
              </label>
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                type="text"
                id="phoneNumber"
                v-model="userverify"
                disabled
              />
            </div>
          </div>

        </div>
 

          <hr class="mt-5 border-b-1 border-blueGray-300" />
          <div class="ml-4">


    </div>
      <div v-if="!isBusinessNameUser">
        <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
          Business Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="businessname"
              >
                Legal business name
              </label>
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="text"
                id="businessname"
                required
                placeholder="Business Name ABC Pty(Ltd) *"                
                autocomplete="business name"
                v-model="business"
                :disabled="!isEditMode"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-xs font-bold mb-2"
              :class="{ 'text-blueGray-600': imageUrl || isEditMode }"
              for="image"
            >
              {{ !imageUrl || isEditMode ? 'Upload business logo (Size: 300 x 80)' : 'Business Logo' }}
            </label>
            <div v-if="!imageUrl || isEditMode">
            <div class="flex">
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="file"
                id="image"
                :disabled="!isEditMode"
                @change="handleImageChange"
              >
            </div>
          </div>
          </div>

          <div v-if="selectedImage">
            <img :src="getAbsoluteImageUrl()" alt="Selected Image" :key="selectedImageKey" class="max-w-full h-auto mb-4">
          </div>

          <div v-else-if="imageUrl">
            <div>
              <img :src="imageUrl" alt="Business Image" class="max-w-full h-auto">
            </div>
          </div>
        </div>
        </div>
      </div>
      <div v-else>
        <div v-if="isCreatedByCurrentUser">
          <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
          Business Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="businessname"
              >
                Legal business name
              </label>
              <input v-if="isEditMode && isDemo"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="text"
                id="businessname"
                required
                placeholder="Business Name ABC Pty(Ltd) *"                
                autocomplete="business name"
                v-model="business"
                :disabled="isDemo"
              />
              <input v-else
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="text"
                id="businessname"
                required
                placeholder="Business Name ABC Pty(Ltd) *"                
                autocomplete="business name"
                v-model="business"
                :disabled="isDemo"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-xs font-bold mb-2"
              :class="{ 'text-blueGray-600': imageUrl || isEditMode }"
              for="image"
            >
              {{ !imageUrl || isEditMode ? 'Upload business logo (Size: 300 x 80)' : 'Business Logo' }}
            </label>
            <div v-if="!imageUrl || isEditMode">
            <div v-if="isDemo" class="flex">
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="file"
                id="image"
                :disabled="isDemo"
                @change="handleImageChange"
              >
            </div>
            <div v-else class="flex">
              <input
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 capitalize"
                type="file"
                id="image"
                :disabled="!isEditMode"
                @change="handleImageChange"
              >
            </div>
          </div>
          </div>

          <div v-if="selectedImage">
            <img :src="getAbsoluteImageUrl()" alt="Selected Image" :key="selectedImageKey" class="max-w-full h-auto mb-4">
          </div>

          <div v-else-if="imageUrl">
            <div>
              <img :src="imageUrl" alt="Business Image" class="max-w-full h-auto">
            </div>
          </div>
        </div>


        </div>
        </div>
      </div>

        <hr class="mt-2 border-b-1 border-blueGray-300" />
          <div class="ml-4">
            <button v-if="isAuthenticated"
  class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="button" @click.prevent="saveUserDetails()"
>
  Update
</button>

<button v-else-if="isValid && business"
  class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  @click.prevent="verifyPhoneNumber"
>
  Verify
</button>

<button v-else-if="isMobileUpdated"
  class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
  type="button" @click.prevent="toggleEditMode"
>
  Edit
</button>

          </div>  
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'

export default {
  name: 'UserProfile',

  mounted() {
this.getUserProfile()
this.getTeam()
this.isBusinessSet()
this.getUser()
this.userRoles()
this.getUserPhoneVerified()
this.retrieveBusinessImage()
this.uuidv4 = this.uuidv4get()


  },

  data() {
    return {
      uuidv4: '00000000-0000-0000-0000-000000000000',
      errors: [],
      selectedImage: null,
      selectedImageKey: null,
      imageUrl: null,
      isVerified: false,
      isAuthenticated: false,
      userPk: null,
      otp: '',
      temp: null,
      user: {
        first_name: '',
        last_name: '',
        username: '',
      },
      team: {
          members: [],
          created_by: {}
        },
      business: '',
      userverify: '',
      isMobileUpdated: false,
      isEditMode: false,
      results: null,
      jobtitle: '',
      otpAttempt: 0,
    };
  },

  components: {
    MazPhoneNumberInput
  },


  props: {
  color: {
    default: "light",
    validator: function (value) {
      return ["light", "dark"].indexOf(value) !== -1;
    },
  }
 },

 watch: {
  imageUrl(newValue) {
      this.selectedImageKey = newValue;
    },
  
  otp(newOtp) {
    if (newOtp.length === 6) {
     if (this.otpAttempt < 2) {
        this.otpAttempt++
        this.verifyOTP(newOtp)
      } else {
        this.otpAttempt = 0
        this.otp = ''
        toast({
                  message: '3 x failed attempts, redirecting you',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
                })
          this.isVerified = false
          this.isEditMode = true
          this.errors = []
      }
    }
  }
},
computed: {
  
  selectedImageKeyComputed() {
      return this.selectedImage ? this.selectedImage.name + this.selectedImage.lastModified : null;
    },


 isValid() {
    return this.results ? this.results.isValid : false
  },


  isCreatedByCurrentUser() {
    const teamCreatedBy = this.team.created_by
    const userId = this.$store.state.user.id
    return teamCreatedBy && teamCreatedBy.id === +userId
  },
  isBusinessNameUser() {
    const businessName = this.$store.state.business.name
    return !!businessName
  },
  isCurrentUserAdmin() {
    const userRoles = this.$store.state.user.roles

    return userRoles.includes("Admin")
   },

   isDemo() {
          const demo = this.$store.state.is_demo;
          return demo
        },


},

  methods: {

    isDemoSend() {
          const demo = this.$store.state.is_demo;
          return demo
        },

    getAbsoluteImageUrl() {
      const baseImageUrl = axios.defaults.baseURL
      this.imageUrl = null
      return this.imageUrl ? `${baseImageUrl}${this.imageUrl}` : (this.selectedImage ? URL.createObjectURL(this.selectedImage) : null);
    },

    handleImageChange(event) {
      const selectedImage = event.target.files[0];

    if (selectedImage) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width <= 300 && img.height <= 80) {
            this.selectedImage = selectedImage;
          } else {
            alert('Image dimensions must be at most 300x80 pixels.');
            event.target.value = null;
          }
        };

        img.src = e.target.result;
      };
      reader.readAsDataURL(selectedImage);
    }
  },
  uploadImage() {
    if (this.selectedImage) {
      const businessID = this.$store.state.business.id;
      const formData = new FormData();
      formData.append('business_image', this.selectedImage);

      axios.defaults.xsrfCookieName = 'csrftoken';
      axios.defaults.xsrfHeaderName = 'X-CSRFToken';

      axios.post(`/mapche-api/v1/business/${businessID}/update_business_image/`, formData)
        .then(response => {
          this.retrieveBusinessImage();
        })
        .catch(error => {
          console.error(error);
        });
    }
  },

  retrieveBusinessImage() {
    const businessID = this.$store.state.business.id;

    axios.get(`/mapche-api/v1/business/${businessID}/retrieve_business_image/`)
      .then(response => {
        const baseURL = axios.defaults.baseURL
        const imageEndpoint = response.data.business_image;

      if (imageEndpoint) {
        this.imageUrl = `${baseURL}${imageEndpoint}`;
      }
      })
      .catch(error => {
        console.error(error);
      });
  },

    uuidv4get() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );        
    },

    toggleEditMode() {
      if (!this.isEditMode) {
        this.isMobileUpdated = false
      }
      this.isEditMode = !this.isEditMode;
    },

    async userRoles() {
      
      this.$store.commit('setIsLoading', true)

      await axios
      .get(`/mapche-api/v1/business/get_user_roles/${localStorage.getItem('userid')}/`)
      .then(roles => {
        const userRoles = roles.data.user_roles
        this.$store.commit('setUser', {'id': localStorage.getItem('userid'), 'username': localStorage.getItem('username'), 'roles': userRoles})
      })
      .catch(error => {
        console.log(error)
      }) 

      this.$store.commit('setIsLoading', false)
    },

     async getUserProfile() {
      this.$store.commit('setIsLoading', true)

      await axios
            .get('/mapche-api/v1/userprofile/')
            .then(response => {
              this.userPk = response.data[0].id
              const mapchikey = response.data[0].mapche_key
              const isUserInnovator = response.data[0].is_innovator
              
              if (mapchikey) {
              this.$store.commit('setKey', {
                'key': mapchikey
              })

                this.$store.commit('setIsInnovator', isUserInnovator)
                localStorage.setItem('setIsInnovator', isUserInnovator)
              }
            })
            .catch(error => {
              console.log(error)
            })  

            this.$store.commit('setIsLoading', false) 
    },
    
    async getTeam() {
      this.$store.commit('setIsLoading', true)

      await axios
          .get('/mapche-api/v1/business/get_my_business/')
          .then(response => {
            this.team = response.data
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },

    async isBusinessSet() {
      this.$store.commit('setIsLoading', true)
      await axios
            .get('/mapche-api/v1/business/get_my_business')
            .then(response => {
              this.business = response.data.name
              const isBusinessIdFalsy = !response.data.id

              const created = !response.data.created_by 

                this.$store.commit('setBusiness', {
                'id': response.data.id, 
                'name': response.data.name,
                'plan': response.data.plan,
                'customer': response.data.stripe_customer_id,
                'status': response.data.plan_status,
                })

                if (!created) {
                  this.$store.commit('setTeam', {
                    'created': response.data.created_by.id
                  })
                }                
              
              if (isBusinessIdFalsy) {
                this.$router.push('/admin/my-account')
              } 

            })
            .catch(error => {
              console.log(error)
            })
      this.$store.commit('setIsLoading', false)
    },

    async getUser() {
      this.$store.commit('setIsLoading', true)
      const userID = this.$store.state.user.id

      await axios
        .get(`/mapche-api/v1/business/member/${userID}/`)
        .then(response => {
          this.user = response.data
          if (!this.user.first_name) {
            this.isEditMode = true
           if (this.$refs.firstNameInput) {
              this.$refs.firstNameInput.focus()
            }
          }
        })
        .catch(error => {
                console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    },

    async getUserPhoneVerified() {
      this.$store.commit('setIsLoading', true)
      const userID = this.$store.state.user.id

      await axios
        .get(`/verify/phone-number/${userID}/`)
        .then(response => {
            this.userverify = response.data.phone_number
            this.isMobileUpdated = true

            this.$store.commit('setMobile', {'phone_number': response.data.phone_number})
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            console.log('Record does not exist');
          } else {
            console.log(error);
          }
        })

      this.$store.commit('setIsLoading', false)
    },

    async getUserPhoneUpdated(mobile) {
      this.$store.commit('setIsLoading', true)
      const userID = this.$store.state.user.id
      const formData = {
          phone_number: mobile
      }
      await axios
        .patch(`/verify/phone-number/${userID}/`, formData)
        .then(response => {
          toast({
                    message: 'Your information was updated',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                  })
                  this.isVerified = false
                  this.isAuthenticated = false
                  this.isEditMode = false
                  this.saveBusinessDetails()
                  this.getUserPhoneVerified()
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            console.log('Record does not exist');
          } else {
            console.log(error);
          }
        })

      this.$store.commit('setIsLoading', false)
    },
  
    async verifyPhoneNumber() {
      this.$store.commit('setIsLoading', true)
      this.errors = []
      if (this.user.first_name === '') {
        this.errors.push('Please enter your first name.')
      }
      if (this.user.last_name === '') {
        this.errors.push('Please enter your last name.')
      }
      if (this.user.username === '') {
        this.errors.push('The Email is missing.')
      } else if (!/\S+@\S+\.\S+/.test(this.user.username)) {
        this.errors.push('Please enter a valid email address.')
      }
      if (this.business === '') {
        this.errors.push('Please enter a business name or team name.')
      }
      if (await this.checkUserProfileId(this.userverify)) {
        this.errors.push('Mobile number already exists.')
      }
  
      if (!this.errors.length) {
        await axios
        .get(this.isDemoSend() ? `/verify/time_basedtest/${this.userverify}/` : `/verify/time_based/${this.userverify}/`)
          .then(response => {
            this.isVerified = true
            this.results.isValid = false
            this.isEditMode = false
            this.$nextTick(() => {
              this.$refs.otpinput.focus()
            })
            this.temp = response.data.OTP
          })
          .catch(error => {
              if (error.response && error.response.status === 404) {
                if (this.userverify !== this.$store.state.mobile.phone_number) {
                  if (this.checkUserProfileId(this.userverify)) {
                    this.errors.push('Mobile number already exists.');
                  }
                  if (confirm("Please confirm if you want to change your phone number?")) {
                     this.getUserPhoneUpdated(this.userverify)
                  } else {
                    this.userverify = this.$store.state.mobile.phone_number
                  }
                  
                }
              } else {
                if (error.response) {
                  this.userverify = '';
                  for (const property in error.response.data) {
                    const errorMessage = `${property}: ${error.response.data[property]}`;
                    toast({
                      message: errorMessage,
                      type: 'is-danger',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 3000,
                      position: 'bottom-right',
                    });
                  }
                } else if (error.message) {
                  toast({
                    message: 'Something went wrong. Please try again!',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                  });
                }
              }
            })
        } else if (this.errors.length) {
            toast({
              message: this.errors[0],
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: 'bottom-right',
            })
        }
      this.$store.commit('setIsLoading', false)
    },
    
    async verifyOTP(verify) {
      this.$store.commit('setIsLoading', true)

      this.errors = []
      const formData = {
          otp: verify
      }

      await axios
        .post(`/verify/time_based/${this.userverify}/`, formData)
        .then(response => {
            this.isAuthenticated = true
            this.isMobileUpdated = true
            this.otp = ''
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 400) {
                this.otp = '' 
                toast({
                  message: 'Your one-time pin expired, redirecting you',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
                })
                this.isVerified = false
                this.isEditMode = true
              } else if (error.response.status === 410) {
                this.otp = '' 
                toast({
                  message: 'Your one-time pin is incorrect.',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
                })
              } else {
                  this.otp = ''
                  toast({
                      message: 'Something went wrong. Please try again!',
                      type: 'is-danger',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 3000,
                      position: 'bottom-right',
                    })
              }
            } else if (error.message) {
              this.otp = '' 
              toast({
                  message: 'Something went wrong. Please try again!',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
                })
            }
          })
          this.$store.commit('setIsLoading', false)
      },

      async checkUserProfileId(mobileNumber) {
      try {
        const response = await axios.get(`/verify/phone-number/mobile/${mobileNumber}/`);
        if (response.status === 200) {
          const userProfileId = response.data.user_profile_id;
          if (userProfileId) {
            const stateUser = this.$store.state.user.id
            if (userProfileId != parseInt(stateUser)) {
              this.userverify = ''
              return true 
            }
          }
        }
        return false 
      }  catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Mobile number not found');
          return false; 
        } else {
          console.error('Error:', error.message);
          return true; 
        }
      }
    },

    async saveUserDetails() {
        this.$store.commit('setIsLoading', true)

        const userID = this.$store.state.user.id
        if (this.user.username === this.user.email) {
          this.user.email = this.user.username
        } else {
          this.user.email = this.user.email
        }


          await axios
          .put(`mapche-api/v1/business/member/${userID}/`, this.user)
          .then(response => {
                  toast({
                    message: 'Your information was updated',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                  })
                  this.isVerified = false
                  this.isAuthenticated = false
                  this.isEditMode = false
                  this.saveBusinessDetails()
                  this.getUserPhoneVerified()
          })
          .catch(error => {
            if (error.response) {
              this.userverify = ''
              for (const property in error.response.data) {
                const errorMessage = `${property}: ${error.response.data[property]}`;
                toast({
                  message: errorMessage,
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 3000,
                  position: 'bottom-right',
                });
              }
            } else if (error.message) {
              toast({
                message: 'Something went wrong. Please try again!',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 3000,
                position: 'bottom-right',
              });
            }
          })

      this.$store.commit('setIsLoading', false)
    },

    async saveMapchiKey() {
      this.$store.commit('setIsLoading', true)
      
      const keydata = {
        mapche_key: this.uuidv4
      }
      
      axios
       .patch(`/mapche-api/v1/userprofile/${this.userPk}/update_mapche_key/`, keydata)
       .then(response => {
        })
        .catch(error => {
          console.log(error)
        })

      this.$store.commit('setIsLoading', false)
    },

    async saveBusinessDetails() {
        this.$store.commit('setIsLoading', true)

        const businessID = this.$store.state.business.id
        const businessdata = {
          name: this.business
        }
        if (businessID) {
          await axios
            .put(`mapche-api/v1/business/${businessID}/`, businessdata)
            .then(response => {

                this.$store.commit('setBusiness', {'id': response.data.id, 'name': this.business})
                this.uploadImage()
                this.isBusinessSet()
              
                this.$router.push('/admin/dashboard')
              
              })
              .catch(error => {
                console.log(error)
              })

        } else {
          const businessdata = {
            name: this.business
          }
          await axios
            .post('/mapche-api/v1/business/', businessdata)
            .then(response => {

                this.$store.commit('setBusiness', {'id': response.data.id, 'name': this.business})
                
                this.saveMapchiKey()
                this.uploadImage()
                this.isBusinessSet()
                this.getUserProfile()
                this.$router.push('/admin/dashboard')

              })
              .catch(error => {
                console.log(error)
              })
            
        }

        this.$store.commit('setIsLoading', false)
      }
    
  }
}
</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,300,1,0");

.error-sign-up {
border-radius: 0.5rem;
display: flex;
align-items: center;
position: absolute;
}

.error-sign-up p {
font-size: 12px;
font-weight: bold;
color: #f420cd;
}
.error-sign-up span {
color: #f420cd;
}

.signup-button-verify.disabled {
  background-color: gray;
  color: #fff;
  line-height: 1.4rem;
  pointer-events: none;
}

.signup-button-verify.verify {
background: linear-gradient(to right,#03a8b4,#231154);
color: #fff;
line-height: 1.4rem;
transform: scale(1);
transition: transform .2s ease;
}

.signup-button-verify:hover {
transform: scale(1.1);
transition: transform .2s ease;
}

.signup-button-verify {
border-radius: .5rem;
height: 3rem;
max-width: 100%;
min-width: 6rem;
}

</style>