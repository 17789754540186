<template>

  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
        Brand Identity
      </h6>
      <form>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-2/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="hexPrimary"
              id="hexPrimary"
            >
              Pick your Primary Color below:
            </label>
            <div class="flex items-center">
              <input type="color" class="border-0 bg-white rounded text-sm shadow focus:outline-none focus:ring w-20 cursor-pointer hover:cursor-pointer" v-model="hexPrimary" @input="changeColor($event, 'primary')" @blur="saveThemeOnprimary"/>
              <span class="ml-2" :style="{ color: hexPrimary }">Default Color: {{ hexPrimary }}</span>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-2/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="hexSecondary"
              id="hexSecondary"
            >
              Pick your Secondary Color below:
            </label>
            <div class="flex items-center"> 
              <input type="color" class="border-0 bg-white rounded text-sm shadow focus:outline-none focus:ring w-20 cursor-pointer hover:cursor-pointer" v-model="hexSecondary" @input="changeColor($event, 'secondary')" @blur="saveThemeOnsecondary"/>
              <span class="ml-2" :style="{ color: hexSecondary }">Default Color: {{ hexSecondary }}</span>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-2/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="is_active"
              id="is_active"
            >
              Customization
            </label>
            <div class="flex items-center"> 
              <input type="checkbox" class="ml-6 border-0 bg-white rounded text-sm shadow focus:ring-0 text-emerald-600 cursor-pointer hover:cursor-pointer" v-model="is_active" @change="saveThemeOnExit">
            </div>
          </div>
        </div>
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
            </label>
            <div class="relative w-full mb-3">
            <textarea
            type="text"
            disabled
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            rows="5"
          >Not only can you seamlessly embed our iFrame / Widget with a single line of code, but you can also fully white label it to match your brand's aesthetics effortlessly. With just a few clicks, you can choose your primary and secondary colors, and witness the magic unfold. Our intuitive color picker supports HEX, RGB, and HSL formats, giving you the flexibility to tailor every detail to perfection. From the background hues to the map marker color and even provider accents, you're in complete control. Experience the simplicity of customization and elevate your website's appeal today. Welcome to a world where integration meets personalization seamlessly. MapChi is not just a widget, it's a game-changer for your market presence.

Customization: Customize your preferred network's appearance to match your brand's identity effortlessly.
          </textarea>
          </div>
          
         </div>
        </div>
</div>

        <h6 class="text-blueGray-500 text-sm mt-6 mb-4 font-semibold uppercase">
      Network
    </h6>   
    <div class="flex flex-wrap">
         <div class="w-full lg:w-2/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="is_exclusive"
              id="is_exclusive"
            >
              Exclusive Branding
            </label>
            <div class="flex items-center"> 
              <input type="checkbox" class="ml-6 border-0 bg-white rounded text-sm shadow focus:ring-0 text-emerald-600 cursor-pointer hover:cursor-pointer" v-model="is_exclusive" @change="saveThemeOnexclusive">
            </div>
          </div>
        </div>

        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
            </label>
            <div class="relative w-full mb-3">
            <textarea
            type="text"
            disabled
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            rows="3"
          >Your logo will be exclusively featured on the widget, becoming the sole brand visible to customers. This means that your brand will be prominently showcased, providing a unique and personalized experience for your customers as they interact with the services offered. By choosing this option, you establish an exclusive branding partnership, allowing you to market and sell services under your brand identity without any other brands competing for visibility. This ensures that your brand receives maximum exposure and recognition, helping you stand out in the market and strengthen your brand presence.
          </textarea>
          </div>
          
         </div>
</div>


        <hr class="mt-5 border-b-1 border-blueGray-300" />

    <div class="w-full">
      <h6 class="text-blueGray-500 text-sm mt-6 mb-4 font-semibold uppercase">
        Theme Magic
      </h6>
      <div class="w-full">
      <div class="relative w-full mb-3">
        <div class="iframe-container">
          <iframe :src="iframeSrc" :width="width" :height="height" style='border-radius: 10px; border: none;' class="bg-blueGray-800"></iframe>
        </div>
      </div>
      </div>
    </div>
        </div>  
      </form>
  </div>

</template>


<script>
import axios from 'axios'
import {toast} from 'bulma-toast'


export default {
data() {
  return {
    mapchi_key: '',
    userPk: null,
    hexPrimary: '#012069',
    hexSecondary: '#ff0059',
    is_active: false,
    is_exclusive: false,
    heightmessage: false,
    widthmessage: false,
    code: `<html>`,
    selectedWidthOption: '100%', 
    customWidth: 640, 
    height: '800', 
    iframeMessage: 'For optimal performance and the best visual experience, it is recommended to maintain a minimum width of 640 pixels.',
    iframeMessageHeight: 'For optimal performance and the best visual experience, it is recommended to maintain a minimum height of 800 pixels. Prefer a smaller version? The Widget is a work in progress...'
  };
},

mounted() {
  this.mapchi_key = localStorage.getItem('key')
  this.getUserProfile()
},

watch: {
shouldShowHeightMessage(newVal) {
  if (newVal) {
    this.heightmessage = true;
  } else {
    this.heightmessage = false;
  }
},
shouldShowIframeCode(newVal) {
  if (newVal) {
    this.widthmessage = false;
  } else {
    this.widthmessage = true;
  }
}
},
computed: {
  isDemo() {
    const demo = this.$store.state.user.username;
    return demo === 'demo@mapchi.com';
  },
  formattedCode() {
    const codeToDisplay = this.shouldShowIframeCode && !this.shouldShowHeightMessage
      ? ''
      :this.shouldShowHeightMessage ? (this.heightmessage = true, this.iframeMessageHeight) 
      :!this.shouldShowIframeCode ? (this.widthmessage = true, this.iframeMessage)
      : this.selectedWidthOption === 'custom' 
      ? this.iframeMessage
      : this.iframeMessage;
    return codeToDisplay;
  },
  iframeSrc() {
    return `https://mapchi.com/iframe.html?mapchi-key=${encodeURIComponent(this.mapchi_key)}`;
  },
  width() {
    if (this.selectedWidthOption === 'custom') {
      return this.customWidth.toString();
    }
    return this.selectedWidthOption;
  },
  
  iframeWidth(){
    return `${this.width}`;
  },
  iframeHeight(){
    return `${this.height}`;
  },


  shouldShowIframeCode() {
    return this.selectedWidthOption !== 'custom' || (this.selectedWidthOption === 'custom' && this.customWidth >= 640);
  },
  shouldShowHeightMessage() {
    return this.height < 800;
  },

},



methods: {
  changeColor(e, type) {
    if (type === 'primary') {
      this.hexPrimary = e.target.value;
    } else if (type === 'secondary') {
      this.hexSecondary = e.target.value;
    }
  },

  saveThemeOnExit() {
    this.saveTheme();
  },

  saveThemeOnexclusive() {
    this.saveThemeexclusive();
  },

  saveThemeOnprimary() {
    this.saveThemeprimary();
  },


  saveThemeOnsecondary() {
    this.saveThemesecondary();
  },

  async saveThemeprimary() {

axios.patch(`/mapche-api/v1/userprofile/${this.userPk}/update_primary_color/`, {
        primary_color: this.hexPrimary,
      })
      .then(response => {
        toast({
                message: 'iFrame / Widget Theme, updated successfully',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
             
              window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.website_address) {
            const websiteErrorMessage = error.response.data.website_address[0];
            toast({
              message: websiteErrorMessage,
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
            this.getUserProfile()
          }
        } else {
          toast({
            message: 'An error occurred. Please try again later.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          })
          this.mapchi_key = ''
          }
      })
  },

  async saveThemesecondary() {

axios.patch(`/mapche-api/v1/userprofile/${this.userPk}/update_secondary_color/`, {
      secondary_color: this.hexSecondary,
      })
      .then(response => {
        toast({
                message: 'iFrame / Widget Theme, updated successfully',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
             
              window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.data.website_address) {
            const websiteErrorMessage = error.response.data.website_address[0];
            toast({
              message: websiteErrorMessage,
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
            this.getUserProfile()
          }
        } else {
          toast({
            message: 'An error occurred. Please try again later.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          })
          this.mapchi_key = ''
          }
      })
  },

  async saveTheme() {

    axios.patch(`/mapche-api/v1/userprofile/${this.userPk}/update_brand_cus/`, {
            is_default_client_primary: this.is_active,
          })
          .then(response => {
            toast({
                    message: 'iFrame / Widget Theme, updated successfully',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
                 
                  window.location.reload();
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              if (error.response.data.website_address) {
                const websiteErrorMessage = error.response.data.website_address[0];
                toast({
                  message: websiteErrorMessage,
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
                this.getUserProfile()
              }
            } else {
              toast({
                message: 'An error occurred. Please try again later.',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
              this.mapchi_key = ''
              }
          })
      },
  
      async saveThemeexclusive() {
        axios.patch(`/mapche-api/v1/userprofile/${this.userPk}/update_brand_exclusive/`, {
                is_exclusive: this.is_exclusive,
              })
              .then(response => {
                toast({
                        message: 'iFrame / Widget Theme, updated successfully',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 5000,
                        position: 'bottom-right',
                      })
                    
                      window.location.reload();
              })
              .catch((error) => {
                if (error.response && error.response.data) {
                  if (error.response.data.website_address) {
                    const websiteErrorMessage = error.response.data.website_address[0];
                    toast({
                      message: websiteErrorMessage,
                      type: 'is-danger',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 5000,
                      position: 'bottom-right',
                    })
                    this.getUserProfile()
                  }
                } else {
                  toast({
                    message: 'An error occurred. Please try again later.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
                  this.mapchi_key = ''
                  }
              })
          },

      async getUserProfile() {
        this.$store.commit('setIsLoading', true)

        axios.get('/mapche-api/v1/userprofile')
          .then(response => {
            if(response.data[0]) {
              const primary = response.data[0]?.primary_color;
              const secondary = response.data[0]?.secondary_color;

              if (primary) {
                this.hexPrimary = primary;
              }

              if (secondary) {
                this.hexSecondary = secondary;
              }
              this.is_active = response.data[0].is_default_client_primary
              this.is_exclusive = response.data[0].is_exclusive

              if (localStorage.getItem('userid') !== localStorage.getItem('created')) {
                this.userPk = response.data[0].admin_id
              } else {
                this.userPk = response.data[0].id
              }

            }
          })
          .catch((error) => {
            toast({
              message: 'An error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
          })

        this.$store.commit('setIsLoading', false)    
      },
}
};
</script>

<style scoped>

.custom-select {
border: 0;
padding: 0.5rem; 
min-width: 110px; 
text-align: left; 
}

.iframe-container {
overflow: hidden;
background-color: rgb(228, 228, 231, 1);
border-radius: 10px;
text-align: center;
}


.iframe-container iframe {
display: inline-block; 
}
</style>
