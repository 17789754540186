<template>
  <div>
    <h1>Logout</h1>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Logout',
  data() {
    return {
    };
  },

  created() {
    this.logout()
  },

  methods: {
    async logout() {
      await axios
          .post('/accounts/token/logout')
          .then(response => {
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })

      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      localStorage.removeItem('userid')
      localStorage.removeItem('user_roles')
      localStorage.removeItem('user_roles')
      localStorage.removeItem('created')
      localStorage.removeItem('invited')
      localStorage.removeItem('resellerinvited')
      localStorage.removeItem('business_name')
      localStorage.removeItem('business_id')

      localStorage.removeItem('reseller_id')
      localStorage.removeItem('business_plan_type')
      localStorage.removeItem('business_customer')
      localStorage.removeItem('business_plan_status')
      localStorage.removeItem('phone_number')
      localStorage.removeItem('key')

      localStorage.removeItem('setIsInnovator')
      localStorage.removeItem('setIsDemo')

      localStorage.removeItem('business')
      localStorage.removeItem('is_innovator')
      localStorage.removeItem('is_demo')
      localStorage.removeItem('countryCodes')



      this.$store.commit('removeToken')

      this.$router.push('/')
    }
  },
};
</script>


