<template>
  <div class="flex flex-wrap">
    <div class="w-full px-4">
      <CardPlans />
    </div>
  </div>
</template>
<script>
import CardPlans from "@/components/Cards/CardPlans.vue";

export default {
  components: {
    CardPlans,
  },
};
</script>
