<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
   <div class="rounded-t mb-0 px-6 py-6 border-0">
      <div class="flex justify-between items-center">
        <div>
          <h3 v-if="resellers"
            class="font-bold text-xl"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
          Accredited Resellers<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalresellers }}
          </h3>
        </div>


     </div>
    </div>  

    <div class="border w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th v-if="!isDemo"
              @click="toggleAddExpanded"
              class="lead-itemprovider px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left bg-emerald-600 text-white hover:text-emerald-600"
            >
            <i :class="isAddExpanded ? 'fas fa-times-circle' : 'fas fa-plus-circle'"></i>
                    <span class="ml-2">{{ isAddExpanded ? 'Cancel' : 'Invite reseller' }}</span>
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Reseller
            </th>            
            
           
            <th
              class="px-6 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Assigned To
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Account ID
            </th>            
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Subscription
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Suspend Services
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isAddExpanded">
          <td colspan="12">
            <CardResellerAdd @update-add="handleUpdateAdd"/> 
          </td>          
        </tr>
          <template v-for="(reseller, index) in combinedResellersAndPhone" :key="index">
          <tr 
           class="lead-itemprovider" 
          :class="{ 'bg-gray-200': selectedUser === reseller.business}"

          >
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 py-4"
          >
           <span v-if="$store.state.user.id != reseller.userId"
              class="ml-3 font-bold capitalize cursor-pointer hover:text-emerald-600"
              :class="[
                color === 'light' ? 'text-blueGray-600' : 'text-white',
                { 'hover:bg-gray-200': !isEditExpanded }
              ]"
              style="line-height: 2.5rem;"
              @click="toggleEdit(reseller.business)"
            >
            <i class="fas fa-caret-down mr-1"></i>{{ isEditExpanded && selectedUser === reseller.business ? 'Close' : 'Edit' }}
            </span>            
          </td>
          <td
            class="px-4 border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
            {{ reseller.business.name}}
          </td>          
          <td v-if="reseller.member === $store.state.user.username"
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          
          </td>
          <td v-else
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
            {{ reseller.member_first }} {{ reseller.member_last }}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          {{ reseller.reselleraccount }}
          </td>          
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          <div class="flex ml-0 justify-left xs:border-l">
            <span v-if="reseller.subscription_active">
              <input type="checkbox" class="focus:ring-0 text-emerald-600" v-model="reseller.subscription_active" disabled />
            </span>
            <span v-else>
              <i class="fas fa-times-circle text-red-500"></i>
            </span>
          </div>
          </td>  
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          </td>          
          <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div v-if="$store.state.user.id != reseller.userId"  class="flex justify-left xs:border-l">
              <button class="images material-symbols-outlined small-icon" @click="removeItemFromUsers(reseller.userId)">delete</button>
            </div>
          </td>
        </tr>
        <tr v-if="selectedUser === reseller.business && isEditExpanded">
          <td colspan="12">
            <CardResellerEdit :member="selectedUser" :assignedUser="reseller.member_id" :assignedRecord="reseller.record_id" @update-edit="handleUpdate" /> 
          </td>
        </tr> 
        </template>
        <template v-for="invite in invitedInvites" :key="invite.id">
          <tr 
           class="lead-itemprovider" 
          >
          <td v-if="invite.status === 'declined'"
              class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 py-4">
              <span
                  class="ml-3 font-bold capitalize cursor-pointer"
                  :class="[color === 'light' ? 'text-red-500' : 'text-white']"
                  style="line-height: 2.5rem;">
                  <i class="fas fa-times-circle mr-1"></i> Declined
              </span>
            </td>
          <td v-else 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 py-4">
              <span
                  class="ml-3 font-bold capitalize cursor-pointer"
                  :class="[color === 'light' ? 'text-emerald-600' : 'text-white']"
                  style="line-height: 2.5rem;">
                  <i class="far fa-envelope mr-1"></i> Invited
              </span>
          </td>
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 capitalize"
          >
          {{ invite.company_name }}
          </td>
          <td v-if="invite.member_id === $store.state.user.username"
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          </td>
          <td v-else
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          {{ invite.member_first }} {{ invite.member_last }}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          {{ invite.accountId }}
          </td>              
          <td
            class="capitalize border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div class="flex ml-5 justify-left xs:border-l">
              <i v-if="invite.status === 'declined'" class="fas fa-times-circle text-red-500 text-lg"></i>
              <i v-else class="far fa-envelope text-xl text-emerald-600 "></i>
            </div>
          </td>          
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          </td>
          <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div class="flex justify-left xs:border-l">
              <button class="images material-symbols-outlined small-icon" @click="deleteInvite(invite.id)">delete</button>
            </div>
          </td>
        </tr>

        </template>
        

      </tbody>
   </table>
   </div>
   </div>
   
   </template>
<script>
import axios from 'axios'
import moment from 'moment'
import { toast } from 'bulma-toast'
import CardResellerAdd from "@/components/Cards/CardResellerAdd.vue";
import CardResellerEdit from "@/components/Cards/CardResellerEdit.vue";


export default {
  name: 'Reseller',
  
  mounted() {
    this.getTeam()
    this.getInvited()
  },

  data() {
    return {
      isEditExpanded: false,
      isAddExpanded: false,
      totalresellers: 0,
      products: [],
      resellers: [],
      selectedUser: null,
      users: {
        members: []
      },
      invites: [],
    }
   },


  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    }
  },  

  components: {
    CardResellerAdd,
    CardResellerEdit,
  },
  
  watch: {
    isAddExpanded(newValue) {
      if (newValue) {
        this.resellers = [];
      } else {
        this.getTeam()
        this.getInvited()
      }
    },
    isEditExpanded(newValue) {
      if (!newValue) {
        this.getTeam()
        this.getInvited()
      }
    },
     
  }, 


  computed: {
    isDemo() {
          const demo = this.$store.state.is_demo;
          return demo
        },

    invitedInvites() {
  if (this.invites && this.invites.invites) {
    return this.invites.invites.filter(invite => invite.status === 'invited' || invite.status === 'declined')
    .map(invite => {
      return {
        id: invite.id,
        email: invite.email,
        company_name: invite.company_name,
        accountId: invite.accountId,
        member_id: invite.member_id,
        member_first: invite.member_first,
        member_last: invite.member_last,
        status: invite.status,
      };
    });
  }
  return [];
},
    combinedResellersAndPhone() {
        const resellers = this.resellers || [];
        const resellerDataArray = [];

        resellers.forEach((reseller) => {
          const relationshipId = reseller.id;
          const business = reseller.business;
          const leads = reseller.leads || [];

          if (business) {
            const userId = business.created_by.id;
            const record_id = reseller.id;
            const member_id = reseller.member_id;
            const member = reseller.member;
            const member_first = reseller.member_first;
            const member_last = reseller.member_last;
            const firstname = business.created_by.first_name;
            const lastname = business.created_by.last_name;
            const username = business.created_by.username;
            const reselleraccount = business.created_by.reselleraccount
            const phoneNumber = business.member_phone[0];
            const formattedLoginDate = business.member_login[userId] || null;
            const formattedDate = business.created_at || null;
            const loginTimestampLogin = formattedLoginDate ? this.formatDate(formattedLoginDate) : '';
            const loginTimestamp = formattedDate ? this.formatDate(formattedDate) : '';
            const planStatus = business.plan_status || null;
            const resellerActive = business.member_active || {};

            const isActive = planStatus === 'active' || planStatus === 'trialing';
            const subscriptionActive = isActive && resellerActive[userId] === true;

            resellerDataArray.push({
              userId: userId,
              record_id: record_id,
              member_id: member_id,
              member: member,
              member_first: member_first,
              member_last: member_last,
              reselleraccount: reselleraccount,
              business: business,
              first_name: firstname,
              last_name: lastname,
              username: username,
              phone_number: phoneNumber,
              leads: leads,
              activation_date: loginTimestamp,
              last_login: loginTimestampLogin,
              reseller_active: resellerActive[userId] === true,
              subscription_active: subscriptionActive,
            });
          }
        });

        return resellerDataArray;
      },
},
  methods: {
    handleUpdate(isEditExpanded) {
      this.isEditExpanded = isEditExpanded
    },

    handleUpdateAdd(isAddExpanded) {
      this.isAddExpanded = isAddExpanded
    },

     formatDate(timestamp) {
      return moment(timestamp).format('D MMM YYYY')
     },

     async getTeam() {
        this.$store.commit('setIsLoading', true)

        await axios
          .get('/mapche-api/v1/business/view_reseller_business/')
            .then(response => {
              this.data = response.data

              this.resellers = this.data.map(resellerData => {
                const businessData = resellerData.business;
                const leadsData = resellerData.leads;

                return {
                  id: resellerData.id,
                  member_id: resellerData.member_id,
                  member: resellerData.member,
                  member_first: resellerData.member_first,
                  member_last: resellerData.member_last,
                  business: businessData,
                  leads: leadsData
                }
              })
              this.totalresellers = this.resellers.length


            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async getInvited() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/resellerinvite/')
            .then(response => {
              this.invites = response.data

            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async removeItemFromUsers(userId) {
      const confirmDelete = await this.showDeleteConfirmation();

      if (!confirmDelete) {
        return; 
      }

      this.$store.commit('setIsLoading', true);

      axios
        .delete(`/mapche-api/v1/business/reseller/${userId}/`)
        .then(response => {
          toast({
            message: 'The reseller was deleted',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          });
          
          this.getTeam()
          this.getInvited()
        })
        .catch(error => {
          console.error('Error deleting reseller:', error);
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false);
        });
      },

      async deleteInvite(inviteId) {
      const confirmDelete = await this.showDeleteConfirmationInvite();

      if (!confirmDelete) {
        return; 
      }

      this.$store.commit('setIsLoading', true);

      axios
        .delete(`/mapche-api/v1/business/resellerinvites/${inviteId}/`)
        .then(response => {
          toast({
            message: 'The invite was deleted',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          });
          
          this.getTeam()
          this.getInvited()
        })
        .catch(error => {
          console.error('Error deleting invite:', error);
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false);
        });
      },

      showDeleteConfirmation() {
        return new Promise(resolve => {
          const confirmText = 'Are you sure you want to delete this reseller?';

          if (window.confirm(confirmText)) {
            resolve(true); 
          } else {
            resolve(false); 
          }
        })
      },

      showDeleteConfirmationInvite() {
        return new Promise(resolve => {
          const confirmText = 'Are you sure you want to delete this invite?';

          if (window.confirm(confirmText)) {
            resolve(true);
          } else {
            resolve(false); 
          }
        })
      },


    toggleAddExpanded() {
      this.isAddExpanded = !this.isAddExpanded
    },

    handleUpdateFeatures(isEditExpanded, selectedProvider, updatedFeatures) {
      this.isEditExpanded = isEditExpanded
    },

    toggleEdit(reseller) {
        if (this.selectedUser === reseller) {
          this.isEditExpanded = !this.isEditExpanded
          
        } else {
          this.selectedUser = reseller
          this.isEditExpanded = true
        }
    },

      selectUser(reseller) {
        this.selectedUser = reseller
      },

  },
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,300,1,0");

.images:hover {
    color:  rgba(239, 68, 68, 1);
    transition: 0.2s;
    transform: scale(1.2);
  }

  .small-icon {
  font-size: 18px; 
}

.lead-itemprovider {
  padding: 10px;
    cursor: pointer;
}

.lead-itemprovider:hover {
   background-color: #f1f3f6;
}


.lead-item {
  transition: transform 0.8s ease, box-shadow 0.3s ease; 
  box-shadow: none; 
}

.lead-item:hover {
  background-color: #fff;
  transform: scale(1.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
  opacity: 1;
}

.lead-header {
  background-color: #f1f3f6;
}


.selected-provider {
  background-color: #f1f3f6;
  opacity: 0.5;
}
.selected-provider:hover {
  background-color: #f1f3f6;
  opacity: 1;
}
.selected-provider-img {
  border-width: 1px; 
  border-style: solid;
  border-color: #e4e4e7;
  transform: scale(1.2);
  box-shadow: 0 1px 2px 0 rgba(168, 7, 130, 0.05);
}

</style>
