<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
  >
    <div class="px-6">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 flex justify-center">
          <div class="relative">
            <img
              alt="..."
              :src="mapchi"
              class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-100-px"
            />
          </div>
        </div>
        <div class="w-full px-4 text-center mt-12">
          <div class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
              {{ $store.state.business.name ? $store.state.business.name : 'mapchi' }}
              </span>
            </div>
          <div class="flex justify-center py-4 lg:pt-4 pt-8">
            <div v-if="isCreatedByCurrentUser" class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
                {{ teamLength }}
              </span>
              <span class="text-sm text-blueGray-400">Users</span>
            </div>
            <div class="mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
                {{ searchesLength }}
              </span>
              <span class="text-sm text-blueGray-400">Searches</span>
            </div>
            <div class="lg:mr-4 p-3 text-center">
              <span
                class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
              >
                {{ leadsLength }}
              </span>
              <span class="text-sm text-blueGray-400">Leads</span>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div class="text-center mt-4">
        <h3
          class="text-xl font-semibold leading-normal text-blueGray-700 mb-2 capitalize"
        >
          {{ user.first_name }} {{ user.last_name }}
        </h3>
        <div v-if="userverify"
          class="text-sm leading-normal mt-4 mb-2 text-blueGray-600"
        >
          <i class="fas fa-phone-volume mr-2 text-lg text-emerald-600"></i>
          {{ userverify }}
        </div>
        <div v-if="user.username" class="mb-2 text-blueGray-600 mt-4">
          <i class="fas fa-envelope mr-2 text-lg text-emerald-600"></i>
          {{ user.username }}
        </div>
        <div class="mb-2 text-blueGray-600 mt-4">
          <i class="fas fa-user-lock mr-2 text-lg text-emerald-600"></i>
          <span class="mx-1">{{ displayRoles }}</span> 
        </div>   
      </div>
      <div v-if="!isSubscriptionPage">
      <div v-if="isActivePlan" class="text-center mt-6">
        <h3
          class="text-xl font-semibold leading-normal text-blueGray-700 mb-2 capitalize"
        >
          Subscription
        </h3>
        <div class="mb-2 text-blueGray-600 mt-4">
          <i class="text-2xl text-emerald-600" v-for="data in planData" :key="data.plan"
          :class="[data.plan === $store.state.business.plan ? data.picture : '']"></i>
          <span class="pl-2" v-for="data in planData" :key="data.plan"
            v-show="data.plan === $store.state.business.plan">{{ data.plan }}</span>
        </div>

       <div class="pt-5">
        <router-link v-if="isCreatedByCurrentUser"
              :to="{'name': 'Subscription'}"
              class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
            Details
        </router-link>
       </div>
      </div>
    </div>

      <div class="mt-6 py-10 text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-9/12 px-4">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mapchi from "@/assets/img/mapchi-icon.png"
import axios from 'axios'

export default {
  data() {
    return {
      mapchi,
      teamLength: 0,
      searchesLength: 0,
      leadsLength: 0,
      allRoles: [],
      user: {
        first_name: '',
        last_name: '',
      },
      team: {
          members: [],
          created_by: {}
      },
      userverify: '',
      planData: [
          {
            plan: 'Broker',
            picture: 'far fa-handshake'
          },
          {
            plan: 'Explorer',
            picture: 'fas fa-user-tie'
          },
          {
            plan: 'Trailblazer',
            picture: 'fas fa-user-ninja'
          },
          {
            plan: 'Innovator',
            picture: 'fas fa-user-astronaut'
          },
      ],          

    };
  },
  mounted() {
      this.getTeam()
      this.getSearches()
      this.getLeads()
      this.getUser()
      this.userRoles()
      this.getUserPhoneVerified()
    },

    computed: {
    
      displayRoles() {
        if (this.allRoles.length === 0) {
          return "Owner";
        } else {
          return this.allRoles.join(" | ");
        }
    },

    isCreatedByCurrentUser() {
      const teamCreatedBy = this.team.created_by
      const userId = this.$store.state.user.id

      return teamCreatedBy && teamCreatedBy.id === +userId
    },
    isActivePlan() {
      const planStatus = this.$store.state.business.plan;
      const isCanceled = this.$store.state.business.status === 'canceled';
      
      return !!planStatus && !isCanceled;
    },
    isSubscriptionPage() {
      return this.$route.path === '/admin/subscription'; 
    }    
  },

    methods: {
      async getTeam() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/get_my_business/')
            .then(response => {
              this.team = response.data

              this.teamLength = this.team && this.team.members ? this.team.members.length : 0

            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async getSearches() {
      this.$store.commit('setIsLoading', true)
      
        await axios
              .get('/mapche-api/v1/search-display/')
              .then(response => {

                this.searchesLength = response.data.count
              })
              .catch(error => {
                console.log(error)
              })
      
            this.$store.commit('setIsLoading', false)
      },

      async getLeads() {
          this.$store.commit('setIsLoading', true)
            axios
              .get('/mapche-api/v1/leads/')
              .then(response => {
                const leads = response.data
                this.leadsLength = response.data.count

              })
              .catch(error => {
                console.log(error)
              })
      
          this.$store.commit('setIsLoading', false)
      },

      async getUser() {
        this.$store.commit('setIsLoading', true)

        const userID = this.$store.state.user.id
      
        await axios
          .get(`/mapche-api/v1/business/member/${userID}/`)
          .then(response => {
            this.user = response.data

          })
          .catch(error => {
            console.log(error)
          })

        this.$store.commit('setIsLoading', false)
      },

      async userRoles() {
        
        this.$store.commit('setIsLoading', true)

        await axios
        .get(`/mapche-api/v1/business/get_user_roles/${localStorage.getItem('userid')}/`)
        .then(roles => {
          this.allRoles = roles.data.user_roles
        })
        .catch(error => {
          console.log(error)
        }) 

        this.$store.commit('setIsLoading', false)
      },

      async getUserPhoneVerified() {
        this.$store.commit('setIsLoading', true)

        const userID = this.$store.state.user.id
      
          await axios
            .get(`/verify/phone-number/${userID}/`)
            .then(response => {
                this.userverify = response.data.phone_number
            })
            .catch(error => {
            if (error.response && error.response.status === 404) {
              console.log('Record does not exist');
            } else {
              console.log(error);
            }
            })

        this.$store.commit('setIsLoading', false)

      },

    }
};
</script>
