<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-5">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Invitation
              </h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="submitForm">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-4"
                  htmlFor="passcode"
                >
                  Activation Code
                </label>
                <input 
                  type="text" 
                  id="uniqueCode" 
                  maxlength="8" 
                  placeholder="Enter 8-character code" 
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="uniqueCode"
                  />
              </div>
              <div class="notification is-primary" v-if="errors.length">
                  <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>
              <div class="text-center mt-5">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

import {toast} from 'bulma-toast'


export default {
  name: 'Invite',
  data() {
    return {
      uniqueCode: '',
      errors: []
    };
  },

  mounted() {
    this.getInvited()
},
  methods: {
    getmybusiness() {
      axios
       .get('/mapche-api/v1/business/get_my_business')
       .then(response => {

        const isBusinessIdFalsy = !response.data.id
        const created = !response.data.created_by  

        this.$store.commit('setBusiness', {
          'id': response.data.id, 
          'name': response.data.name,
          'plan': response.data.plan,
          'customer': response.data.stripe_customer_id,
          'status': response.data.plan_status,
        })

        if (!created) {
          this.$store.commit('setTeam', {
            'created': response.data.created_by.id
          })
        }

                
        if (isBusinessIdFalsy || this.isMobileFalsy) {
          this.$router.push('/admin/my-account')
          } else {
          this.$router.push('/admin/dashboard')
          }
        })
         .catch(error => {
           console.log(error)
        })

    },

    getbusiness(businessId) {
      axios
       .get(`/mapche-api/v1/business/get_business/${businessId}/`)
       
      .then(response => {

        const created = !response.data.created_by  

        this.$store.commit('setBusiness', {
          'id': response.data.id, 
          'name': response.data.name,
          'plan': response.data.plan,
          'customer': response.data.stripe_customer_id,
          'status': response.data.plan_status,
        })


        if (!created) {
          this.$store.commit('setTeam', {
            'created': response.data.created_by.id
          })
        }

        })
         .catch(error => {
           console.log(error)
        })

    },    
    async getInvited() {
      this.$store.commit('setIsLoading', true)

      await axios
              .get('/mapche-api/v1/business/loginvite/')
              .then(response => {
                  this.invites = response.data.invites
                  const loginusername = this.$store.state.user.username
                  if (this.invites.length) {
                  const invitedInvite = this.invites.find((invite) => {
                    const username = loginusername.trim().toLowerCase()
                    const email = invite.email.trim().toLowerCase()
                    return email === username && invite.status === 'invited'
                  })
                  if (invitedInvite) {
                    this.getbusiness(invitedInvite.business)
                    
                    const invitedvalue = true

                    this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                    localStorage.setItem('invited', invitedvalue)                    

                    this.$router.push('/admin/invite')

                  } else {
                    const invitedvalue = false
                    this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                    localStorage.setItem('invited', invitedvalue)

                    this.getmybusiness()

                  }
                } else {
                  const invitedvalue = false
                  this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                  localStorage.setItem('invited', invitedvalue)

                  this.getmybusiness()

                }
                

              })
              .catch(error => {
                console.log(error)
              })

          this.$store.commit('setIsLoading', false)
    },

    async isBusinessSet() {
      this.$store.commit('setIsLoading', true)
      
      await axios
            .get('/mapche-api/v1/business/get_my_business')
            .then(response => {
              const created = !response.data.created_by  

                this.$store.commit('setBusiness', {
                'id': response.data.id, 
                'name': response.data.name,
                'plan': response.data.plan,
                'customer': response.data.stripe_customer_id,
                'status': response.data.plan_status,
                })

                if (!created) {
                  this.$store.commit('setTeam', {
                    'created': response.data.created_by.id
                  })
                }                

                this.userRoles()
            })
            .catch(error => {
              console.log(error)
            })

      this.$store.commit('setIsLoading', false)
    },

   async userRoles() {
  
    this.$store.commit('setIsLoading', true)

    await axios
     .get(`/mapche-api/v1/business/get_user_roles/${localStorage.getItem('userid')}/`)
     .then(roles => {
      const userRoles = roles.data.user_roles
      this.$store.commit('setUser', {'id': localStorage.getItem('userid'), 'username': localStorage.getItem('username'), 'roles': userRoles})
     })
     .catch(error => {
      console.log(error)
     }) 
  
     this.$store.commit('setIsLoading', false)
   },

    async submitForm() {
      this.errors = []

        this.$store.commit('setIsLoading', true)

        const formData = {
          code: this.uniqueCode
        }
        await axios
            .post('/mapche-api/v1/business/accept_invitation/', formData)
            .then(response => {

                toast({
                  message: 'Invitation accepted',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
                
                const invitedvalue = false
                this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                localStorage.setItem('invited', invitedvalue)

                this.userRoles()
                
                this.$router.push('/admin/my-account')
                
            })
            .catch(error => {
              if(error.response) {
                if (error.response.status === 400) {
                  this.password = ''
                  this.errors.push('Invitation was not found')
                }
              } else if (error.message) {
                  this.password = ''
                  this.errors.push('Something went wrong. Please try again!')
              }
            })

          this.$store.commit('setIsLoading', false)
    }
  }
};
</script>
