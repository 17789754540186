<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <sub-navbar />
  <div class="relative md:pt-32 pb-6 pt-12">
    <div
          class="absolute top-0 w-full h-full bg-no-repeat bg-full bg-pink"
          :style="`background-image: url('${registerBg2}');`"
        >
        </div>
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <router-view />
        <footer class="block py-4">
    <div class="container mx-auto px-4">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4">
          <div
            class="text-sm text-white font-semibold py-1 text-center md:text-left"
          >
            Copyright © {{ date }} - MapChi
          </div>
        </div>
      </div>
    </div>
  </footer>
      </div>
  </div>


      </div>
    </div>
  </div>
</template>
<script>
import SubNavbar from "@/components/Navbars/SubNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import registerBg2 from "@/assets/img/register_bg_2.png"

export default {
  name: "subscribe-layout",
  data() {
    return {
      registerBg2,
      date: new Date().getFullYear(),
    }
  },
  components: {
    SubNavbar,
    Sidebar,
  },
};
</script>
