<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
   <div class="rounded-t mb-0 px-6 py-6 border-0">
      <div class="flex justify-between items-center">
        <div>
          <h3 v-if="users"
            class="font-bold text-xl"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Organisation Users<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalusers }}
          </h3>
        </div>


     </div>
    </div>  

    <div class="border w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              @click="toggleAddExpanded"
              class="lead-itemprovider px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left bg-emerald-600 text-white hover:text-emerald-600"
            >
            <i :class="isAddExpanded ? 'fas fa-times-circle' : 'fas fa-plus-circle'"></i>
                <span class="ml-2">{{ isAddExpanded ? 'Cancel' : 'Invite user' }}</span>
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              User
            </th>
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Email
            </th>            
            <th
              class="px-4 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Phone Number
            </th>            
            <th
              class="px-6 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Role
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Last Login
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>
            <th
              class="px-5 py-3 text-xs uppercase whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            </th>                        
          </tr>
        </thead>
        <tbody>
          <tr v-if="isAddExpanded">
          <td colspan="12">
            <CardUserAdd @update-add="handleUpdateAdd"/> 
          </td>          
        </tr>
          <template v-for="(user, index) in combinedMembersAndPhone" :key="index">
          <tr 
           class="lead-itemprovider" 
          :class="{ 'bg-gray-200': selectedUser === user.member}"

          >
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4 py-4"
          >
           <span v-if="($store.state.user.id != user.member.id) && ($store.state.team.created != user.member.id)"
              class="ml-3 font-bold capitalize cursor-pointer hover:text-emerald-600"
              :class="[
                color === 'light' ? 'text-blueGray-600' : 'text-white',
                { 'hover:bg-gray-200': !isEditExpanded }
              ]"
              style="line-height: 2.5rem;"
              @click="toggleEdit(user.member)"
            >
            <i class="fas fa-caret-down mr-1"></i>{{ isEditExpanded && selectedUser === user.member ? 'Close' : 'Edit' }}
            </span>            
          </td>
          <td
            class="px-4 border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
            {{ user.member.first_name}} {{ user.member.last_name}}
          </td>
          <td
            class="px-4 align-middle text-xs"
          >
           {{ user.member.username }}
          </td>
          <td
            class="px-4 align-middle text-xs whitespace-nowrap capitalize"
          >
            {{ user.phone_number }}
          </td>
          <td 
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          <ul>
            <li v-for="(role, roleIndex) in user.roles" :key="roleIndex">
              {{ role }}
            </li>
          </ul>
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          {{ user.last_login }}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div class="flex justify-center xs:border-l">
              <input type="checkbox" class="focus:ring-0 text-emerald-600" v-model="user.member_active" disabled />
            </div>
          </td>
          <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div v-if="($store.state.user.id != user.member.id) && ($store.state.team.created != user.member.id)"  class="flex justify-center xs:border-l">
              <button class="images material-symbols-outlined small-icon" @click="removeItemFromUsers(user.member.id)">delete</button>
            </div>
          </td>
        </tr>
        <tr v-if="selectedUser === user.member && isEditExpanded">
          <td colspan="12">
            <CardUserEdit :member="selectedUser" :memberRole="user.roles" @update-edit="handleUpdate" /> 
          </td>
        </tr> 
        </template>
        <template v-for="invite in invitedInvites" :key="invite.id">
          <tr 
           class="lead-itemprovider" 
          >
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4"
          >
          </td>
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left px-4"
          >
          </td>
          <td
            class="px-4 py-4 align-middle text-xs"
          >
           {{ invite.email }}
          </td>
          <td
            class="px-4 align-middle text-xs whitespace-nowrap capitalize"
          >
          </td>
          <td 
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap capitalize"
          >
          <ul>
            <li v-for="(permission, permissionIndex) in invite.permissions" :key="permissionIndex">
              {{ permission }}
            </li>
          </ul>
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          </td>
          <td
            class="capitalize border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
          
          <div class="flex justify-center xs:border-l">
            <i class="far fa-envelope text-base"></i>
            </div>          
          </td>
          <td class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
          >
            <div class="flex justify-center xs:border-l">
              <button class="images material-symbols-outlined small-icon" @click="deleteInvite(invite.id)">delete</button>
            </div>
          </td>
        </tr>

        </template>
        

      </tbody>
   </table>
   </div>
   </div>
   
   </template>
<script>
import axios from 'axios'
import moment from 'moment'
import { toast } from 'bulma-toast'
import CardUserAdd from "@/components/Cards/CardUserAdd.vue";
import CardUserEdit from "@/components/Cards/CardUserEdit.vue";


export default {
  name: 'Users',
  
  mounted() {
    this.getTeam()
    this.getInvited()
  },

  data() {
    return {
      isEditExpanded: false,
      isAddExpanded: false,
      totalusers: 0,
      products: [],
      selectedUser: null,
      users: {
        members: []
      },
      invites: [],
    }
   },


  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    }
  },  

  components: {
    CardUserAdd,
    CardUserEdit,
  },
  
  watch: {
    isAddExpanded(newValue) {
      if (newValue) {
        this.users = [];
      } else {
        this.getTeam()
        this.getInvited()

      }
    },
    isEditExpanded(newValue) {
      if (!newValue) {
        this.getTeam()
        this.getInvited()

      }
    },
     
  }, 


  computed: {
    invitedInvites() {
  if (this.invites && this.invites.invites) {
    return this.invites.invites.filter(invite => invite.status === 'invited').map(invite => {
      return {
        id: invite.id,
        email: invite.email,
        permissions: invite.permissions || [],
        
      };
    });
  }
  return [];
},
  combinedMembersAndPhone() {
    const members = this.users.members || [];
    const memberRoles = this.users.member_roles || {};
    const timestamps = this.users.member_login || {};
    const memberActive = this.users.member_active || {}; 
    const memberPhones = this.users.member_phone || [];

    return members.map((member, index) => {
      const userId = member.id;
      const roles = memberRoles[userId];
      const formattedDate = timestamps[userId] || null; 
      const loginTimestamp = formattedDate ? this.formatDate(formattedDate) : '';
      const active = memberActive[userId] === true; 
      const phoneNumber = memberPhones[userId]

      return {
        member: member, 
        phone_number: phoneNumber || '', 
        roles: roles, 
        last_login: loginTimestamp, 
        member_active: active, 
      };
    });
  },
},
  methods: {
    handleUpdate(isEditExpanded) {
      this.isEditExpanded = isEditExpanded
    },

    handleUpdateAdd(isAddExpanded) {
      this.isAddExpanded = isAddExpanded
    },

     formatDate(timestamp) {
      return moment(timestamp).format('D MMM YYYY')
     },

     async getTeam() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/get_my_business/')
            .then(response => {
              this.users = response.data
              this.totalusers = response.data.members.length

            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async getInvited() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/invite/')
            .then(response => {
              this.invites = response.data

            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async removeItemFromUsers(userId) {
      const confirmDelete = await this.showDeleteConfirmation();

      if (!confirmDelete) {
        return; 
      }

      this.$store.commit('setIsLoading', true);

      axios
        .delete(`/mapche-api/v1/business/member/${userId}/`) 
        .then(response => {
          toast({
            message: 'The user was deleted',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          });
          
          this.getTeam()
          this.getInvited()
        })
        .catch(error => {
          console.error('Error deleting user:', error);
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false);
        });
      },

      async deleteInvite(inviteId) {
      const confirmDelete = await this.showDeleteConfirmationInvite();

      if (!confirmDelete) {
        return; 
      }

      this.$store.commit('setIsLoading', true);

      axios
        .delete(`/mapche-api/v1/business/invites/${inviteId}/`)
        .then(response => {
          toast({
            message: 'The invite was deleted',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          });
          
          this.getTeam()
          this.getInvited()
        })
        .catch(error => {
          console.error('Error deleting user:', error);
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false);
        });
      },

      showDeleteConfirmation() {
        return new Promise(resolve => {
          const confirmText = 'Are you sure you want to delete this user?';

          if (window.confirm(confirmText)) {
            resolve(true); 
          } else {
            resolve(false); 
          }
        })
      },

      showDeleteConfirmationInvite() {
        return new Promise(resolve => {
          const confirmText = 'Are you sure you want to delete this invite?';

          if (window.confirm(confirmText)) {
            resolve(true); 
          } else {
            resolve(false);
          }
        })
      },


    toggleAddExpanded() {
      this.isAddExpanded = !this.isAddExpanded
    },

    handleUpdateFeatures(isEditExpanded, selectedProvider, updatedFeatures) {
      this.isEditExpanded = isEditExpanded
    },

    toggleEdit(user) {
        if (this.selectedUser === user) {
          this.isEditExpanded = !this.isEditExpanded
        } else {
          this.selectedUser = user
          this.isEditExpanded = true
        }
    },

      selectUser(user) {
        this.selectedUser = user.member
      },

  },
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,300,1,0");

.images:hover {
    color:  rgba(239, 68, 68, 1);
    transition: 0.2s;
    transform: scale(1.2);
  }

  .small-icon {
  font-size: 18px; 
}

.lead-itemprovider {
  padding: 10px;
    cursor: pointer;
}

.lead-itemprovider:hover {
   background-color: #f1f3f6;
}


.lead-item {
  transition: transform 0.8s ease, box-shadow 0.3s ease; 
  box-shadow: none; 
}

.lead-item:hover {
  background-color: #fff;
  transform: scale(1.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
  opacity: 1;
}

.lead-header {
  background-color: #f1f3f6;
}


.selected-provider {
  background-color: #f1f3f6;
  opacity: 0.5;
}
.selected-provider:hover {
  background-color: #f1f3f6;
  opacity: 1;
}
.selected-provider-img {
  border-width: 1px; 
  border-style: solid;
  border-color: #e4e4e7;
  transform: scale(1.2);
  box-shadow: 0 1px 2px 0 rgba(168, 7, 130, 0.05);
}

</style>
