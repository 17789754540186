import axios from 'axios';
import _ from 'lodash';


let authToken;
let authTokenExpiration;

export async function getAuthToken() {
  if (authToken && Date.now() < authTokenExpiration) {
    return authToken
  }

  const formData = {
    username: "testapi@pindrop.co.za",
    password: "24BwJ!Vgn%H%r2"
  }
  
  
  try {
    const response = await axios.post('https://adapi.pindrop.co.za/advanced/authorize', formData)

    authToken = response.data.token
    authTokenExpiration = Date.now() + 45 * 60 * 1000
    axios.defaults.headers.common['Token'] = authToken
    localStorage.setItem('adapi', authToken)
    return authToken
  
  } catch (error) {
    throw error
  }
}

export async function getProviders(lat, lng, leadprovider) {
  
  const authToken = await getAuthToken()
  
  const providerid = await getLeadProvider(leadprovider)

  if (!providerid || providerid === '') {
    const results = ''
    return {
      providerApiresults: results
    }
  }
  const chosenProviders = [providerid]


  const requestData = {
    Lat: lat,
    Lng: lng,
    SupplierIncludes: chosenProviders
  }


  try {
    const response = await axios.post('https://adapi.pindrop.co.za/advanced/requestServices', requestData)

    const results = response.data.results

    return {
      providerApiresults: results
    }
  } catch (error) {
    throw error
  }
}

export async function getLeadProvider(leadprovider) {
  try {
    const requestData = {
      suppliers: true
    }
    const response = await axios.post('https://adapi.pindrop.co.za/advanced/requestLists', requestData)
    
    const providers = response.data.suppliers
    

    const provider = providers.find(provider =>
      provider.description.toLowerCase().replace(/\s/g, '') === leadprovider.toLowerCase().replace(/\s/g, '')
    );
    
    const providerid = provider ? provider.id : null;
    
    
    return providerid
  
  } catch (error) {
    throw error
  }
}