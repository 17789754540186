<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >

    
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Reference ID
            </th>            
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Contact Person
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Budget
            </th>
            <th
              class="px-5 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Product
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Provider
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Speed
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Lead Age
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Assigned To
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Lead Age
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Quote
          </th>
          </tr>
        </thead>
       <tbody>
          <tr class="lead-item" v-for="lead in leads" v-bind:key="lead.id" @click="selectLead(lead)" :class="{ 'bg-gray-200': selectedLead === lead}"
          >
          <td v-if="lead.options != ''"
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            
            <span
                  class="font-bold capitalize cursor-pointer"
                  :class="[color === 'light' ? 'text-emerald-600' : 'text-white']"
                  style="line-height: 2.5rem;"
                  title="Contact">
                  <i class="fas fa-mobile-alt mr-1"></i> {{ lead.reference_id }}
              </span>
          </td>
          <td v-else
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            <span
                title="Send Quote">
                <i class="fas fa-file-invoice mr-1"></i> {{ lead.reference_id }}
            </span>
          </td>
          <td 
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
          >
          <div class="flex item-center">
            <span
              class="ml-3 font-bold capitalize"
              :class="[
              color === 'light' ? 'text-blueGray-600' : 'text-white'], { 'rejected': lead.status === 'quoted' }" style="line-height: 2.5rem;"
            >
            {{ lead.first_name }} {{ lead.last_name }}
            </span>
          </div>
          </td>
      
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            {{ getproviderCurrency(lead.suppliername) }}{{ formatPrice(lead.mrc) }}
          </td>
          <td v-if="lead.options != ''"
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 capitalize"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            Contact Me
          </td>
          <td v-else
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 capitalize"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            {{ lead.product }}
          </td>          
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <div class="flex ml-1">
                <img
              :src="getproviderThumbIcon(lead.suppliername)"
              class="h-10 w-10 bg-white rounded-full border"
            />
            </div>
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            {{ lead.capacity }}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 capitalize"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
            <i class="fas fa-circle mr-1" :class="getStatusClass(lead)"></i> {{ lead.status }}
          </td>
          <td v-if="lead.status !== 'quoted' && lead.status !== 'rejected'"             
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 
              'rejected': lead.status === 'rejected', 
              'font-bold text-xs' : lead.status === 'new',
              [getStatusLeadAge(lead)]: lead.status === 'new'
              }"
          >
            {{ formatTime (lead.countdown) }}
            <span class="pl-2 inline-block animate-pulse"><i class=" animate-pulse-stopwatch" :class="[getStatusLeadAge(lead), getStatusHourGlass(lead)]"></i></span>
          </td>
          <td v-else if="lead.status === 'quoted'"            
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 
              'rejected': lead.status === 'rejected',
            }"
          >
          {{ formatOpportunityTime(lead.created_at, lead.modified_at) }}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'quoted' }"
          >
            <span v-if="lead.assigned_to" class="capitalize">{{ lead.assigned_to.first_name }} {{ lead.assigned_to.last_name }}</span>
          </td>
          
          <td v-if="lead.status !== 'quoted' && lead.status !== 'rejected' && lead.assigned_to"             
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 
              'rejected': lead.status === 'rejected', 
              'font-bold text-xs' : lead.status === 'new',
              [getStatusLeadAgeassigned(lead)]: lead.status === 'new'
              }"
          >
            {{ formatTimeassigned (lead.countdownassigned) }}
            <span class="pl-2 inline-block animate-pulse"><i class=" animate-pulse-stopwatch" :class="[getStatusLeadAgeassigned(lead), getStatusHourGlassassigned(lead)]"></i></span>
          </td>
          <td v-else             
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
          {{ '' }}
          </td>
          
        <td v-if="lead.options != ''"
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
         >
         <div class="flex ml-3 justify-left xs:border-l">
         </div>
        </td>
        <td v-else
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
         >
         <div class="flex ml-3 justify-left xs:border-l">
              <i class="fas fa-file-invoice text-xl text-emerald-600" title="View Quote"
              @click="generateQuote(lead)"></i>
         </div>
        </td>
        </tr>
      </tbody>
    </table>
   </div>
  </div>
  <div>
    <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" @click.prevent="goToPreviousPage" v-if="showPreviousButton">Previous</button>
    <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" @click.prevent="goToNextPage" v-if="showNextButton">Next</button>
  </div>
</template>
<script>
import axios from 'axios'
import { getCurrencyList  } from '@/components/currency'

export default {
  name: 'Leads',
  
  async created() {
    this.currencyList = await getCurrencyList()
  },

  mounted() {
    this.getTeam()
    this.getUser()
    this.getUserProfile()
    this.getVendors()
    this.getCoverages()
    this.getLeads()
  },

  beforeDestroy() {
    clearInterval(this.timerInterval)
  },

  beforeDestroyallocated() {
    clearInterval(this.timerIntervalallocated)
  },

  data() {
    return {
      leads: [],
      vendors: [],
      coverages: [],
      currencyList: [],
      showNextButton: false,
      showPreviousButton: false,
      currentPage: 1,
      percentage: 0,
      companyName: '',
      selectedLead: null,
      selectedLocation: null,
      countdown: [],
      countdownassigned: [],
      timerInterval: [],
      timerIntervalallocated: [],
      providers: {
             mapchi:'https://res.cloudinary.com/dlucmehqv/image/upload/v1718364729/mapchi-iconnewblue_lipacz_xkhiey_adt4mv.png'
      }

    }
  },
  components: {

  },
  emits: ['lead-selected'],
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    updatedStatus: {
      type: [Number, null],
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  
  watch: {
    selectedLead(lead) {
      if (lead) {
        this.getLocationDetails(lead.search).then (() => {
          const currencySymbol = this.getproviderCurrency(lead.suppliername);

          this.$emit('lead-selected', 
          lead.id, 
          lead.shopper_phone, 
          lead.suppliername,
          this.selectedLocation.location_lat, 
          this.selectedLocation.location_lng,
          currencySymbol
          )
        })
      }
    },
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLeads()
      }
    }
  }, 
  computed: {
        

    getproviderCurrency() {
      return (supplierName) => {

        if (supplierName) {
        const matchingCoverage = this.coverages.find((coverage) =>
          coverage.vendors?.some((vendor) => vendor.toLowerCase() === supplierName.toLowerCase())
        );

        if (matchingCoverage) {
          const currency = matchingCoverage.currency || '';
          const symbol = this.getSymbol(currency) || '';
          return symbol
        } else {
          return ''
        }
       }
      }
    },

    getCountryVAT() {
  return (supplierName) => {
    if (supplierName) {
      const matchingCoverage = this.coverages.find((coverage) =>
        coverage.vendors?.some((vendor) => vendor.toLowerCase() === supplierName.toLowerCase())
      );

      if (matchingCoverage) {
        const vat_rate = matchingCoverage.vat_rate || '0.00';
        const excise_duty_rate = matchingCoverage.excise_duty_rate || '0.00';

        return {
          vat_rate,
          excise_duty_rate,
        };
      } else {
        return {
          vat_rate: '0.00',
          excise_duty_rate: '0.00',
        };
      }
    } else {
      return {
        vat_rate: '0.00',
        excise_duty_rate: '0.00',
      };
    }
  };
},


    getproviderThumbIcon() {
      return (supplierName) => {
        if (!supplierName) {
          return this.providers.mapchi;
        }

        const supplier = this.vendors.find(vendor => vendor.name.toLowerCase().replace(/\s/g, '') === supplierName.toLowerCase().replace(/\s/g, ''));
        
        if (supplier && supplier.icon_url) {
          return supplier.icon_url;
        }

        return this.providers.mapchi;
      };
    },
   
    progressValues() {
      return this.leads.reduce((values, lead) => {
        values[lead.id] = this.calculateProgress(lead.status)
        return values
      }, {}) 
    },
    fifteenPercentMRC() {
      return (this.percentage * this.selectedLead.mrc / 100).toFixed(2);
    },
    totalAmountMRC() {
      return (parseFloat(this.fifteenPercentMRC) + parseFloat(this.selectedLead.mrc)).toFixed(2);
     },
     fifteenPercentNRC() {
      return (this.percentage * this.selectedLead.nrc / 100).toFixed(2);
    },
    totalAmountNRC() {
      return (parseFloat(this.fifteenPercentNRC) + parseFloat(this.selectedLead.nrc)).toFixed(2);
     },  
     documentCreatedDateTime() {
      const currentDate = new Date();
      const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
      return currentDate.toLocaleDateString('en-ZA', options);
    },
  },

  methods: {

    formatOpportunityTime(createdTime, modifiedTime) {
  if (!createdTime || !modifiedTime) return 'Invalid times';

  const createdDate = new Date(createdTime);
  const modifiedDate = new Date(modifiedTime);
  const timeInSeconds = Math.floor((modifiedDate - createdDate) / 1000);

  if (timeInSeconds <= 24 * 60 * 60) {
            const hours = Math.floor(timeInSeconds / 3600);
            const minutes = Math.floor((timeInSeconds % 3600) / 60);
            const seconds = timeInSeconds % 60;

            let formattedTime = '';

            if (hours > 0) {
                formattedTime += `${hours} hour${hours > 1 ? 's' : ''}`;
            }

            if (minutes > 0) {
                formattedTime += ` ${minutes} minute${minutes > 1 ? 's' : ''}`;
            }

            if (seconds > 0 && formattedTime === '') {
                formattedTime += ` ${seconds} second${seconds > 1 ? 's' : ''}`;
            }

            return formattedTime.trim();
        } else if (timeInSeconds <= 48 * 60 * 60) {
            const hours = Math.floor(timeInSeconds / 3600);
            return `${hours} hours`;
        } else {
            const days = Math.floor(timeInSeconds / (24 * 60 * 60));
            const months = Math.floor(days / 30);
            const remainingDays = days % 30;

            if (months >= 1) {
                if (remainingDays >= 1) {
                    return `${months} months and ${remainingDays} days`;
                } else {
                    return `${months} months`;
                }
            } else {
                return `${days} days`;
            }
        }
    
},

    formatPrice(price) {
      if (price) {
        return Number(price).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return ''; 
    },

    getSymbol(currency) {
      const currencyObject = this.currencyList.find(item => item.code === currency);
      return currencyObject ? currencyObject.symbol : '';
    },

    async getUser() {

        const userID = this.$store.state.user.id
      
        await axios
          .get(`/mapche-api/v1/business/member/${userID}/`)
          .then(response => {
            this.user = response.data
          })
          .catch(error => {
            console.log(error)
          })

      },

      async getUserProfile() {
      this.$store.commit('setIsLoading', true)

        axios.get('/mapche-api/v1/userprofile/')
          .then(response => {
            if(response.data[0]) {
              this.isExclusive = response.data[0].is_exclusive
            }
          })
          .catch((error) => {
            toast({
              message: 'An error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })
          })

      this.$store.commit('setIsLoading', false)    
    },

    async getTeam() {
      this.$store.commit('setIsLoading', true)

      await axios
          .get('/mapche-api/v1/business/get_my_business/')
          .then(response => {
            this.team = response.data
            this.companyName = this.team.name
            this.companyName = this.companyName.replace(/\s*(\(Pty\) Ltd|Ltd|CC|LLC)\s*$/i, '')
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },

    generateQuote(lead) {
      this.$store.commit('setIsLoading', true)
      let exclusiveName = ''
      this.selectLead(lead)
      const businessImage = this.team.business_image
      const quoteNumber = this.selectedLead.reference_id
      let formattedId = this.selectedLead.id.toString().padStart(6, '0');
      const currency = this.getproviderCurrency(this.selectedLead.suppliername)
      this.percentage = this.getCountryVAT(this.selectedLead.suppliername).vat_rate
      if (this.isExclusive) {
         exclusiveName = this.companyName
      } else {
        exclusiveName = this.selectedLead.suppliername         
      }
      const dataDict = {
        'Supplier 1': ' ' + exclusiveName || '',
        'enddate': this.documentCreatedDateTime,
        'Description 1': ' ' + this.selectedLead.description || '',
        'Service Capacity 1': this.selectedLead.capacity || '',
        'Term 1': this.selectedLead.contractperiod || '',
        'Quantity 1': '1',
        'Monthly Cost 1': currency + ' ' + this.selectedLead.mrc + ' ',
        'Monthly Cost Sub Total': currency + ' ' + this.selectedLead.mrc + ' ',
        'Monthly Cost VAT': currency + ' ' + this.fifteenPercentMRC + ' ',
        'VAT': ' VAT ' + '(' + this.percentage + '%' +')' + ':',
        'Monthly Cost Total': currency + ' ' + this.totalAmountMRC + ' ',
        'Once Off Cost 1': currency + ' ' + this.selectedLead.nrc + ' ',
        'Once Off Cost Sub Total': currency + ' ' + this.selectedLead.nrc + ' ',
        'Once Off Cost VAT': currency + ' ' + this.fifteenPercentNRC + ' ',
        'Once Off Cost Total': currency + ' ' + this.totalAmountNRC + ' ',
        'Site Name#0': this.selectedLocation.address_component_street || '',
        'Prepared By Company Name#0': this.$store.state.business.name || '',
        'Location Name#0': this.selectedLocation.address_component_street || '',
        'Recipient Name#0': (this.selectedLead.first_name || '') + ' ' + (this.selectedLead.last_name || ''),
        'Prepared By Name#0': (this.user.first_name || '') + ' ' + (this.user.last_name || ''),
        'Location Address#1': this.selectedLocation.formatted_address || '',
        'Recipient Email#0': this.selectedLead.email || '',
        'Prepared By Email#0': this.$store.state.user.username || '',
        'Location Coordinates#0': (this.selectedLocation.location_lat || '') + ' , ' + (this.selectedLocation.location_lng || ''),
        'Recipient Number#0': this.selectedLead.shopper_phone || '',
        'Prepared By Number#0': this.$store.state.mobile.phone_number || '',
        'Document Created Date and Time#1': this.documentCreatedDateTime,
        'Site NameDes#0': this.selectedLocation.address_component_street || '',
        'Customer Reference#0': 'Reference ID:' + ' ' + (quoteNumber || ''),
        'Quote Name#0': '[' + formattedId + ']',
        'image1_af_image': businessImage,
      };

      const start = performance.now(); 
      axios.post(`/accounts/generate_quote/${formattedId}/`, { data_dict: dataDict }, {
        responseType: 'blob', 
      })
      .then(response => {

        const end = performance.now(); 
        const totalTime = end - start;

        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Quote[${formattedId}].pdf`;
        link.click();
        this.$store.commit('setIsLoading', false); 
      })
      .catch(error => {
        console.error('Error generating quote:', error);
        this.$store.commit('setIsLoading', false); 
      });


    },


    goToNextPage() {
          this.currentPage += 1
          this.getLeads()
        },
        goToPreviousPage() {
          this.currentPage -= 1
          this.getLeads()
        },

    calculateProgress(status) {
      if (status === 'new') {
        return 10
      } else if (status === 'quoted') {
        return 100
      } else {
        return 0
      }
    },

    getStatusClass(lead) {
        if (lead.status === 'new') {
          return 'text-pink-400'
        } else if (lead.status === 'quoted') {
          return 'text-emerald-500'
        } else {
          return ''
        }
    },

    getStatusClassassigned(lead) {
        if (lead.status === 'new') {
          return 'text-pink-400'
        } else if (lead.status === 'quoted') {
          return 'text-emerald-500'
        } else {
          return ''
        }
    },

    getStatusClassCompletionbase(lead) {
        if (lead.status === 'new') {
          return 'bg-pink-200'
        } else if (lead.status === 'quoted') {
          return 'bg-emerald-500'
        } else {
          return ''
        }
    },

    getStatusClassCompletionLine(lead) {
        if (lead.status === 'new') {
          return 'bg-pink-500'
        } else if (lead.status === 'quoted') {
          return 'bg-emerald-500'
        } else {
          return ''
        }
    },

    selectLead(lead) {
      this.selectedLead = lead
    },

    getStatusLeadAge (lead) {
      
        const createdAt = new Date(lead.created_at)
        const now = new Date()
        const timeDiff = now - createdAt
        const minuteDiff = Math.floor(timeDiff / 1000)

        if (lead.status === 'new') {
          if (minuteDiff <= (10 * 60)) {
            return 'text-emerald-500'
          } else if (minuteDiff<= 30 * 60) {11
            return 'text-yellow-500'
          } else if (minuteDiff <= (24 * 60 * 60)) {
            return 'text-orange-500'  
          } else {
            return 'text-red-500'
          }
        }
    },

    getStatusHourGlass(lead) {
      const createdAt = new Date(lead.created_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)

        if (lead.status === 'new') {
          if (minuteDiff <= (10 * 60)) {
            return 'fas fa-hourglass-start'
          } else if (minuteDiff <= 30 * 60) {
            return 'fas fa-hourglass-half'
          } else if (minuteDiff <= (24 * 60 * 60)) {
            return 'fas fa-hourglass-end'
          } else {
            return 'fas fa-hourglass'
          }
        }
    },

    getStatusLeadAgeassigned (lead) {
      
      const createdAt = new Date(lead.modified_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)

      if (lead.status === 'new') {
        if (minuteDiff <= (10 * 60)) {
          return 'text-emerald-500'
        } else if (minuteDiff<= 30 * 60) {11
          return 'text-yellow-500'
        } else if (minuteDiff <= (24 * 60 * 60)) {
          return 'text-orange-500'  
        } else {
          return 'text-red-500'
        }
      }
  },

  getStatusHourGlassassigned(lead) {
    const createdAt = new Date(lead.modified_at)
    const now = new Date()
    const timeDiff = now - createdAt
    const minuteDiff = Math.floor(timeDiff / 1000)

      if (lead.status === 'new') {
        if (minuteDiff <= (10 * 60)) {
          return 'fas fa-hourglass-start'
        } else if (minuteDiff <= 30 * 60) {
          return 'fas fa-hourglass-half'
        } else if (minuteDiff <= (24 * 60 * 60)) {
          return 'fas fa-hourglass-end'
        } else {
          return 'fas fa-hourglass'
        }
      }
  },

  startTimer(lead) {
    const createdAt = new Date(lead.created_at);
    const now = new Date();
    const timeDiff = now - createdAt;
    const minuteDiff = Math.floor(timeDiff / 1000);
    lead.countdown = minuteDiff;
    
    clearInterval(lead.timerIntervals)
    lead.timerIntervals = setInterval(() => {
      lead.countdown += 1;
    }, 1000);
  },

  startleadtimer(lead) {
    const createdAt = new Date(lead.modified_at);
    const now = new Date();
    const timeDiff = now - createdAt;
    const minuteDiff = Math.floor(timeDiff / 1000);
    lead.countdownassigned = minuteDiff;
    
    clearInterval(lead.timerIntervalsallocated)
    lead.timerIntervalsallocated = setInterval(() => {
      lead.countdownassigned += 1;
    }, 1000);
  },

formatTime(countdown) {

  if (countdown <= 24 * 60 * 60) {
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const remainingSeconds = countdown % 60;

    const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
    const minutesText = minutes.toString().padStart(2, '0');
    const secondsText = remainingSeconds.toString().padStart(2, '0');

    if (hours > 0) {
      return `${hoursText}${minutesText}:${secondsText} hours`;
    } else if (minutes > 0) {
      return `${minutesText}:${secondsText} minutes`;
    } else {
      return `${secondsText} seconds`;
    }
  } else if (countdown <= 48 * 60 * 60) {
    const hours = Math.floor(countdown / 3600);
    return `${hours} hours`;


  } else {
    const days = Math.floor(countdown / (24 * 60 * 60));
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;

    if (months >= 1) {
      if (remainingDays >= 1) {
        return `${months} months and ${remainingDays} days`;
      } else {
        return `${months} months`;
      }
    } else {
      return `${days} days`;
    }
  }
},
  
formatTimeassigned(countdownassigned) {

if (countdownassigned <= 24 * 60 * 60) {
  const hours = Math.floor(countdownassigned / 3600);
  const minutes = Math.floor((countdownassigned % 3600) / 60);
  const remainingSeconds = countdownassigned % 60;

  const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
  const minutesText = minutes.toString().padStart(2, '0');
  const secondsText = remainingSeconds.toString().padStart(2, '0');

  if (hours > 0) {
    return `${hoursText}${minutesText}:${secondsText} hours`;
  } else if (minutes > 0) {
    return `${minutesText}:${secondsText} minutes`;
  } else {
    return `${secondsText} seconds`;
  }
} else if (countdownassigned <= 48 * 60 * 60) {
  const hours = Math.floor(countdownassigned / 3600);
  return `${hours} hours`;


} else {
  const days = Math.floor(countdownassigned / (24 * 60 * 60));
  const months = Math.floor(days / 30);
  const remainingDays = days % 30;

  if (months >= 1) {
    if (remainingDays >= 1) {
      return `${months} months and ${remainingDays} days`;
    } else {
      return `${months} months`;
    }
  } else {
    return `${days} days`;
  }
}
},

      async getVendors() {
        try {
          const response = await axios.get(`/vendor/vendors/`)
          this.vendors = response.data;
        } catch (error) {
          console.error(error);
        }
      },  

      async getCoverages() {
        try {
          const response = await axios.get('/coverage/userglobalcoverages/')
          this.coverages = response.data;
        } catch (error) {
          console.error(error);
        }
      },

    async getLeads() {

      this.showNextButton = false
      this.showPreviousButton = false

            axios
              .get(`/mapche-api/v1/leads/?page=${this.currentPage}&search=${this.searchQuery}`)
              .then(response => {
                this.leads = response.data.results
                this.leads.sort((a, b) => {
                  if (a.status === 'new' && b.status !== 'new') {
                    return -1; 
                  } else if (a.status !== 'new' && b.status === 'new') {
                    return 1; 
                  }
                  return new Date(b.created_at) - new Date(a.created_at);
                });

                if (this.leads.length > 0) {
                  this.selectedLead = this.leads[0]
                  this.getLocationDetails(this.selectedLead.search)
                  this.leads.forEach((lead) => {
                    lead.statusClass = this.getStatusClass(lead)
                    lead.statusClass = this.getStatusClassassigned(lead)
                    this.startleadtimer(lead)
                    this.startTimer(lead)
                  })
                }

                if(response.data.next) {
                  this.showNextButton = true
                }

                if(response.data.previous) {
                  this.showPreviousButton = true
                }

                
              })
              .catch(error => {
                console.log(error)
              })

    },


    async getLocationDetails(locdetails) {
      await axios
          .get(`/mapche-api/v1/search/${locdetails}`)
          .then(response => {
            this.selectedLocation = response.data
          })
          .catch(error => {
            console.log(error)
          })

    },


  
  }
}
</script>

<style scoped>


  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #f1f3f6;
  }

  .rejected {
    text-decoration: line-through;
  }
</style>