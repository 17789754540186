<template>
  
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >

    <div class="rounded-t mb-0 px-6 py-6 border-0" >
        <div v-if="this.totalvendors > 0"  class="flex justify-between items-center">
          <div>
            <h3
              class="font-bold text-xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Networks<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalvendors}}
            </h3>
          </div>
          <div class="search-box">
                <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                  type="text"
                  placeholder="Search.."
                  v-model="query"
                  v-on:input="handleSearch"
                  v-on:click.stop
                />
          </div>
       </div>
        <div v-else class="flex justify-between items-center">
          <div>
          <h3
              
              class="font-bold text-xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Lets add a country first - Global Coverage
              <router-link v-if="(isCreatedByCurrentUser || isCurrentUserAdmin)" 
              :to="{'name': 'Settings'}"
              class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ml-4 ease-linear transition-all duration-150"
            >
              Settings
            </router-link>
            </h3>
         </div>
      </div>
    </div>


    
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Active
            </th>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Network
            </th>
            <th
              class="p-4 justify-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            <div class="flex justify-center item-center">
              GPON (FTTH)
            </div>  
            </th>
            <th
              class="p-4 align-middle justify-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            <div class="flex justify-center item-center">
              LTE / 5G
            </div>  
            </th>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            <div class="flex justify-center item-center">
              Fibre
            </div>  
            </th>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            <div class="flex justify-center item-center">
              Wireless
            </div>  
            </th>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            <div class="flex justify-center item-center">
              VSAT
            </div>  
            </th>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            <div class="flex justify-center items-center">
              Country
            </div>
            </th>  
           
          </tr>
        </thead>
       <tbody>
          <tr class="lead-item" v-for="vendor in vendors" :key="vendor.supplier_id" :class="{ 'bg-gray-200': selectedVendor === vendor}"
          >          
          <td 
            class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
          <div class="flex ml-4 item-center">
            <input
            type="checkbox"
            v-model="selectedVendors"
            :value="vendor.supplier_id"
            @change="handleVendorSelection(vendor.supplier_id)"
            class="focus:ring-0 text-emerald-600"
          />
          </div>
          </td>
      
          <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <div class="flex items-center ml-1">
              <img :src="vendor.icon_url" class="h-16 w-16 bg-white rounded-full border" />
              <span class="ml-2">{{ vendor.name }}</span>
            </div>
          </td>

          <td class="border-t-0 align-middle border-l-0 bg-emerald-200 border-r-0 text-xs whitespace-nowrap p-4">
            <div class="flex justify-center item-center">
            <input
              type="checkbox"
              v-model="selectedGPONByVendor"
              :value="getCheckboxValue(vendor, 'Fibre to the Home')"
              @change="handleSelection(vendor.supplier_id)"
              :disabled="shouldDisableGPONCheckbox(vendor)"
              :class="getCheckboxClass(vendor, 'Fibre to the Home', this.shouldDisableGPONCheckbox)"
            />
            </div>
          </td>

          <td class="border-t-0 align-middle border-l-0 bg-gray-200 border-r-0 text-xs whitespace-nowrap p-4">
            <div class="flex justify-center item-center">
            <input
              type="checkbox"
              v-model="selectedLTEByVendor"
              :value="getCheckboxValue(vendor, 'LTE')"
              @change="handleSelection(vendor.supplier_id)"
              :disabled="shouldDisableLTECheckbox(vendor)"
              :class="getCheckboxClass(vendor, 'LTE', this.shouldDisableLTECheckbox)"
            />
            </div>
          </td>          

          <td class="border-t-0 align-middle border-l-0 border-r-0 bg-orange-200 text-xs whitespace-nowrap p-4"
          >
          <div  class="flex justify-center item-center">
            <input
              type="checkbox"
              v-model="selectedFibreByVendor"
              :value="getCheckboxValue(vendor, 'Fibre Business')"
              @change="handleSelection(vendor.supplier_id)"
              :disabled="shouldDisableFibreCheckbox(vendor)"
              :class="getCheckboxClass(vendor, 'Fibre Business', this.shouldDisableFibreCheckbox)"
            />
            </div>
          </td>

          <td class="border-t-0 align-middle border-l-0 border-r-0 bg-blue-200 text-xs whitespace-nowrap p-4"
          >
          <div  class="flex justify-center item-center">
            <input
              type="checkbox"
              v-model="selectedMicrowaveByVendor"
              :value="getCheckboxValue(vendor, 'Microwave')"
              @change="handleSelection(vendor.supplier_id)"
              :disabled="shouldDisableMicrowaveCheckbox(vendor)"
              :class="getCheckboxClass(vendor, 'Microwave', this.shouldDisableMicrowaveCheckbox)"
            />
            </div>
          </td>

          
          <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs bg-purple-500 whitespace-nowrap p-4"
          >
          <div  class="flex justify-center item-center">
            <input
              type="checkbox"
              v-model="selectedVSATByVendor"
              :value="getCheckboxValue(vendor, 'Satellite Internet')"
              @change="handleSelection(vendor.supplier_id)"
              :disabled="shouldDisableVSATCheckbox(vendor)"
              :class="getCheckboxClass(vendor, 'Satellite Internet', this.shouldDisableVSATCheckbox)"
            />
            </div>
          </td>
          <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <div class="flex justify-center items-center">
              <img :src="getFlagUrl(vendor.country_name)" class="h-10 w-10 bg-white rounded-full border" />

            </div>
          </td>
        </tr>
      </tbody>
    </table>
   </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'CardProviders',
  
  mounted() {
    this.getVendors()
    this.getTeam()
    this.fetchCountries();
  },


  data() {
    return {
      query: '',
      vendors: [],
      countries: [],
      totalvendors: 0,
      selectedVendors: [],
      selectedVendor: null, 
      selected_services_GPON: [],
      selected_services_LTE: [],
      selected_services_Microwave: [],
      selected_services_Fibre: [],
      selected_services_Copper: [],
      selected_services_VSAT: [],
      selectedVendorId: null,
      selectedServices: [],
      selectedGPONByVendor: [], 
      selectedLTEByVendor: [],
      selectedMicrowaveByVendor: [],
      selectedFibreByVendor: [],
      selectedCopperByVendor: [],
      selectedVSATByVendor: [],
      team: {
          members: [],
          created_by: {}
        },
    }
  },
  components: {

  },
  emits: ['vendor-selected'],
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  
  watch: {
    selectedVendor(vendor) {
      if (vendor) {
        this.$emit('vendor-selected', vendor.name, vendor.supplier_id)
      }
    },
    query(newVal, oldVal) {
      if (newVal !== oldVal && this.query && this.query.length > 3 || this.query.length == 0) {
        if (this.query.length === 0) {
          this.selectedProvider = []; 

        }

        this.getVendors()
      }
    }  
  }, 
  computed: {
    filteredCountries() {
      return this.countries.map(country => ({
        ...country,
        flagUrl: `https://flagcdn.com/w80/${country.cca2.toLowerCase()}.png`,
      }));
    },
  isCreatedByCurrentUser() {
      const teamCreatedBy = this.team.created_by
      const userId = this.$store.state.user.id
      return teamCreatedBy && teamCreatedBy.id === +userId
  },
  isCurrentUserAdmin() {
    const userRoles = this.$store.state.user.roles 


    return userRoles.includes("Admin")
   },

  },

  methods: {
    async getTeam() {
      this.$store.commit('setIsLoading', true)

      await axios
          .get('/mapche-api/v1/business/get_my_business/')
          .then(response => {
            this.team = response.data
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },

    getFlagUrl(countryName) {
      const country = this.filteredCountries.find(c => c.name.common === countryName);
      return country ? country.flagUrl : ''; 
    },
    async fetchCountries() {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      this.countries = response.data;

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  },

      addUniqueSelectedGPONService(vendor_id, service_id) {
        const exists = this.selected_services_GPON.some(
          item => item.vendor_id === vendor_id && item.service_id === service_id
        );

        if (!exists) {
          this.selected_services_GPON.push({ vendor_id, service_id });
          
        }
      },

    addUniqueSelectedLTEService(vendor_id, service_id) {
        const exists = this.selected_services_LTE.some(
          item => item.vendor_id === vendor_id && item.service_id === service_id
        );

        if (!exists) {
          this.selected_services_LTE.push({ vendor_id, service_id });
          
        }
      },
      addUniqueSelectedMicrowaveService(vendor_id, service_id) {
        const exists = this.selected_services_Microwave.some(
          item => item.vendor_id === vendor_id && item.service_id === service_id
        );

        if (!exists) {
          this.selected_services_Microwave.push({ vendor_id, service_id });
          
        }
      },      
      addUniqueSelectedFibreService(vendor_id, service_id) {
        const exists = this.selected_services_Fibre.some(
          item => item.vendor_id === vendor_id && item.service_id === service_id
        );

        if (!exists) {
          this.selected_services_Fibre.push({ vendor_id, service_id });
          
        }
      },

      addUniqueSelectedCopperService(vendor_id, service_id) {

        const exists = this.selected_services_Copper.some(
          item => item.vendor_id === vendor_id && item.service_id === service_id
        );


        if (!exists) {
          this.selected_services_Copper.push({ vendor_id, service_id });
          
        }
      },

      addUniqueSelectedVSATService(vendor_id, service_id) {

        const exists = this.selected_services_VSAT.some(
          item => item.vendor_id === vendor_id && item.service_id === service_id
        );


        if (!exists) {
          this.selected_services_VSAT.push({ vendor_id, service_id });
          
        }
      },      

      handleVendorSelection(vendorId) {

        const selected_services = [
            ...this.selectedGPONByVendor,
            ...this.selectedLTEByVendor,
            ...this.selectedMicrowaveByVendor,
            ...this.selectedFibreByVendor,
            ...this.selectedCopperByVendor,
            ...this.selectedVSATByVendor
          ]
          const isServiceSelected = selected_services.some(service => service.vendor_id === vendorId)
          if (this.selectedVendors.includes(vendorId)) {
            if (isServiceSelected) {
              this.saveUserPreferences();
            }

            this.toggleServicesMapRules(vendorId);
          } else {
            this.saveUserPreferences();

          }
      },


      handleSelection(vendorId) {
        
        const selected_services = [
            ...this.selectedGPONByVendor,
            ...this.selectedLTEByVendor,
            ...this.selectedMicrowaveByVendor,
            ...this.selectedFibreByVendor,
            ...this.selectedCopperByVendor,
            ...this.selectedVSATByVendor
          ]
          const isServiceSelected = selected_services.some(service => service.vendor_id === vendorId)
        if (!isServiceSelected) {
          const index = this.selectedVendors.indexOf(vendorId)

          if (index !== -1) {
            this.selectedVendors.splice(index, 1);
            this.saveUserPreferences();
          }
        } 
          this.saveUserPreferences()
      },


      getCheckboxValue(vendor, serviceName) {
        const serviceId = vendor.services && vendor.services.find(service => service.name === serviceName)?.service_id;
        return { vendor_id: vendor.supplier_id, service_id: serviceId || null };
      },
      shouldDisableGPONCheckbox(vendor) {

        const hasSelectedVendorWithGPON = this.selectedVendors.some(selectedVendorId => {
          return selectedVendorId === vendor.supplier_id && this.hasService(vendor.services, 'Fibre to the Home');
        });


        if (!hasSelectedVendorWithGPON) {
          return true;
        }


        for (const selectedVendorId of this.selectedVendors) {
          if (
            selectedVendorId === vendor.supplier_id &&
            !this.hasService(vendor.services, 'Fibre to the Home')
          ) {
            return true; 
          }
        }
        return false; 
      },
      shouldDisableLTECheckbox(vendor) {
        const hasSelectedVendorWithLTE = this.selectedVendors.some(selectedVendorId => {
          return selectedVendorId === vendor.supplier_id && this.hasService(vendor.services, 'LTE');
        });

        if (!hasSelectedVendorWithLTE) {
          return true;
        }

        for (const selectedVendorId of this.selectedVendors) {
          if (
            selectedVendorId === vendor.supplier_id &&
            !this.hasService(vendor.services, 'LTE')
          ) {
            return true; 
          }
        }
        return false; 
      },
      shouldDisableFibreCheckbox(vendor) {
        const hasSelectedVendorWithFibre = this.selectedVendors.some(selectedVendorId => {
          return selectedVendorId === vendor.supplier_id && this.hasService(vendor.services, 'Fibre Business');
        });

        if (!hasSelectedVendorWithFibre) {
          return true;
        }

        for (const selectedVendorId of this.selectedVendors) {
          if (
            selectedVendorId === vendor.supplier_id &&
            !this.hasService(vendor.services, 'Fibre Business')
          ) {
            return true; 
          }
        }
        return false; 
      },      
      shouldDisableMicrowaveCheckbox(vendor) {
        const hasSelectedVendorWithMicrowave = this.selectedVendors.some(selectedVendorId => {
          return selectedVendorId === vendor.supplier_id && this.hasService(vendor.services, 'Microwave');
        });

        if (!hasSelectedVendorWithMicrowave) {
          return true;
        }

        for (const selectedVendorId of this.selectedVendors) {
          if (
            selectedVendorId === vendor.supplier_id &&
            !this.hasService(vendor.services, 'Microwave')
          ) {
            return true; 
          }
        }
        return false; 
      },
      shouldDisableCopperCheckbox(vendor) {
        const hasSelectedVendorWithCopper = this.selectedVendors.some(selectedVendorId => {
          return selectedVendorId === vendor.supplier_id && this.hasService(vendor.services, 'Copper');
        });

        if (!hasSelectedVendorWithCopper) {
          return true;
        }

        for (const selectedVendorId of this.selectedVendors) {
          if (
            selectedVendorId === vendor.supplier_id &&
            !this.hasService(vendor.services, 'Copper')
          ) {
            return true; 
          }
        }
        return false; 
      },
      shouldDisableVSATCheckbox(vendor) {
        const hasSelectedVendorWithVSAT = this.selectedVendors.some(selectedVendorId => {
          return selectedVendorId === vendor.supplier_id && this.hasService(vendor.services, 'Satellite Internet');
        });

        if (!hasSelectedVendorWithVSAT) {
          return true;
        }

        for (const selectedVendorId of this.selectedVendors) {
          if (
            selectedVendorId === vendor.supplier_id &&
            !this.hasService(vendor.services, 'Satellite Internet')
          ) {
            return true; 
          }
        }
        return false; 
      },

      getCheckboxClass(vendor, serviceName, shouldDisableFn) {
        return {
            'focus:ring-0 text-emerald-600': this.hasService(vendor.services, serviceName),
            'disabled-checkbox': shouldDisableFn(vendor)
        };
      },

      hasService(services, serviceName) {
        return services && services.some(service => service.name === serviceName);
      },


      async getVendors() {
        this.$store.commit('setIsLoading', true)
        try {
          const response = await axios.get(`/vendor/vendors/?search=${this.query}`)
          this.vendors = response.data;
          this.totalvendors = response.data.length 

          const vendorIds = this.vendors.map(vendor => vendor.supplier_id);
          await this.fetchServicesAndMapRulesForAll(vendorIds);
          await this.checkUserPreferencesForAllold()
        } catch (error) {
          console.error(error);
        }
        this.$store.commit('setIsLoading', false)
      },      


async fetchServicesAndMapRulesForAll(vendorIds) { 
  try {
    const params = new URLSearchParams();
    vendorIds.forEach(id => params.append('vendor_ids', id));

    const response = await axios.get(`/vendor/vendors/all-vendors-services/`, { params });
    const allServices = response.data;

    allServices.forEach(service => {
      service.supplier_ids.forEach(supplier_id => {
        const vendor = this.vendors.find(v => v.supplier_id === supplier_id);
        if (vendor) {
          if (!vendor.services) vendor.services = [];
          vendor.services.push(service);
        }
      });
    });
  } catch (error) {
    console.error("Error fetching services for vendors:", error);
  }
},


async checkUserPreferencesForAllold() {
  try {
    const response = await axios.get('/mapche-api/v1/user-preferences/');
    const userPreferences = response.data;
    this.selectedVendors = userPreferences.map(item => item.supplier_id);

    const serviceFunctionMapping = {
      'LTE': this.addUniqueSelectedLTEService,
      'Fibre to the Home': this.addUniqueSelectedGPONService,
      'Microwave': this.addUniqueSelectedMicrowaveService,
      'Fibre Business': this.addUniqueSelectedFibreService,
      'Copper': this.addUniqueSelectedCopperService,
      'Satellite Internet': this.addUniqueSelectedVSATService
    };

    this.vendors.forEach(vendor => {
      if (this.selectedVendors.includes(vendor.supplier_id)) {
        const userPreferenceForVendor = userPreferences.find(item => item.supplier_id === vendor.supplier_id);
        if (userPreferenceForVendor) {
          vendor.services.forEach(service => {
            if (userPreferenceForVendor.services.includes(service.service_id) && serviceFunctionMapping[service.name]) {
              serviceFunctionMapping[service.name](vendor.supplier_id, service.service_id);

              switch (service.name) {
                case 'LTE':
                  this.selectedLTEByVendor = this.selected_services_LTE;
                  break;
                case 'Fibre to the Home':
                  this.selectedGPONByVendor = this.selected_services_GPON;
                 break;
                  case 'Microwave':
                  this.selectedMicrowaveByVendor = this.selected_services_Microwave;
                  break;
                case 'Fibre Business':
                  this.selectedFibreByVendor = this.selected_services_Fibre;
                  break;
                case 'Copper':
                  this.selectedCopperByVendor = this.selected_services_Copper;
                  break;
                case 'Satellite Internet':
                  this.selectedVSATByVendor = this.selected_services_VSAT;
                  break;
              }
            }
          });
        }
      }
    });
  } catch (error) {
    console.error("Error fetching user preferences:", error);
  }
},

        async saveUserPreferences() {
          const selected_services = [
            ...this.selectedGPONByVendor,
            ...this.selectedLTEByVendor,
            ...this.selectedMicrowaveByVendor,
            ...this.selectedFibreByVendor,
            ...this.selectedCopperByVendor,
            ...this.selectedVSATByVendor
          ]

          
          try {
            const response = await axios.post('/mapche-api/v1/user-preferences/', {
              selectedVendors: this.selectedVendors,
              selectedServices: selected_services
            });


          } catch (error) {
            console.error('Error saving preferences:', error);
          }
        },

        toggleServicesMapRules(vendorId) {
          if (this.selectedVendorId === vendorId) {
            this.selectedVendorId = null;
          } else {  
          const vendor = this.vendors.find((v) => v.supplier_id === vendorId)
          vendor.showServicesMap = !vendor.showServicesMap

          this.selectedVendorId = vendorId
          }
        },
  }
}
</script>

<style scoped>
  .disabled-checkbox {
    opacity: 0.2;
    background-color: rgba(241, 245, 249, 1);
    cursor: not-allowed;
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center center;
  }

  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #f1f3f6;
  }

</style>