<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >

    
    <div class="block w-full overflow-x-auto">
      
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Reseller
            </th> 
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Reference ID
            </th>            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Budget
            </th>
            <th
              class="px-5 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Product
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Provider
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Speed
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Lead Age
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            ></th>
          </tr>
        </thead>
       <tbody>
          <tr class="lead-item" v-for="lead in leads" v-bind:key="lead.id" @click="selectLead(lead)" :class="{ 'bg-gray-200': selectedLead === lead}"
          >
          <td
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 font-bold capitalize"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
           <span class="ml-1 font-bold capitalize">
            {{ currentreseller }}
          </span>
          </td>          
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
            {{ lead.reference_id }}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
            {{ currency }}{{ lead.mrc }}
          </td>
          <td
            class="border-t-0 px-5 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 capitalize"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
            {{ lead.product }}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          >
            <div class="flex ml-1">
                <img
              :src="getproviderThumbIcon(lead.suppliername)"
              class="h-10 w-10 bg-white rounded-full border"
            />
            </div>
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
            {{ lead.capacity }}
          </td>
          <td
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 capitalize"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
            <i class="fas fa-circle mr-1" :class="getStatusClass(lead)"></i> {{ lead.status }}
          </td>
          <td v-if="lead.status !== 'contacted' && lead.status !== 'rejected'"             
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 
              'rejected': lead.status === 'rejected', 
              'font-bold text-xs' : lead.status === 'new',
              [getStatusLeadAge(lead)]: lead.status === 'new'
              }"
          >
            {{ formatTime (lead.countdown) }}
            <span class="pl-2 inline-block animate-pulse"><i class=" animate-pulse-stopwatch" :class="[getStatusLeadAge(lead), getStatusHourGlass(lead)]"></i></span>
          </td>
          <td v-else             
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            :class="{ 'rejected': lead.status === 'rejected' }"
          >
          {{ '' }}
          </td>
          
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
         >
        </td>
        </tr>
      </tbody>
    </table>
   </div>
  </div>
  <div>
    <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" @click.prevent="goToPreviousPage" v-if="showPreviousButton">Previous</button>
    <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" @click.prevent="goToNextPage" v-if="showNextButton">Next</button>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Leads',
  
  mounted() {
    this.getVendors()
    this.getLeads()

  },

  beforeDestroy() {
    clearInterval(this.timerInterval)
  },

  beforeDestroyallocated() {
    clearInterval(this.timerIntervalallocated)
  },

  data() {
    return {
      leads: [],
      vendors: [],
      showNextButton: false,
      showPreviousButton: false,
      currentPage: 1,
      selectedLead: null,
      selectedLocation: null,
      currency: 'R',
      countdown: [],
      countdownassigned: [],
      timerInterval: [],
      timerIntervalallocated: [],
      providers: {
             mapchi:'https://res.cloudinary.com/dlucmehqv/image/upload/v1718364729/mapchi-iconnewblue_lipacz_xkhiey_adt4mv.png'
      }

    }
  },
  components: {

  },
  emits: ['lead-selected'],
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    searchQuery: {
      type: String,
      default: '',
    },
    resellerleads: {
      type: Array,
      default: () => [],
    },
    currentreseller: String,
  },
  
  watch: {
    resellerleads(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLeads()
      }
    },
    selectedLead(lead) {
      if (lead) {
      }
    },
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLeads()
      }
    }
  }, 
  computed: {

    getproviderThumbIcon() {
      return (supplierName) => {
        const supplier = this.vendors.find(vendor => vendor.name.toLowerCase().replace(/\s/g, '') === supplierName.toLowerCase().replace(/\s/g, ''));
        if (supplier && supplier.icon_url) {
          return supplier.icon_url;
        }
        return this.providers.mapchi;
      };
    },

    progressValues() {
      return this.leads.reduce((values, lead) => {
        values[lead.id] = this.calculateProgress(lead.status)
        return values
      }, {}) 
    }
  },

  methods: {
    goToNextPage() {
          this.currentPage += 1
          this.getLeads()
        },
        goToPreviousPage() {
          this.currentPage -= 1
          this.getLeads()
        },

    calculateProgress(status) {
      if (status === 'new') {
        return 10
      } else if (status === 'contacted') {
        return 100
      } else {
        return 0
      }
    },

    getStatusClass(lead) {
        if (lead.status === 'new') {
          return 'text-pink-400'
        } else if (lead.status === 'contacted') {
          return 'text-emerald-500'
        } else {
          return ''
        }
    },

    getStatusClassassigned(lead) {
        if (lead.status === 'new') {
          return 'text-pink-400'
        } else if (lead.status === 'contacted') {
          return 'text-emerald-500'
        } else {
          return ''
        }
    },

    getStatusClassCompletionbase(lead) {
        if (lead.status === 'new') {
          return 'bg-pink-200'
        } else if (lead.status === 'contacted') {
          return 'bg-emerald-500'
     
        } else {
          return ''
        }
    },

    getStatusClassCompletionLine(lead) {
        if (lead.status === 'new') {
          return 'bg-pink-500'
        } else if (lead.status === 'contacted') {
          return 'bg-emerald-500'

        } else {
          return ''
        }
    },

    selectLead(lead) {
      this.selectedLead = lead
    },

    getStatusLeadAge (lead) {
      
        const createdAt = new Date(lead.created_at)
        const now = new Date()
        const timeDiff = now - createdAt
        const minuteDiff = Math.floor(timeDiff / 1000)

        if (lead.status === 'new') {
          if (minuteDiff <= (10 * 60)) {
            return 'text-emerald-500'
          } else if (minuteDiff<= 30 * 60) {11
            return 'text-yellow-500'
          } else if (minuteDiff <= (24 * 60 * 60)) {
            return 'text-orange-500'  
          } else {
            return 'text-red-500'
          }
        }
    },

    getStatusHourGlass(lead) {
      const createdAt = new Date(lead.created_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)

        if (lead.status === 'new') {
          if (minuteDiff <= (10 * 60)) {
            return 'fas fa-hourglass-start'
          } else if (minuteDiff <= 30 * 60) {
            return 'fas fa-hourglass-half'
          } else if (minuteDiff <= (24 * 60 * 60)) {
            return 'fas fa-hourglass-end'
          } else {
            return 'fas fa-hourglass'
          }
        }
    },


  startTimer(lead) {
    const createdAt = new Date(lead.created_at);
    const now = new Date();
    const timeDiff = now - createdAt;
    const minuteDiff = Math.floor(timeDiff / 1000);
    lead.countdown = minuteDiff;
    
    clearInterval(lead.timerIntervals)
    lead.timerIntervals = setInterval(() => {
      lead.countdown += 1;
    }, 1000);
  },

  startleadtimer(lead) {
    const createdAt = new Date(lead.modified_at);
    const now = new Date();
    const timeDiff = now - createdAt;
    const minuteDiff = Math.floor(timeDiff / 1000);
    lead.countdownassigned = minuteDiff;
    
    clearInterval(lead.timerIntervalsallocated)
    lead.timerIntervalsallocated = setInterval(() => {
      lead.countdownassigned += 1;
    }, 1000);
  },

formatTime(countdown) {

  if (countdown <= 24 * 60 * 60) {
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const remainingSeconds = countdown % 60;

    const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
    const minutesText = minutes.toString().padStart(2, '0');
    const secondsText = remainingSeconds.toString().padStart(2, '0');

    if (hours > 0) {
      return `${hoursText}${minutesText}:${secondsText} hours`;
    } else if (minutes > 0) {
      return `${minutesText}:${secondsText} minutes`;
    } else {
      return `${secondsText} seconds`;
    }
  } else if (countdown <= 48 * 60 * 60) {
    const hours = Math.floor(countdown / 3600);
    return `${hours} hours`;


  } else {
    const days = Math.floor(countdown / (24 * 60 * 60));
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;

    if (months >= 1) {
      if (remainingDays >= 1) {
        return `${months} months and ${remainingDays} days`;
      } else {
        return `${months} months`;
      }
    } else {
      return `${days} days`;
    }
  }
},
  
formatTimeassigned(countdownassigned) {

if (countdownassigned <= 24 * 60 * 60) {
  const hours = Math.floor(countdownassigned / 3600);
  const minutes = Math.floor((countdownassigned % 3600) / 60);
  const remainingSeconds = countdownassigned % 60;

  const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
  const minutesText = minutes.toString().padStart(2, '0');
  const secondsText = remainingSeconds.toString().padStart(2, '0');

  if (hours > 0) {
    return `${hoursText}${minutesText}:${secondsText} hours`;
  } else if (minutes > 0) {
    return `${minutesText}:${secondsText} minutes`;
  } else {
    return `${secondsText} seconds`;
  }
} else if (countdownassigned <= 48 * 60 * 60) {
  const hours = Math.floor(countdownassigned / 3600);
  return `${hours} hours`;


} else {
  const days = Math.floor(countdownassigned / (24 * 60 * 60));
  const months = Math.floor(days / 30);
  const remainingDays = days % 30;

  if (months >= 1) {
    if (remainingDays >= 1) {
      return `${months} months and ${remainingDays} days`;
    } else {
      return `${months} months`;
    }
  } else {
    return `${days} days`;
  }
}
},

      async getVendors() {
         this.$store.commit('setIsLoading', true)
        try {
          const response = await axios.get(`/vendor/vendors/`)
          this.vendors = response.data;
        } catch (error) {
          console.error(error);
        }
        this.$store.commit('setIsLoading', false)
      },  

    async getLeads() {
      this.leads = this.resellerleads
      if (this.leads.length > 0) {
                  this.selectedLead = this.leads[0]
                  this.leads.forEach((lead) => {
                    lead.statusClass = this.getStatusClass(lead)
                    lead.statusClass = this.getStatusClassassigned(lead)
                    this.startleadtimer(lead)
                    this.startTimer(lead)
                  })
                }

    },

    async getLocationDetails(locdetails) {
      this.$store.commit('setIsLoading', true)
      await axios
          .get(`/mapche-api/v1/search/${locdetails}`)
          .then(response => {
            this.selectedLocation = response.data
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },
  
  }
}
</script>

<style scoped>
  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #f1f3f6;
  }

  .rejected {
    text-decoration: line-through;
  }
</style>