import axios from 'axios';
import _ from 'lodash';


let currencyCache = {}; 

export async function getCurrencySymbol(currencyCode) {
  if (currencyCache[currencyCode]) {
    return currencyCache[currencyCode];
  }

  try {
    const response = await axios.get(`/vendor/get_currency_symbol/?code=${currencyCode}`);
    const symbol = response.data.symbol;
    currencyCache[currencyCode] = symbol; 
    return symbol;
  } catch (error) {
    console.error('Error fetching currency symbol:', error);
    return '';
  }
}

export async function getCurrencyList() {
  try {
    const response = await axios.get('/vendor/get_currency_list/');
    return response.data;
  } catch (error) {
    console.error('Error fetching currency list:', error);
    return [];
  }
}


export const clearMemoizedFilterCache = () => {
  if (memoizedFilter.cache) {
    memoizedFilter.cache.clear();
  }
};

const memoizeKey = (services, filterObject) => {
  return JSON.stringify({ services, filterObject });
};

export const memoizedFilter = _.memoize(
  (services, filterObject) => {
    if (Object.keys(filterObject).length > 0) {
      return _.filter(services, filterObject);
    } else {
      return services;
    }
  },
  memoizeKey
);

export const clearMemoizedFilterPromoCache = () => {
  if (memoizedFilterPromo.cache) {
    memoizedFilterPromo.cache.clear();
  }
};

const memoizeKeyPromo = (services, filterFunction) => {
  return JSON.stringify({ services, filterFunction: filterFunction.toString() });
};

export const memoizedFilterPromo = _.memoize(
  (services, filterFunction) => {
    return _.filter(services, filterFunction);
  },
  memoizeKeyPromo
);


