<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-white border-0"
  >

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <div class="flex flex-wrap">
          <div class=" w-full lg:w-6/12">
            <div class="px-4 mt-4 border border-blueGray-50 mr-4 shadow-sm">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
              Customer Information
            </h6>
              <div class="flex">
                <div class="w-1/5">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Reference ID:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <b class="normal-case text-xs whitespace-nowrap mb-2">{{ lead.reference_id }}</b>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-1/5">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Customer Name:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.first_name }} {{ lead.last_name }}</span>
                  </div>
                </div>
              </div>


              <div class="flex">
                <div class="w-1/5">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Location:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <span class="capitalize text-xs whitespace-nowrap mb-2">Latitude: {{ location.location_lat }} Longitude: {{ location.location_lng }}</span>
                  </div>
                </div>
              </div>


              <div class="flex">
                <div class="w-1/5">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Address:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <span class="normal-case text-xs whitespace-nowrap mb-2">{{ location.formatted_address }}</span>
                  </div>
                </div>
              </div>

              <br>

              <div class="flex">
                <div class="w-1/5">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Contact:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.first_name }} {{ lead.last_name }}</span>
                  </div>
                </div>
              </div>


              <div class="flex">
                <div class="w-1/5">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Contact Email:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <a :href="`mailto:${ lead.email }`" class="normal-case text-xs whitespace-nowrap mb-2 text-emerald-600 underline">{{ lead.email }}</a>
                  </div>
                </div>
              </div>


              <div class="flex">
                  <div class="w-1/5">
                    <div class="relative mb-4">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>Contact Mobile:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="normal-case text-xs whitespace-nowrap mb-2">{{ lead.shopper_phone }}</span>
                    </div>
                  </div>
              </div>
          </div>
        </div>
          <div v-if="isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserSalesManager" class="w-full lg:w-3/12">
            <div class="bg-blueGray-100 border border-blueGray-200 px-4 mt-4 shadow-sm">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Update Lead Status
            </h6>
            <div class="flex">
              <div class="w-1/3">
                <div class="relative mt-2">
                  <div class="uppercase text-blueGray-600 text-xs font-bold">
                    <label>Status:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/3">
                <div class="block">
                  <select class="text-xs whitespace-nowrap" v-model="lead.status" v-on:change.prevent="updateLeadStatus" :disabled="lead.status === 'quoted'" :class="{ 'disabled-percentage': lead.status === 'quoted' }">
                    <option value="" selected>Select status</option>
                    <option value="new">New</option>
                    <option value="quoted">Quoted</option>
                  </select>
                </div>
              </div>


            </div>
            <div class="flex">
              <div class="w-1/3">
                  <div class="relative mt-2">
                    <div class="uppercase text-blueGray-600 text-xs font-bold ">
                      <label>Created:</label>
                    </div>
                  </div>
              </div>
              <div class="w-1/3">
                  <div class="block mt-2 mb-2">
                    <span class="text-xs font-bold mb-2">{{ formatTimeDisplay(lead.created_at) }}</span>
                  </div>
              </div>
            </div>
            <div v-if="lead.status !== 'quoted' && lead.status !== 'rejected'" class="flex">
              <div class="w-1/3">
                <div class="relative mt-1">
                  <div class="uppercase text-blueGray-600 text-xs font-bold ">
                    <label>Lead Age:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/12">
                <div class="block mt-1">
                 <span class="text-xs font-bold"
                 :class="lead.status === 'new' ? 'font-bold text-xs ' + getStatusClass(lead) : ''">{{ formatTime (countdown) }}
                 </span>
                </div>
              </div>
              <div v-if="lead.status === 'new'" class="w-1/12">
                <div class="block mt-1">
                  <span class="pl-2 inline-block animate-pulse"><i class=" animate-pulse-stopwatch" :class="[getStatusClass(lead), getStatusHourGlass(lead)]"></i></span>
                </div>
              </div>
            </div>
            <div v-else if="lead.status === 'quoted'" class="flex">
              <div class="w-1/3">
                <div class="relative mt-1">
                  <div class="uppercase text-blueGray-600 text-xs font-bold ">
                    <label>Lead Age:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/12">
                <div class="block mt-1">
                 <span class="text-xs font-bold getStatusClass(lead)">
                  {{ formatOpportunityTime(opportunityCreatedTime, opportunityModifiedTime) }}
                 </span>
                </div>
              </div>
            </div>
            
            <div class="py-5">
            <div class="flex">
              <div class="w-1/3">
                <div class="relative mt-4">
                  <div class="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <label>Assigned To:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/3">
                <div>
                  <select v-if="lead.assigned_to" class="text-xs whitespace-nowrap capitalize" v-model="lead.assigned_to.id" v-on:change.prevent="(event) => updateAssignedStatus(event, member)" :disabled="lead.status === 'quoted'" :class="{ 'disabled-percentage': lead.status === 'quoted' }">
                    <option :value="null" selected>Select member</option>
                    <option v-for="member in team.members" :key="member.id" :value="member.id">
                        {{ member.first_name }} {{ member.last_name }}
                    </option>
                </select>
                <select v-else class="text-xs whitespace-nowrap capitalize" v-model="selectedMemberId" v-on:change.prevent="(event) => updateAssignedStatus(event, member)" :disabled="lead.status === 'quoted'" :class="{ 'disabled-percentage': lead.status === 'quoted' }">
                  <option :value="null" selected>Select member</option>
                  <option v-for="member in team.members" :key="member.id" :value="member.id">
                      {{ member.first_name }} {{ member.last_name }}
                  </option>
                </select>
                </div>
              </div>
           
            </div>


            <div v-if="!editMode && lead.assigned_to">
              <div class="flex">
                <div class="w-1/3">
                    <div class="relative mt-2 mb-2">
                      <div class="uppercase text-blueGray-600 text-xs font-bold ">
                        <label>Date:</label>
                      </div>
                    </div>
                </div>
                <div class="w-1/12">
                    <div class="block mt-2">
                      <span class="text-xs font-bold">{{ formatTimeDisplay(lead.assigned_to) }}</span>
                    </div>
                </div>
              </div>
              <div v-if="lead.status !== 'quoted' && lead.status !== 'rejected' && lead.assigned_to" class="flex">
                <div class="w-1/3">
                  <div class="relative mt-1 mb-1">
                    <div class="uppercase text-blueGray-600 text-xs font-bold ">
                      <label>Lead Age:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/12">
                  <div class="block mt-1">
                  <span class="text-xs font-bold mt-3 mb-1"
                  :class="lead.status === 'new' ? 'font-bold text-xs ' + getStatusClassassigned(lead) : ''">{{ formatTimeassigned (countdownassigned) }}
                  </span>
                  </div>
                </div>
                <div v-if="lead.status === 'new'" class="w-1/12">
                  <div class="block mt-1">
                    <span class="pl-2 inline-block animate-pulse"><i class=" animate-pulse-stopwatch" :class="[getStatusClassassigned(lead), getStatusHourGlassassigned(lead)]"></i></span>
                  </div>
                </div>
              </div>
            </div> 
            </div>
          </div>
          </div>

          <div v-else class="w-full lg:w-3/12">
            <div class="bg-blueGray-100 border border-blueGray-200 px-4 mt-4 shadow-sm">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
              Update Lead Status
            </h6>

            <div class="mb-3">

            <div class="flex">
              <div class="w-1/3">
                <div class="relative mt-2 mb-2">
                  <div class="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <label>Status:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                  <select class="text-xs whitespace-nowrap" v-model="lead.status" v-on:change.prevent="updateLeadStatus" :disabled="lead.status === 'quoted'" :class="{ 'disabled-percentage': lead.status === 'quoted' }">
                    <option value="" selected>Select status</option>
                    <option value="new">New</option>
                    <option value="quoted">Quoted</option>
                  </select>
                </div>

              </div>
            </div>
            <div class="flex">
              <div class="w-1/3">
                  <div class="relative mt-2 mb-2">
                    <div class="uppercase text-blueGray-600 text-xs font-bold ">
                      <label>Date:</label>
                    </div>
                  </div>
              </div>
              <div class="w-1/12">
                  <div class="block mt-2">
                    <span class="text-xs font-bold ">{{ formatTimeDisplay(lead.assigned_to) }}</span>
                  </div>
              </div>
            </div>
            <div v-if="lead.status !== 'quoted' && lead.status !== 'rejected'" class="flex">
              <div class="w-1/3">
                <div class="relative mt-2 mb-2">
                  <div class="uppercase text-blueGray-600 text-xs font-bold ">
                    <label>Lead Age:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/12">
                <div class="block mt-2">
                 <span class="text-xs font-bold"
                 :class="lead.status === 'new' ? 'font-bold text-xs ' + getStatusClassassigned(lead) : ''">{{ formatTimeassigned (countdownassigned) }}
                 </span>
                </div>
              </div>
              <div v-if="lead.status === 'new'" class="w-1/12">
                <div class="block mt-2">
                  <span class="pl-2 inline-block animate-pulse"><i class=" animate-pulse-stopwatch" :class="[getStatusClassassigned(lead), getStatusHourGlassassigned(lead)]"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>


          <div class="w-full lg:w-3/12">
            <div class="border border-blueGray-50 px-4 mt-4 ml-4 shadow-sm">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
              IP Information
            </h6>
            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap mb-2">
                    <label>IP Address:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <b class="normal-case text-xs whitespace-nowrap mb-2">{{ location.infoip }}</b>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap">
                    <label>City:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <span class="capitalize text-xs whitespace-nowrap mb-2">{{ location.infocity }}</span>
                </div>
              </div>
            </div>


            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap">
                    <label>Region:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <span class="capitalize text-xs whitespace-nowrap">{{ location.inforegion }}</span>
                </div>
              </div>
            </div>


            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap">
                    <label>Country:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <span class="normal-case text-xs whitespace-nowrap">{{ location.infocountry }}</span>
                </div>
              </div>
            </div>
            <div class="flex">
                <div class="w-1/2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Time Zone:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <span class="capitalize text-xs whitespace-nowrap">{{ location.infotimezone }}</span>
                  </div>
                </div>
              </div>


            <div class="flex">
                <div class="w-1/2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service Provider:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                  <label class="normal-case text-xs whitespace-nowrap text-emerald-600">{{ location.infoorganization }}</label>
                  </div>
                </div>
              </div>
              <div class="flex w-full justify-center items-center">
                  <div>
                     <img :src="getproviderThumbLogo(location.infoorganization)" class="w-1/3 mx-auto">
                  </div>
              </div>
            </div>
          </div>
      </div>      
      </form>
    </div>

    <div v-if="lead.options != ''" class="flex-auto px-4 lg:px-10 py-4 pt-0">
      <form>
        <div class="flex flex-wrap">
        <div class="w-full">
          <div class="border flex px-4 border-blueGray-50 mr-4 shadow-sm">
            <div class="flex-1">
                <h6 class="text-blueGray-400 text-sm mt-2 mb-4 font-bold uppercase">
                  Product Interest
                </h6>


                <div class="flex">
                  <div class="w-full border mb-4 p-2 shadow-md">
                    <div class="mb-0">
                      <label class="text-blueGray-400 text-xs block mb-3 ml-2 font-bold uppercase">Options</label>
                      <div class="flex flex-wrap">
                        <div v-for="(option, index) in leadoptions" :key="option" class="flex items-center justify-between">
                          <span class="text-xs capitalize px-2 py-1 mb-4 mr-2 bg-gray-300 rounded">
                            {{ index + 1 }}. {{ option }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="w-full">
                    <div class="mb-0">
                      <div>
                        <label class="text-blueGray-400 text-xs block mb-3 ml-0 font-bold uppercase">Customer Comments</label>
                        <div>
                          <textarea
                            class="border-1 border-gray-300 px-2 py-3 bg-white rounded text-xs w-full shadow-md" 
                            rows="3"
                            maxlength="254"
                            disabled
                          >{{ lead.description }}</textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </div>    
        </div>
      </div>   
</div>
</form>
</div>
    <div v-else class="flex-auto px-4 lg:px-10 py-4 pt-0">
      <form>
        <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12">
          <div class="border flex px-4 border-blueGray-50 mr-4 shadow-sm">
            <div class="flex-1">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
                  Product Details
                </h6>
                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>Product:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <b class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.product }}</b>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>Product Type:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.producttype}}</span>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>Link Speed:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.capacity}} Mbps</span>
                    </div>
                  </div>
                </div>                

                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>
                          Contract Term:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.contractperiod}} Months</span>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>Description:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.description }}</span>
                    </div>
                  </div>
                </div>
                
                <br>

                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap mb-2">
                        <label>Cost Code:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="normal-case text-xs whitespace-nowrap mb-2">{{ lead.costcode }}</span>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="w-1/3">
                    <div class="relative mb-3">
                      <div class="capitalize text-xs whitespace-nowrap">
                        <label>Service Provider:</label>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">
                    <div class="block">
                    <span class="capitalize text-xs whitespace-nowrap mb-2">{{ lead.suppliername }}</span>
                    </div>
                  </div>
                </div>
            </div>    
            <div class="flex justify-center items-center">
            <img :src="getproviderThumbLogo(lead.suppliername)" 
            class="w-40">
          </div>
        </div>
      </div>   
          

      <div class="w-full lg:w-3/12">
            <div class="bg-blueGray-100 border border-blueGray-200 px-4 mt-0 shadow-sm">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
              Lead Summary
            </h6>
            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <label>Monthly:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <b class="uppercase text-xs whitespace-nowrap mb-2">{{currency}}{{ formatPrice(lead.mrc) }}</b>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="uppercase text-blueGray-600 text-xs font-bold">
                    <label>Once-Off:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <span class="uppercase text-blueGray-600 text-xs font-bold mb-2">{{currency}}{{ formatPrice(lead.nrc) }}</span>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-1/2">
                <div class="relative mb-3">
                  <div class="uppercase text-blueGray-600 text-xs font-bold mb-2">
                    <label>Contract Value:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                 <b class="uppercase text-xs whitespace-nowrap mb-2">{{currency}}{{ formatPrice(lead.mrc) }}</b>
                </div>
              </div>
            </div>

          </div>
          </div>



      <div class="w-full lg:w-3/12">
            <div class="border border-blueGray-50 px-4 mt-0 ml-4 shadow-sm">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-4 font-bold uppercase">
              Add-Ons
            </h6>

            </div>
          </div>
        
      </div>
      </form>

      
    </div>

    


</div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import moment from 'moment'

export default {
  name: 'Lead',

  mounted() {
    this.getVendors()
    this.getLeadDetails(this.leadId)
    this.getTeam()
  },

  beforeDestroy() {
    clearInterval(this.timerInterval)
  },

  beforeDestroyallocated() {
    clearInterval(this.timerIntervalallocated)
  },

  data () {
    return {
      editMode: false,
      editModestatus: false,
      opportunityCreatedTime: '',
      opportunityModifiedTime: '',
      lead: {},
      vendors: [],
      team: {
        members: [],
        created_by: {}
      },
      location: {},
      countdown: 0,
      countdownassigned: 0,
      timerInterval: null,
      timerIntervalallocated: null,
      providers: {
             mapchi: 'https://res.cloudinary.com/dlucmehqv/image/upload/v1718364729/mapchi-iconnewblue_lipacz_xkhiey_adt4mv.png',
            },
    }
  },
  props: {
    leadId: Number,
    currency: String,
  },

  watch: {
    leadId(newLeadID) {
      this.getLeadDetails(newLeadID)
    }
  },

  computed: {
    leadoptions() {
        if (this.lead && this.lead.options) {
          return this.lead.options.map(option => option.name);
        } else {
          return []; 
        }
    },
    getproviderThumbLogo() {
      return (infoorganization) => {
        if (!infoorganization) {
          return this.providers.mapchi;
        }
        const supplier = this.vendors.find(vendor => {
          return vendor.name && vendor.name.toLowerCase().replace(/\s/g, '') === infoorganization.toLowerCase().replace(/\s/g, '');
        });
        if (supplier && supplier.logo_url) {
          return supplier.logo_url;
        }
        return this.providers.mapchi;
      }
    },
    selectedMemberId: {
      get() {
        return this.lead.assigned_to ? this.lead.assigned_to.id : null;
      },
      set(value) {
        this.lead.assigned_to = value ? { id: value } : null;
      },
    },
    isCreatedByCurrentUser() {
      const teamCreatedBy = this.team.created_by
      const userId = this.$store.state.user.id

      return teamCreatedBy && teamCreatedBy.id === +userId;
    },

    isCurrentUserAdmin() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Admin")
   },
   isCurrentUserSalesManager() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Manager")
   },   



  },

  methods: {
    
formatOpportunityTime(createdTime, modifiedTime) {
  if (!createdTime || !modifiedTime) return 'Invalid times';

  const createdDate = new Date(createdTime);
  const modifiedDate = new Date(modifiedTime);
  const timeInSeconds = Math.floor((modifiedDate - createdDate) / 1000);

  if (timeInSeconds <= 24 * 60 * 60) {
            const hours = Math.floor(timeInSeconds / 3600);
            const minutes = Math.floor((timeInSeconds % 3600) / 60);
            const seconds = timeInSeconds % 60;

            let formattedTime = '';

            if (hours > 0) {
                formattedTime += `${hours} hour${hours > 1 ? 's' : ''}`;
            }

            if (minutes > 0) {
                formattedTime += ` ${minutes} minute${minutes > 1 ? 's' : ''}`;
            }

            if (seconds > 0 && formattedTime === '') {
                formattedTime += ` ${seconds} second${seconds > 1 ? 's' : ''}`;
            }

            return formattedTime.trim();
        } else if (timeInSeconds <= 48 * 60 * 60) {
            const hours = Math.floor(timeInSeconds / 3600);
            return `${hours} hours`;
        } else {
            const days = Math.floor(timeInSeconds / (24 * 60 * 60));
            const months = Math.floor(days / 30);
            const remainingDays = days % 30;

            if (months >= 1) {
                if (remainingDays >= 1) {
                    return `${months} months and ${remainingDays} days`;
                } else {
                    return `${months} months`;
                }
            } else {
                return `${days} days`;
            }
        }
    
},

    formatPrice(price) {
      if (price) {
        return Number(price).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return ''; 
    },

    formatTimeDisplay(datetime) {
      const dateObj = moment(datetime)

      const formattedDate = dateObj.format('YYYY-MM-DD')
      
      const dateObjnew = moment(formattedDate)

      const newDate = dateObjnew.format('DD MMM YYYY')
    
      return newDate
    },

    formatDateTime(datetime) {
      const createdAt = new Date(datetime)
      const now = new Date()
      const timeDiff = now - createdAt

      const minuteDiff = Math.floor(timeDiff / (1000 * 60))
      const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60))
      const daysDiff = Math.floor(hoursDiff / 24)
      const monthsDiff = Math.floor(daysDiff / 30)
      const remainingDays = daysDiff % 30

      if (monthsDiff >= 1) {
        if (remainingDays >= 1) {
          return monthsDiff + " months and " + remainingDays + " days"
        } else {
        return monthsDiff + " months"
        }
      } else if (daysDiff >= 1) {
        return daysDiff + " days"
      } else if (hoursDiff >= 1) {
        return hoursDiff + " hours"
      } else {
        return minuteDiff + " minutes"
      }

    },

    getStatusClass(lead) {
        const createdAt = new Date(lead.created_at)
        const now = new Date()
        const timeDiff = now - createdAt
        const minuteDiff = Math.floor(timeDiff / 1000)
        
        if (lead.status === 'new') {
          if (this.countdown <= (10 * 60)) {
            return 'text-emerald-500'
          } else if (this.countdown <= 30 * 60) {11
            return 'text-yellow-500'
          } else if (this.countdown <= (24 * 60 * 60)) {
            return 'text-orange-500'  
          } else {
            return 'text-red-500'
          }
        }
    },

    getStatusHourGlass(lead) {
      const createdAt = new Date(lead.created_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)

        if (lead.status === 'new') {
          if (this.countdown <= (10 * 60)) {
            return 'fas fa-hourglass-start'
          } else if (this.countdown <= 30 * 60) {
            return 'fas fa-hourglass-half'
          } else if (this.countdown <= (24 * 60 * 60)) {
            return 'fas fa-hourglass-end'
          } else {
            return 'fas fa-hourglass'
          }
        }
    },

    getStatusClassassigned(lead) {
        const createdAt = new Date(lead.modified_at)
        const now = new Date()
        const timeDiff = now - createdAt
        const minuteDiff = Math.floor(timeDiff / 1000)
        
        if (lead.status === 'new') {
          if (this.countdownassigned <= (10 * 60)) {
            return 'text-emerald-500'
          } else if (this.countdownassigned <= 30 * 60) {11
            return 'text-yellow-500'
          } else if (this.countdownassigned <= (24 * 60 * 60)) {
            return 'text-orange-500'  
          } else {
            return 'text-red-500'
          }
        }
    },

    getStatusHourGlassassigned(lead) {
      const createdAt = new Date(lead.modified_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)

        if (lead.status === 'new') {
          if (this.countdownassigned <= (10 * 60)) {
            return 'fas fa-hourglass-start'
          } else if (this.countdownassigned <= 30 * 60) {
            return 'fas fa-hourglass-half'
          } else if (this.countdownassigned <= (24 * 60 * 60)) {
            return 'fas fa-hourglass-end'
          } else {
            return 'fas fa-hourglass'
          }
        }
    },

    startleadtimer() {
      const createdAt = new Date(this.lead.modified_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)
      this.countdownassigned = minuteDiff
      clearInterval(this.timerIntervalallocated)
      this.timerIntervalallocated = setInterval(() => {
        this.countdownassigned += 1
      }, 1000);
    },

    startTimer() {
      const createdAt = new Date(this.lead.created_at)
      const now = new Date()
      const timeDiff = now - createdAt
      const minuteDiff = Math.floor(timeDiff / 1000)
      this.countdown = minuteDiff
      clearInterval(this.timerInterval)
      this.timerInterval = setInterval(() => {
        this.countdown += 1
      }, 1000);

    },

    formatTime(totalSeconds) {
      if (this.countdown <= 24 * 60 * 60) {
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const remainingSeconds = totalSeconds % 60

      const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''
      const minutesText = minutes > 0 ? `${minutes.toString().padStart(2, '0')}` : ''
      const secondsText = remainingSeconds > 0 ? `${remainingSeconds.toString().padStart(2, '0')}` : '00'

      if (hours > 0) {
        return `${hoursText}${minutesText}${minutes > 0 ? ':' : ''}${secondsText}${secondsText > 0 ? ' hours' : ''}`
      } else if (minutes > 0) {
        return `${hoursText}${hours > 0 ? ':' : ''}${minutesText}${minutes > 0 ? ':' : ''}${secondsText}${secondsText > 0 ? ' minutes' : ''}`
      } else {
        return `${hoursText}${hours > 0 ? ':' : ''}${minutesText}${minutes > 0 ? ':' : ''}${secondsText}${secondsText > 0 ? ' seconds' : ''}`
      }
      } else if (this.countdown <= 48 * 60 * 60) {
       const hours = Math.floor(totalSeconds / 3600)
       const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')} hours` : ''

       return `${hoursText}`

      } else {
        const days = Math.floor(this.countdown / (24 * 60 * 60))
        const months = Math.floor(days / 30)
        const remainingDays = days % 30
        if (months >= 1) {
          if (remainingDays >= 1) {
            return `${months} months and ${remainingDays} days`
          } else {
            return `${months} months`
          }
        } else {
          return `${days} days`
        }
      }
    },

    formatTimeassigned(totalSeconds) {
      if (this.countdownassigned <= 24 * 60 * 60) {
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const remainingSeconds = totalSeconds % 60

      const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : ''
      const minutesText = minutes > 0 ? `${minutes.toString().padStart(2, '0')}` : ''
      const secondsText = remainingSeconds > 0 ? `${remainingSeconds.toString().padStart(2, '0')}` : '00'

      if (hours > 0) {
        return `${hoursText}${minutesText}${minutes > 0 ? ':' : ''}${secondsText}${secondsText > 0 ? ' hours' : ''}`
      } else if (minutes > 0) {
        return `${hoursText}${hours > 0 ? ':' : ''}${minutesText}${minutes > 0 ? ':' : ''}${secondsText}${secondsText > 0 ? ' minutes' : ''}`
      } else {
        return `${hoursText}${hours > 0 ? ':' : ''}${minutesText}${minutes > 0 ? ':' : ''}${secondsText}${secondsText > 0 ? ' seconds' : ''}`
      }
      } else if (this.countdownassigned <= 48 * 60 * 60) {
       const hours = Math.floor(totalSeconds / 3600)
       const hoursText = hours > 0 ? `${hours.toString().padStart(2, '0')} hours` : ''

       return `${hoursText}`

      } else {
        const days = Math.floor(this.countdownassigned / (24 * 60 * 60))
        const months = Math.floor(days / 30)
        const remainingDays = days % 30
        if (months >= 1) {
          if (remainingDays >= 1) {
            return `${months} months and ${remainingDays} days`
          } else {
            return `${months} months`
          }
        } else {
          return `${days} days`
        }
      }
    },

    async getVendors() {
         this.$store.commit('setIsLoading', true)
        try {
          const response = await axios.get(`/vendor/vendors/`)
          this.vendors = response.data;
        } catch (error) {
          console.error(error);
        }
        this.$store.commit('setIsLoading', false)
      },  

    async deleteLead() {
      this.$store.commit('setIsLoading', true)
      
      const leadID = this.$route.params.id

      await axios
          .post(`/api/v1/leads/delete_lead/${leadID}/`)
          .then(response => {
            this.$router.push('/dashboard/leads')
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },

    async getLeadDetails(leadId) {
      this.$store.commit('setIsLoading', true)

      
      if (leadId !== null) {
      await axios
          .get(`/mapche-api/v1/leads/${leadId}/`)
          .then(response => {
            this.lead = response.data
            this.opportunityCreatedTime = response.data.created_at
            this.opportunityModifiedTime = response.data.modified_at
            this.getLocationDetails(this.lead.search)
            this.startleadtimer()
            this.startTimer()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        await axios
          .get('/mapche-api/v1/leads/')
          .then(response => {
            this.lead = response.data.results
          })
          .catch(error => {
            console.log(error)
          })
      }    

      this.$store.commit('setIsLoading', false)
    },

    async getLocationDetails(search) {
      this.$store.commit('setIsLoading', true)

      await axios
          .get(`/mapche-api/v1/search/${search}`)
          .then(response => {
            this.location = response.data
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },

    async updateAssignedStatus(event, member) {
        this.$store.commit('setIsLoading', true)
        
       const selectedValue = event.target.value;
      if (selectedValue !== 'Select member') {

        const leadID = this.leadId
        
        axios
            .patch(`/mapche-api/v1/leads/${leadID}/`, this.lead)
            .then(response => {
                toast({
                  message: 'The lead was successfully assigned',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
                this.getLeadDetails(leadID)
                const updatedStatus = this.lead.assigned_to?.id || null
                this.$emit('update-status', updatedStatus)
                
            })
            .catch(error => {
              console.log(error)
            })
        } else{
          const leadID = this.leadId
          this.getLeadDetails(leadID)
                const updatedStatus = this.lead.assigned_to?.id || null
                this.$emit('update-status', updatedStatus)

        }
        this.$store.commit('setIsLoading', false)
      },

      async updateLeadStatus() {
        this.$store.commit('setIsLoading', true)

        const leadID = this.leadId

        axios
            .patch(`/mapche-api/v1/leads/${leadID}/`, this.lead)
            .then(response => {
                toast({
                  message: 'The lead status was updated',
                  type: 'is-success',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
                })
            })
            .catch(error => {
              console.log(error)
            })
        this.$store.commit('setIsLoading', false)
      },

    async getTeam() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/get_my_business/')
            .then(response => {
              this.team = response.data
            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

    async convertToClient() {
      this.$store.commit('setIsLoading', true)

      const leadID = this.$route.params.id
      const data = {
        lead_id: leadID
      }

      await axios
          .post(`/api/v1/convert_lead_to_client/`, data)
          .then(response => {

            this.$router.push('/dashboard/clients')
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    }
  }
}
</script>

<style>

  @keyframes pulse-stopwatch {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }

  .animate-pulse-stopwatch {
    animation: pulse-stopwatch 1.5s ease-in-out infinite;
  }

  .disabled-percentage {
    background-color: rgba(241, 245, 249, 1);
    cursor: not-allowed;
    background-size: contain;
    background-repeat: no-repeat;
  }
</style>