<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <CardCategories />
    </div>
  </div>
</template>
<script>
  import CardCategories from "@/components/Cards/CardCategories.vue";

export default {
  components: {
    CardCategories,
  },
};
</script>
