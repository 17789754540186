<template>

    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-500 text-sm mt-3 mb-4 font-semibold uppercase">
          iFrame Information
        </h6>
        <form>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-9/12 px-4">
            <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              >
                Add this to your website <span class="text-red-500 text-base ml-3">{{ code }}</span>
              </label>
          </div>

          <div class="px-4 pt-3 w-auto gap-2 overflow-auto relative">
            <div v-if="!heightmessage && !widthmessage" id="textiframe" class="text-base mx-auto rounded-none sm:rounded w-full border border-gray-300 bg-gray-800 overflow-x-auto" data-type="html">
            <button class="text-white font-semibold absolute top-4 px-4 hover:text-mapchi" v-on:click.prevent="CopyToClipboardiFrame" id="copybtniframe">Copy</button>
            <pre id="copyiframe" class="bg-gray-800 pt-12"> <span class="text-gray-500">&lt;</span><span class="text-mapchi">iframe</span> <span class="text-mapchiblue">src</span><span class="text-gray-500">=</span><span class="text-orange-500">'{{ iframeCode }}'</span> <span class="text-mapchiblue">width</span><span class="text-gray-500">=</span><span class="text-orange-500">'{{ iframeWidth }}'</span> <span class="text-mapchiblue">height</span><span class="text-gray-500">=</span><span class="text-orange-500">'{{ iframeHeight }}'</span> <span class="text-gray-200">title</span><span class="text-gray-500">=</span><span class="text-orange-500">'MapChi iFrame'</span> <span class="text-gray-200">style</span><span class="text-gray-500">=</span><span class="text-orange-500">'border-radius: 10px; border: none;'</span><span class="text-gray-500">&gt;</span><span class="text-gray-500">&lt;/</span><span class="text-mapchi">iframe</span><span class="text-gray-500">&gt;</span>            
            </pre>
            </div>
            <div v-else id="textiframe" class="text-base mx-auto rounded-none sm:rounded w-full border border-gray-300 bg-gray-800 overflow-x-auto" data-type="html">
            <pre id="copyiframe" class="bg-gray-800 text-white">{{ formattedCode }}</pre>
          </div>
          </div>

        </div>


        <h6 class="text-blueGray-500 text-sm mt-6 mb-4 font-semibold uppercase">
        Important
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <textarea
              type="text"
              disabled
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="4"
            >Embed the iframe effortlessly with this single line of code. Click the 'Copy' button above to grab the iFrame code. Instantly enable a map for searching of services on your own website, allowing users to explore the services you offer. Experience seamless search, feasibility checks, and a lead generation tool that drives service and sales. It doesn't get simpler than this.
            </textarea
            >
          </div>
        </div>
      </div>


      <h6 class="text-blueGray-500 text-sm mt-6 mb-4 font-semibold uppercase">
        Configure iFrame
      </h6>      


        
        <div class="flex flex-wrap">
          <div class="w-full lg:w-2/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="customWidth"
                id="customWidth"
              >
                Preferred Width
              </label>
              <div class="flex items-center">
                <select v-model="selectedWidthOption" class="custom-select border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring mr-3">
                  <option value="100%">Full Width</option>
                  <option value="1080">1080</option>
                  <option value="640">640</option>
                  <option value="custom">Custom</option>
                </select>
                <input v-if="selectedWidthOption === 'custom'" type="number" class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-20" placeholder="Enter your width" v-model="customWidth"/>
                <span v-if="selectedWidthOption === 'custom'" class="text-blueGray-600 ml-2">px</span>
              </div>

            </div>
          </div>
          <div class="w-full lg:w-2/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="height"
                id="height"
              >
                Preferred Height
              </label>
              <input type="number" class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-20" placeholder="Enter your height" v-model="height"/>
              <span class="text-blueGray-600 ml-2">px</span>
            </div>
          </div>

          <hr class="mt-5 border-b-1 border-blueGray-300" />

      <div class="w-full">
        <h6 class="text-blueGray-500 text-sm mt-6 mb-4 font-semibold uppercase">
          Window Size
        </h6>
        <div class="w-full">
        <div class="relative w-full mb-3">
          <div class="iframe-container">
            <iframe :src="iframeSrc" :width="width" :height="height" style='border-radius: 10px; border: none;' class="bg-blueGray-800"></iframe>
          </div>
        </div>






  
        </div>
      </div>


          </div>  
        </form>
    </div>

</template>


<script>
import axios from 'axios'
import {toast} from 'bulma-toast'


export default {
  data() {
    return {
      mapchi_key: '',
      websiteaddress: null,
      heightmessage: false,
      widthmessage: false,
      code: `<html>`,
      selectedWidthOption: '100%', 
      customWidth: 640, 
      height: '800', 
      iframeMessage: 'For optimal performance and the best visual experience, it is recommended to maintain a minimum width of 640 pixels.',
      iframeMessageHeight: 'For optimal performance and the best visual experience, it is recommended to maintain a minimum height of 800 pixels. Prefer a smaller version? The Widget is a work in progress...'
    };
  },

  mounted() {
    this.mapchi_key = localStorage.getItem('key')
    this.getUserProfile()
  },

  watch: {
  shouldShowHeightMessage(newVal) {
    if (newVal) {
      this.heightmessage = true;
    } else {
      this.heightmessage = false;
    }
  },
  shouldShowIframeCode(newVal) {
    if (newVal) {
      this.widthmessage = false;
    } else {
      this.widthmessage = true;
    }
  }
},
  computed: {
    isDemo() {
      const demo = this.$store.state.user.username;
      return demo === 'demo@mapchi.com';
    },

    formattedCode() {
      const codeToDisplay = this.shouldShowIframeCode && !this.shouldShowHeightMessage
        ? ''
        :this.shouldShowHeightMessage ? (this.heightmessage = true, this.iframeMessageHeight) 
        :!this.shouldShowIframeCode ? (this.widthmessage = true, this.iframeMessage)
        : this.selectedWidthOption === 'custom' 
        ? this.iframeMessage
        : this.iframeMessage;
      return codeToDisplay;
    },
    iframeSrc() {
      return `https://mapchi.com/iframe.html?mapchi-key=${encodeURIComponent(this.mapchi_key)}`;
    },
    width() {
      if (this.selectedWidthOption === 'custom') {
        return this.customWidth.toString();
      }
      return this.selectedWidthOption;
    },
    iframeCode() {
      if (!this.isDemo) {
       return `${this.iframeSrc}`;            
      }
      else {
        const placeholder = "demo-1234-4321-56768-987654321000"
        const iframeDemoSrc = `https://mapchi.com/iframe.html?mapchi-key=${encodeURIComponent(placeholder)}`
        return `${iframeDemoSrc}`;
      }
      
    },
    iframeWidth(){
      return `${this.width}`;
    },
    iframeHeight(){
      return `${this.height}`;
    },


    shouldShowIframeCode() {
      return this.selectedWidthOption !== 'custom' || (this.selectedWidthOption === 'custom' && this.customWidth >= 640);
    },
    shouldShowHeightMessage() {
      return this.height < 800;
    },

  },



  methods: {
    async getUserProfile() {
    this.$store.commit('setIsLoading', true)

      axios.get('/mapche-api/v1/userprofile')
        .then(response => {
          if(response.data[0]) {
            this.websiteaddress = response.data[0].website_address
          }
        })
        .catch((error) => {
          toast({
            message: 'An error occurred. Please try again later.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          })
        })

    this.$store.commit('setIsLoading', false)    
  },

    CopyToClipboardiFrame() {
  try {
    const copyText = document.getElementById("copyiframe").textContent;
    const btn = document.getElementById("copybtniframe");
    const textArea = document.createElement('textarea');
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy"); 
    textArea.remove();
    const initialText = "Copy";
    if (btn.textContent.includes(initialText)) {
      btn.textContent = "Copied!";
    } else {
      btn.textContent = initialText;
    }
  } catch(e) {
    throw e;
  }
},

 }
};
</script>

<style scoped>

.custom-select {
  border: 0;
  padding: 0.5rem; 
  min-width: 110px; 
  text-align: left; 
}

.iframe-container {
  overflow: hidden;
  background-color: rgb(228, 228, 231, 1);
  border-radius: 10px;
  text-align: center;
}


.iframe-container iframe {
  display: inline-block; 
}
</style>
