<template>
<div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >

    <div v-if="popoverShow" class="rounded-t mb-0 px-4 py-6 border-0 bg-emerald-500">
      <div class="flex flex-wrap items-center">
        <div ref="popoverRef"
        >
          <h3
            class="font-bold text-xl flex items-center"
            :class="[color === 'light' ? 'text-white' : 'text-white']"
          >
            Verified Search: This is a mobile number confirmed via OTP, feel free to reach out to the provided contact for further communication.
          </h3>
        </div>
      </div>
    </div>
    <div v-else class="rounded-t mb-0 px-6 py-6 border-0">
      <div class="flex justify-between items-center">
        <div>
          <h3
            class="font-bold text-xl"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Searches<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalsearches}}
          </h3>
        </div>
        <div class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="query"
                v-on:input="handleSearch"
                v-on:click.stop
              />
        </div>
     </div>
    </div>
  <div v-if="totalsearches">
    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Address
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Latitude
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Longitude
            </th>                   
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Date
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Verified Search
            </th>
            <th
              class="align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="search in searches" 
            v-bind:key="search.id"
          >
          <td 
            class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left"
          >
            <div class="flex item-center">
              <span
                class="ml-0 font-bold capitalize"
                :class="[
                color === 'light' ? 'text-blueGray-600' : 'text-white']" style="line-height: 2.5rem;"
              >
                {{ search.formatted_address}}
              </span>
            </div>
          </td>
          <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
            >
              {{ search.location_lat }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
            >
              {{ search.location_lng }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
            >
                {{ formatDateTime(search.created_at) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
            >
                {{ search.mobile }}
            </td>

            <td
              class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
            <button ref="btnRef" v-if="search.mobile" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()" class="material-icons pr-1" style="color: #10b981">verified</button>
            </td>
          </tr>
          <br/>
        </tbody>
      </table>
    </div>
  </div>
  </div>
  <div>
    <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" @click.prevent="goToPreviousPage" v-if="showPreviousButton">Previous</button>
    <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" type="button" @click.prevent="goToNextPage" v-if="showNextButton">Next</button>
  </div>
</template>

<script>
import axios from 'axios'
import { createPopper } from "@popperjs/core";

export default {
  name: 'Searches',

  mounted() {
    this.createPopperInstance();
    this.getSearches()
  },

  computed: {
    percentage() {
      return (this.searches.length / this.totalSearches * 100).toFixed(1)
    },
  },

  data() {
    return {
      searches: [],
      totalsearches: 0,
      query: '',
      totalSearches: 1000,
      showNextButton: false,
      showPreviousButton: false,
      currentPage: 1,
      colorSearchBG: '',
      colorSearch: '',
      colorText: '',
      popoverShow: false,
    };
  },
  components: {
    
  },

  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    goToNextPage() {
          this.currentPage += 1
          this.getSearches()
        },
        goToPreviousPage() {
          this.currentPage -= 1
          this.getSearches()
        },
    
    handleSearch() {
      this.getSearches();
    },

    formatDateTime(datetime) {
      const datetimeObj = new Date(datetime)
      const formattedDate = datetimeObj.toISOString().split("T")[0]
      const formattedTime = datetimeObj.toTimeString().split(" ")[0]
      const formattedDateTime = `${formattedDate} ${formattedTime}`
      return formattedDateTime
    },

    toggleTooltip() {
      this.popoverShow = !this.popoverShow;
    },
    createPopperInstance() {
      if (this.popoverShow) {
        createPopper(this.$refs.btnRef, this.$refs.popoverRef, {
          placement: "left"
        });
      }
    },
    async getSearches() {
      this.$store.commit('setIsLoading', true)
      
      this.showNextButton = false
      this.showPreviousButton = false

           await axios
              .get(`/mapche-api/v1/search-display/?page=${this.currentPage}&search=${this.query}`)
              .then(response => {
                this.totalsearches = response.data.count
                this.searches = response.data.results

                if(response.data.next) {
                  this.showNextButton = true
                }

                if(response.data.previous) {
                  this.showPreviousButton = true
                }

                if (this.percentage >= 100){
                  this.colorSearchBG = 'bg-red-200'
                  this.colorSearch = 'bg-red-500'
                  this.colorText = "text-red-500"
                } else {
                  if (this.percentage >= 0 && this.percentage < 31) {
                    this.colorSearchBG = 'bg-emerald-200'
                    this.colorSearch = 'bg-emerald-500'
                    this.colorText = "text-emerald-500"
                  } else if (this.percentage >= 31 && this.percentage < 70) {
                    this.colorSearch = 'Orange'
                    this.colorSearchBG = 'bg-orange-200'
                    this.colorSearch = 'bg-orange-500'
                    this.colorText = "text-orange-500"
                  } else if (this.percentage < 101) {
                    this.colorSearchBG = 'bg-red-200'
                    this.colorSearch = 'bg-red-500'
                    this.colorText = "text-red-500"
                  }
                }
                
              })
              .catch(error => {
                console.log(error)
              })
      
      this.$store.commit('setIsLoading', false)
    },
  
  }
}
</script>

<style>

  @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
  
</style>