import router from '@/router'
import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    isAuthenticated: false,
    token: '',
    is_innovator: false, 
    is_demo: false, 
    invited: false,
    resellerinvited: false,
    user: {
      id: 0,
      username: '',
      roles: [] 
    },
    team: {
      created: 0
    },
    business: {
      id: 0,
      name: '',
      plan: '',
      customer: '',
      status: '',
    },
    reseller: {
      id: 0,
    },
    mobile: {
      phone_number: '',
    },
    mapchikey: {
      key: '',
    }
  },


  getters: {
    hasBusinessPlan: (state) => {
      return (state.business.plan !== null && state.business.plan !== '' && state.business.status !== 'canceled');
    },
  },

  mutations: {
    initializeStore(state) {
      if(localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        state.invited = localStorage.getItem('invited')
        state.resellerinvited = localStorage.getItem('resellerinvited')
        state.user.id = localStorage.getItem('userid')
        state.user.username = localStorage.getItem('username')
        state.user.roles = localStorage.getItem('user_roles') 
        state.team.created = localStorage.getItem('created')
        state.business.id = localStorage.getItem('business_id')
        state.business.name = localStorage.getItem('business_name')
        state.business.plan = localStorage.getItem('business_plan_type')
        state.business.customer = localStorage.getItem('business_customer')
        state.business.status = localStorage.getItem('business_plan_status')
        state.reseller.id = localStorage.getItem('reseller_id')
        state.mobile.phone_number = localStorage.getItem('phone_number')
        state.mapchikey.key = localStorage.getItem('key')
        state.is_innovator = JSON.parse(localStorage.getItem('setIsInnovator'))
        state.is_demo = JSON.parse(localStorage.getItem('setIsDemo'))
      } else {
        state.token = ''
        state.isAuthenticated = false
        state.invited = false
        state.resellerinvited = false
        state.user.id = 0
        state.user.username = ''
        state.user.roles = [] 
        state.team.created = 0
        state.business.id = 0
        state.business.name = ''
        state.business.plan = ''
        state.business.customer = ''
        state.business.status = ''
        state.reseller.id = 0
        state.mobile.phone_number = ''
        state.mapchikey.key = ''
        state.is_innovator = false
        state.is_demo = false
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },

    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },

    setInvited(state, status) {
      state.invited = status
    },
    
    setResellerInvited(state, status) {
      state.resellerinvited = status
    },

    setUser(state, user) {
      state.user = user

      localStorage.setItem('user_roles', user.roles) 
    },
    setTeam(state, team) {
      state.team = team

      localStorage.setItem('created', team.created) 

    },  
    setBusiness(state, business) {
      state.business = business

      localStorage.setItem('business_id', business.id)
      localStorage.setItem('business_name', business.name)
      localStorage.setItem('business_plan_type', business.plan)
      localStorage.setItem('business_customer', business.customer)
      localStorage.setItem('business_plan_status', business.status)

    },

    setResellerID(state, reseller) {
      state.reseller = reseller
      
      localStorage.setItem('reseller_id', reseller.id)
    },

    setMobile(state, mobile) {
      state.mobile = mobile

      localStorage.setItem('phone_number', mobile.phone_number)

    },
    setKey(state, mapchikey) {
      state.mapchikey = mapchikey
      localStorage.setItem('key', mapchikey.key)

    },
    setIsInnovator(state, isUserInnovator) {
      state.is_innovator = isUserInnovator
    },

    setIsDemo(state, isUserDemo) {
      state.is_demo = isUserDemo
    },
    
  },
  actions: {
  },
  modules: {
  }
})
