import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { toast } from 'bulma-toast';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";


import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";
import Subscribe from "@/layouts/Subscribe.vue";

import Dashboard from "@/views/admin/Dashboard.vue";
import Invite from '@/views/admin/Invite.vue';
import ResellerInvite from '@/views/admin/ResellerInvite.vue';
import MyAccount from "@/views/admin/MyAccount.vue";
import Settings from "@/views/admin/Settings.vue";
import Theme from "@/views/admin/Theme.vue";
import Categories from "@/views/admin/Categories.vue";
import Pricing from "@/views/admin/Pricing.vue";
import Subscription from "@/views/admin/Subscription.vue";
import Search from "@/views/admin/Search.vue";
import Networks from "@/views/admin/Networks.vue";
import Leads from "@/views/admin/Leads.vue";
import Resellers from "@/views/admin/Resellers.vue";
import Users from "@/views/admin/Users.vue";
import ResellerUser from "@/views/admin/ResellerUser.vue";


import Plans from "@/views/subscribe/Plans.vue";


import Login from "@/views/auth/Login.vue";
import Logout from "@/views/auth/Logout.vue";
import Register from "@/views/auth/Register.vue";
import Activation from '@/views/auth/Activation.vue';
import PasswordReset from '@/views/auth/PasswordReset.vue';
import PasswordResetConfirm from '@/views/auth/PasswordResetConfirm.vue';
import ResendActivation from '@/views/auth/ResendActivation.vue';


import Landing from "@/views/Landing.vue";



const routes = [
  {
    path: "/subscribe",
    redirect: "/subscribe/plans",
    component: Subscribe,
    children: [
      {
        path: "/subscribe/plans",
        name: "Plans",
        component: Plans,
        meta: {
          requiredLogin: true
        },
      },
    ]
  },
  
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          requiredLogin: true
        },

        beforeEnter: (to, from, next) => {
          const isInvited = store.state.invited
          const isResellerInvited = store.state.resellerinvited
          if (isInvited.invited) {
            toast({
              message: 'Accept the invitation to continue.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/invite')
           } else if (isResellerInvited.resellerinvited) {
            toast({
              message: 'Accept or Decline the invitation to continue.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/resellerinvite')
           } else {
             next()
           }
        }
      },
      {
        path: "/admin/invite",
        name: "Invite",
        component: Invite,
        meta: {
          requiredLogin: true
        },
      },      
      {
        path: "/admin/resellerinvite",
        name: "ResellerInvite",
        component: ResellerInvite,
        meta: {
          requiredLogin: true
        },
      },      
      {
        path: "/admin/my-account",
        name: 'MyAccount',
        component: MyAccount,
        meta: {
          requiredLogin: true
        },

        beforeEnter: (to, from, next) => {
          const isInvited = store.state.invited
          const isResellerInvited = store.state.resellerinvited
          if (isInvited.invited) {
            toast({
              message: 'Accept the invitation to continue.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/invite')
           } else if (isResellerInvited.resellerinvited) {
            toast({
              message: 'Accept or Decline the invitation to continue.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/resellerinvite')
           } else {
            next()
           }
        }
      },
      {
        path: "/admin/settings",
        name: 'Settings',
        component: Settings,
        meta: {
          requiredLogin: true
        },

        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') 
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') 
              return             
           } else {
             next()
           }
        }
      },
      {
        path: "/admin/users",
        name: "Users",
        component: Users,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') 
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') 
              return             
           } else {
             next()
           }
        }
      },      
      {
        path: "/admin/reselleruser",
        name: "ResellerUser",
        component: ResellerUser,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') 
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') 
              return             
           } else {
             next()
           }
        }
      },      
      {
        path: "/admin/categories",
        name: 'Categories',
        component: Categories,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') 
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') 
              return             
           } else {
             next()
           }
        }
      },
      {
        path: "/admin/pricing",
        name: 'Pricing',
        component: Pricing,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') 
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') 
              return             
           } else {
             next()
           }
        }
      },  
      {
        path: "/admin/theme",
        name: 'Theme',
        component: Theme,
        meta: {
          requiredLogin: true
        },

        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') // Redirect to another route like home or any other route that makes sense in your app
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') // Redirect to another route like home or any other route that makes sense in your app
              return             
           } else {
             // Continue with the navigation
             next()
           }
        }
      },
      {
        path: "/admin/subscription",
        name: 'Subscription',
        component: Subscription,
        meta: {
          requiredLogin: true
        },
      },
      {
        path: "/admin/networks",
        name: 'Networks',
        component: Networks,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') // Redirect to another route like home or any other route that makes sense in your app
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') // Redirect to another route like home or any other route that makes sense in your app
              return             
           } else {
             // Continue with the navigation
             next()
           }
        }
      },      
      {
        path: "/admin/search",
        name: 'Search',
        component: Search,
        meta: {
          requiredLogin: true
        },

        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          const isInvited = store.state.invited
          const isResellerInvited = store.state.resellerinvited
          if (isInvited.invited) {
            toast({
              message: 'Accept the invitation to continue.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/invite')
           } else if (isResellerInvited.resellerinvited) {
            toast({
              message: 'Accept or Decline the invitation to continue.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/resellerinvite')
           } else if (businessId && mobile) {
              toast({
                message: 'Complete your user and business information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              })
              next('/admin/my-account')
           } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
           } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') // Redirect to another route like home or any other route that makes sense in your app
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') // Redirect to another route like home or any other route that makes sense in your app
              return             
           } else {
             // Continue with the navigation
             next()
           }
        }
      },
      {
        path: "/admin/leads",
        name: 'Leads',
        component: Leads,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') // Redirect to another route like home or any other route that makes sense in your app
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') // Redirect to another route like home or any other route that makes sense in your app
              return             
           } else {
             // Continue with the navigation
             next()
           }
        }
      },
      {
        path: "/admin/resellers",
        name: 'Resellers',
        component: Resellers,
        meta: {
          requiredLogin: true
        },
        beforeEnter: (to, from, next) => {
          const businessId = !store.state.business.id
          const mobile = !store.state.mobile.phone_number
          const userRoles = store.state.user.roles
          const created = store.state.team.created
          const userId = store.state.user.id
          if (businessId && mobile) {
            toast({
              message: 'Complete your user and business information.',
              type: 'is-danger',
              position: 'bottom-right',
              duration: 5000,
              dismissible: true
             })
             next('/admin/my-account')
            } else if (mobile) {
              toast({
                message: 'Complete your user information.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
               })
               next('/admin/my-account')
              } else if (businessId) {
                toast({
                  message: 'Complete your business information.',
                  type: 'is-danger',
                  position: 'bottom-right',
                  duration: 5000,
                  dismissible: true
                 })
                 next('/admin/my-account')
            } else if (!store.getters.hasBusinessPlan && (userRoles.includes('Finance') || userRoles.includes('Admin') || (created === parseInt(userId)))) {
             toast({
               message: 'You need to buy a subscription, before you can access the features.',
               type: 'is-danger',
               position: 'bottom-right',
               duration: 5000,
               dismissible: true
             });
             next('/subscribe/plans') // Redirect to another route like home or any other route that makes sense in your app
             return
            } else if (!store.getters.hasBusinessPlan) {
              toast({
                message: 'Your subscription expired, speak to your administrator for assistance.',
                type: 'is-danger',
                position: 'bottom-right',
                duration: 5000,
                dismissible: true
              });
              next('/admin/dashboard') // Redirect to another route like home or any other route that makes sense in your app
              return             
           } else {
             // Continue with the navigation
             next()
           }
        }
      }

    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/auth/logout",
        name: "Logout",
        component: Logout,
      },
      {
        path: "/auth/register",
        name: "Register",
        component: Register,
      },
      {
            path: '/accounts/users/activation/:uid?/:token?',
            name: 'Activation',
            component: Activation,
          },
      {
        path: '/auth/password_reset',
        name: 'PasswordReset',
        component: PasswordReset,
      },
      {
            path: '/accounts/users/reset_password_confirm/:uid?/:token?',
            name: 'PasswordResetConfirm',
            component: PasswordResetConfirm,
      },
      {
            path: '/auth/resend_activation',
            name: 'ResendActivation',
            component: ResendActivation,
      },
    ],
  },
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredLogin) && !store.state.isAuthenticated) {
   next("/auth/login")
  } else {
    next()
  }
})
export default router
