<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold"><i class="text-4xl text-emerald-600 pl-1" v-for="data in planData" :key="data.plan" :class="[data.plan === $store.state.business.plan ? data.picture : '']"></i> {{business.plan}}
        </h6>
      </div>
    </div>
    <div class="flex-auto px-10 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-500 text-sm mt-3 font-semibold uppercase">
          
          Billing Information
        </h6>
        <p class="mt-1 text-blueGray-500 text-sm">Personal details and application.</p>
        <div class="flex flex-wrap border-t border-gray-200 mt-3">
          <div class="w-full lg:w-3/12 mt-5">
            <div class="relative w-full">
              <label
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
              >
              Status
              </label>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4 mt-5">
            <div class="relative w-full mb-3">
              <span
                class="text-sm font-bold"
                :class="{
                  'text-emerald-500': business.plan_status !== 'canceled',
                  'text-red-500': business.plan_status === 'canceled',
                  'text-yellow-500': business.plan_status === 'trialing',
                }"
              >
              {{ formattedPlanStatus }} {{ business.plan_status !== 'canceled' ? formattedPlanEndDate : '' }}
              </span>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap border-t border-gray-200 mt-3">
          <div class="w-full lg:w-3/12 mt-5">
            <div class="relative w-full">
              <label
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
              >
              Customer ID
              </label>
            </div>
          </div>
          <div v-if="isDemo" class="w-full lg:w-6/12 px-4 mt-5">
            <div class="relative w-full mb-3">
              <label 
                class="block text-blueGray-600 text-xs mb-2"
              >
              cusdemo_123456
              </label>
            </div>
          </div>
          <div v-else class="w-full lg:w-6/12 px-4 mt-5">
            <div class="relative w-full mb-3">
              <label 
                class="block text-blueGray-600 text-xs mb-2"
              >
              {{business.stripe_customer_id}}
              </label>
            </div>
          </div>

        </div>

        <div class="flex flex-wrap border-t border-gray-200 mt-3">
          <div class="w-full lg:w-3/12 mt-3">
            <div class="relative w-full">
              <label
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
              >
              
              Subscription ID
              </label>
            </div>
          </div>
          <div v-if="isDemo" class="w-full lg:w-6/12 px-4 mt-3">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
              >
              subdemo_123456
              </label>
            </div>
          </div>
          <div v-else class="w-full lg:w-6/12 px-4 mt-3">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
              >
              {{business.stripe_subscription_id}}
              </label>
            </div>
          </div>
        </div>
        
        <div class="flex flex-wrap border-t border-gray-200 mt-3">
          <div class="w-full lg:w-3/12 mt-3">
            <div class="relative w-full">
              <label
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
              >
              Billing PLan
              </label>
            </div>
          </div>
          <div v-if="isDemo" class="w-full lg:w-6/12 px-4 mt-3">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
              >
              {{business.plan}} {{ formattedDemoAmount }} / {{ business.plan_interval }}
              </label>
            </div>
          </div>
          <div v-else class="w-full lg:w-6/12 px-4 mt-3">
            <div class="relative w-full mb-3">
              <label
                class="block text-blueGray-600 text-xs mb-2"
              >
              {{business.plan}} {{ formattedAmount }} / {{ business.plan_interval }}
              </label>
            </div>
          </div>
        </div>
        
        <div class="flex flex-wrap border-t border-gray-200 mt-3">
          <div class="w-full lg:w-3/12 mt-5">
            <div class="relative w-full">
              <label
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
              >
              Manage Subscription
              </label>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4 mt-4">
            <div class="relative align-middle w-full">
              <button v-if="isDemo"
                class="bg-gray text-white font-bold uppercase text-xs px-4 py-2 rounded shadow cursor-not-allowed"
                type="button" 
                :disabled="isDemo"
                style="cursor: default;"
              >
                Manage
              </button>
              <button v-else
                class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button" 
                @click.prevent="redirectToBillingPortal"
              >
                Manage
              </button>              
            </div>
          </div>
        </div>        
        <div class="mt-4">
          <textarea
                  type="text"
                  disabled
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="7"
                  :value="getPlanText($store.state.business.plan)"

          >
          </textarea>
        </div>

        </form>
        </div>
        </div>
</template>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
  
    export default {
      name: 'Subscription',

      data() {
        return {
          business: '',
          currencyOptions: {
            style: 'currency',
            currency: 'EUR'
          },
          planData: [
          {
            plan: 'Broker',
            picture: 'far fa-handshake',
            text: 'Connect and resell services from your preferred service providers (Innovator) effortlessly. As an Broker, gain access to live pricing, feasibility analysis and a powerful widget that helps you grow your leads. Unlock new opportunities and propel your business forward with our comprehensive solution.'
          },
          {
            plan: 'Trailblazer',
            picture: 'fas fa-user-ninja',
            text: 'Unleash your potential in the home and business internet market. As a Trailblazer, you have the freedom to price and sell your own services. Choose from a diverse range of trusted network providers and customize plans to align with your unique requirements. Stay informed with real-time coverage information to ensure accurate feasibility analysis. Take full ownership of your customers, from the initial connection to the final experience. Seize the opportunity to redefine the internet landscape and drive your business forward.',
          },
          {
            plan: 'Innovator',
            picture: 'fas fa-user-astronaut',
            text: 'Empower your innovation with our platform. Create your own network instance, products, and pricing structures. Customize coverage maps, services, and network offerings to provide your resellers (Brokers) with a seamless experience. Expand your reseller channel digitally with up-to-date coverage and pricing information. Benefit from a fully white-labeled solution for maximum brand awareness. All Innovator instances are visible in the Broker version, enabling your resellers to effectively sell your products and services.',
          },
        ],          
        }
      },
      props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    },
      mounted() {
        this.isBusinessSet()
      },
      computed: {
        isDemo() {
          const demo = this.$store.state.is_demo;
          return demo
        },

        parsedAmount() {
          return parseFloat(this.business.plan_amount) / 100
        },
        formattedDemoAmount() {
          const demoamount = 0
          if (isNaN(demoamount)){
            return 'Invalid Amount'
          }
          return demoamount.toLocaleString('en-GB', this.currencyOptions)
        },
        formattedAmount() {
          if (isNaN(this.parsedAmount)){
            return 'Invalid Amount'
          }
          return this.parsedAmount.toLocaleString('en-GB', this.currencyOptions)
        },
        formattedPlanStatus() {
          if (this.business.cancel_end_date) {
            return 'Cancels at'
          } else {
            if (this.business.plan_status === 'trialing') {
              return 'Trial ends'
            } else if (this.business.plan_status === 'active') {
              return 'Renews at'
            } else if (this.business.plan_status === 'canceled') {
              return 'Canceled'
            } else {
              return this.business.plan_status
            }
          }
        },

        formattedPlanEndDate() {
          if (!this.business.plan_end_date) {
            return '';
          }

          const planEndDate = new Date(this.business.plan_end_date);
          const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          };
          return planEndDate.toLocaleString('en-GB', options);
        },        
      },
      methods: {
        getPlanText(plan) {
          const matchingPlan = this.planData.find(data => data.plan === plan);
          return matchingPlan ? matchingPlan.text : '';
        },        
        async redirectToBillingPortal() {
          this.$store.commit('setIsLoading', true)

          try {
            const customer_id = this.$store.state.business.customer 

            const response = await axios.get(`/mapche-api/v1/stripe/billing_portal/${customer_id}/`)
            if (response.status === 200 && response.data.url) {
              window.location.href = response.data.url
            } else {
                toast({
                message: 'A Billing Portal error occurred. Please try again later.',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
            }
          } catch (error) {
            toast({
              message: 'A Billing Portal error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })

          }
          this.$store.commit('setIsLoading', false)
        },
        
         async isBusinessSet() {
          this.$store.commit('setIsLoading', true)
 
          await axios
                .get('/mapche-api/v1/business/get_my_business')
                .then(response => {
                  this.business = response.data
                  const created = !response.data.created_by  

                    this.$store.commit('setBusiness', {
                    'id': response.data.id, 
                    'name': response.data.name,
                    'plan': response.data.plan,
                    'customer': response.data.stripe_customer_id,
                    'status': response.data.plan_status,
                    })
                    
                    if (!created) {
                      this.$store.commit('setTeam', {
                        'created': response.data.created_by.id
                      })
                    }   
                    
                })
                .catch(error => {
                  console.log(error)
                })

          this.$store.commit('setIsLoading', false)
        },


      }
    }

</script>