<template>
  <div class="flex-auto overflow-x-auto px-4 lg:px-10 py-4 pt-0">
    <div class="flex flex-wrap">
      <div class="w-full lg:flex lg:flex-grow">
        <div class="px-4 mt-0 border shadow-md">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 class="text-blueGray-400 text-sm mt-3 mb-3 font-bold uppercase">
                Reseller Information
              </h6>
              <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="email"
                    >
                      Email Address
                    </label>
                    <input
                      class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                      type="email"
                      required
                      placeholder="example@example.com *"
                      id="email"
                      autocomplete="email"
                      v-model="username"
                    />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="company"
                    >
                      Company Name
                    </label>
                    <input
                      class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                      type="text"
                      required
                      placeholder="Test (Pty) Ltd *"
                      id="company"
                      autocomplete="company"
                      v-model="company"
                    />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                      class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                      for="accountID"
                    >
                      Account ID
                    </label>
                    <input
                      class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2"
                      type="text"
                      required
                      placeholder="MSA12345 / PID-1234-48376"
                      id="accountID"
                      v-model="accountID"
                    />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-semibold mb-2"
                for="assignedto"
                >
                Assigned To - Sales Person
              </label>
              <select v-if="isCreated" class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2 capitalize" v-model="assignedto">
                <option value="" selected>Select member</option>
                <option v-for="member in team.members" :key="member.id" :value="member.id">
               {{ member.first_name }} {{ member.last_name }}
               </option>
              </select>
              <select v-else class="w-full placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2 capitalize" v-model="assignedto">
                <option value="" selected>Select member</option>
                <option v-for="member in team.members" :key="member.id" :value="member.id">
               {{ member.first_name }} {{ member.last_name }}
               </option>
              </select>
            </div>
          </div> 
          
          
            </div>
            <div class="flex align-middle px-4">
                  <button class="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    @click="sendResellerInvite()"
                  >
                    Add
                  </button>
                  <div class="flex error-sign-up" v-if="errors.length">
                    <span class="scale-75 material-symbols-outlined mr-1">error</span>
                    <p>{{ errors[0] }}</p>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

  export default {
    name: 'CardUserAdd',
    data() {
    return {
      username: '',
      userRoles: [],
      company: '',
      accountID: '',
      team: {
        members: [],
      },
      assignedto: '',
      errors: []
    }
  },
  emits: ['update-add'],

  mounted() {
   this.getTeam()
  },

  computed: {
    isCreated() {
      const created = localStorage.getItem('created');
      const userid = localStorage.getItem('userid');
      if (created !== userid) {
        this.assignedto = localStorage.getItem('userid')
      }
      return created === userid;
    }
  },
  
    methods: {
      async getTeam() {
        this.$store.commit('setIsLoading', true)

        await axios
            .get('/mapche-api/v1/business/get_my_business/')
            .then(response => {
              this.team = response.data
            })
            .catch(error => {
              console.log(error)
            })

        this.$store.commit('setIsLoading', false)
      },

      async sendResellerInvite() {
        this.errors = []

        if (this.username === '') {
          this.errors.push('The Email is missing.')
        } else if (!/\S+@\S+\.\S+/.test(this.username)) {
          this.errors.push('Please enter a valid email address.')
        }

        if (this.company === '') {
          this.errors.push('Please enter a Company Name')
        }

        if (this.assignedto === '') {
          this.assignedto = this.$store.state.user.id
        }

        if (!this.errors.length) {
          this.$store.commit('setIsLoading', true)
        
          const formData = {
            email: this.username,
            company_name: this.company,
            reselleraccount_id: this.accountID,
            member_id: this.assignedto
          }

          await axios
             .post('/mapche-api/v1/business/resellerinvite/', formData)  
             .then(response => {
                  toast({
                    message: 'The broker was invited, an invite with instructions has been sent.',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                  })
          
                  const isAddExpanded = false
                  this.$emit('update-add', isAddExpanded)

              })
              .catch(error => {
                if (error.response && error.response.status === 400) {
                  this.errors = [];

                  for (const property in error.response.data) {
                    const errorMessage = `${error.response.data[property]}`;

                    toast({
                      message: errorMessage,
                      type: 'is-danger', 
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 5000,
                      position: 'bottom-right',
                    });
                  }
                }  else if (error.message) {
                    const errorMessage= 'Something went wrong. Please try again!'
                    toast({
                      message: errorMessage,
                      type: 'is-danger', 
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 5000,
                      position: 'bottom-right',
                    });
                }
              })


              
        this.$store.commit('setIsLoading', false)

      } else if (this.errors.length) {
          setTimeout(() => {
            this.errors = []
          }, 3000)
          }

      },
  
    }
  }
</script>
<style scope>
.error-sign-up {
border-radius: 0.5rem;
margin-left: 8rem;
align-items: center;
justify-content: center;
}

.error-sign-up p {
font-size: 12px;
font-weight: bold;
color: #f420cd;
}
.error-sign-up span {
color: #f420cd;
}
</style>