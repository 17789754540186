<template>
  <div class="flex flex-wrap">
    <div class="w-full px-0">
      <CardResellerUser />
    </div>
  </div>
</template>
<script>
import CardResellerUser from "@/components/Cards/CardResellerUser.vue";



export default {
  components: {
    CardResellerUser,

  },
};
</script>
