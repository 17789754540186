<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-5">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in
              </h6>
            </div>

          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="submitForm">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  v-model="username"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
             <br/>
              <div class="notification is-primary" v-if="errors.length">
                  <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
              </div>
              <div class="text-center mt-6">
                <button
                  class="bg-navy text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/auth/password_reset" class="text-blueGray-200">
              <small>Forgot password?</small>
            </router-link>
            <div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';


export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      isMobileFalsy: true,
      errors: [],
      invites: [],
      resellerinvites: [],
      resellerID: null,
    };
  },

  watch: {
    username(newVal) {
      this.username = newVal.toLowerCase();
    }
  },

  methods: {

  getmybusiness() {
      axios
       .get('/mapche-api/v1/business/get_my_business')
       .then(response => {

        const isBusinessIdFalsy = !response.data.id
        const created = !response.data.created_by  

        this.$store.commit('setBusiness', {
          'id': response.data.id, 
          'name': response.data.name,
          'plan': response.data.plan,
          'customer': response.data.stripe_customer_id,
          'status': response.data.plan_status,
        })

        if (!created) {
          this.$store.commit('setTeam', {
            'created': response.data.created_by.id
          })
        }

        if (this.resellerID) {
        this.$store.commit('setResellerID', {
            'id': this.resellerID
          })
        }
        
        if (isBusinessIdFalsy || this.isMobileFalsy) {
          this.$router.push('/admin/my-account')
          } else {
          this.$router.push('/admin/dashboard')
          }
        })
         .catch(error => {
           console.log(error)
        })

    },

    getresellerbusiness() {
      axios
       .get('/mapche-api/v1/business/get_my_business')
       .then(response => {

        const isBusinessIdFalsy = !response.data.id
        const created = !response.data.created_by  

        this.$store.commit('setBusiness', {
          'id': response.data.id, 
          'name': response.data.name,
          'plan': response.data.plan,
          'customer': response.data.stripe_customer_id,
          'status': response.data.plan_status,
        })

        if (!created) {
          this.$store.commit('setTeam', {
            'created': response.data.created_by.id
          })
        }

        })
         .catch(error => {
           console.log(error)
        })      
    },

    getbusiness(businessId) {
      axios
       .get(`/mapche-api/v1/business/get_business/${businessId}/`)
       
      .then(response => {

        const created = !response.data.created_by 

        this.$store.commit('setBusiness', {
          'id': response.data.id, 
          'name': response.data.name,
          'plan': response.data.plan,
          'customer': response.data.stripe_customer_id,
          'status': response.data.plan_status,
        })


        if (!created) {
          this.$store.commit('setTeam', {
            'created': response.data.created_by.id
          })
        }

        })
         .catch(error => {
           console.log(error)
        })

    },



    async submitForm() {
        this.$store.commit('setIsLoading', true)
        this.errors = []
        axios.defaults.headers.common['Authorization'] = ''
        localStorage.removeItem('token')
        localStorage.removeItem('key')

        const formData = {
          username: this.username,
          password: this.password
        }

        await axios
          .post('/accounts/token/login/', formData)
          .then(response => {
            const token = response.data.auth_token

            this.$store.commit('setToken', token)  
            
            axios.defaults.headers.common['Authorization'] = 'Token ' + token

            localStorage.setItem('token', token)

          })
          .catch(error => {
              if(error.response) {
                if (error.response.status === 400) {
                  this.password = ''
                  this.errors.push('Unable to log in with provided credentials!')
                }
              } else if (error.message) {
                  this.password = ''
                  this.errors.push('Something went wrong. Please try again!')
              }
            })

            await axios
            .get('accounts/users/me/')
            .then(response => {
              const responseID = response.data.id

              this.$store.commit('setUser', {'id': response.data.id, 'username': response.data.username})

              localStorage.setItem('username', response.data.username)
              localStorage.setItem('userid', response.data.id)

            })
            .catch(error => {
              console.log(error)
            })

            await axios
              .get(`/mapche-api/v1/business/get_user_roles/${localStorage.getItem('userid')}/`)
              .then(roles => {
                const userRoles = roles.data.user_roles
                this.$store.commit('setUser', {'id': localStorage.getItem('userid'), 'username': localStorage.getItem('username'), 'roles': userRoles})

              })
              .catch(error => {
                console.log(error)
              })    
                     
          if (this.userRoles) {
            await axios
            .get('/mapche-api/v1/userprofile/')
            .then(response => {
              const mapchikey = response.data[0].mapche_key
              const isUserInnovator = response.data[0].is_innovator
              const isUserDemo = response.data[0].is_demo

              this.$store.commit('setKey', {
                'key': mapchikey
              })

              this.$store.commit('setIsInnovator', isUserInnovator)
              localStorage.setItem('setIsInnovator', isUserInnovator)

              this.$store.commit('setIsDemo', isUserDemo)
              localStorage.setItem('setIsDemo', isUserDemo)



            })
            .catch(error => {
              console.log(error)
            })    

          } else {
            await axios
            .get('/mapche-api/v1/userprofilefirst/')
            .then(response => {
              const mapchikey = response.data[0].mapche_key
              const isUserInnovator = response.data[0].is_innovator
              const isUserDemo = response.data[0].is_demo

              this.$store.commit('setKey', {
                'key': mapchikey
              })

              this.$store.commit('setIsInnovator', isUserInnovator)
              localStorage.setItem('setIsInnovator', isUserInnovator)

              this.$store.commit('setIsDemo', isUserDemo)
              localStorage.setItem('setIsDemo', isUserDemo)



            })
            .catch(error => {
              console.log(error)
            })    
          }    

           await axios
            .get(`/verify/phone-number/${localStorage.getItem('userid')}/`)
            .then(response => {
              this.isMobileFalsy = !response.data.phone_number

              this.$store.commit('setMobile', {
                'phone_number': response.data.phone_number
                })

              })
              .catch(error => {
                if (error.response && error.response.status === 404) {
                  console.log('Record does not exist');
                } else {
                  console.log(error);
                }
              })

           await axios
              .get('/mapche-api/v1/business/loginvite/')
              .then(response => {
                  this.invites = response.data.invites
                  if (this.invites.length) {
                  const invitedInvite = this.invites.find((invite) => {
                    const username = this.username.trim().toLowerCase()
                    const email = invite.email.trim().toLowerCase()
                    return email === username && invite.status === 'invited'
                  })
                  if (invitedInvite) {

                    const invitedvalue = true

                    this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                    localStorage.setItem('invited', invitedvalue)                    
                    
                    this.getbusiness(invitedInvite.business)

                    this.$router.push('/admin/invite')

                  } else {
                    const invitedvalue = false
                    this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                    localStorage.setItem('invited', invitedvalue)

                    this.getmybusiness()

                  }
                } else {
                  const invitedvalue = false
                  this.$store.commit('setInvited', {
                      'invited': invitedvalue
                    })

                  localStorage.setItem('invited', invitedvalue)

                  this.getmybusiness()

                }
                

              })
              .catch(error => {
                console.log(error)
              })

              await axios
              .get('/mapche-api/v1/business/resellerloginvite/')
              .then(response => {
                  this.resellerinvites = response.data.invites
                  if (this.resellerinvites.length) {
                  const resellerinvitedInvite = this.resellerinvites.find((invite) => {
                    const username = this.username.trim().toLowerCase()
                    const email = invite.email.trim().toLowerCase()
                    return email === username && invite.status === 'invited'
                  })

                  const resellerid = this.resellerinvites.find((invite) => {
                    const username = this.username.trim().toLowerCase()
                    const email = invite.email.trim().toLowerCase()
                    return email === username && invite.status === 'accepted'
                  })

                  if (resellerid) {
                    this.resellerID = resellerid.business
                  }

                  if (resellerinvitedInvite) {

                    const resellerinvitedvalue = true

                    this.$store.commit('setResellerInvited', {
                      'resellerinvited': resellerinvitedvalue
                    })

                    localStorage.setItem('resellerinvited', resellerinvitedvalue)                    
                    

                    this.getresellerbusiness()

                    this.$router.push('/admin/resellerinvite')

                  } else {
                    const resellerinvitedvalue = false
                    this.$store.commit('setResellerInvited', {
                      'resellerinvited': resellerinvitedvalue
                    })

                    localStorage.setItem('resellerinvited', resellerinvitedvalue)

                    this.getmybusiness()

                  }
                } else {
                  const resellerinvitedvalue = false
                  this.$store.commit('setResellerInvited', {
                      'resellerinvited': resellerinvitedvalue
                    })

                  localStorage.setItem('resellerinvited', resellerinvitedvalue)

                  this.getmybusiness()

                }
                

              })
              .catch(error => {
                console.log(error)
              })

          this.$store.commit('setIsLoading', false)
    }
  }

  

};
</script>
