<template>
  <div v-if="widgetURL === 'mapchi.com'">
    <div v-if="shouldShowReseller" class="container shadow-xl">
      <stripe-pricing-table class="pt-4"
          pricing-table-id="prctbl_1PDT02AK8IvkifNkkr38j7gA"
          publishable-key="pk_live_51NIt1uAK8IvkifNkWuj6uq0r0BfXN4gsbbAa61NWgkhQz0u7A6qzETpNiqQm8GgyotyMK47ZxcHiyg7JESja2Ydp002fwZ2OVm"
          :client-reference-id="$store.state.user.id"
          :customer-email="$store.state.user.username"
          />
    </div>
    <div v-else class="container shadow-xl">
      <stripe-pricing-table 
        pricing-table-id="prctbl_1PDSfVAK8IvkifNkVktsfGAy"
        publishable-key="pk_live_51NIt1uAK8IvkifNkWuj6uq0r0BfXN4gsbbAa61NWgkhQz0u7A6qzETpNiqQm8GgyotyMK47ZxcHiyg7JESja2Ydp002fwZ2OVm"        
        :client-reference-id="$store.state.user.id"
        :customer-email="$store.state.user.username"
        />
    </div>
  </div>  
  <div v-else>
    <div v-if="shouldShowReseller" class="container shadow-xl">
      <stripe-pricing-table class="pt-4"
          pricing-table-id="prctbl_1NxRnDAK8IvkifNkwk1Oxpks"
          publishable-key="pk_test_51NIt1uAK8IvkifNkp80eBt8NZbIRWS34g1mWfn6Jw1XKzccAMBZJECXNLSyOTNPtvNEq7YR0GP7LDoA8bORLL5fN00fO1fJXNq"
          :client-reference-id="$store.state.user.id"
          :customer-email="$store.state.user.username"
          />
    </div>
    <div v-else class="container shadow-xl">
      <stripe-pricing-table 
        pricing-table-id="prctbl_1NV8MXAK8IvkifNk3dH9SP6H"
        publishable-key="pk_test_51NIt1uAK8IvkifNkp80eBt8NZbIRWS34g1mWfn6Jw1XKzccAMBZJECXNLSyOTNPtvNEq7YR0GP7LDoA8bORLL5fN00fO1fJXNq"
        :client-reference-id="$store.state.user.id"
        :customer-email="$store.state.user.username"
        />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
  
    export default {
      name: 'Plans',

      mounted() {
        this.isBusinessSet()
      },
      created() {
        this.widgetURL = window.location.hostname
      },

      computed: {
        shouldShowReseller() {
            return this.$store.state.reseller.id
        }
      },      
      methods: {
        async isBusinessSet() {
          this.$store.commit('setIsLoading', true)
 
          await axios
                .get('/mapche-api/v1/business/get_my_business')
                .then(response => {
                  this.business = response.data
                  const created = !response.data.created_by 

                    this.$store.commit('setBusiness', {
                    'id': response.data.id, 
                    'name': response.data.name,
                    'plan': response.data.plan,
                    'customer': response.data.stripe_customer_id,
                    'status': response.data.plan_status,
                    })
                    
                    if (!created) {
                      this.$store.commit('setTeam', {
                        'created': response.data.created_by.id
                      })
                    }   
                    
                })
                .catch(error => {
                  console.log(error)
                })

          this.$store.commit('setIsLoading', false)
        },


        async redirectToBillingPortal() {
          this.$store.commit('setIsLoading', true)

          try {
            const customer_id = this.$store.state.business.customer 

            const response = await axios.get(`/mapche-api/v1/stripe/billing_portal/${customer_id}/`)
            if (response.status === 200 && response.data.url) {
              window.location.href = response.data.url
            } else {
                toast({
                message: 'A Billing Portal error occurred. Please try again later.',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              })
            }
          } catch (error) {
            toast({
              message: 'A Billing Portal error occurred. Please try again later.',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 5000,
              position: 'bottom-right',
            })

          }
          this.$store.commit('setIsLoading', false)
        },
      }
    }

</script>

<style scoped>
.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

</style>