<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-8/12 px-4">
      <CardSubscription />
    </div>
    <div class="w-full lg:w-4/12 px-4">
      <CardProfile />
    </div>
  </div>
</template>
<script>
import CardSubscription from "@/components/Cards/CardSubscription.vue";
import CardProfile from "@/components/Cards/CardProfile.vue";

export default {
  components: {
    CardSubscription,
    CardProfile,
  },
};
</script>
