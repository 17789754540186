<template>
  <div>
    <navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-no-repeat bg-full bg-pink"
          :style="`background-image: url('${registerBg2}');`"
        ></div>
      </div>

      <section class="relative py-18">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
            width="100%"
            height="85%"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="pt-2 container mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-6/12 ml-auto mr-auto">
              <div class="md:pr-10">
                <h1 class="text-4xl text-blueGray-800 font-semibold">
                  Streamlined Solutions for your Business
                </h1>
                <p class="mt-4 leading-relaxed text-blueGray-500">
                  MapChi is a SaaS business tailored to the telecommunications
                  industry.
                </p>
                <p class="mt-2 leading-relaxed text-blueGray-500">
                  Our innovative all-in-one software simplifies your business
                  journey, from marketing to sales to operational efficiencies.
                  With our unique feasibility, pricing and lead management
                  modules, MapChi transforms the way you sell. Say goodbye to
                  costly development processes, our self-managed platform puts
                  you in full control. With just one line of code, we enable
                  faster, smarter selling.
                </p>
                <ul class="list-none mt-4">
                  <li class="py-0">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-2 px-2 uppercase rounded-full text-mapchi-600 mr-3"
                        >
                          <i class="fas fa-store"></i>
                        </span>
                      </div>
                      <div>
                        <h4
                          class="text-blueGray-800 font-semibold text-lg ml-0"
                        >
                          Resellers:
                        </h4>
                        <h4 class="text-blueGray-500">
                          Streamline and optimize with tailored solutions.
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-2 px-2 uppercase rounded-full text-mapchi-600 mr-3"
                        >
                          <i class="fas fa-laptop-code"></i>
                        </span>
                      </div>
                      <div>
                        <h4
                          class="text-blueGray-800 font-semibold text-lg ml-0"
                        >
                          IT Managers:
                        </h4>
                        <h4 class="text-blueGray-500">
                          Simplify IT operations and reduce costs.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-2 px-2 uppercase rounded-full text-mapchi-600 mr-3"
                        >
                          <i class="fas fa-sitemap"></i>
                        </span>
                      </div>
                      <div>
                        <h4
                          class="text-blueGray-800 font-semibold text-lg ml-0"
                        >
                          Multi-Branch Enterprises:
                        </h4>
                        <h4 class="text-blueGray-500">
                          Unify and economize operations across all branches .
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-2 px-2 uppercase rounded-full text-mapchi-600 mr-3"
                        >
                          <i class="fas fa-wifi"></i>
                        </span>
                      </div>
                      <div>
                        <h4
                          class="text-blueGray-800 font-semibold text-lg ml-0"
                        >
                          Internet Service Providers (ISP):
                        </h4>
                        <h4 class="text-blueGray-500">
                          Elevate your service offerings and customer
                          satisfaction.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-2 px-2 uppercase rounded-full text-mapchi-600 mr-3"
                        >
                          <i class="fas fa-handshake"></i>
                        </span>
                      </div>
                      <div>
                        <h4
                          class="text-blueGray-800 font-semibold text-lg ml-0"
                        >
                          Network Owners:
                        </h4>
                        <h4 class="text-blueGray-500">
                          Efficiently manage and scale your network.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <br />
              <div class="flex items-center">
                <a
                  class="widget-button bg-navy text-white active:bg-blueGray-600 hover:bg-pink hover:text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 cursor-pointer"
                  v-on:click="scrollMeTo('contact')"
                  >Get in touch</a
                >
              </div>
            </div>

            <div class="w-full md:w-5/12">
              <div class="logo-scroller-container">
                <div class="logo-scroller">
                  <div class="logos">
                    <div class="py-10" style="display: flex">
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('za')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('sz')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('ls')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('zw')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('na')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('mz')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('bw')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('zm')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('gb')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                      <div
                        class="mr-4 ml-4"
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        <img
                          :src="getFlagUrl('na')"
                          class="bg-white"
                          style="width: 80px; height: 42px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="shadow-2xl">
                <a v-on:click="scrollMeTo('contact')">
                  <img
                    alt="..."
                    class="max-w-full mb-24 rounded-lg"
                    src="../assets/img/Group.jpg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section ref="contact" class="mt-20 relative border bg-mapchi-navy">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
            width="100%"
            height="85%"
          >
            <polygon
              class="text-mapchi-navy fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white pt-8">
                Want to Expand your Horizons?
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                Let's put your country on the map with MapChi.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Experience Excellence in Services
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Dive into a world of telecommunication services. Empower your
                business with the ability to offer an extensive range of
                services, fueling your market expansion and growth endeavors.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Expand Your Market Reach
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Effortlessly extend your footprint to reach all your potential
                clients and resellers. With our solution, reaching new markets
                becomes a seamless and straightforward process, propelling your
                business towards greater success.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Instant Launch
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                With just a single line of code, you can launch your new
                business in seconds. Our streamlined process ensures that you
                can get up and running swiftly, allowing you to focus on what
                truly matters: growing your business.
              </p>
              <br />
            </div>
          </div>
        </div>
      </section>
      <form @submit="sendEmail">
        <section class="relative block py-24 lg:pt-0 bg-mapchi-navy">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div class="w-full lg:w-6/12 px-4">
                <div
                  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
                >
                  <div class="flex-auto p-5 lg:p-10">
                    <h4 class="text-2xl font-semibold">
                      Want to work with us?
                    </h4>
                    <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Complete the form below and we will get back to you,
                      soonest.
                    </p>
                    <div class="relative w-full mb-3 mt-8">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                        v-model="name"
                        required
                      />
                    </div>

                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        v-model="emailname"
                        required
                      />
                    </div>

                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Type a message..."
                        v-model="message"
                        required
                      />
                    </div>

                    <div class="text-center mt-6">
                      <button
                        class="widget-button bg-navy text-white active:bg-blueGray-600 hover:bg-pink hover:text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 cursor-pointer"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import registerBg2 from "@/assets/img/register_bg_2.png";
import axios from "axios";
import {toast} from "bulma-toast";

export default {
  async mounted() {
    this.uuidv4 = await uuidv4get();
    function uuidv4get() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    }
  },

  data() {
    return {
      uuidv4: "00000000-0000-0000-0000-000000000000",
      registerBg2,
      code: `<head>`,
      name: "",
      emailname: "",
      message: "",
    };
  },

  components: {
    Navbar,
    FooterComponent,
  },

  methods: {
    getFlagUrl(country) {
      return `https://flagcdn.com/w80/${country}.png`;
    },

    sendEmail() {
      const formData = {
        name: this.name,
        email: this.emailname,
        message: this.message,
      };

      axios
        .post("/sms/send-emailinfo/", formData)
        .then((response) => {
          toast({
            message: "Email recieved, we will be in contact soon!",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },

    CopyToClipboard() {
      try {
        const copyText = document.getElementById("copy").textContent;
        const btn = document.getElementById("copybtn");
        const textArea = document.createElement("textarea");
        textArea.textContent = copyText;
        document.body.append(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
        const intialText = "Copy";
        if (btn.textContent.includes(intialText)) {
          btn.textContent = "Copied!";
        } else {
          btn.textContent = intialText;
        }
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>
<style lang="scss">
$h1color: #6e768d;
$h3color: #b4bdc6;
$widthoftable: 31%;
$bgctables: #ffffff;
$bgfontcolor: #717787;

.priceTitle {
  margin-bottom: 20px;
}
.priceSub {
  margin-bottom: 60px;
}

.pricingTable {
  margin: 40px auto;

  & > &-title {
    text-align: center;
    color: $h1color;
    font-size: 3em;
    font-size: 300%;
    margin-bottom: 20px;
    letter-spacing: 0.04em;
  }

  & > &-subtitle {
    text-align: center;
    color: $h3color;
    font-size: 1.8em;
    letter-spacing: 0.04em;
    margin-bottom: 60px;

    @media screen and (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &-firstTable {
    list-style: none;
    padding-left: 2em;
    padding-right: 2em;
    text-align: center;

    &_table {
      vertical-align: middle;
      width: $widthoftable;
      background-color: $bgctables;
      display: inline-block;
      padding: 0px 30px 40px;
      text-align: center;
      max-width: 320px;
      transition: all 0.3s ease;
      border-radius: 5px;
      background-color: #f0f4f7;

      @media screen and (max-width: 767px) {
        display: block;
        width: 90%;
        margin: 0 auto;
        max-width: 90%;
        margin-bottom: 20px;
        padding: 10px;
        padding-left: 20px;
      }

      & > * {
        @media screen and (max-width: 767px) {
          display: inline-block;
          vertical-align: middle;
        }

        @media screen and (max-width: 480px) {
          display: block;
          float: none;
        }
      }

      &:after {
        @media screen and (max-width: 767px) {
          display: table;
          content: "";
          clear: both;
        }
      }

      &:hover {
        transform: scale(1.08);
        @media screen and (max-width: 767px) {
          transform: none;
        }
      }

      &:not(:last-of-type) {
        margin-right: calc((100% - $widthoftable * 3) / 2);
        @media screen and (max-width: 767px) {
          margin-right: auto;
        }
      }

      &:nth-of-type(2) {
        position: relative;

        & h1 {
          @media screen and (max-width: 767px) {
            padding-top: 8%;
          }
        }

        &:before {
          content: "Popular";
          position: absolute;
          color: white;
          display: block;
          background-color: #29b6f6;

          text-align: center;
          right: 15px;
          top: -25px;
          height: 65px;
          width: 65px;
          border-radius: 50%;
          box-sizing: border-box;
          font-size: 0.5em;
          font-weight: bold;
          padding-top: 27px;
          text-transform: uppercase;
          letter-spacing: 0.13em;
          transition: all 0.5s ease;

          @media screen and (max-width: 988px) {
            font-size: 0.6em;
          }

          @media screen and (max-width: 767px) {
            left: 10px;
            width: 45px;
            height: 45px;
            top: -10px;
            padding-top: 17px;
          }

          @media screen and (max-width: 480px) {
            font-size: 0.8em;
          }
        }
        &:hover:before {
          transform: rotate(360deg);
        }
      }

      &__header {
        font-size: 1.6em;
        padding: 0px 0px;
        border-bottom: 2px solid #ebedec;
        letter-spacing: 0.03em;

        @media screen and (max-width: 1068px) {
          font-size: 1.45em;
        }
        @media screen and (max-width: 767px) {
          padding: 0px;
          border-bottom: none;
          float: left;
          width: 33%;
          padding-top: 3%;
          padding-bottom: 2%;
        }

        @media screen and (max-width: 610px) {
          font-size: 1.3em;
        }

        @media screen and (max-width: 480px) {
          float: none;
          width: 100%;
          font-size: 1.8em;
          margin-bottom: 5px;
        }
      }

      &__pricing {
        font-size: 3em;
        padding: 30px 0px;
        border-bottom: 2px solid #ebedec;
        line-height: 0.7;

        @media screen and (max-width: 1068px) {
          font-size: 2.8em;
        }

        @media screen and (max-width: 767px) {
          border-bottom: none;
          padding: 0;
          float: left;
          clear: left;
          width: 33%;
        }

        @media screen and (max-width: 610px) {
          font-size: 2.4em;
        }

        @media screen and (max-width: 480px) {
          float: none;
          width: 100%;
          font-size: 3em;
          margin-bottom: 10px;
        }

        span:first-of-type {
          font-size: 0.35em;
          vertical-align: top;
          letter-spacing: 0.15em;
          @media screen and (max-width: 1068px) {
            font-size: 0.3em;
          }
        }
        span:last-of-type {
          vertical-align: bottom;
          font-size: 0.3em;
          letter-spacing: 0.04em;
          padding-left: 0.2em;
          @media screen and (max-width: 1068px) {
            font-size: 0.25em;
          }
        }
      }

      &__options {
        list-style: none;
        padding: 15px;
        font-size: 0.9em;
        border-bottom: 2px solid #ebedec;

        @media screen and (max-width: 1068px) {
          font-size: 0.85em;
        }

        @media screen and (max-width: 767px) {
          border-bottom: none;
          padding: 0;
          margin-right: 10%;
        }

        @media screen and (max-width: 610px) {
          font-size: 0.7em;
          margin-right: 8%;
        }

        @media screen and (max-width: 480px) {
          font-size: 1.3em;
          margin-right: none;
          margin-bottom: 10px;
        }

        & > li {
          padding: 8px 0px;

          @media screen and (max-width: 767px) {
            text-align: left;
          }

          @media screen and (max-width: 610px) {
            padding: 5px 0;
          }

          @media screen and (max-width: 480px) {
            text-align: center;
          }

          &:before {
            content: "✓";
            display: inline-flex;
            margin-right: 15px;
            color: white;
            background-color: #74ce6a;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            font-size: 0.8em;
            padding: 2px;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1068px) {
              width: 14px;
              height: 14px;
              padding: 1.5px;
            }

            @media screen and (max-width: 767px) {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      &__getstart {
        color: white;
        border: 0;
        background-color: #71ce73;
        margin-top: 30px;
        border-radius: 5px;
        cursor: pointer;
        padding: 15px;
        box-shadow: 0px 3px 0px 0px rgba(102, 172, 100, 1);
        letter-spacing: 0.07em;
        transition: all 0.4s ease;

        @media screen and (max-width: 1068px) {
          font-size: 0.95em;
        }

        @media screen and (max-width: 767px) {
          margin-top: 0;
        }

        @media screen and (max-width: 610px) {
          font-size: 0.9em;
          padding: 10px;
        }

        @media screen and (max-width: 480px) {
          font-size: 1em;
          width: 50%;
          margin: 10px auto;
        }

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0px 40px 29px -19px rgba(102, 172, 100, 0.9);
          @media screen and (max-width: 767px) {
            transform: none;
            box-shadow: none;
          }
        }

        &:active {
          box-shadow: inset 0 0 10px 1px rgba(102, 165, 100, 1),
            0px 40px 29px -19px rgba(102, 172, 100, 0.95);
          transform: scale(0.95) translateY(-9px);
          @media screen and (max-width: 767px) {
            transform: scale(0.95) translateY(0);
            box-shadow: none;
          }
        }
      }
    }
  }
}

body {
  @media screen and (max-width: 960px) {
    font-size: 80%;
  }
  @media screen and (max-width: 776px) {
    font-size: 70%;
  }
  @media screen and (max-width: 496px) {
    font-size: 50%;
  }
  @media screen and (max-width: 320px) {
    font-size: 40%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logo-scroller-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.logo-scroller {
  width: 60%;
  overflow: hidden;
}

.logos {
  display: flex;
  animation: scroll 30s linear infinite;
}

.logos img {
  min-width: 80px;
  min-height: 42px;
  width: 100px;
  height: auto;
}

@media screen and (max-width: 2200px) {
  .logos img {
    min-width: 80px;
    min-height: 42px;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
