<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">Settings</h6>
        </div>
      </div>
  
  
  
  
    <div class="flex flex-wrap">
    <div class="w-full">
      <div class="w-full px-4">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li v-if="(isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserMarketing)" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-emerald-600': openTab === 1}">
            <div class="mapchicontainer">
              <img :src="mapchimg" alt="MapChi Icon" class="icon-class">
              <span class="description">MapChi</span>
            </div>
          </a>
        </li>
        <li v-if="(isBrokerGoogle)" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-emerald-600': openTab === 2}">
            <i class="fas fa-key text-base mr-1"></i> Google API
          </a>
        </li>
        <li v-if="(isCreatedByCurrentUser || isCurrentUserAdmin || isCurrentUserMarketing)" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-emerald-600': openTab === 3}">
            <i class="fas fa-crop-alt text-base mr-1"></i> iFrame
          </a>
        </li>
        <li v-if="(isBrokerGoogle || isCurrentUserProduct)" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 4, 'text-white bg-emerald-600': openTab === 4}">
            <i class="fas fa-globe-africa text-base mr-1"></i> Global Coverage
          </a>
        </li>

      </ul>
    </div>
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
              <CardPlatformWidget />
            </div>  
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
              <CardPlatformGoogle />
            </div>

            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
              <CardiFrame />
            </div>
            <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}">
              <CardCoverage/>
            </div>
          </div>
  
      </div>
    </div>
  </div>
  </div>
    
   </template>
  
  <script>
  import CardPlatformWidget from "@/components/Cards/CardPlatformWidget.vue";
  import CardPlatformGoogle from "@/components/Cards/CardPlatformGoogle.vue";
  import CardiFrame from "@/components/Cards/CardiFrame.vue";
  import CardCoverage from "@/components/Cards/CardCoverage.vue";
  import CardAdvertising from "@/components/Cards/CardAdvertising.vue";
  import mapchimg from "@/assets/img/mapchi-icon.png"
  
  
  export default {
    name: "Settings",
  
    mounted() {
      },
  
    data() {
      return {
        openTab: 1,
        comingSoon: true,
        mapchimg,
      }
    },
    components: {
      CardPlatformWidget,
      CardPlatformGoogle,
      CardiFrame,
      CardCoverage,
      CardAdvertising,
  },
  
  computed: {


    isCreatedByCurrentUser() {
      const teamCreatedBy = parseInt(this.$store.state.team.created);
      const userId = parseInt(this.$store.state.user.id);

      return teamCreatedBy === userId;
    },


    isCurrentUserAdmin() {
    const userRoles = this.$store.state.user.roles

    return userRoles.includes("Admin")
   },
   isCurrentUserSales() {
    const userRoles = this.$store.state.user.roles

    return userRoles.includes("Sales")
   },   

   
   isCurrentUserSalesManager() {
    const userRoles = this.$store.state.user.roles

    return userRoles.includes("Manager")
   },   
   
   isCurrentUserFinance() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Finance")
   },   
   isCurrentUserProduct() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Product")
   },  

   isCurrentUserMarketing() {
    const userRoles = this.$store.state.user.roles 

    return userRoles.includes("Marketing")
   },   

   isBrokerGoogle() {
    const plan = this.$store.state.business.plan; 
    const validPlans = ['Innovator', 'Trailblazer', 'Explorer']; 

    if (plan === 'Broker' || this.$store.state.reseller.id) {
      return false;
    }

    const userRoles = this.$store.state.user.roles
    if (userRoles.includes('Admin')) {
      return validPlans.includes(plan);
    }

    if (userRoles.includes('Marketing') && userRoles.includes('Product')) {
      this.toggleTabs(1);
      return true;
    }
    if (userRoles.includes('Marketing')) {
      this.toggleTabs(1);
      return true;
    }
    if (userRoles.includes('Product')) {
      this.toggleTabs(4);
      return true;
    }
    return validPlans.includes(plan);
  }


  },
  
  methods: {
        toggleTabs: function(tabNumber){
          this.openTab = tabNumber
        },
  
      }
  }
  </script>
  
  <style scoped="strict">
  
  .mapchicontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-class {
    width: 23px; 
    height: auto; 
  }
  
  .description {
    margin-left: 8px; 
  }
  </style>