<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <CardProviders />
    </div>

  </div>
</template>
<script>
import CardProviders from "@/components/Cards/CardProviders.vue";

export default {
  name: "Networks",
  components: {
    CardProviders,
  },
};
</script>
