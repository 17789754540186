<template>
 <div
    class="relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-4 border-0">
      <div class="flex justify-between items-center">
        <div>
          <h3 class="font-bold text-xl" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
            Countries<i class="fas fa-share text-emerald-500 px-1"></i>{{ totalcountries}}
          </h3>
        </div>
      </div>
    </div>

    <div class="block w-full overflow-x-auto">
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              <span class="ml-2">Active</span>
            </th>
            <th
              class="p-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Country
            </th>            
            <th
              class="p-4 justify-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              <div class="flex justify-center item-center">
                Currency
              </div>
            </th>
            <th
              class="p-4 justify-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              <div class="flex justify-center item-center">
                VAT Percentage
              </div>
            </th>
            <th
              class="p-4 justify-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              <div class="flex justify-center item-center">
                Excise Duty
              </div>
            </th>
            <th
              class="p-4 align-middle justify-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              <div class="flex justify-center item-center">
                Overview
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(country, index) in filteredCountries" :key="country.id">
          <tr class="lead-item">
            <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs   whitespace-nowrap p-4">
              <div class="flex ml-4 item-center">
                <input v-if="isDemo" type="checkbox" disabled 
                class="focus:ring-0 text-gray-200 cursor-not-allowed" v-model="country.is_active">
                <input v-else type="checkbox" class="focus:ring-0 text-emerald-600 cursor-pointer" v-model="country.is_active" @change="updateUserPreferences(country)">
              </div>
            </td>

            <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <div class="flex items-center ml-1">
                <img :src="getFlagUrl(country)" class="w-16 h-16 bg-white rounded-full border" /> 
                <span class="ml-2">{{ country.name?.common }}</span>
              </div>
            </td>
            <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <div class="flex justify-center item-center ml-1">
                <select class="placeholder-blueGray-300 text-blueGray-600 text-xs whitespace-nowrap mb-2 pt-2 pb-2 capitalize" v-model="country.selectedCurrency" @change="updateUserPreferences(country)">
                  <option v-for="currency in allCurrencies" :key="currency" :value="currency">
                    {{ currency }}
                  </option>
                </select>
              </div>
            </td>
            <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <div class="flex justify-center item-center ml-1">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" type="number" 
                    v-model="country.selectedVAT"
                    @change="updateUserPreferences(country)"
                    :disabled="!country.is_active"
                    :class="{ 'disabled-percentage': !country.is_active }"
                    min="0"
                    step="0.01"/>
                  </div>
                  %
              </div>
            </td>
            <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <div class="flex justify-center item-center ml-1">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" type="number" 
                    v-model="country.selectedExcise"
                    @change="updateUserPreferences(country)"
                    :disabled="!country.is_active"
                    :class="{ 'disabled-percentage': !country.is_active }"                    
                    min="0"
                    step="0.01"/>
                  </div>
                  %
              </div>
            </td>

            <td class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <div class="flex justify-center items-center ml-1">
              <div class="text-xs capitalize text-emerald-600">
                      <button @click="openModal(index)"><i class="fas fa-eye text-xl"></i></button>
              </div>
              <div v-if="showModal" class="modal" @click="closeModal">
                <div class="modal-content" @click.stop>
                  <div class="flex justify-end">
                    <button class="close-button" @click="closeModal">
                      <i class="fas fa-times"></i>
                    </button>
                  </div> 
                  <h2>
                    <img :src="getFlagUrl(selectedCountry)" class="flag bg-white border" />
                    {{ selectedCountry.name?.common }}
                  </h2>
                  <table>
                  <tr>
                    <th class="text-xs capitalize">Country</th>
                    <td class="text-xs capitalize">{{ selectedCountry.name?.common }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Capital</th>
                    <td class="text-xs capitalize">{{ selectedCountry.capital[0] }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Lat & Long</th>
                    <td class="text-xs capitalize">{{ selectedCountry.capitalInfo?.latlng.join(', ') }}</td>
                  </tr>
                  <tr>
                  <th class="text-xs capitalize">View Map</th>
                  <td class="text-xs capitalize text-emerald-600">
                    <b>
                      <a :href="selectedCountry.maps?.googleMaps" target="_blank">
                        <i class="fas fa-eye text-xl"></i>
                      </a>
                    </b>
                  </td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Population</th>
                    <td class="text-xs">{{ (selectedCountry.population / 1000000).toFixed(2) }} million</td>

                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Timezones</th>
                    <td class="text-xs">{{ selectedCountry.timezones.join(', ') }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Currency</th>
                    <td class="text-xs">{{ `${getCurrencyName(selectedCountry.currencies).code} ( ${getCurrencyName(selectedCountry.currencies).symbol} )` }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Continents</th>
                    <td class="text-xs capitalize">{{ selectedCountry?.continents?.join(', ') }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Languages</th>
                    <td class="text-xs capitalize">{{ getLanguage(selectedCountry.languages) }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Dial-Code</th>
                    <td class="text-xs">{{ getIDD(selectedCountry.idd) }}</td>
                  </tr>
                  <tr>
                    <th class="text-xs capitalize">Borders</th>
                    <td class="text-xs capitalize">{{ getCountryNames(selectedCountry.borders).join(', ') }}</td>
                  </tr>
                  </table>
                </div>
              </div>
            </div>
            </td>
          </tr>
          <tr v-if="selectedCountryIndex === index && isEditExpanded">
          <td colspan="4">
            <CardCoverageEdit :country="selectedProvider" :countryName="selectedProviderName" @update-edit="handleUpdate" />
          </td>
          <td colspan="2">
            <CardCoverageRemove :country="selectedProvider" @update-edit="handleUpdate"/>
          </td>
        </tr> 
        </template>

        </tbody>

      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast'
import CardCoverageEdit from "@/components/Cards/CardCoverageEdit.vue";
import CardCoverageRemove from "@/components/Cards/CardCoverageRemove.vue";

export default {
  name: "CardCoverage",

  data() {
    return {
      isEditExpanded: false,
      countries: [],
      supportcountries: [],
      totalcountries: 0,
      selectedCountry: null,
      selectedCurrency: null,
      selectedCountryIndex: 0,
      allCurrencies: [],
      showModal: false,
      userPreferences: [],
      selectedProvider: '',
      selectedProviderName: '',
      country: {
        selectedExcise: null,
        selectedVAT: null,
      },
    };
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },

  components: {
    CardCoverageEdit,
    CardCoverageRemove,
  },
  
  watch: {
    isEditExpanded(newValue) {
      if (!newValue) {
        this.supportCountries();
        this.fetchCountries();
      }
    },

  }, 

  computed: {
    isDemo() {
          const demo = this.$store.state.is_demo;
          return demo
        },
    filteredCountries() {
          return this.countries.filter(country => {
          const commonName = country.name?.common;

            return this.supportcountries.some(supportCountry => supportCountry.country === commonName);
          }).map(country => {
            const commonName = country.name?.common;
            const supportCountry = this.supportcountries.find(sc => sc.country === commonName);
            const userPreference = this.userPreferences.find(up => up.country === supportCountry?.id);

            const selectedCurrency = userPreference?.currency || this.getDefaultCurrency(country);
            const selectedVAT = userPreference?.vat_rate || null; 
            const selectedExcise = userPreference?.excise_duty_rate || 0.00; 

          return {
            ...country,
            is_active: userPreference?.is_active || false,
            selectedCurrency,
            selectedVAT,  
            selectedExcise, 
            userPreferenceId: userPreference?.id || null,
          };
        });
      },
  },
  mounted() {
    this.supportCountries();
    this.fetchCountries();
    this.UserPreferences();

  },
  methods: {
    handleUpdate(isEditExpanded, country_id, apiProviderId, apiIDArray, activeRemove) {
      this.isEditExpanded = isEditExpanded
      this.updateUserPreferencesAPI(country_id, apiProviderId, apiIDArray, activeRemove)
    },
    
    getCountryNames(borders) {
      if (borders) {
        const countryNames = borders.map(border => {
          const country = this.countries.find(c => c.cca3 === border);
          return country ? country.name.common : border;
        });
        return countryNames;
      }
      return [];
    },
    getIDD(idd) {
      if (idd && idd.root && idd.suffixes) {
        const combinedIDD = idd.root + idd.suffixes.join('');
        return combinedIDD;
      }
      return 'N/A';
    },
    getLanguage(languages) {
      if (languages) {
        const languageArray = Object.entries(languages).map(([code, name]) => `${name} (${code})`);
        return languageArray.join(', ');
      }
      return 'N/A';
    },

    openModal(index) {
      this.showModal = true;
      this.selectedCountry = this.filteredCountries[index];
    },

    closeModal() {
      this.showModal = false;
      this.selectedCountry = null; 
    },


    toggleEdit(index, country) {
        if (this.selectedCountryIndex === index) {
          const provider = country.name?.common
          this.selectedProvider = this.findSupportID(provider);
          this.selectedProviderName = this.findSupportName(provider);
          this.isEditExpanded = !this.isEditExpanded
          
        } else {
          const provider = country.name?.common
          this.selectedProvider = this.findSupportID(provider);
          this.selectedProviderName = this.findSupportName(provider);
          this.selectedCountryIndex = index
          this.isEditExpanded = true
        }
    },

    findSupportID(countryName) {
      return this.supportcountries.find(item => item.country === countryName)?.id || null;
    },

    findSupportName(countryName) {
      return this.supportcountries.find(item => item.country === countryName)?.country || null;
    },


    getDefaultCurrency(country) {
      if (country.name.common === 'Zimbabwe') {
        return 'USD';
      } else {
        return Object.keys(country.currencies || {})[0];
      }

    },
    getCountryNames(borders) {
      if (borders) {
        const countryNames = borders.map(border => {
          const country = this.countries.find(c => c.cca3 === border);
          return country ? country.name.common : border;
        });
        return countryNames;
      }
      return [];
    },
    getIDD(idd) {
    if (idd && idd.root && idd.suffixes) {
      const combinedIDD = idd.root + idd.suffixes.join('');
      return combinedIDD;
    }
    return 'N/A';
  },
    getLanguage(languages) {
    if (languages) {
      const languageArray = Object.entries(languages).map(([code, name]) => `${name} (${code})`);
      return languageArray.join(', ');
    }
    return 'N/A';
  },
  
    async supportCountries() {
      try {
        const response = await axios.get('/coverage/globalcoverages/');
        this.supportcountries = response.data;
        this.totalcountries = response.data.length

      } catch (error) {
        console.error('Error fetching supportcountries:', error);
      }
    },

    async UserPreferences() {
      try {
        const response = await axios.get('/coverage/userglobalcoverages/');
        this.userPreferences = response.data;
      } catch (error) {
        console.error('Error fetching user preferences', error);
      }
    },

    async updateUserPreferences(country) {
        let supportID = null;
        for (const supportCountry of this.supportcountries) {
          if (supportCountry.country === country.name.common) {
            supportID = supportCountry.id;
            break;
          }
        }

        if (!supportID) {
          console.error('Support ID not found for the country:', country.name.common);
          return;
        }

        let userPreferences = {
            is_active: Boolean(country.is_active),
            currency: country.selectedCurrency,
            vat_rate: country.selectedVAT,
            excise_duty_rate: country.selectedExcise,
            user: this.$store.state.user.id,
            country: supportID,
            api_providers: [],
        };

        try {
          const response = await axios.post('/coverage/userglobalcoverages/', userPreferences);
              await this.supportCountries();
              await this.UserPreferences();

              await this.fetchCountries();

          toast({
            message: 'User preferences updated successfully',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'bottom-right',
          });


        } catch (error) {
          if (error.response) {
            if (error.response.status === 400) {
              const errorMessage = error.response.data.business[0];
              const capitalizeMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
              toast({
                message: capitalizeMessage,
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 5000,
                position: 'bottom-right',
              });
            }
          } else if (error.message) {
            this.errors.push('Something went wrong. Please try again!');
          }
        }
      },


      async updateUserPreferencesAPI(country_id, apiProviderId, apiIDArray, activeRemove) {
          try {
              let country
              let passdelete
              let temp
              const response = await axios.get(`/coverage/userglobalcoverages/?country=${country_id}`);
              country = response.data[0]
              const userPreferencesExist = response.data.length > 0;
              if (userPreferencesExist) {
              if (!activeRemove) {
                if (!country.api_providers.includes(apiProviderId)) {
                    country.api_providers.push(apiProviderId);
                } 
              } else {
                      temp = country.api_providers
                      country.api_providers = country.api_providers.filter(id => apiProviderId.includes(id))
                      if (country.api_providers.length === 0) {
                        temp = []
                      }

                activeRemove = false
                if (country.api_providers.length === 0) {
                    passdelete = apiIDArray;
                } else {
                    passdelete = country.api_providers   
                }

                  const postData = {
                      api_provider_ids: passdelete, 
                      business_id: this.$store.state.business.id
                  };

                  axios.post('/coverage/api-details/remove-unused-api-details/', postData)
                      .then(response => {
                      })
                      .catch(error => {
                          console.error(error);
                      });                


              }

              
              if (temp) {
                country.api_providers = temp.filter(id => !apiProviderId.includes(id))
              } 

              const userPreferences = {
                    is_active: Boolean(country.is_active),  
                    currency: country.currency,
                    vat_rate: country.vat_rate,
                    excise_duty_rate: country.excise_duty_rate,
                    user: country.user,
                    country: country_id,
                    api_providers: country.api_providers, 
                };

                const response = await axios.post('/coverage/userglobalcoverages/', userPreferences);
                if (response.data) {
                  await this.supportCountries();
                  await this.UserPreferences();
                  await this.fetchCountries();
                }

                toast({
                    message: 'User preferences updated successfully',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 5000,
                    position: 'bottom-right',
                });
              } else {
                  console.error('User preferences do not exist for country:', country.name.common);
              }
          } catch (error) {
              console.error('Error updating user preferences:', error);
              toast({
                  message: 'Error updating user preferences. Please try again.',
                  type: 'is-danger',
                  dismissible: true,
                  pauseOnHover: true,
                  duration: 5000,
                  position: 'bottom-right',
              });
          }
      },


    async fetchCountries() {
      this.$store.commit('setIsLoading', true)
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      this.countries = response.data;

      this.allCurrencies = this.extractCurrencies(response.data);

      this.countries.forEach(country => {
        country.selectedCurrency = Object.keys(country.currencies || {})[0];
      });

      this.selectedCountry = this.countries[this.selectedCountryIndex];
      this.selectedCurrency = this.selectedCountry.selectedCurrency;

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
    this.$store.commit('setIsLoading', false)
  },



    extractCurrencies(countries) {
  const uniqueCurrencies = new Set();
  countries.forEach(country => {
    const codes = Object.keys(country.currencies || {});
    codes.forEach(code => uniqueCurrencies.add(code));
  });
  return Array.from(uniqueCurrencies);
},

    activateCountry(activatedCountry) {
      this.selectedCountry = activatedCountry;
      if (activatedCountry && activatedCountry.currencies) {
        const currencyCodes = Object.keys(activatedCountry.currencies);
        if (currencyCodes.length > 0) {
          activatedCountry.selectedCurrency = currencyCodes[0];
        }
      }
    },


    getFlagUrl(country) {
      return `https://flagcdn.com/w80/${country.cca2.toLowerCase()}.png`;
    },
    

    getCurrencyName(currencies) {
      if (currencies) {
        for (const code in currencies) {
          if (currencies.hasOwnProperty(code)) {
            return {
              code: code,
              symbol: currencies[code].symbol || 'N/A', 
            };
          }
        }
      }
      return { code: 'N/A', symbol: 'N/A' }; 
    },
  },
};
</script>

<style scoped>

.lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #f1f3f6;
  }
.flag {
  width: 90px; 
  height: 50px; 
  object-fit: cover; 
  cursor: pointer;

}


table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.0rem;
    color: #000; 
  }

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.disabled-percentage {
    background-color: rgba(241, 245, 249, 1);
    cursor: not-allowed;
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center center;
  }

</style>