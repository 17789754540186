<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">Theme</h6>
        </div>
      </div>
  
    <div class="flex flex-wrap">
    <div class="w-full">
      <div class="w-full px-4">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-emerald-600': openTab === 1}">
            <i class="fas fa-paint-brush text-base mr-1"></i> iFrame / Widget
          </a>
        </li>
        <li v-if="comingSoon" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
           <span class="text-xs font-bold uppercase px-0 py-3 shadow-lg rounded block leading-normal relative border opacity-50">
            <i class="fas fa-paint-brush text-base mr-1"></i>MapChi Portal
             <span class="absolute top-0 left-0 transform -translate-x-1 translate-y-1 text-xxs bg-blueGray-100 text-blueGrey px-2 py-1 rounded-full opacity-75">
               Coming Soon...
            </span>
           </span>
          </li>
          <li v-else="!comingSoon" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-emerald-600': openTab === 2}">
              <i class="fas fa-paint-brush text-base mr-1"></i>MapChi Portal
            </a>
          </li>
      </ul>
    </div>
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
              <CardThemeiFrame />
            </div>  
          </div>
      </div>
    </div>
  </div>
  </div>
    
   </template>
  
  <script>
  import CardThemeiFrame from "@/components/Cards/CardThemeiFrame.vue";
  import mapchimg from "@/assets/img/mapchi-icon.png"
  
  
  export default {
    name: "Theme",
  
    mounted() {
      },
  
    data() {
      return {
        openTab: 1,
        comingSoon: true,
        mapchimg,
      }
    },
    components: {
      CardThemeiFrame,

  },
  
  methods: {
        toggleTabs: function(tabNumber){
          this.openTab = tabNumber
        },
  
      }
  }
  </script>
  
  <style scoped="strict">
  
  .mapchicontainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-class {
    width: 23px; 
    height: auto; 
  }
  
  .description {
    margin-left: 8px; 
  }
  </style>