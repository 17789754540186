<template>
  <div class="flex flex-wrap">
    <div class="w-full px-0">
      <CardUsers />
    </div>
  </div>
</template>
<script>
import CardUsers from "@/components/Cards/CardUsers.vue";



export default {
  components: {
    CardUsers,

  },
};
</script>
