<template>
    <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
  <div v-if="!totalleads" class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Leads<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalleads }}</h6>
      </div>
  </div>
  <div v-else>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Leads<i class="fas fa-share text-emerald-500 px-1"></i>{{ this.totalleads}}
        </h6>
        <div class="search-box">
              <input class="focus:outline-none focus:ring-0 border-emerald-500 focus:border-emerald-500 text-xs font-bold hover:text-blueGray-700 px-7 py-2 shadow-md rounded block leading-normal text-emerald-600 bg-white"
                type="text"
                placeholder="Search.."
                v-model="query"
                v-on:input="handleSearch"
                v-on:click.stop
              />
        </div>
      </div>
  </div>

  <div class="flex flex-wrap">
    <div class="w-full">
      <div class="w-full px-4">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-emerald-600': openTab === 1}">
            <i class="fas fa-chart-pie text-base mr-1"></i> Summary   
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-emerald-600': openTab === 2}">
            <i class="fas fa-info-circle text-base mr-1"></i> Detail
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-emerald-600': openTab === 3}">
            <i class="fas fa-street-view text-base mr-1"></i> User Searches <i class="fas fa-share px-1"></i>{{ amountSearches }}
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 4, 'text-white bg-emerald-600': openTab === 4}">
            <i class="fas fa-store-alt text-base mr-1"></i> Product Options
          </a>
        </li>
      </ul>
    </div>
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}">
              <CardLeads ref="cardLeads" @lead-selected="handleLeadSelected" :updatedStatus="updatedStatus" :searchQuery="query"/>
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}">
              <CardLeadsDetail ref="cardDetails" @update-status="handleUpdateStatus" :leadId="selectedLeadId" :currency="currency"/>
            </div>
            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}">
              <CardLeadsSearches @amount-searches="handleAmountSearches" :mobile="selectedLeadSearches" :searchQuery="query"/>
            </div>        
            <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}">
              <CardLeadsOptions v-if="openTab === 4" :suppliername="suppliername" :lat="lat" :lng="lng" :searchQuery="query"/>
            </div>     
          </div>
        </div>
    </div>
  </div>
 </div>
 </div> 
</template>

<script>
import axios from 'axios'
import CardLeads from "@/components/Cards/CardLeads.vue";
import CardLeadsDetail from "@/components/Cards/CardLeadsDetail.vue";
import CardLeadsSearches from "@/components/Cards/CardLeadsSearches.vue";
import CardLeadsOptions from "@/components/Cards/CardLeadsOptions.vue";

export default {
  name: "leads-tabs",
  
  mounted() {
    this.getLeads()
  },
  
  data() {
    return {
      leads: [],
      totalleads: 0,
      openTab: 1,
      query: '',
      selectedLeadId: null,
      selectedLeadSearches: null,
      amountSearches: null,
      leadProvider: null,
      suppliername: null,
      lat: null,
      lng: null,
      currency: '',
      updatedStatus: null,
      isCardLeadsUpdated: false,
    }
  },

  components: {
    CardLeads,
    CardLeadsDetail,
    CardLeadsSearches,
    CardLeadsOptions,
  },

  watch: {
    openTab(newVal) {
      if (newVal === 1) {
       this.$nextTick(() => {
         this.$refs.cardLeads.getLeads();
       })
     }
    }
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.query);
    },
    async getLeads() {
      this.$store.commit('setIsLoading', true)
            axios
              .get('/mapche-api/v1/leads/')
              .then(response => {
                this.totalleads = response.data.count
                this.leads = response.data.results
                if (this.leads.length > 0) {
                  var choice = this.leads.length -1
                  this.selectedLead = this.leads[choice]
                  this.selectedLeadSearches = this.leads[choice].shopper_phone
                  this.suppliername = this.leads[choice].suppliername
                  const locdetails = this.selectedLead.search
                  this.getLocationDetails(locdetails)
                }
              })
              .catch(error => {
                console.log(error)
              })
      
      this.$store.commit('setIsLoading', false)
    },

    async getLocationDetails(locdetails) {
      this.$store.commit('setIsLoading', true)

      await axios
          .get(`/mapche-api/v1/search/${locdetails}/`)
          .then(response => {
            this.lat = response.data.location_lat
            this.lng = response.data.location_lng
          })
          .catch(error => {
            console.log(error)
          })

      this.$store.commit('setIsLoading', false)
    },

    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },

    handleLeadSelected(leadId, shopper_phone, suppliername, lat, lng, currencySymbol) {
      this.selectedLeadId = leadId
      this.selectedLeadSearches = shopper_phone
      this.suppliername = suppliername
      this.lat = lat
      this.lng = lng
      this.currency = currencySymbol
    },

    handleUpdateStatus(updatedStatus, leadId) {
      this.updatedStatus = updatedStatus
    },

    handleAmountSearches(amount_searches) {
      this.amountSearches = amount_searches
    },


  }
}
</script>
