<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="block w-full overflow-x-auto">

        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Address
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Latitude
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Longitude
              </th>                   
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Date
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Verified Search
              </th>
              <th
                class="align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="search in searches.slice().reverse()"
              v-bind:key="search.id"
            >
            <td 
              class="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left"
            >
              <div class="flex item-center">
                <span
                  class="ml-0 font-bold capitalize"
                  :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white']" style="line-height: 2.5rem;"
                >
                  {{ search.formatted_address}}
                </span>
              </div>
            </td>
            <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                {{ search.location_lat }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                {{ search.location_lng }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                  {{ formatDateTime(search.created_at) }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap"
              >
                  {{ search.mobile }}
              </td>
  
              <td
                class="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
              >
              <i v-if="search.mobile" class="material-icons pr-1" style="color: #10b981">verified</i>
              </td>
            </tr>
            <br/>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  
  export default {
    name: 'Searches',
  
    mounted() {
      this.getSearches(this.mobile)
    },

    computed: {

    },
  
    data() {
      return {
        searches: [],
      };
    },
    components: {
      
    },
  
    props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    mobile: String,
    searchQuery: {
      type: String,
      default: '',
    },
   },

   watch: {
    mobile(newMobile) {
      this.getSearches(newMobile)
    },
    searches(newSearches) {
      if (newSearches) {
        this.$emit('amount-searches', newSearches.length)
      }
    },
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getSearches(this.mobile)
      }
    }
  },

    methods: {
      formatDateTime(datetime) {
        const datetimeObj = new Date(datetime)
        const formattedDate = datetimeObj.toISOString().split("T")[0]
        const formattedTime = datetimeObj.toTimeString().split(" ")[0]
        const formattedDateTime = `${formattedDate} ${formattedTime}`
        return formattedDateTime
      },
  
      async getSearches(mobile) {
        this.$store.commit('setIsLoading', true)

        if (mobile !== null) {
          const encodedsearchQuery = encodeURIComponent(this.searchQuery)
          const encodedmobile = encodeURIComponent(mobile)
          await axios
            .get(`/mapche-api/v1/search/search_by_mobile/?mobile=${encodedmobile}&search=${encodedsearchQuery}`)
              .then(response => {
                this.searches = response.data
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          await axios
          .get(`/mapche-api/v1/search/search_by_mobile/?mobile=01234567`)
            .then(response => {
              this.searches = response.data
            })
            .catch(error => {
              console.log(error)
            })
      }    
        
        this.$store.commit('setIsLoading', false)
      },
    
    }
  }
  </script>
  <style>

    @import url("https://fonts.googleapis.com/icon?family=Material+Icons");

  </style>