<template>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <div class="flex flex-wrap">
          <div class=" w-full lg:w-20/12">
            <div class="px-4 mt-4 border shadow-md">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Product Information
            </h6>
            <div class="">
              <div class="flex">
                <div class="w-4/12">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Provider:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedProvider">
                      <option disabled selected>Select a provider</option>
                      <option v-for="provider in filteredProviders" :key="provider.supplier_id" :value="provider.supplier_id"
                      >Pindrop</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedService">
                      <option disabled selected>Select a service</option>
                      <option v-for="service in services" :key="service.vendorserviceId" :value="service.vendorserviceId">{{ service.serviceName}}</option>
                    </select>
                  </div>
                </div>
              </div>              

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Service Type:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedServiceType">
                      <option selected>Internet</option>
                      <option>Layer2</option>
                    </select>
                  </div>
                </div>
              </div>         

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap mb-2">
                      <label>Feasibility Rule:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedMapRule">
                      <option disabled selected>Select a rule</option>
                      <option v-for="mapRule in mapRules" :key="mapRule.id" :value="mapRule.id">{{ getDescriptionAfterAsterisk(mapRule.description) }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="capitalize text-xs whitespace-nowrap">
                      <label>Product Name:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="text"
                    required
                    placeholder="Bronze Starter *" 
                    v-model="productName"/>
                  </div>
                </div>
              </div>                 

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Cost Code:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="text"
                    required
                    placeholder="LQ-L2-12-10 *" 
                    v-model="costCode"/>
                  </div>
                </div>
              </div>

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Capacity:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-xs font-semibold items-center">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" 
                    type="number"
                    required
                    min="1"
                    placeholder="10 Mbps" 
                    v-model="capacity"/>
                  </div>
                  Mbps
                </div>
              </div> 

              <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Contract Term:</label>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="block mr-2">
                    <input class="w-full placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mt-2 pt-2 pb-2" type="number"
                    required
                    min="1"
                    placeholder="12 Months"
                    v-model="contractTermNumber"/>
                  </div>
                  <div class="block">
                  <select class="text-xs mt-2 pt-2 pb-2 font-semibold" v-model="contractTerm">
                    <option value="days">Days</option>
                    <option value="weeks">Weeks</option>
                    <option value="months">Months</option>
                  </select>
                </div>
                </div>
              </div> 

              <div class="flex">
                <div class="w-4/12">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Active Status:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600" type="checkbox" v-model="isActive" />
                  </div>
                </div>
              </div>
            </div>  
            </div>
          </div>

          <div class="w-full lg:w-3/12">
            <div class="border px-4 mt-4 ml-4 shadow-md">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
              Pricing
            </h6>
            <div class="flex">
              <div class="w-4/12 mt-2">
                <div class="relative mb-3">
                  <div class="capitalize text-xs whitespace-nowrap mb-2">
                    <label>Currency:</label>
                  </div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="block">
                    <select class="w-full text-xs mb-2 pt-2 pb-2" v-model="selectedCurrency">
                      <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                    </select>
                  </div>                
              </div>
            </div>

            <div class="flex">
                <div class="w-4/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>VAT Inclusive:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600" type="checkbox" id="vatInclusive"
                     v-model="computedVatInclusive"
                     />
                  </div>
                </div>
              </div> 

              <div class="flex">
                <div class="w-4/12 mt-1">
                  <div class="relative mb-3">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>VAT Exclusive:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-1">
                  <div class="block">
                    <input class="focus:ring-0 text-emerald-600" type="checkbox" id="vatExclusive"
                    v-model="computedVatExclusive"
                     />
                  </div>
                </div>
              </div> 
                           
              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative mb-2">
                    <div class="text-xs whitespace-nowrap">
                      <label>VAT Percentage:</label>
                    </div>
                  </div>
                </div>
                <div class="flex text-sm font-semibold items-center">
                  <div class="block mr-2">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap pt-2 pb-2" type="number" 
                    placeholder="15%"
                    v-model="vatPercentage" 
                    :disabled="!vatExclusive"
                    :class="{ 'disabled-percentage': !vatExclusive }"
                    min="0" 
                    step="0.01"/>
                  </div>
                  %
                </div>
              </div>               


              <div class="flex">
                <div class="w-4/12 mt-4">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Monthly:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mt-2">
                  <div class="block">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="number" 
                    placeholder="R 1500"
                    v-model="sellingPrice" 
                    min="0" 
                    step="0.01" />
                  </div>
                </div>
              </div>  

              <div class="flex">
                <div class="w-4/12 mt-2">
                  <div class="relative">
                    <div class="text-xs whitespace-nowrap mb-2">
                      <label>Once-Off:</label>
                    </div>
                  </div>
                </div>
                <div class="w-1/2 mb-2">
                  <div class="block">
                    <input class="placeholder-blueGray-300 text-blueGray-600  text-xs whitespace-nowrap mb-2 pt-2 pb-2" type="number" 
                    placeholder="R 2500"
                    v-model="onceoffPrice" 
                    min="0" 
                    step="0.01" />
                  </div>
                </div>
              </div> 
            </div>
          </div>          


          <div class="w-full lg:w-5/12 ">
            <div class="border px-4 mt-4 ml-4 shadow-md">
              <div class="flex items-center justify-between">
                <div>
                  <h6 class="text-blueGray-400 text-sm mt-3 mb-2 font-bold uppercase">
                    Feasibility Priority (Global)
                  </h6>
                </div>
                <div class="flex items-center flex-row-reverse space-x-2">
                  <label class="switch">
                    <input type="checkbox" @click="toggleCheckbox">
                    <div class="slider round"></div>
                  </label>
                </div>
              </div>
            <div class="flex space-x-4" :class="{ 'opacity-50': !this.checkbox }">
              <div class="w-1/2 border mb-4 p-4 shadow-md">
                <div class="mb-0">
                  <label class="text-xs font-bold block mb-1">Options:</label>
                  <ul>
                    <li class="lead-item text-xs mb-2" v-for="mapRule in sortedMapRules" :key="mapRule.id">
                      <div class="flex items-center cursor-pointer">
                        <span class="flex-1" @click="this.checkbox ? moveToPriorityList(mapRule.id) : null"
                        :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                        >
                          {{ getDescriptionAfterAsterisk(mapRule.description) }}
                        </span>
                        <span>
                          <i
                            class="fas fa-arrow-right cursor-pointer text-emerald-600"
                            @click="this.checkbox ? moveToPriorityList(mapRule.id) : null"
                            :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                            >
                          </i>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="flex items-center justify-center">
                <i class="fas fa-angle-double-left text-xl cursor-pointer text-red-500" 
                @click="handleMoveAllToOptionsList"
                :title="this.checkbox ? 'Move All' : 'Disabled'"
                :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                ></i>
              </div>              
              <div class="w-1/2 border mb-4 p-4 shadow-md">
                <label class="text-xs font-bold block mb-2">Priority List:</label>
                <ul>
                  <li class="lead-item text-xs mb-2" v-for="(priorityOption, index) in priorityOptions" :key="priorityOption.id"
                  >
                    <div class="flex items-center">
                      <span>
                        <i
                          class="fas fa-arrow-left cursor-pointer text-red-500"
                          @click="this.checkbox ? moveToOptionsList(index) : null"
                          :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                        ></i>
                      </span>
                      <span class="flex-1 text-right" @click="this.checkbox ? moveToOptionsList(index) : null"
                      :style="{ cursor: this.checkbox ? 'pointer' : 'not-allowed' }"
                      >
                        {{getDescriptionAfterAsterisk(priorityOption.description)}} : <span class="font-bold">{{ index + 1 }}</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>



      </div> 
      <button type="submit">Save</button>
      <button type="submit">Update</button>
</div>
        
</template>
<script>
import pricing from "@/assets/img/pricing.png"
import axios from 'axios'


export default {
  
  components: {

    },
  
  data() {
    return {
      pricing,
      checkbox: false, 
      productName: '',
      vendor: [],
      selectedProvider: 'Select a provider',
      selectedServiceType: 'Internet',
      selectedUserProviders: [],
      selectedService: 'Select a service',
      selectedMapRule: 'Select a rule',
      costCode: '',
      suppliercostCode: '',
      capacity: '',
      contractTerm: 'months',
      contractTermNumber: '',
      isActive: false,
      selectedCurrency: 'ZAR',
      vatInclusive: true,
      vatExclusive: false,
      vatPercentage: '',
      sellingPrice: null,
      onceoffPrice: null,
      installationCost: '',
      providers: [],
      products: [],
      services: [],
      mapRules: [],
      currencies: [],
      selectedOptionIndex: -1,
      priorityOptions: [],
    };
  },
  async created() {
    try {
      const response = await axios.get('https://openexchangerates.org/api/currencies.json');
      this.currencies = Object.keys(response.data);
    } catch (error) {
      console.error('Error fetching currencies:', error);
    }
  },
  mounted() {
    this.getVendors()
    this.checkUserPreferencesVendor()
    if (this.product) {
      this.selectedProvider = this.product.supplier_id
      this.selectedService = this.product.service_id
      this.selectedServiceType = this.product.service_type
      this.selectedMapRule = this.product.maprule
      this.productName = this.product.name
      this.costCode = this.product.cost_code
      this.capacity = this.product.capacity
      this.contractTermNumber = this.product.term
      this.isActive = this.product.is_active
      this.selectedCurrency = this.product.currency
      this.vatInclusive = this.product.inclusive 
      this.vatExclusive = this.product.exclusive
      this.vatPercentage = this.product.percentage
      this.sellingPrice = this.product.mrc 
      this.onceoffPrice = this.product.nrc 
      
    }

  },

  
  props: {
    product: Object,
  },

  watch: {
    selectedProvider(newSelectedProvider) {
      this.fetchServicesAndMapRules(newSelectedProvider)
    },
    selectedServiceType(newSelectedProvider) {
    },
    selectedService() {
      this.filterMapRules();
    },    
    vendor: {
      deep: true,
      handler() {
        this.filterMapRules();
      },
    },
    vatExclusive(newValue) {
      if (newValue) {
        this.vatInclusive = false;
      }
    },
    vatInclusive(newValue) {
      if (newValue) {
        this.vatExclusive = false;
        this.vatPercentage = ''
      }
    },
  },
  

  
  computed: {

    filteredProviders() {
      if (this.selectedProvider) {
      return this.providers
        .filter(provider => provider.supplier_id === this.selectedProvider)
        .sort((a, b) => a.name.localeCompare(b.name));
    } else if (this.selectedUserProviders && this.selectedUserProviders.length > 0) {
      return this.providers
        .filter(provider => this.selectedUserProviders.includes(provider.supplier_id))
        .sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return [];
    }
  },
    sortedMapRules() {

      return this.mapRules.slice().sort((a, b) => {
      const priorityA = this.getPriority(a.description.toLowerCase());
      const priorityB = this.getPriority(b.description.toLowerCase());

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      if (priorityA === 7) { 
        const distanceA = this.extractDistance(a.description);
        const distanceB = this.extractDistance(b.description);
        return distanceA - distanceB;
      }

      return a.description.localeCompare(b.description);
    });

  },
    formattedCapacity() {
      return `${this.capacity} Mbps`;
    },
    computedVatInclusive: {
      get() {
        return this.vatInclusive;
      },
      set(value) {
        if (!value) {
          this.vatExclusive = true;
        }
        this.vatInclusive = value;
      },
    },
    computedVatExclusive: {
      get() {
        return this.vatExclusive;
      },
      set(value) {
        if (!value) {
          this.vatInclusive = true;
        }
        this.vatExclusive = value;
      },
    },
  },

    methods: {
   
      toggleCheckbox() {
      this.checkbox = !this.checkbox
      },      
      getPriority(description) {
        const sortOrder = [
        { keyword: 'special', priority: 1 },
        { keyword: 'ftth', priority: 2 },
        { keyword: 'fttb', priority: 3 },
        { keyword: 'broadband', priority: 4 },
        { keyword: 'precinct', priority: 5 },
        { keyword: 'on-net', priority: 6 },
        { keyword: 'onnet', priority: 6 },
        { keyword: /\((\d+)\s*m\)/, priority: 7 },
        { keyword: 'zone', priority: 8 },
        { keyword: 'metro', priority: 9 },
        { keyword: 'national', priority: 10 },
        { keyword: 'offnet', priority: 11 },
        { keyword: 'off-net', priority: 11 }
      ]        
        const match = sortOrder.find(order => {
          if (order.keyword instanceof RegExp) {
            return description.match(order.keyword);
          } else {
            return description.includes(order.keyword);
          }
        });

        return match ? match.priority : Number.POSITIVE_INFINITY;
      },

      extractDistance(description) {
        const distanceMatch = description.match(/\((\d+)m\)/);
        return distanceMatch ? parseInt(distanceMatch[1]) : Number.POSITIVE_INFINITY;
      },

      selectOption(index) {
          this.selectedOptionIndex = this.selectedOptionIndex === index ? -1 : index;
        },

        handleMoveAllToOptionsList() {
          if (this.checkbox) {
            this.moveAllToOptionsList();
          }
        },
        moveAllToOptionsList() {
            this.priorityOptions.forEach(option => {
              if (!this.mapRules.some(opt => opt.id === option.id)) {
                this.mapRules.push(option);
              }
            });
            this.priorityOptions = []; 
        },        
        moveToPriorityList(mapRuleId) {
          const selectedOption = this.mapRules.find(option => option.id === mapRuleId);
          if (selectedOption) {
            if (!this.priorityOptions.some(option => option.id === mapRuleId)) {
              this.priorityOptions.push(selectedOption);
              const index = this.mapRules.findIndex(option => option.id === mapRuleId);
              this.mapRules.splice(index, 1);
            } else {
              console.log('Option already in priorityOptions');
            }
          }
        },
        moveToOptionsList(index) {
          const selectedOption = this.priorityOptions[index];
          this.mapRules.push(selectedOption);
          this.priorityOptions.splice(index, 1);
        },
      handleVatInclusiveToggle() {
        if (this.computedVatInclusive) {
          this.computedVatExclusive = false;
        }
      },

      handleVatExclusiveToggle() {
        if (this.computedVatExclusive) {
          this.computedVatInclusive = false;
        }
      },
      getDescriptionAfterAsterisk(description) {
        const parts = description.split('*');
        return parts.length > 1 ? parts[1] : description;
      },      
      filterMapRules() {
        if (!this.selectedService) {
          this.mapRules = [];
          return;
        }

        this.mapRules = this.vendor.maprules.filter(rule => rule.service_id === this.selectedService);
      },

      async fetchServicesAndMapRules(vendorId) {
        this.$store.commit('setIsLoading', true)
        this.services = []
        try {
          const [serviceResponse, mapRuleResponse] = await Promise.all([
            axios.get(`/vendor/vendors/${vendorId}/services/`),
            axios.get(`/vendor/vendors/${vendorId}/maprules/`),
          ]);

            this.vendor.services = serviceResponse.data;
            this.vendor.maprules = mapRuleResponse.data;
            this.checkUserPreferences(vendorId);

          } catch (error) {
            console.error(`Error fetching services and map rules for vendor ${vendorId}:`, error);
          }

          this.$store.commit('setIsLoading', false)
        },  

        getVendors() {
          this.$store.commit('setIsLoading', true)
          axios.get('/vendor/vendors')
            .then(response => {
              this.providers = response.data
            })
            .catch(error => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

        checkUserPreferencesVendor() {
          this.$store.commit('setIsLoading', true)
          axios
            .get('/mapche-api/v1/user-preferences/')
            .then((response) => {
              this.selectedUserProviders = []
              this.selectedUserProviders = response.data.map(item => item.supplier_id)
             })
            .catch((error) => {
              console.error(error);
            });
            this.$store.commit('setIsLoading', false)  
        },

      checkUserPreferences(vendorId) {
        axios
          .get('/mapche-api/v1/user-preferences/')
          .then((response) => {
            this.selectedVendors = response.data.map(item => item.supplier_id);

            this.selectedVendors.forEach(supplier_id => {
              if (supplier_id === vendorId) {
                  response.data.forEach(item => {
                    const userServices = item.services;
                    const uservendor_id = item.supplier_id;
                    userServices.forEach(service_id => {
                      if (uservendor_id === vendorId) {
                        this.vendor.services.forEach(service => {
                          if (service.id === service_id) {
                            const serviceName = service.name
                            const vendorserviceId = service.service_id
                            this.services.push({ vendorserviceId, serviceName });
                            this.selectedService = this.services[0].vendorserviceId;
                          }

                        })
                      }
                    })
                  })

              }
            })
          })
          .catch((error) => {
            console.error(error);
          });
        },
      
    }
};
</script>
<style scoped>
  .opacity-50 {
    opacity: 0.5;
    cursor: not-allowed;
  }  
  .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(239,68,68,1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: rgba(5,150,105,1)
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .lead-item {
    padding: 10px;
    cursor: pointer;
  }

  .lead-item:hover {
    background-color: #e2e8f0;
  }

  .disabled-percentage {
    background-color: rgba(241, 245, 249, 1);
    cursor: not-allowed;
    background-size: contain; 
    background-repeat: no-repeat;
    background-position: center center;
  }

</style>