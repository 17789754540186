<template>
  <!-- Header -->
  <div class="relative md:pt-32 pb-32 pt-12">
    <div
          class="absolute top-0 w-full h-full bg-no-repeat bg-full bg-pink"
          :style="`background-image: url('${registerBg2}');`"
        >
        </div>
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="SEARCHES"
              :statTitle="`${this.searchesstatTitle}`"
              :statArrow="`${this.searchesstatArrow}`"
              :statPercent="`${this.searchesstatPercent}`"
              :statPercentColor="`${this.searchesstatPercentColor}`"
              statDescripiron="Since last month"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="NEW USERS"
              :statTitle="`${this.userstatTitle}`"
              :statArrow="`${this.userstatArrow}`"
              :statPercent="`${this.userstatPercent}`"
              :statPercentColor="`${this.userstatPercentColor}`"              
              statDescripiron="Since last week"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="LEADS"
              :statTitle="`${this.newLeadsCount}`"
              :statArrow="`${this.leadstatArrow}`"
              :statPercent="`${this.leadstatPercent}`"
              :statPercentColor="`${this.leadstatPercentColor}`"
              statDescripiron="Since yesterday"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <card-stats
              statSubtitle="PERFORMANCE"
              :statTitle="`${this.conversionRate}`"
              :statArrow="`${this.performancestatArrow}`"
              :statPercent="`${this.conversionRateChange}`"
              :statPercentColor="`${this.performancestatPercentColor}`"
              statDescripiron="Since last month"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-600"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CardStats from "@/components/Cards/CardStats.vue"
import registerBg2 from "@/assets/img/register_bg_2.png"

export default {
  name: 'HeaderStats',

  mounted() {
    this.getLeads()
    
  },
  computed: {
    conversionRate() {
      if (this.thisMonth > 0) {
        const conversionRate = (this.newLeadsCount / this.thisMonth) * 100;
        return conversionRate.toFixed(2);
      } else {
        return '0.00'; 
      }
    },

    conversionRateChange() {

      const previousConversionRate = this.previousMonth !== 0
      ? (this.previousLeadsCount / this.previousMonth) * 100
      : 0;

      const currentConversionRate = parseFloat(this.conversionRate);
      const percentageChange = previousConversionRate !== 0
      ? ((currentConversionRate - previousConversionRate) / previousConversionRate) * 100
      : 0;
      return percentageChange.toFixed(2);
    },
  },
  components: {
    CardStats,
  },
  data() {
    return {
      registerBg2,
      searches: [],
      thisMonth: 0,
      previousMonth: 0,
      previousLeadsCount: 0,
      thisMonthLeads: 0,
      thisSevenDays: 0,
      this2Weeks: 0,
      searchesstatTitle: 0,
      searchesstatArrow: '',
      searchesstatPercent: 0,
      searchesstatPercentColor: '',
      newLeadsCount: 0,
      leadstatArrow: '',
      leadstatPercent: 0,
      leadstatPercentColor: '',
      performancestatPercentColor: '',
      performancestatArrow: '',
      userstatTitle: 0,
      userstatArrow: '',
      userstatPercent: 0,
      userstatPercentColor: '',
      numberValue: '',
      numArray: [],
      numberValue2: '',
      numArray2: []
    };
  },

  methods: {
    calculatePercentageChange(newLeads, previousLeads) {
  if (previousLeads === 0) {
    return 100; 
  } else {
    const percentageChange = ((newLeads - previousLeads) / Math.abs(previousLeads)) * 100;
    return percentageChange;
  }
},

    async getLeads() {
      this.$store.commit('setIsLoading', true);

      const today = new Date();
      today.setHours(0, 0, 0, 0); 

      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1); 


      try {
        const response = await axios.get('/mapche-api/v1/leads/');
        this.leads = response.data.results;

        if (this.leads.length > 0) {
          for (var i = 0; i < response.data.results.length; i++) {
            const leadData = new Date(response.data.results[i].created_at);
            leadData.setHours(0, 0, 0, 0);

            const isValidYesterday = leadData  >= yesterday && leadData <= today;

            if (isValidYesterday) {
              this.newLeadsCount++;
            }
          }

          const leadsMom = this.calculatePercentageChange(this.newLeadsCount, this.previousLeadsCount);

          if (leadsMom >= 0) {
              this.leadstatArrow = 'up'
              this.leadstatPercent = leadsMom.toFixed(2)
              this.leadstatPercentColor = "text-emerald-500"   
            } else if (leadsMom < 0) {
              this.leadstatArrow = 'down'
              this.leadstatPercent = leadsMom.toFixed(2)
              this.leadstatPercentColor = "text-red-500"
            }
            const teamCreatedBy = this.$store.state.team.created
            const userId = this.$store.state.user.id
            
              if (teamCreatedBy != userId) {
                this.getSearches()
              } else {
                this.getSearches()
              }



        } else {
          const teamCreatedBy = this.$store.state.team.created
            const userId = this.$store.state.user.id
            
              if (teamCreatedBy != userId) {
                this.getSearches()
              } else {
                this.getSearches()
              }
        }
      } catch (error) {
        console.error('Error fetching leads data:', error);
      } finally {
        this.$store.commit('setIsLoading', false);
      }
    },

    async getSearchesbulk() {
      this.$store.commit('setIsLoading', true)

      const mobileNumbersString = this.assignedSearches.join(','); 

      const formData = {
          mobile: mobileNumbersString,
      };

      axios
        .get('/mapche-api/v1/search/search_by_mobilebulk/', { params: formData })
        .then(response => {
          this.searches = response.data
          this.thisMonth = 0
          this.previousMonth = 0
          this.thisSevenDays = 0
          this.this2Weeks = 0
          for (var i = 0; i < response.data.length; i++) {
            const searchData = new Date(response.data[i].created_at)
            const today = new Date();

            const lastSevenDays = new Date(today);
            lastSevenDays.setDate(today.getDate() - 7);
            const last2Weeks = new Date(today);
            last2Weeks.setDate(today.getDate() - 14);

            const lastThirtyDays = new Date(today);
            lastThirtyDays.setDate(today.getDate() - 30);

            const lastSixtyDays = new Date(today);
            lastSixtyDays.setDate(today.getDate() - 60);            

            const isValidSevenDays = searchData >= lastSevenDays && searchData <= today;
            const isValid2Weeks = searchData >= last2Weeks && searchData <= lastSevenDays;
            const isValidThirtyDays = searchData >= lastThirtyDays && searchData <= today;
            const isValidSixtyDays = searchData >= lastSixtyDays && searchData <= lastThirtyDays;

            if (isValidThirtyDays) {
              this.thisMonth = (this.thisMonth + 1)
            } 
            if (isValidSixtyDays) {
              this.previousMonth = (this.previousMonth + 1)
            }
            if (isValidSevenDays) {
              this.numberValue = response.data[i].infoip
              if (this.numberValue != null) {
                this.numArray.push(this.numberValue)
                this.numArray = [...new Set(this.numArray)]
               }
            }
            if (isValid2Weeks) {
              this.numberValue2 = response.data[i].infoip
              if (this.numberValue2 != null) {
                this.numArray2.push(this.numberValue2)
                this.numArray2 = [...new Set(this.numArray2)]
               }
            }
          } 
          this.thisSevenDays = (this.numArray)
          this.this2Weeks = (this.numArray2)
          
          if (this.previousMonth == 0 && this.thisMonth != 0) {
            var searchmom = ((this.thisMonth)/this.thisMonth * 100)      
          } else if (this.thisMonth == 0 && this.previousMonth !=0) {
            var searchmom = ((this.thisMonth - this.previousMonth)/this.previousMonth * 100)      
          } else if (this.thisMonth == 0 && this.previousMonth == 0) {
            var searchmom = 0
          } else {
            var searchmom = ((this.thisMonth - this.previousMonth)/this.previousMonth * 100)      
          }

          if (this.this2Weeks.length == 0 && this.thisSevenDays.length != 0) {
            var usermom = ((this.thisSevenDays.length)/this.thisSevenDays.length * 100)      
          } else if (this.thisSevenDays.length == 0 && this.this2Weeks.length !=0) {
            var usermom = ((this.thisSevenDays.length - this.this2Weeks.length)/this.this2Weeks.length * 100)      
          } else if (this.thisSevenDays.length == 0 && this.this2Weeks.length == 0) {
            var usermom = 0
          } else {
            var usermom = ((this.thisSevenDays.length - this.this2Weeks.length)/this.this2Weeks.length * 100)      
          }

          if (searchmom >= 0) {
            this.searchesstatTitle = this.thisMonth
            this.searchesstatArrow = 'up'
            this.searchesstatPercent = searchmom
            this.searchesstatPercentColor = "text-emerald-500"   
          } else if (searchmom < 0) {
            this.searchesstatTitle = this.thisMonth
            this.searchesstatArrow = 'down'
            this.searchesstatPercent = searchmom
            this.searchesstatPercentColor = "text-red-500"
          }

          if (usermom >= 0) {
            this.userstatTitle = this.thisSevenDays.length
            this.userstatArrow = 'up'
            this.userstatPercent = usermom.toFixed(2)
            this.userstatPercentColor = "text-emerald-500"   
          } else if (usermom < 0) {
            this.userstatTitle = this.thisSevenDays.length
            this.userstatArrow = 'down'
            this.userstatPercent = usermom.toFixed(2)
            this.userstatPercentColor = "text-red-500"
          }

          const percentageChange = parseFloat(this.conversionRateChange);
          this.performancestatPercentColor = percentageChange >= 0 ? "text-emerald-500" : "text-red-500";
          this.performancestatArrow = percentageChange >= 0 ? 'up' : 'down';
        })
        .catch(error => {
          console.log(error)
        })

      
      this.$store.commit('setIsLoading', false)
    },


    async getSearches() {
      this.$store.commit('setIsLoading', true)

      axios
        .get('/mapche-api/v1/search/')
        .then(response => {
          this.searches = response.data
          this.thisMonth = 0
          this.previousMonth = 0
          this.thisSevenDays = 0
          this.this2Weeks = 0
          for (var i = 0; i < response.data.length; i++) {
            const searchData = new Date(response.data[i].created_at)
            const today = new Date();
  
            const lastSevenDays = new Date(today);
            lastSevenDays.setDate(today.getDate() - 7);

            const last2Weeks = new Date(today);
            last2Weeks.setDate(today.getDate() - 14);

            const lastThirtyDays = new Date(today);
            lastThirtyDays.setDate(today.getDate() - 30);

            const lastSixtyDays = new Date(today);
            lastSixtyDays.setDate(today.getDate() - 60);


            const isValidSevenDays = searchData >= lastSevenDays && searchData <= today;
            const isValid2Weeks = searchData >= last2Weeks && searchData <= lastSevenDays;
            const isValidThirtyDays = searchData >= lastThirtyDays && searchData <= today;
            const isValidSixtyDays = searchData >= lastSixtyDays && searchData <= lastThirtyDays;

            if (isValidThirtyDays) {
              this.thisMonth = (this.thisMonth + 1)
            } 
            if (isValidSixtyDays) {
              this.previousMonth = (this.previousMonth + 1)
            }
            if (isValidSevenDays) {
              this.numberValue = response.data[i].infoip

              if (this.numberValue != null) {
                this.numArray.push(this.numberValue)
                this.numArray = [...new Set(this.numArray)]
               }
            }
            if (isValid2Weeks) {
              this.numberValue2 = response.data[i].infoip
              if (this.numberValue2 != null) {
                this.numArray2.push(this.numberValue2)
                this.numArray2 = [...new Set(this.numArray2)]
               }
            }
          } 
          this.thisSevenDays = (this.numArray)
          this.this2Weeks = (this.numArray2)
          
          if (this.previousMonth == 0 && this.thisMonth != 0) {
            var searchmom = ((this.thisMonth)/this.thisMonth * 100)      
          } else if (this.thisMonth == 0 && this.previousMonth !=0) {
            var searchmom = ((this.thisMonth - this.previousMonth)/this.previousMonth * 100)      
          } else if (this.thisMonth == 0 && this.previousMonth == 0) {
            var searchmom = 0
          } else {
            var searchmom = ((this.thisMonth - this.previousMonth)/this.previousMonth * 100)      
          }
          
          if (this.this2Weeks.length == 0 && this.thisSevenDays.length != 0) {
            var usermom = ((this.thisSevenDays.length)/this.thisSevenDays.length * 100)      
          } else if (this.thisSevenDays.length == 0 && this.this2Weeks.length !=0) {
            var usermom = ((this.thisSevenDays.length - this.this2Weeks.length)/this.this2Weeks.length * 100)      
          } else if (this.thisSevenDays.length == 0 && this.this2Weeks.length == 0) {
            var usermom = 0
          } else {
            var usermom = ((this.thisSevenDays.length - this.this2Weeks.length)/this.this2Weeks.length * 100)      
          }

          if (searchmom >= 0) {
            this.searchesstatTitle = this.thisMonth
            this.searchesstatArrow = 'up'
            this.searchesstatPercent = searchmom.toFixed(2)
            this.searchesstatPercentColor = "text-emerald-500"   
          } else if (searchmom < 0) {
            this.searchesstatTitle = this.thisMonth
            this.searchesstatArrow = 'down'
            this.searchesstatPercent = searchmom.toFixed(2)
            this.searchesstatPercentColor = "text-red-500"
          }

          if (usermom >= 0) {
            this.userstatTitle = this.thisSevenDays.length
            this.userstatArrow = 'up'
            this.userstatPercent = usermom
            this.userstatPercentColor = "text-emerald-500"   
          } else if (usermom < 0) {
            this.userstatTitle = this.thisSevenDays.length
            this.userstatArrow = 'down'
            this.userstatPercent = usermom
            this.userstatPercentColor = "text-red-500"
          }
          
          const percentageChange = parseFloat(this.conversionRateChange);
          this.performancestatPercentColor = percentageChange >= 0 ? "text-emerald-500" : "text-red-500";
          this.performancestatArrow = percentageChange >= 0 ? 'up' : 'down';
        })
        .catch(error => {
          console.log(error)
        })

      
      this.$store.commit('setIsLoading', false)
    }
  
  }
};
</script>
