<template>
  <div id="app" :style="{ marginTop: isLoading ? '0' : 'auto' }">
    <div class="is-loading-bar has-text-centered " v-bind:class="{'is-loading': $store.state.isLoading}">
      <div class="lds-dual-ring"></div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'App',
  data() {
    return {
      isLoading: false 
    };
  },
  async beforeCreate() {
    await this.$store.commit('initializeStore');

    if (this.$store.state.token) {
      axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token;
    } else {
      axios.defaults.headers.common['Authorization'] = "";
    }
  },
  watch: {
    '$store.state.isLoading'(newValue) {
      this.isLoading = newValue;
      if (newValue) {
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style lang="scss">
@import '../node_modules/bulma';

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring::after {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.is-loading-bar {
    height: 0rem;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &.is-loading {
        height: 80px;
    }
}

</style>